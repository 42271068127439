import "./ProductItem.scss";
import translate from "../../../translate";
import CurrencyExchange from "../CurrencyExchange";

const tagColor: any = {
  "PI": {
    color: "rgb(0, 118, 227)",
    backgroundColor: "rgba(0, 118, 227, 0.4)"
  },
  "MA": {
     color: "#ff1e54",
    backgroundColor: "rgba(255, 30, 84,0.4)"
  },
  "PC": {
    color: "#D8225E",
    backgroundColor: "rgba(216,34,94, 0.4)"
  },
  "FI": {
    color: "rgb(109,80,165)",
    backgroundColor: "rgba(109,80,165, 0.4)"
  },
  "EQ": {
    color: "rgb(85,89,180)",
    backgroundColor: "rgba(85,89,180, 0.4)"
  },
  "RI": {
    color: "rgb(0,67,133)",
    backgroundColor: "rgba(0,67,133, 0.4)"
  }
}

const ProductItem = ({ item, onClick }: any) => {
  return (
    <div
      className="cies-product-container"
      onClick={onClick}
      style={{ borderColor: item?.color, backgroundImage: item?.backgroundImage }}
    >
      <div style={{ fontWeight: "600", color: "#37454b" }} className="cies-product-title-container">{translate(item?.name)}</div>
      {(!item?.comingSoon || (item?.comingSoon && item?.isShow)) && (
        <>
          <div
            style={{
              marginTop: "5px",
              paddingBottom: "15px",
              borderBottom: "1px solid #aaa",
              marginBottom: "15px",
              display:"flex", 
              alignItems:"center"
            }}
          >
            {item?.tags?.map((tag: string, index: number) => {
              return <div className={`cies-tag-${index}`} style={{color:tagColor[tag]?.color, backgroundColor: tagColor[tag]?.backgroundColor}}>{tag}</div>;
            })}
            {/* <div className={`cies-tag-2`} >{translate(item?.tagLabel)}</div> */}
          </div>
          <div className="cies-product-description-container">
            {translate(item?.description)}
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <div style={{ flex: "1 1 auto", display: "flex" }}>
              <div>
                {item?.riskLevel &&  <div style={{ color: "#ff1e54" }}>{item?.riskLevel}/7</div>}
                {!item?.riskLevel &&  <div style={{ color: "rgb(166, 166, 166)" }}>N/A</div>}
               
                <div
                  style={{
                    fontSize: "10px",
                    color: "#A6A6A6",
                    fontWeight: "600",
                  }}
                >
                  {translate("Risk Level")}
                </div>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <div style={{ color: "rgb(0, 118, 227)" }}>
                  HK$<CurrencyExchange currency={item?.currency} amount={item?.minimumInitialAmount}/>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    color: "#A6A6A6",
                    fontWeight: "600",
                  }}
                >
                  {translate('Minimum Initial')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {item?.comingSoon && !item?.isShow && (
        <div
          style={{
            marginTop: "20px",
            paddingBottom: "15px",
            paddingTop: "20px",
            borderTop: "1px solid #aaa",
            marginBottom: "15px",
          }}
        >
          {translate('Coming Soon')}
        </div>
      )}
    </div>
  );
};

export default ProductItem;
