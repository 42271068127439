import { Checkbox } from "@chakra-ui/react";
import "./ComponentCheckbox.scss";

const ComponentCheckbox = (props: any) => {
  const { isChecked, title, onHandleChange } = props;
  return (
    <div className="component-checkbox">
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => {
          onHandleChange(e.target.checked);
        }}
      >
        {title}
      </Checkbox>
    </div>
  );
};

export default ComponentCheckbox;
