import { Link, useNavigate } from "react-router-dom";
import DashboardContainer from "../../sub-components/DashbordContainer";
import ComponentButton from "../../atoms/ComponentButton";
import { Grid } from "@chakra-ui/react";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { ALTERNATIVES_PRODUCT_URL } from "../../routes";
import translate from "../../../translate";

import '../FoStrategy/FoStrategy.scss'
import './Alternatives.scss'

const dummyData = [
  {
    id: "private-credit",
    content:
      "",
    title: translate("Private Credit"),
    backgroundGradient:
      "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
  },
  {
    id: "real-estate",
    content:
      "",
    title: translate("Real Estate"),
    backgroundGradient:
      "linear-gradient(315deg, #F44369 16.23%, #392E27 80.35%)",
  },
  {
    id: "fof",
    content:
      "",
    title: translate("FOF"),
    backgroundGradient:
      "linear-gradient(135deg, #60482B 19.05%, #FF930F 84.88%)",
  },
  {
    content:
      "",
    title: translate("Private Equity"),
    // backgroundGradient:
    //   "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
    backgroundGradient: "#252525",
    comingSoon: true,
  },
  {
    content:
      "",
    title: translate("Private Deals"),
    // backgroundGradient:
    //   "linear-gradient(135deg, #60482B 19.05%, #FF930F 84.88%)",
    backgroundGradient: "#252525",
    comingSoon: true,
  },

  {
    content:
      "",
    title: translate("Hedge Funds"),
    // backgroundGradient:
    //   "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
    backgroundGradient: "#252525",
    comingSoon: true,
  },

  {
    content:
      "",
    title: translate("Other"),
    // backgroundGradient:
    //   "linear-gradient(315deg, #F44369 16.23%, #392E27 80.35%)",
    backgroundGradient: "#252525",
    comingSoon: true,
  },
];
const dummyDataCard = [
  {
    name: "Japan: The Emerging Hotspot for Global Asset Allocation",
    link: "https://www.dlifo.com/blog/japan-the-emerging-hotspot-for-global-asset-allocation",
  },
  {
    name: "What is the risk of the United States defaulting?",
    link: "https://www.dlifo.com/blog/what-is-the-risk-of-the-united-states-defaulting",
  },
  {
    name: "Reconsidering the Perception of Luxury Homes in Hong Kong",
    link: "https://www.dlifo.com/blog/reconsidering-the-perception-of-luxury-homes-in-hong-kong",
  },
  {
    name: "Rare Things are Precious, Just in Time for the Cold Winter",
    link: "https://www.dlifo.com/blog/rare-things-are-precious-just-in-time-for-the-cold-winter",
  },
];

const Alternatives = () => {
  const navigate = useNavigate();

  const getList = () => {
    return dummyData.map((item) => {
      return (
        <ComponentFoCard
          title={item.title}
          content={item?.content}
          backgroundGradient={item.backgroundGradient}
          commingSoon={item.comingSoon}
          onClick={() => {
            navigate(`${ALTERNATIVES_PRODUCT_URL}?id=${item?.id}`);
          }}
        />
      );
    })
  }

  return (
    <DashboardContainer>
      <div className="fo-container">
        <div className="fo-header">
          <div className="fo-heading">
            <h2>{translate("alternatives")}</h2>
            {/* <p>
              That determine your eligibility for private investments and
              expanded pricing options
            </p> */}
          </div>
          <div className="component-btn">
            <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
              <ComponentButton title={translate("TALK TO AN EXPERT")} blackBgBtn={true} />
            </a>

            <p>{translate("FOR A PERSONALISED APPROACH")}</p>
          </div>
        </div>
        <div className="alternatives-fo-cards">
          <Grid templateColumns="repeat(4, 1fr)" gap={15}>
            {getList()}
          </Grid>
        </div>
        <div className="alternatives-fo-cards-tablet">
          <Grid templateColumns="repeat(3, 1fr)" gap={15}>
          {getList()}
          </Grid>
        </div>
        <div className="alternatives-fo-cards-mobile">
          {/* <Grid templateColumns="repeat(4, 1fr)" gap={15}> */}
          {getList()}
          {/* </Grid> */}
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Alternatives;
