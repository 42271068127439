import { FormControl, useToast } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import MainContainer from "../../sub-components/MainContainer";
import ComponenetInput from "../../atoms/ComponentInput";
import { useNavigate } from "react-router-dom";
import { HOME_URL, LOGIN_URL, SIGN_UP_INVESTOR_STATUS_URL } from "../../routes";
import ComponentProgressBar from "../../atoms/ComponentProgressBar";
import "./PersonalInformation.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_USER_MUTATION } from "../../graphql/user.mutation";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import translate from "../../../translate";

const PersonalInformation = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      console.log("get User", data);
      if (data?.user?.firstName) {
        setFirstName(data?.user?.firstName);
      }
      if (data?.user?.middleName) {
        setMiddleName(data?.user?.middleName);
      }
      if (data?.user?.lastName) {
        setLastName(data?.user?.lastName);
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // toast.error(err.graphQLErrors[0]?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 1,
      //   theme: "dark",
      // });
    },

    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const [UpdateUser, { data, loading, error }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        console.log("USer Data", data);
        navigate(HOME_URL);
      },
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);
        // toast({
        //   title: err.graphQLErrors[0]?.message,
        //   status: "error",
        //   isClosable: true,
        // });

        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
      },
    }
  );

  console.log("I am getting called2");
  const onClickEvent = () => {
    UpdateUser({
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          onboardStep: 2,
        },
      },
    });
  };
  return (
    <MainContainer>
      <div className="sign-up-phone">
        <h2>{translate("personal_information")}</h2>
        <p>{translate("use_a_legal_name")}</p>
        <div className="sign-up-component">
          <FormControl style={{ width: "100%" }}>
            <ComponenetInput
              placeholder={translate("first_name")}
              value={firstName}
              handleChange={(e: any) => {
                setFirstName(e.target.value);
              }}
            />
            <div style={{ display: "flex", gap: "40px", marginTop: "40px" }}>
              <ComponenetInput
                placeholder={translate("middle_name")}
                value={middleName}
                handleChange={(e: any) => {
                  setMiddleName(e.target.value);
                }}
              />
              <ComponenetInput
                placeholder={translate("last_name")}
                value={lastName}
                handleChange={(e: any) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </FormControl>

          <ComponentButton title={translate("next")} onClick={onClickEvent} />
        </div>
        {/* <ComponentProgressBar defaultValue={1} /> */}
      </div>
    </MainContainer>
  );
};

export default PersonalInformation;
