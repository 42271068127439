import "./ComponentBlackCard.scss"

const ComponentBlackCard = (props:any) => {
    const {children, style} = props;
    return(
        <div className="black-card" style={style}>
            {children}
        </div>
    )
}

export default ComponentBlackCard;