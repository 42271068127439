import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ACCOUNT_URL,
  ALTERNATIVES_URL,
  FO_SERVICES_PRODUCT_COUNTRY_URL,
  FO_SERVICES_PRODUCT_URL,
  FO_SERVICES_URL,
  FO_STRATEGY_PRODUCT_URL,
  FO_STRATEGY_URL,
  HOME_URL,
  PORTFOLIO_URL,
  SIGN_UP_INVESTOR_STATUS_URL,
  SIGN_UP_LEGAL_AGGREMENT__URL,
  SIGN_UP_PERSONAL_INFO_URL,
  SIGN_UP_PHONE_URL,
  SIGN_UP_PHONE_VERIFICATION_URL,
  SIGN_IN_VERIFICATION_URL,
  SIGN_UP_URL,
  LOGIN_URL,
  SECURITY_ACCOUNT_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  AI_BOT_URL,
  ALTERNATIVES_PRODUCT_URL,
  FO_SERVICES_TRUST_PLANNING,
  VIDEO_VERIFICATION_URL,
  LEGAL_GPT_URL,
  INVESTMENT_GPT_URL,
  CORPORATE_FINANCE_GPT_URL,
  DOCUMENT_UPLOAD_URL,
  GO_TO_LEGAL_URL,
  INVESTMENT_URL,
  EDIT_FORM_URL,
  ADMIN_FILE_UPLOAD,
  PRE_LANDING_PAGE,
  CIES_URL,
  AI_STRATEGIES_URL,
  SECURITY_DISCLAIMER_URL,
  VIDEO_FORM_TEST
} from "./routes";
import SignUp from "./pages/SignUp";
import SignUpPhone from "./pages/SIgnUpPhone";
import SignUpPhoneVerification from "./pages/SignUpPhoneVerification";
import PersonalInformation from "./pages/PersonalInformation";
import InvestorStatus from "./pages/InvestorStatus";
import LegalAggrement from "./pages/LegalAggrement";
import Home from "./pages/Home";
import Account from "./pages/Accounts";
import FoStrategy from "./pages/FoStrategy";
import FoStrategyProduct from "./pages/FoStrategyProduct";
import FoServices from "./pages/FoServices";
import FoServicesProduct from "./pages/FoServicesProduct";
import FoServicesProductCountry from "./pages/FoServicesProductCountry";
import Alternatives from "./pages/Alternatives";
import SecurityAccount from "./pages/SecurityAccount";
import Portfolio from "./pages/Portfolio";
import Auth from "./auth";
import ForgotPassword from "./pages/ForgetPassword";
import AIBot from "./pages/AIBot";
import AlternativesProduct from "./pages/AlternativesProduct";
import TrustPlanning from "./pages/TrustPlanning";
import VideoVerification from "./pages/VideoVerification";
import LegalAIBot from "./pages/LegalAIBot";
import DocumentUpload from "./pages/DocumentUpload";
import GoToLegal from "./pages/GoToLegal";
import InvestmentForm from "./pages/InvestmentForm";
import EditForm from "./pages/EditForm";
import { useEffect, useState } from "react";
import AdminUpload from "./pages/AdminUpload";
import PreLandingPage from "./pages/PreLanding";
import Cies from "./pages/CIES";
import AIStrategies from "./pages/AIStrategies";
import SecurityDisclaimer from './pages/SecurityDisclaimer'
import VideoFormTest from "./pages/VideoFormTest";

const HomeWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <Home />
    </Auth>
  );
};

const SignUpWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <SignUp type="signup" />
    </Auth>
  );
};

const LoginWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <SignUp type="login" />
    </Auth>
  );
};

const ForgotPasswordWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <ForgotPassword />
    </Auth>
  );
};

const ResetPasswordWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <SignUp type="login" />
    </Auth>
  );
};

const SignUpOTPWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <SignUpPhoneVerification />
    </Auth>
  );
};

const SignInOTPWrapper = () => {
  return (
    <Auth allow="$unauthenticated" redirectTo={HOME_URL}>
      <SignUpPhoneVerification type="login" />
    </Auth>
  );
};

const PersonalInformationWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <PersonalInformation />
    </Auth>
  );
};

const InvestorStatusWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <InvestorStatus />
    </Auth>
  );
};

const LegalStatusWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <LegalAggrement />
    </Auth>
  );
};

const GoToLegalWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <GoToLegal />
    </Auth>
  );
};

const DocumentUploadWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <DocumentUpload />
    </Auth>
  );
};

const AccountWrapper = ({ afeLoginData, setAfeLoginData, setSocket, socket }: any) => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <Account afeLoginData={afeLoginData} setAfeLoginData={setAfeLoginData}  setSocket={setSocket}
        socket={socket}/>
    </Auth>
  );
};

const SecurityAccountWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <SecurityAccount />
    </Auth>
  );
};


const SecurityDisclaimerWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <SecurityDisclaimer />
    </Auth>
  );
};


const PortfolioWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <Portfolio />
    </Auth>
  );
};

const AlternativesWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <Alternatives />
    </Auth>
  );
};

const AlternativesProductWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <AlternativesProduct />
    </Auth>
  );
};

const CIESWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <Cies />
    </Auth>
  );
};

const FoStrategyWrapper = ({ afeLoginData, setAfeLoginData }: any) => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <FoStrategy
        afeLoginData={afeLoginData}
        setAfeLoginData={setAfeLoginData}
      />
    </Auth>
  );
};

const FoServicesWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <FoServices />
    </Auth>
  );
};

const AIStrategiesWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <AIStrategies />
    </Auth>
  );
};

const FoServicesTrustPlanningWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <TrustPlanning />
    </Auth>
  );
};

const FoStrategyProductWrapper = ({ afeLoginData, setAfeLoginData, setSocket, socket }: any) => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <FoStrategyProduct
        afeLoginData={afeLoginData}
        setAfeLoginData={setAfeLoginData}
        setSocket={setSocket}
        socket={socket}

      />
    </Auth>
  );
};

const FoServicesProductWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <FoServicesProduct />
    </Auth>
  );
};

const FoServicesProductCountryWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <FoServicesProductCountry />
    </Auth>
  );
};

const InvestmentWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <InvestmentForm />
    </Auth>
  );
};

const AIBotWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <AIBot />
    </Auth>
  );
};

const VideoVerificationWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <VideoVerification />
    </Auth>
  );
};

const EditFormWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <EditForm />
    </Auth>
  );
};

const AdminFileUploadWrapper = () => {
  return (
    <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
      <AdminUpload />
    </Auth>
  );
};

export function App() {
  const [afeLoginData, setAfeLoginData] = useState(null);
  const [socket, setSocket] = useState<any>(null);
  const [afeLoginId, setAfeLoginId] = useState("")
  console.log("on app Changed");
  useEffect(() => {
    if (!document.getElementById("eself-open-button")) {
      const script = document.createElement("script");
      const lang = localStorage.getItem("user-lang") || "en"
      if(lang === "en"){
        script.src = "https://assets.eself.ai/dl-holdings-en/prod/bundle.js";
      } else {
        script.src = "https://assets.eself.ai/dl-holdings-zh/prod/bundle.js"
      }
   
     
      document.head.appendChild(script);
    }

    return () => {
      const scriptElem: any = document.getElementById("eself-open-button");
      if (scriptElem) {
        scriptElem.remove();
      }
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
        path='/'
        render={(props: any) => (
         
        )}
      /> */}

        {/* <Route path={"/"} element={ <Auth allow='$authenticated' redirectTo={SIGN_UP_URL}>
            <Home />
          </Auth>} /> */}
        <Route path={"/"} element={<HomeWrapper />} />
        <Route path={SIGN_UP_URL} element={<SignUpWrapper />} />
        <Route path={LOGIN_URL} element={<LoginWrapper />} />
        <Route path={FORGOT_PASSWORD_URL} element={<ForgotPasswordWrapper />} />
        {/* <Route path={SIGN_UP_PHONE_URL} element={<SignUpPhone />} /> */}
        <Route
          path={SIGN_UP_PHONE_VERIFICATION_URL}
          element={<SignUpOTPWrapper />}
        />
        <Route path={SIGN_IN_VERIFICATION_URL} element={<SignInOTPWrapper />} />
        <Route
          path={SIGN_UP_PERSONAL_INFO_URL}
          element={<PersonalInformationWrapper />}
        />
        <Route
          path={SIGN_UP_INVESTOR_STATUS_URL}
          element={<InvestorStatusWrapper />}
        />
        <Route path={DOCUMENT_UPLOAD_URL} element={<DocumentUploadWrapper />} />
        <Route path={GO_TO_LEGAL_URL} element={<GoToLegalWrapper />} />
        <Route
          path={SIGN_UP_LEGAL_AGGREMENT__URL}
          element={<LegalStatusWrapper />}
        />
        <Route path={HOME_URL} element={<HomeWrapper />} />
        <Route
          path={ACCOUNT_URL}
          element={
            <AccountWrapper
              afeLoginData={afeLoginData}
              setAfeLoginData={setAfeLoginData}
              setSocket={setSocket}
              socket={socket}
            />
          }
        />
        <Route path={ALTERNATIVES_URL} element={<AlternativesWrapper />} />
        <Route
          path={ALTERNATIVES_PRODUCT_URL}
          element={<AlternativesProductWrapper />}
        />
        <Route path={CIES_URL} element={<CIESWrapper />} />
        <Route
          path={FO_STRATEGY_URL}
          element={
            <FoStrategyWrapper
              afeLoginData={afeLoginData}
              setAfeLoginData={setAfeLoginData}
            />
          }
        />
        <Route path={FO_SERVICES_URL} element={<FoServicesWrapper />} />
        <Route path={AI_STRATEGIES_URL} element={<AIStrategiesWrapper />} />
        <Route
          path={FO_STRATEGY_PRODUCT_URL}
          element={
            <FoStrategyProductWrapper
              afeLoginData={afeLoginData}
              setAfeLoginData={setAfeLoginData}
              setSocket={setSocket}
              socket={socket}
            />
          }
        />
        <Route
          path={FO_SERVICES_PRODUCT_URL}
          element={<FoServicesProductWrapper />}
        />
        <Route
          path={FO_SERVICES_TRUST_PLANNING}
          element={<FoServicesTrustPlanningWrapper />}
        />
        <Route
          path={FO_SERVICES_PRODUCT_COUNTRY_URL}
          element={<FoServicesProductCountryWrapper />}
        />
        <Route
          path={`${SECURITY_ACCOUNT_URL}/:formId?`}
          element={<SecurityAccountWrapper />}
        />
         <Route
          path={SECURITY_DISCLAIMER_URL}
          element={<SecurityDisclaimerWrapper />}
        />
        <Route path={INVESTMENT_URL} element={<InvestmentWrapper />} />
        <Route path={EDIT_FORM_URL} element={<EditFormWrapper />} />
        <Route path={PORTFOLIO_URL} element={<PortfolioWrapper />} />
        <Route path={AI_BOT_URL} element={<AIBotWrapper />} />
        <Route
          path={VIDEO_VERIFICATION_URL}
          element={<VideoVerificationWrapper />}
        />
        <Route
          path={LEGAL_GPT_URL}
          element={<LegalAIBot type="legal" title="Legal GPT" />}
        />
        <Route
          path={INVESTMENT_GPT_URL}
          element={<LegalAIBot type="investment" title="Investment GPT" />}
        />
        <Route
          path={CORPORATE_FINANCE_GPT_URL}
          element={
            <LegalAIBot type="corp_finance" title="Corporate Finance GPT" />
          }
        />
        <Route path={ADMIN_FILE_UPLOAD} element={<AdminFileUploadWrapper />} />
        <Route path={PRE_LANDING_PAGE} element={<PreLandingPage />} />
        <Route path={VIDEO_FORM_TEST} element={<VideoFormTest />} />
      </Routes>
    </BrowserRouter>
  );
}
