import { createIntl, createIntlCache } from "react-intl";

export const messages: any = {
  en: {
    "Payment Info": "Payment Info",
    "Basic Information": "Basic Information",
    "Manage your financial future: Access account information and explore your investment portfolio, all conveniently located in one place.": "Manage your financial future: Access account information and explore your investment portfolio, all conveniently located in one place.",
    "USER PROFILE": "USER PROFILE",
    "Account": "Account",
    "click the button in the top-right corner to speak with our experts": "click the button in the top-right corner to speak with our experts",
    "For tailored advice,": "For tailored advice,",
    "Try DL Assistant": "Try DL Assistant",
    "Use 'DL Assistant' for instant help.": "Use 'DL Assistant' for instant help.",
    "Quick Answers?": "Quick Answers?",
    "Explore our comprehensive suite of products and expert advisory services tailored for Hong Kong's Capital Investment Entrant Scheme (CIES) clients.": "Explore our comprehensive suite of products and expert advisory services tailored for Hong Kong's Capital Investment Entrant Scheme (CIES) clients.",
    "highlight": "HIGHLIGHT",
    welcome: "Welcome to your",
    "AI Family Office!": "AI Family Office!",
    welcome_subtext: "Add funds to start building your portfolio",
    get_started: "Let’s start with your email",
    get_started_subtext: "We will use it to login into your account",
    already_a_member: "Already a member? Login",
    not_a_user: "Not a user? Signup",
    next: "Next",
    six_digit_code: "Enter the six digit code",
    six_digit_code_sub_text: "We have sent you 6 digit code on your email",
    request_new_code: "Request a new code in",
    sec: "sec",
    personal_information: "Personal information",
    use_a_legal_name:
      "Use your legal name as per your government authorised documents",
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    what_is_investor_status: "What is your investor status?",
    what_is_investor_status_subtext:
      "That determine your eligibility for private investments and expanded pricing options",
    select_all_apply: "SELECT ALL THAT APPLY",
    investor_status1:
      "A individual with net worth exceeding 1 million US dollars.",
    investor_status2:
      "Anticipating making an investment over 5 million US dollars.",
    investor_status3: "A Professional/licensed individual.",
    investor_status4: "None of above",
    legal_agreements_text: "Legal agreements",
    legal_agreements1: "Professional Investor Disclaimer",
    legal_agreements2: "Consequences of being treated as Professional Investor",
    "Waiting on approval": "Waiting on approval",
    continue: "Continue",
    home: "HOME",
    investments: "INVESTMENTS",
    services: "SERVICES",
    cies: "CIES",
    fo_strategy: "FO strategy",
    fo_services: "FO services",
    alternatives: "Alternatives",
    dlgpt: "DLGPT",
    account: "ACCOUNT",
    portfolio: "Portfolio",
    complete_account_setup: "Complete Account Setup",
    download_security_form: "Download Security Form",
    not_sure_where_to_start: "NOT SURE WHERE TO START",
    not_sure1: "I'm not sure where to start.",
    not_sure2: "What is the most popular product on DL DFO?",
    not_sure3: "I want diversification but don't know how.",
    not_sure4: "Fix deposits is at x%. How can I beat that?",
    not_sure5: "I need insurance",
    "That's OK. Lets start with a few questions.":
      "That's OK. Lets start with a few questions.",
    "Which of the following do you think best fits your needs:":
      "Which of the following do you think best fits your needs:",
    "Help me invest": "Help me invest",
    "I need help with identity planning": "I need help with identity planning",
    "I need estate planning": "I need estate planning",
    "I'm interested in trust services": "I'm interested in trust services",
    "I need insurance": "I need insurance",
    "Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?":
      "Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?",
    flagship1: "Flagship 1",
    ": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment":
      ": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment",
    flagship2: "Flagship 2",
    "Fix deposits is at x%. How can I beat that?": "Fix deposits is at x%. How can I beat that?",
    "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.":
      "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.",
    "Flagship 3: Optimizing higher growth through strategic investments in public and private securities":
      "Flagship 3: Optimizing higher growth through strategic investments in public and private securities",
    "US Treasuries: Protecting your capital through diversified US Treasury":
      "US Treasuries: Protecting your capital through diversified US Treasury",
    "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%":
      "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%",
    "Explore Flagship 1": "Explore Flagship 1",
    "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.":
      "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.",
    "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.":
      "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.",
    "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.":
      "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.",
    "What is your prefered destination?": "What is your preferred destination?",
    "Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance.":
      "Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance.",
    "Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth.":
      "Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth.",
    "Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries.":
      "Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries.",
    "Investment:Some insurance policies have a savings component that grows over time, adding to your wealth.":
      "Investment:Some insurance policies have a savings component that grows over time, adding to your wealth.",
    "Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation.":
      "Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation.",
    "Book a consultation today": "Book a consultation today",
    "What is the most popular product on DL DFO?":
      "What is the most popular product on DL DFO?",
    ": This is our flagship strategy, trusted and utilized by traditional family offices. It offers a balanced approach to investing with a focus on stable returns and capital safety.":
      ": This is our flagship strategy, trusted and utilized by traditional family offices. It offers a balanced approach to investing with a focus on stable returns and capital safety.",
    "DL US Treasuries: With current U.S. interest rates at cycle highs and an uncertain macroeconomic climate, U.S. Treasury bonds have become increasingly popular. This strategy focuses on these bonds, providing a steady and dependable income while also ensuring capital preservation.":
      "DL US Treasuries: With current U.S. interest rates at cycle highs and an uncertain macroeconomic climate, U.S. Treasury bonds have become increasingly popular. This strategy focuses on these bonds, providing a steady and dependable income while also ensuring capital preservation.",
    "Explore DL US Treasuries": "Explore DL US Treasuries",
    "Diversification can indeed be tricky, but our Flagship Strategy 1 can help you jump right in.":
      "Diversification can indeed be tricky, but our Flagship Strategy 1 can help you jump right in.",
    "Flagship Strategy 1 spreads your money across different asset classes and global regions. This can lower your risk and let you benefit from growth opportunities anywhere in the world.":
      "Flagship Strategy 1 spreads your money across different asset classes and global regions. This can lower your risk and let you benefit from growth opportunities anywhere in the world.",
    "With Flagship Strategy 1, you don't have to worry about the details. Our experts handle everything, so you can enjoy the benefits of diversification":
      "With Flagship Strategy 1, you don't have to worry about the details. Our experts handle everything, so you can enjoy the benefits of diversification",
    "FO strategy": "FO strategy",
    "That determine your eligibility for private investments and expanded pricing options":
      "That determine your eligibility for private investments and expanded pricing options",
    "TALK TO AN EXPERT": "TALK TO AN EXPERT",
    "FOR A PERSONALISED APPROACH": "FOR A PERSONALISED APPROACH",
    "Flagship 1": "Flagship 1",
    "Focus on achieving balanced and stable returns":
      "Focus on achieving balanced and stable returns",
    "Flagship 2": "Flagship 2",
    "Explore a diversified investment portfolio with global ETFs":
      "Explore a diversified investment portfolio with global ETFs",
    "Flagship 3": "Flagship 3",
    "Well-crafted portfolios in both public and private market investments":
      "Well-crafted portfolios in both public and private market investments",
    "DL US Treasuries": "DL US Treasuries",
    "Safeguarding your funds": "Safeguarding your funds",
    "PE Funds": "PE Funds",
    "100+ funds, details to follow": "100+ funds, details to follow",
    "COMING SOON": "COMING SOON",
    Invest: "Invest",
    "Review your account": "Review your account",
    "Product Overview": "Product Overview",
    "The goal of this strategy is to pursue high returns while emphasizing asset stability. It focuses on stable sources of income to ensure the safety of funds. By flexibly allocating products with higher absolute returns, it can maximize asset appreciation. At the same time, by establishing a diversified investment portfolio and selecting assets with low correlation to the broader market, it can reduce the systematic risk of the overall investment portfolio. This strategy aims to balance risk and return, provide stable returns, and protect assets from market fluctuations. By considering various investment opportunities and asset classes, this strategy can better adapt to different market environments and provide long-term financial security for investors.":
      "The goal of this strategy is to pursue high returns while emphasizing asset stability. It focuses on stable sources of income to ensure the safety of funds. By flexibly allocating products with higher absolute returns, it can maximize asset appreciation. At the same time, by establishing a diversified investment portfolio and selecting assets with low correlation to the broader market, it can reduce the systematic risk of the overall investment portfolio. This strategy aims to balance risk and return, provide stable returns, and protect assets from market fluctuations. By considering various investment opportunities and asset classes, this strategy can better adapt to different market environments and provide long-term financial security for investors.",
    Allocation: "Allocation",
    "Asset Class Allocation": "Asset Allocation",
    "60% fixed-income assets": "60% fixed-income assets",
    "40% equity assets": "40% equity assets",
    "Geographical allocation": "Geographical Allocation",
    "Geographical distribution (%)": "Geographical distribution (%)",
    Performance: "Performance",
    "North America": "North America",
    "Asia Pacific (excluding Japan)": "Asia Pacific",
    World: "World",
    Europe: "Europe",
    Japan: "Japan",
    Total: "Total",
    "Top holdings": "Top holdings",
    "Fees and expected return": "Fees and expected return",
    "Expected return": "Expected return",
    "Subscription Fee": "Subscription Fee",
    "Management Fee": "Management Fee",
    "Performance Fee": "Performance Fee",
    Comparison: "Comparison",
    Return: "Return",
    "DL Special Opportunities SP": "DL Special Opportunities SP",
    "Disruptive Technologies Inc": "Disruptive Technologies Inc",
    "Products": "Products",
    "Sharp Ratio": "Sharp Ratio",
    "Data room": "Data room",
    "This strategy consists of globally highly liquid exchange-traded funds (ETFs). Its uniqueness lies in its high liquidity, transparency, and global asset allocation strategy, aiming to effectively counter market risks. First, the high liquidity of this product enables investors to quickly buy and sell shares, facilitating convenient fund flow. Investors can adjust their investment portfolios quickly to adapt to market changes, whether in normal market conditions or high volatility. Second, high transparency is another notable feature of this product. Investors can access real-time information on the fund's holdings, asset allocation, daily net asset value, and market prices. This transparency provides investors with better decision-making tools, enabling them to assess risks and returns more accurately. Most importantly, this product adopts a global asset allocation strategy. It covers a wide range of targets across different regions, industries, and asset classes to achieve portfolio diversification. By diversifying investments globally, this product can reduce the risks of specific markets or industries and provide more stable investment returns.":
      "This strategy consists of globally highly liquid exchange-traded funds (ETFs). Its uniqueness lies in its high liquidity, transparency, and global asset allocation strategy, aiming to effectively counter market risks. First, the high liquidity of this product enables investors to quickly buy and sell shares, facilitating convenient fund flow. Investors can adjust their investment portfolios quickly to adapt to market changes, whether in normal market conditions or high volatility. Second, high transparency is another notable feature of this product. Investors can access real-time information on the fund's holdings, asset allocation, daily net asset value, and market prices. This transparency provides investors with better decision-making tools, enabling them to assess risks and returns more accurately. Most importantly, this product adopts a global asset allocation strategy. It covers a wide range of targets across different regions, industries, and asset classes to achieve portfolio diversification. By diversifying investments globally, this product can reduce the risks of specific markets or industries and provide more stable investment returns.",
    "This strategy focuses on helping clients invest in high-quality funds in the market. It aims to provide clients with the most reasonable rates to ensure they can enjoy premium fund management services. Additionally, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. Firstly, by carefully screening, evaluating, and selecting high-quality funds in the market, it provides clients with a wide range of investment choices. These funds have good reputations and records in terms of management teams, performance, and risk control, ensuring investment quality and returns for clients. Secondly, this strategy emphasizes the reasonableness of rates. It strives for the optimal rate conditions for clients through fine rate design and negotiation. This allows clients to enjoy high-quality fund management services at lower costs, thereby increasing investment returns. Meanwhile, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. The DL Family Office has rich investment experience and expertise, capable of conducting indepth analysis of macroeconomic trends and adjusting investment portfolios based on market changes. This combination enables clients to flexibly respond to market fluctuations and achieve stable asset growth.":
      "This strategy focuses on helping clients invest in high-quality funds in the market. It aims to provide clients with the most reasonable rates to ensure they can enjoy premium fund management services. Additionally, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. Firstly, by carefully screening, evaluating, and selecting high-quality funds in the market, it provides clients with a wide range of investment choices. These funds have good reputations and records in terms of management teams, performance, and risk control, ensuring investment quality and returns for clients. Secondly, this strategy emphasizes the reasonableness of rates. It strives for the optimal rate conditions for clients through fine rate design and negotiation. This allows clients to enjoy high-quality fund management services at lower costs, thereby increasing investment returns. Meanwhile, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. The DL Family Office has rich investment experience and expertise, capable of conducting indepth analysis of macroeconomic trends and adjusting investment portfolios based on market changes. This combination enables clients to flexibly respond to market fluctuations and achieve stable asset growth.",
    "The investment portfolio of this strategy mainly consists of U.S. government bonds with different maturities. U.S. government bonds with different maturities have distinct risk and return characteristics. By investing in U.S. government bond ETF products with various maturities, this product can achieve balanced returns in different market environments. When the market experiences significant volatility, short-term U.S. government bonds can provide relatively stable income and capital protection. In more optimistic market conditions, long-term U.S. government bonds may offer higher returns. This strategy aims to provide investors with a conservative investment option for reliable returns. By investing in U.S. government bond ETF products, investors can gain income from U.S. government bonds and to some extent reduce the risk of the investment portfolio. This strategy is suitable for investors seeking stable income and capital preservation.":
      "The investment portfolio of this strategy mainly consists of U.S. government bonds with different maturities. U.S. government bonds with different maturities have distinct risk and return characteristics. By investing in U.S. government bond ETF products with various maturities, this product can achieve balanced returns in different market environments. When the market experiences significant volatility, short-term U.S. government bonds can provide relatively stable income and capital protection. In more optimistic market conditions, long-term U.S. government bonds may offer higher returns. This strategy aims to provide investors with a conservative investment option for reliable returns. By investing in U.S. government bond ETF products, investors can gain income from U.S. government bonds and to some extent reduce the risk of the investment portfolio. This strategy is suitable for investors seeking stable income and capital preservation.",
    "FO services": "FO services",
    "Your online family office": "Your online family office",
    "Residency Planning": "Residency Planning",
    "Trust Planning": "Trust Planning",
    "Taxation Planning": "Taxation Planning",
    "Income Planning": "Income Planning",
    "Customized your identity layout": "Customized your identity layout",
    "Safeguarding your wealth inheritance":
      "Safeguarding your wealth inheritance",
    "Professional tax strategies.": "Professional tax strategies.",
    "Thoroughly assess your income situation":
      "Thoroughly assess your income situation",
    "Hong Kong": "Hong Kong",
    "Stable Returns": "Stable Returns",
    "Global ETFs": "Global ETFs",
    "High-quality Returns": "High-quality Returns",
    "Safeguarding": "Safeguarding",
    "Log Only": "Log Only",
    "Global Sectors": "Global Sectors",
    "Expected Return": "Expected Return",
    "Coming Soon": "Coming Soon",
    "Attracting top": "Attracting top",
    "talent globally": "talent globally",
    "The fund aims to achieve positive performance": "The fund aims to achieve positive performance",
    "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform.": "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform.",
    Singapore: "Singapore",
    "Attracting high-net-worth family offices":
      "Attracting high-net-worth family offices",
    "Attracting high-net": "Attracting high-net",
    "-worth family offices": "-worth family offices",
    Thailand: "Thailand",
    "Introducing long-term": "Introducing long-term",
    "residency visas": "residency visas",
    Malta: "Malta",
    "Low-threshold access": "Low-threshold access",
    "to EU citizenship": "to EU citizenship",
    Australia: "Australia",
    "Offering a diverse": "Offering a diverse",
    "range of entrepreneur-": "range of entrepreneur",
    "-ship and investment": "-ship and investment",
    "immigration options": "immigration options",
    "New Zealand": "New Zealand",
    "Attracting high-value": "Attracting high-value",
    "investments to promote": "investments to promote",
    "economic development": "economic development",
    Canada: "Canada",
    "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy":
      "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy",
    "Encouraging innovative": "Encouraging innovative",
    "and entrepreneurial": "and entrepreneurial",
    "talents to immigrate and": "talents to immigrate and",
    "contribute to the economy": "contribute to the economy",
    Portugal: "Portugal",
    "Attain EU residency rights through investment":
      "Attain EU residency rights through investment",
    "Attain EU residency": "Attain EU residency",
    "rights through investment": "rights through investment",
    Greece: "Greece",
    "Attain permanent residency through real estate investment":
      "Attain permanent residency through real estate investment",
    "Attain permanent": "Attain permanent",
    "residency through real": "residency through real",
    "estate investment": "estate investment",
    "St Lucia": "St Lucia",
    "Attain citizenship by making economic contributions to the country":
      "Attain citizenship by making economic contributions to the country",
    "Attain citizenship by": "Attain citizenship by",
    "making economic": "making economic",
    "contributions to the": "contributions to the",
    "country": "country",
    Introduction: "Introduction",
    "Application Requirements": "Application Requirements",
    "Investment Requirements": "Investment Requirements",
    "Investment Duration": "Investment Duration",
    "Processing Time": "Processing Time",
    "Indentity Category": "Indentity Category",
    "Processing time and identity category":
      "Processing time and identity category",
    "Net Asset Value ($)": "Net Asset Value ($)",
    "Portfolio Allocation (as of today)": "Portfolio Allocation (as of today)",
    "Private Equity": "Private Equity",
    "Account settings": "Account settings",
    "Account Verification Status": "Account Verification Status",
    "General Account": "General Account",
    "Securities Account": "Securities Account",
    Verified: "Verified",
    "Get verified": "Get verified",
    "Download as PDF": "Download as PDF",
    Others: "Others",
    "Investment Objective(s)": "Investment Objective(s)",
    "Sign Out": "Sign Out",
    "Private Credit": "Private Credit",
    "Real Estate": "Real Estate",
    FOF: "FOF",
    "Private Deals": "Private Deals",
    "Hedge Funds": "Hedge Funds",
    Other: "Other",
    "Investment Strategy": "Investment Strategy",
    "Core Advantages": "Core Advantages",
    "Geographic Focus": "Geographic Focus",
    Investment: "Investment",
    "Service Provider": "Service Provider",
    "Key Term and Structure": "Key Term and Structure",
    "Key Term": " Key Term",
    "US Treasuries": "US Treasuries",
    "Available Trust Types ": "Available Trust Types",
    "Legal System": "Legal System",
    "Proper Laws Jurisdictions": "Proper Laws Jurisdictions",
    "Powers held by the Settlor/person designated by the Settlor/Trustee":
      "Powers held by the Settlor/person designated by the Settlor/Trustee",
    "Assets Injected": "Assets Injected",
    "Discretionary Trust": "Discretionary Trust",
    "Based on English Common Law": "Based on English Common Law",
    "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan":
      "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan",
    "Powers to manage the trust assets and to make distributions to beneficiaries are all held by the trustee to excercise at its discretion":
      "Powers to manage the trust assets and to make distributions to beneficiaries are all held by the trustee to excercise at its discretion",
    "Diversified investment portfolios & low risk investment products":
      "Diversified investment portfolios & low risk investment products",
    "Reserved Powers Trust": "Reserved Powers Trust",
    "Settlor reserves and retains control over certain powers, and most commonly seen is the power to make investment decisions over the trust assets, He or she can also appoint an investment manager":
      "Settlor reserves and retains control over certain powers, and most commonly seen is the power to make investment decisions over the trust assets, He or she can also appoint an investment manager",
    "Low to high risk investment products, concentrated holding investments & operating companies":
      "Low to high risk investment products, concentrated holding investments & operating companies",
    "BVI VISTA Trust Cayman STAR Trust Labuan Special Trust":
      "BVI VISTA Trust Cayman STAR Trust Labuan Special Trust",
    "The company directors (who can be the settlor of the trust or appointed by the settlor) remain entirely responsible for managing the BVI Holdco and all the trust assets held beneath it":
      "The company directors (who can be the settlor of the trust or appointed by the settlor) remain entirely responsible for managing the BVI Holdco and all the trust assets held beneath it",
    "Private Trust Company (PTC)": "Private Trust Company (PTC)",
    "British Virgin Islands, Cayman Islands":
      "British Virgin Islands, Cayman Islands",
    "Stay involved with management of family trust assets as a director of the PTC":
      "Stay involved with management of family trust assets as a director of the PTC",
    "Similar to a normal discretionary trust, but a Special Trust Advisor can be appointed to guide the trustee on investment decisions over the trust assets":
      "Similar to a normal discretionary trust, but a Special Trust Advisor can be appointed to guide the trustee on investment decisions over the trust assets",
    "Limited Partnership Trust": "Limited Partnership Trust",
    Labuan: "Labuan",
    "Stay involved with management of family trust assets as a director of the General Partner of the Limited Partnership":
      "Stay involved with management of family trust assets as a director of the General Partner of the Limited Partnership",
    "Special Provisions Trust": "Special Provisions Trust",
    "Discretionary Trust with Special Trust Advisor":
      "Discretionary Trust with Special Trust Advisor",
    "Allocation on Private Credit Opportunities on High Defensive Business":
      "Allocation on Private Credit Opportunities on High Defensive Business",
    "Emphasis on Downside Protection with Potential Equity Upside":
      "Emphasis on Downside Protection with Potential Equity Upside",
    "Proprietary Sourcing by Leveraging Long Standing Relationships":
      "Proprietary Sourcing by Leveraging Long Standing Relationships",
    "Active Portfolio Management & Risk Controls":
      "Active Portfolio Management & Risk Controls",
    "Long Only Credit Strategy with Target IRR of Low to Mid-Teens on an unlevered basis":
      "Long Only Credit Strategy with Target IRR of Low to Mid-Teens on an unlevered basis",
    "Low correlation with overall market volatility":
      "Low correlation with overall market volatility",
    "The source of rare and quality investment opportunities, ability to reach out to the best investment opportunities benefited from DL’s long-term partnership with corporates, leading credit managers and industry advisors":
      "The source of rare and quality investment opportunities, ability to reach out to the best investment opportunities benefited from DL’s long-term partnership with corporates, leading credit managers and industry advisors",
    "Solid and quality collaterals": "Solid and quality collaterals",
    "A rigorous due diligence and screening process with relevant resources and experience to evaluate investment opportunities":
      "A rigorous due diligence and screening process with relevant resources and experience to evaluate investment opportunities",
    "Core focus on developed economics, including Hong Kong, Singapore and U.S.":
      "Core focus on developed economics, including Hong Kong, Singapore and U.S.",
    "Opportunistic Exposure to Emerging Asia":
      "Opportunistic Exposure to Emerging Asia",
    "Fund Administrator:": "Fund Administrator: ",
    "Cayman Islands Legal Counsel:": "Cayman Islands Legal Counsel:",
    "Auditor:": "Auditor:",
    "Fund Name": "Fund Name",
    "Fund Manager": "Fund Manager",
    Domicile: "Domicile",
    "Cayman Islands": "Cayman Islands",
    "Base Currency": "Base Currency",
    "Lock up period": "Lock up period",
    "12 months": "12 months",
    "Subscription Frequency": "Subscription Frequency",
    Monthly: "Monthly",
    "Minimum initial subscription": "Minimum initial subscription",
    "Minimum subsequent Subscription": "Minimum subsequent Subscription",
    "Redemption Frequency": "Redemption Frequency",
    "Notice period": "Notice period",
    "180 calendar days": "180 calendar days",
    quarterly: "quarterly",
    "Filters": "Filters",
    "Advanced": "Advanced",
    "Categories": "Category",
    "Minimum Initial": "Min Initial Investment (HKD)",
    "AUM": "AUM",
    "Search Products": "Search Products",
    "Enter keywords here...": "Enter keywords here...",
    "Seeks to invest primarily in fixed-income securities and credit opportunities,":
      "Seeks to invest primarily in fixed-income securities and credit opportunities,",
    "including but not limited to listed or unlisted loans and debt instruments, secured or unsecured loans and debt instruments":
      "including but not limited to listed or unlisted loans and debt instruments, secured or unsecured loans and debt instruments",
    "Ability to reach out to the best fund managers due to DL's long-term relationship with global funds":
      "Ability to reach out to the best fund managers due to DL's long-term relationship with global funds",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process":
      "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process",
    "A real estate project located in California, USA":
      "A real estate project located in California, USA",
    "891 acre residential development site with 73 estate lots":
      "891 acre residential development site with 73 estate lots",
    "Average lot size of 4.38 acres with and equestrian center located in the heart of Carmel Valley":
      "Average lot size of 4.38 acres with and equestrian center located in the heart of Carmel Valley",
    "FA & Custodian : ": "FA & Custodian : ",
    "Fund Legal Counsel: ": "Fund Legal Counsel: ",
    "Auditor: ": "Auditor: ",
    "Maintain a low correlation with the market and reduce the overall systemic risk of the portfolio while pursuing high absolute returns.":
      "Maintain a low correlation with the market and reduce the overall systemic risk of the portfolio while pursuing high absolute returns.",
    "Invest in multiple funds to avoid the high volatility of investing in a single fund.":
      "Invest in multiple funds to avoid the high volatility of investing in a single fund.",
    "Focus on funds that use non-traditional investment methods (i.e. fund performance has low correlation with overall market volatility and are more resistant to volatility)":
      "Focus on funds that use non-traditional investment methods (i.e. fund performance has low correlation with overall market volatility and are more resistant to volatility).",
    "Ability to reach out to the best fund managers due to DL’s long-term relationship with global funds.":
      "Ability to reach out to the best fund managers due to DL’s long-term relationship with global funds.",
    "Utilize a strong network of professional parties such as top tier investment banks in sourcing up-and-coming and established funds.":
      "Utilize a strong network of professional parties such as top tier investment banks in sourcing up-and-coming and established funds.",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process.":
      "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process.",
    "Voyage Fund invests in a basket of cross-regional diversified hedge funds.":
      "Voyage Fund invests in a basket of cross-regional diversified hedge funds.",
    "Effectively reduce portfolio volatility by diversifying into different hedge funds.":
      "Effectively reduce portfolio volatility by diversifying into different hedge funds.",
    "From Jan. 2023 to the present, the annual yield is 2.19%. Compared to MSCI China, Hang Seng, CSI 500, the fund's monthly average return has remained stable.":
      "From Jan. 2023 to the present, the annual yield is 2.19%. Compared to MSCI China, Hang Seng, CSI 500, the fund's monthly average return has remained stable.",
    "Comparison of trust Jurisdictions": "Comparison of trust Jurisdictions",
    "Quality Migrant Admission Scheme": "Quality Migrant Admission Scheme",
    "The Hong Kong government launched the Quality Migrant Admission Scheme in 2006. The scheme seeks to attract highly skilled or talented persons to settle in Hong Kong in order to enhance Hong Kong's economic competitiveness.":
      "The Hong Kong government launched the Quality Migrant Admission Scheme in 2006. The scheme seeks to attract highly skilled or talented persons to settle in Hong Kong in order to enhance Hong Kong's economic competitiveness.",
    "Over 18 years of age": "Over 18 years of age",
    "No criminal record": "No criminal record",
    "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test":
      "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test",
    "None needed": "None needed",
    "6+": "6+",
    "Resident Status": "Resident Status",
    "Admission Scheme for Mainland Talents and Professionals":
      "Admission Scheme for Mainland Talents and Professionals",
    "Chinese residents of the Mainland of China (the Mainland) who possess special skills, knowledge or experience of value to and not readily available in the HKSAR may apply to come to work under the ASMTP. The ASMTP is quota-free and non-sector specific.":
      "Chinese residents of the Mainland of China (the Mainland) who possess special skills, knowledge or experience of value to and not readily available in the HKSAR may apply to come to work under the ASMTP. The ASMTP is quota-free and non-sector specific.",
    "Three application categories": "Three application categories",
    "Persons with annual income reaching HK$2.5 million or above, or its equivalent in foreign currency, in the year immediately preceding the date of application":
      "Persons with annual income reaching HK$2.5 million or above, or its equivalent in foreign currency, in the year immediately preceding the date of application",
    "Degree graduates of the eligible universities with at least three years of work experience over the past five years immediately preceding the date of application":
      "Degree graduates of the eligible universities with at least three years of work experience over the past five years immediately preceding the date of application",
    "Degree graduates of the eligible universities in the past five years immediately preceding the date of application with less than three years of work experience, subject to an annual quota which is to be allotted on a first-come, first-served basis":
      "Degree graduates of the eligible universities in the past five years immediately preceding the date of application with less than three years of work experience, subject to an annual quota which is to be allotted on a first-come, first-served basis",
    "4-6": "4-6",
    "Top Talent Pass Scheme": "Top Talent Pass Scheme",
    "Eligible talents include individuals who had an annual salary of HKD 2.5 million or more in the past year, and individuals who graduated from the top 100 universities globally and have accumulated 3 years or more of work experience in the past 5 years. These two types of people can be issued a pass to develop in Hong Kong for a period of 2 years, with no quota restrictions. Graduates from the top 100 universities globally who do not meet the work experience requirements but have graduated in the past 5 years can also be issued passes, with an annual limit of 10,000 people. This scheme lasts for 2 years and will be reviewed one year after its launch.":
      "Eligible talents include individuals who had an annual salary of HKD 2.5 million or more in the past year, and individuals who graduated from the top 100 universities globally and have accumulated 3 years or more of work experience in the past 5 years. These two types of people can be issued a pass to develop in Hong Kong for a period of 2 years, with no quota restrictions. Graduates from the top 100 universities globally who do not meet the work experience requirements but have graduated in the past 5 years can also be issued passes, with an annual limit of 10,000 people. This scheme lasts for 2 years and will be reviewed one year after its launch.",
    "1-2": "1-2",
    "Thailand Elite-Visa": "Thailand Elite-Visa",
    "The Thailand Elite Visa, initiated by the Royal Thai Government in November 2003, is the world's first visa program that allows foreigners to reside in Thailand for up to 20 years. It is issued by the Thailand Privilege Card Company Limited (TPC), a wholly-owned subsidiary of the Tourism Authority of Thailand, under the Ministry of Tourism and Sports. Holders of the Thailand Elite Visa can enjoy VIP services and other privileges for free.":
      "The Thailand Elite Visa, initiated by the Royal Thai Government in November 2003, is the world's first visa program that allows foreigners to reside in Thailand for up to 20 years. It is issued by the Thailand Privilege Card Company Limited (TPC), a wholly-owned subsidiary of the Tourism Authority of Thailand, under the Ministry of Tourism and Sports. Holders of the Thailand Elite Visa can enjoy VIP services and other privileges for free.",
    "Over 20 years of age": "Over 20 years of age",
    "Donation of 600,000 to 2,000,000 Thai Baht":
      "Donation of 600,000 to 2,000,000 Thai Baht",
    "By making a donation of 600,000 to 2,000,000 Thai Baht, one can obtain long-term residency status for 5 years, 10 years, or 20 years.":
      "By making a donation of 600,000 to 2,000,000 Thai Baht, one can obtain long-term residency status for 5 years, 10 years, or 20 years.",
    "One time donation": "One time donation",
    "2-3": "2-3",
    "Permanent Residency Program": "Permanent Residency Program",
    "On January 12, 2021, the Identity Malta Agency formally announced a new policy for its permanent residency program, named the Malta Permanent Residence Program (MPRP). The cost of implementing the new policy is lower than the previous MRVP project, making the Malta program one of the most cost-effective investment projects on the market.":
      "On January 12, 2021, the Identity Malta Agency formally announced a new policy for its permanent residency program, named the Malta Permanent Residence Program (MPRP). The cost of implementing the new policy is lower than the previous MRVP project, making the Malta program one of the most cost-effective investment projects on the market.",
    "Proof of assets worth 50,000 Euros (including proof of liquid assets worth 15,000 Euros)":
      "Proof of assets worth 50,000 Euros (including proof of liquid assets worth 15,000 Euros)",
    "Meet the government's investment requirements":
      "Meet the government's investment requirements",
    "Administrative fee: 40,000 Euros": "Administrative fee: 40,000 Euros",
    "Non-profit donation: 2,000 Euros": "Non-profit donation: 2,000 Euros",
    "Government donation: For property purchase method, the donation amount is 28,000 Euros; For the rental method, the donation amount is 58,000 Euros":
      "Government donation: For property purchase method, the donation amount is 28,000 Euros; For the rental method, the donation amount is 58,000 Euros",
    "Property investment: Purchase a property worth over 300,000 Euros for a total of 5 years or lease a property worth 10,000 Euros per year for a total of 5 years.":
      "Property investment: Purchase a property worth over 300,000 Euros for a total of 5 years or lease a property worth 10,000 Euros per year for a total of 5 years.",
    "At least 5 years": "At least 5 years",
    "8-10": "8-10",
    "Permanent Residency": "Permanent Residency",
    "Citizenship by Investment Program": "Citizenship by Investment Program",
    "The Malta passport policy was launched in 2014. In July 2020, the Malta passport project reached its upper limit and was closed. At the end of November 2020, the new Malta passport policy, the Granting of Citizenship for Exceptional Services Regulations, 2020, was launched. This policy stipulates that the quota for new citizenship is 1500 families, with a maximum of 400 families per year.":
      "The Malta passport policy was launched in 2014. In July 2020, the Malta passport project reached its upper limit and was closed. At the end of November 2020, the new Malta passport policy, the Granting of Citizenship for Exceptional Services Regulations, 2020, was launched. This policy stipulates that the quota for new citizenship is 1500 families, with a maximum of 400 families per year.",
    "Hold Malta residence for 3 years or 12 months":
      "Hold Malta residence for 3 years or 12 months",
    "Make a donation of 600,000 EUR or 750,000 EUR to the country of Malta.":
      "Make a donation of 600,000 EUR or 750,000 EUR to the country of Malta.",
    "Purchase residential property in Malta valued at least 700,000 EUR and hold for at least 5 years; or lease a residential property with a rent of at least 16,000 EUR 3. per year for a minimum lease period of 5 years. Donate 10,000 EUR to local Maltese organizations involved in charity, culture, sports, science, art, or non-governmental organizations.":
      "Purchase residential property in Malta valued at least 700,000 EUR and hold for at least 5 years; or lease a residential property with a rent of at least 16,000 EUR 3. per year for a minimum lease period of 5 years. Donate 10,000 EUR to local Maltese organizations involved in charity, culture, sports, science, art, or non-governmental organizations.",
    "15-18": "15-18",
    Citizenship: "Citizenship",
    "188A": "188A",
    "The 188A visa, introduced by the Australian government in 1981 as an entrepreneurial immigration program, was previously known as the 163A visa. To date, it has a history of 40 years and is one of the oldest and most consistently stable immigration programs in the Commonwealth countries.":
      "The 188A visa, introduced by the Australian government in 1981 as an entrepreneurial immigration program, was previously known as the 163A visa. To date, it has a history of 40 years and is one of the oldest and most consistently stable immigration programs in the Commonwealth countries.",
    "The main applicant is under 55 years old.":
      "The main applicant is under 55 years old.",
    "The individual and family net assets under the name of the couple are not less than 1.25 million AUD.":
      "The individual and family net assets under the name of the couple are not less than 1.25 million AUD.",
    "In the past four fiscal years, in two of those fiscal years, the company's annual turnover is not less than 750,000 AUD.":
      "In the past four fiscal years, in two of those fiscal years, the company's annual turnover is not less than 750,000 AUD.",
    "In the past four fiscal years, in two of those fiscal years, the shareholding ratio under the couple's name is not less than 30%; if it is a listed company, it is not less than 10%.":
      "In the past four fiscal years, in two of those fiscal years, the shareholding ratio under the couple's name is not less than 30%; if it is a listed company, it is not less than 10%.",
    "The score in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system is not less than 65 points.":
      "The score in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system is not less than 65 points.",
    "As long as either spouse meets the entrepreneurial (residential) requirements, they can become the main applicant for the 888 Permanent Residence Visa.":
      "As long as either spouse meets the entrepreneurial (residential) requirements, they can become the main applicant for the 888 Permanent Residence Visa.",
    "During the 5-year residency visa period, meet the entrepreneurial requirements for 2 years.":
      "During the 5-year residency visa period, meet the entrepreneurial requirements for 2 years.",
    "During the 2 years of entrepreneurship, reside in the sponsoring state for at least 1 year.":
      "During the 2 years of entrepreneurship, reside in the sponsoring state for at least 1 year.",
    "Meet at least 2 of the following 3 conditions: 1) The family net assets under the couple's name reach 600,000 AUD; 2) The net assets of the company held reach 200,000 AUD; 3) Employ 2 local full-time employees.":
      "Meet at least 2 of the following 3 conditions: 1) The family net assets under the couple's name reach 600,000 AUD; 2) The net assets of the company held reach 200,000 AUD; 3) Employ 2 local full-time employees.",
    "At least 2 years": "At least 2 years",
    "21-27": "21-27",
    "188B": "188B",
    "The 188B visa, introduced by the Australian government in 1981 as an investment immigration program, was previously known as the 165 visa. To date, it has a history of 40 years and is one of the most consistently stable immigration programs in the Commonwealth countries.":
      "The 188B visa, introduced by the Australian government in 1981 as an investment immigration program, was previously known as the 165 visa. To date, it has a history of 40 years and is one of the most consistently stable immigration programs in the Commonwealth countries.",
    "The main applicant must be under 55 years old.":
      "The main applicant must be under 55 years old.",
    "The family's net assets must not be less than 2.75 million AUD over the past two fiscal years.":
      "The family's net assets must not be less than 2.75 million AUD over the past two fiscal years.",
    "Have at least 3 years of successful investment experience, and in one of the past five years, have over 10% shares in a business or own an investment market value of 2.5 million AUD.":
      "Have at least 3 years of successful investment experience, and in one of the past five years, have over 10% shares in a business or own an investment market value of 2.5 million AUD.",
    "Through the above business or investment, profits exceed 1 million AUD.":
      "Through the above business or investment, profits exceed 1 million AUD.",
    "Score no less than 65 points in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system.":
      "Score no less than 65 points in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system.",
    "Invest 2.5 million AUD in compliant investments.":
      "Invest 2.5 million AUD in compliant investments.",
    "Hold compliant investments of 2.5 million AUD, with the required proportions as follows:":
      "Hold compliant investments of 2.5 million AUD, with the required proportions as follows:",
    "AUD 500,000 VC and growth private equity funds":
      "AUD 500,000 VC and growth private equity funds",
    "AUD 750,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 750,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 1,250,000 balancing investment": "AUD 1,250,000 balancing investment",
    "At least 3 years": "At least 3 years",
    "21-25": "21-25",
    "188C": "188C",
    "The Australian 188C Investment Immigration is an immigration program aimed at high-net-worth individuals. Applicants are required to declare assets of 5 million AUD and provide a reasonable explanation of the source of funds. Later, the assets of 5 million AUD will be liquidated and invested through a fund company.":
      "The Australian 188C Investment Immigration is an immigration program aimed at high-net-worth individuals. Applicants are required to declare assets of 5 million AUD and provide a reasonable explanation of the source of funds. Later, the assets of 5 million AUD will be liquidated and invested through a fund company.",
    "No age restriction": "No age restriction",
    "No points required, no English requirement":
      "No points required, no English requirement",
    "Relaxed residence requirements for later conversion to permanent residence":
      "Relaxed residence requirements for later conversion to permanent residence",
    "Family assets above 5 million AUD, with a reasonable explanation of the source":
      "Family assets above 5 million AUD, with a reasonable explanation of the source",
    "Invest 5 million AUD in compliant investments":
      "Invest 5 million AUD in compliant investments",
    "Hold compliant investments of 5 million AUD, with the required proportions as follows:":
      "Hold compliant investments of 5 million AUD, with the required proportions as follows:",
    "AUD 1,000,000 VC and growth private equity funds":
      "AUD 1,000,000 VC and growth private equity funds",
    "AUD 1,500,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 1,500,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 2,500,000 balancing investment": "AUD 2,500,000 balancing investment",
    "25-28": "25-28",
    "New Zealand Active Investor Plus": "New Zealand Active Investor Plus",
    "The Active Investor Plus Visa, established to attract high-value investors, came into effect on 19th September 2022. This new visa replaces the previous investor visa category.":
      "The Active Investor Plus Visa, established to attract high-value investors, came into effect on 19th September 2022. This new visa replaces the previous investor visa category.",
    "Language: IELTS score of 5 or above.":
      "Language: IELTS score of 5 or above.",
    "Residence: The main applicant must reside in New Zealand for at least 117 days within the 4-year investment period.":
      "Residence: The main applicant must reside in New Zealand for at least 117 days within the 4-year investment period.",
    "Investment: Invest between 5 million and 15 million NZD in New Zealand over 4 years.":
      "Investment: Invest between 5 million and 15 million NZD in New Zealand over 4 years.",
    "Dependents: Children under the age of 24 who are economically dependent on the main applicant can be included in the application.":
      "Dependents: Children under the age of 24 who are economically dependent on the main applicant can be included in the application.",
    "Invest at least 5 million NZD directly into enterprises approved by the New Zealand Trade and Enterprise (NZTE), or;":
      "Invest at least 5 million NZD directly into enterprises approved by the New Zealand Trade and Enterprise (NZTE), or;",
    "Invest at least 7.5 million NZD in equity funds or venture capital funds, or;":
      "Invest at least 7.5 million NZD in equity funds or venture capital funds, or;",
    "Invest at least 15 million NZD in publicly listed company shares and charitable causes (50% each).":
      "Invest at least 15 million NZD in publicly listed company shares and charitable causes (50% each).",
    "At least 4 years": "At least 4 years",
    "12-24": "12-24",
    "Canada Start-up Visa Program (SUV)": "Canada Start-up Visa Program (SUV)",
    "The Canadian Startup Visa (SUV) Program was launched by Immigration, Refugees and Citizenship Canada (IRCC) on April 1, 2013, initially as a 5-year pilot project. In 2018, IRCC announced that the program would become a permanent one. The main goal of the Startup Visa Program is to attract innovative foreign entrepreneurs who can contribute to Canada's economic and industrial development and create new job opportunities.":
      "The Canadian Startup Visa (SUV) Program was launched by Immigration, Refugees and Citizenship Canada (IRCC) on April 1, 2013, initially as a 5-year pilot project. In 2018, IRCC announced that the program would become a permanent one. The main goal of the Startup Visa Program is to attract innovative foreign entrepreneurs who can contribute to Canada's economic and industrial development and create new job opportunities.",
    "Age: The main applicant must be over 18 years old.":
      "Age: The main applicant must be over 18 years old.",
    "Education: The main applicant must have a full-time college degree or higher.":
      "Education: The main applicant must have a full-time college degree or higher.",
    "Industry Background: Over 3 years of related background in the technology industry (such as IT, communications, biomedical technology, new energy, environmental protection, artificial intelligence, and other tech-related industries).":
      "Industry Background: Over 3 years of related background in the technology industry (such as IT, communications, biomedical technology, new energy, environmental protection, artificial intelligence, and other tech-related industries).",
    "To establish an innovative business in Canada, there is no explicit investment amount requirement, but the amount is usually more than 100,000 Canadian dollars":
      "To establish an innovative business in Canada, there is no explicit investment amount requirement, but the amount is usually more than 100,000 Canadian dollars",
    "30-38": "30-38",
    "Portugal Golden Visa": "Portugal Golden Visa",
    "On October 8, 2012, the Portuguese Ministry of Internal Affairs issued the Portuguese Golden Residence Permit Program. The law stipulates that citizens from non-EU countries can obtain the Portuguese Golden Residence Permit by making an investment in Portugal.":
      "On October 8, 2012, the Portuguese Ministry of Internal Affairs issued the Portuguese Golden Residence Permit Program. The law stipulates that citizens from non-EU countries can obtain the Portuguese Golden Residence Permit by making an investment in Portugal.",
    "500,000 Euros in a fund approved by the Portuguese government":
      "500,000 Euros in a fund approved by the Portuguese government",
    "12+": "12+",
    "Greece Golden Visa": "Greece Golden Visa",
    "In order to increase domestic consumption and promote the development of the domestic real estate industry, the Greek government announced a new immigration policy for home buyers in July 2015. Non-EU citizens who invest in local properties worth more than 250,000 Euros in Greece can obtain permanent residency in Greece. In March 2016, the Greek government further relaxed the application conditions by removing the requirement for no criminal record. Starting from August 2023, the Greek property immigration policy will be adjusted, with the property purchase requirement in some areas rising from the original 250,000 Euros to 500,000 Euros.":
      "In order to increase domestic consumption and promote the development of the domestic real estate industry, the Greek government announced a new immigration policy for home buyers in July 2015. Non-EU citizens who invest in local properties worth more than 250,000 Euros in Greece can obtain permanent residency in Greece. In March 2016, the Greek government further relaxed the application conditions by removing the requirement for no criminal record. Starting from August 2023, the Greek property immigration policy will be adjusted, with the property purchase requirement in some areas rising from the original 250,000 Euros to 500,000 Euros.",
    "Non-EU/European Economic Area/Swiss nationals":
      "Non-EU/European Economic Area/Swiss nationals",
    "At least 14 years old": "At least 14 years old",
    "Property worth more than 250,000 Euros (some regions require more than 500,000 Euros)":
      "Property worth more than 250,000 Euros (some regions require more than 500,000 Euros)",
    "Permanent possession": "Permanent possession",
    "6-8": "6-8",
    "St Lucia Citizenship by Investment": "St Lucia Citizenship by Investment",
    "The Saint Lucia Citizenship by Investment Program requires applicants to make a significant economic contribution to the country. As an exchange, and after a strict application process and due diligence, the applicant and their family will be granted full citizenship. The Saint Lucia Citizenship by Investment Program is regulated by the Citizenship by Investment Act No. 14 of 2015.":
      "The Saint Lucia Citizenship by Investment Program requires applicants to make a significant economic contribution to the country. As an exchange, and after a strict application process and due diligence, the applicant and their family will be granted full citizenship. The Saint Lucia Citizenship by Investment Program is regulated by the Citizenship by Investment Act No. 14 of 2015.",
    "Donations:": "Donations:",
    "$100,000 (single applicant); or": "$100,000 (single applicant); or",
    "$140,000 for a couple; or": "$140,000 for a couple; or",
    "$150,000 for a family of 3-4, with each additional person beyond 5 requiring an additional investment of $15,000.":
      "$150,000 for a family of 3-4, with each additional person beyond 5 requiring an additional investment of $15,000.",
    Months: "Months",
    Category: "Category",
    "At least 18 years old": "At least 18 years old",
    "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth.":
      "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth.",
    "AUM SGD 20 million": "AUM SGD 20 million",
    "At least SGD 200,000 in total business spending":
      "At least SGD 200,000 in total business spending",
    "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member.":
      "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member.",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market.":
      "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market.",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies, and private equity investments in local unlisted companies. Newly added are climate-related investments and blended finance in which Singapore financial institutions have broad participation.":
      "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies, and private equity investments in local unlisted companies. Newly added are climate-related investments and blended finance in which Singapore financial institutions have broad participation.",
    "Maintain until application for permanent residency.":
      "Maintain until application for permanent residency.",
    "AUM SGD 50 million": "AUM SGD 50 million",
    "At least SGD 500,000 in total business spending":
      "At least SGD 500,000 in total business spending",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies and private equity investments in local non-listed companies. New additions include climate-related investments and blended finance widely participated by Singapore financial institutions.":
      "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies and private equity investments in local non-listed companies. New additions include climate-related investments and blended finance widely participated by Singapore financial institutions.",
    "63% fixed-income assets": "63% fixed-income assets",
    "34% equity assets": "34% equity assets",
    "3% derivatives": "3% derivatives",
    "37% equity assets": "37% equity assets",
    "100% fixed-income assets": "100% fixed-income assets",
    "20% fixed-income assets": "20% fixed-income assets",
    "80% equity assets": "80% equity assets",
    "Are you New User or an Existing DL Holding Client":
      "Are you New User or an Existing DL Holding Client?",
    "I’m a New User": "I’m a New User",
    "I’m an Existing Client": "I’m an Existing Client",
    "Bank Account Number": "Bank Account Number",
    "Account Name": "Account Name",
    "Bank Name": "Bank Name",
    "Bank Code": "Bank Code",
    "Remittance Code": "Remittance Code",
    "Please check your spam or junk mail folder to make sure you didn’t miss the OTP email. If issues persist, please reach out to ":
      "Please check your spam or junk mail folder to make sure you didn’t miss the OTP email. If issues persist, please reach out to ",
    "Bank of Communications": "Bank of Communications",
    "Payment Proof": "Payment Proof",
    "View Payment Proofs": "View Payment Proofs",
    "view": "View",
    "Upload Proof": "Upload Proof",
    "PI Account": "PI Account",
    "PRODUCTS": "PRODUCTS",
    "Investments": "Investments",
    "Please complete the security account opening flow": "Please complete the security account opening flow",
    "Go to Investments": "Go to Investments",
    "LOGIN": "LOGIN",
    "Login": "Login",
    "Login ID": "Login ID",
    "Enter Login ID": "Enter Login ID",
    "Password": "Password",
    "Enter password": "Enter password",
    "Show": "Show",
    "Hide": "Hide",
    "Forgot Password?": "Forgot Password?",
    "Strategy Information": "Strategy Information",
    "Performance Comparison": "Performance Comparison",
    "Name": "Name",
    "Investment Structure": "Investment Structure",
    "Discretionary Account": "Discretionary Account",
    "Minimum Investment Amount": "Minimum Initial Investment Amount (HKD)",
    "No less than $200,000 (With $1,000,000 asset proof)": "No less than $200,000 (With $1,000,000 asset proof)",
    "Open Frequency": "Open Frequency",
    "Fully redemption after 6-month lock-up period": "Fully redemption after 6-month lock-up period",
    "Quarterly redemption with no redemption fee (1-month notice period )": "Quarterly redemption with no redemption fee (1-month notice period )",
    "Top 5 Holdings": "Top 5 Holdings",
    "BACK": "BACK",
    "READ MORE": " Read More >",
    "Fixed Income": "Fixed Income",
    "Equity": "Equity",
    "Cash": "Cash",
    "as of today": "as of today",
    "Stock Holdings": "Stock Holdings",
    "Cash Holdings": "Cash Holdings",
    "Portfolio Summary": "Portfolio Summary",
    "Product Type": "Product Type",
    "Product Name": "Product Name",
    "Currency": "Currency",
    "Closing Price At": "Closing Price At",
    "Market Value": "Market Value",
    "Profit/(Loss)": "Profit/(Loss)",
    "Phone": "Phone",
    "Select Product": "Select Product",
    "Flagship 1 Teaser": "Flagship I Teaser",
    "Flagship 2 Teaser": "Flagship II Teaser",
    "Flagship 3 Teaser": "Flagship III Teaser",
    "US Treasury Teaser": "US Treasury Teaser",
    "SHOW LESS" : "Show less",
    "Risk Level": "Risk Level",
    "Top 10 Holdings": "Top 10 Holdings",
    "Past 1 year returns": "Past 1 year returns",
    "Dear Client,": "Dear Client,",
    "By continuing with the account opening process, you voluntarily and irrevocably agree to open a securities account with DL Securities (HK) Limited (hereinafter referred to as 'DL Securities').": "By continuing with the account opening process, you voluntarily and irrevocably agree to open a securities account with DL Securities (HK) Limited (hereinafter referred to as 'DL Securities').", 
    "DL Digital Family Office (HK) Limited (hereinafter referred to as 'DLiFO') does not have any legal relationship with you based on rights and obligations arising from securities transactions.": "DL Digital Family Office (HK) Limited (hereinafter referred to as 'DLiFO') does not have any legal relationship with you based on rights and obligations arising from securities transactions.",
    "By proceeding with the account opening steps, you authorize DLiFO, in accordance with the Personal Data (Privacy) Ordinance, to transfer the account opening information you subsequently fill in and provide to DL Securities. You acknowledge that the information and data provided during the securities account opening on this website will be used for the purpose of opening a personal account with DL Securities.": "By proceeding with the account opening steps, you authorize DLiFO, in accordance with the Personal Data (Privacy) Ordinance, to transfer the account opening information you subsequently fill in and provide to DL Securities. You acknowledge that the information and data provided during the securities account opening on this website will be used for the purpose of opening a personal account with DL Securities.",
    "DL Securities (HK) Limited is a licensed corporation regulated by the Securities and Futures Commission of Hong Kong, holding a Type 1 regulated activity license, and is qualified to engage as a licensed introducing agent, dealer, or securities non-clearing broker.": "DL Securities (HK) Limited is a licensed corporation regulated by the Securities and Futures Commission of Hong Kong, holding a Type 1 regulated activity license, and is qualified to engage as a licensed introducing agent, dealer, or securities non-clearing broker.",
    "DLiFO is an associated company of DL Securities. According to the agreements signed between the parties, DLiFO provides client referral services and website technical support services to DL Securities, and receives compensation and related fees for these services.": "DLiFO is an associated company of DL Securities. According to the agreements signed between the parties, DLiFO provides client referral services and website technical support services to DL Securities, and receives compensation and related fees for these services.",
    "The ownership of this website/software belongs to DL Digital Family Office (HK) Limited.":"The ownership of this website/software belongs to DL Digital Family Office (HK) Limited.",
    "Please confirm that you voluntarily and irrevocably authorize DL Digital Family Office (HK) Limited, acting on behalf of DL Securities (HK) Limited, to collect and transfer your personal data and document proofs for the purpose of opening your personal securities account with DL Securities (HK) Limited.": "Please confirm that you voluntarily and irrevocably authorize DL Digital Family Office (HK) Limited, acting on behalf of DL Securities (HK) Limited, to collect and transfer your personal data and document proofs for the purpose of opening your personal securities account with DL Securities (HK) Limited.",
    "Please also confirm that you are aware of the client referral and online platform technology consultancy contract relationship between the aforementioned parties.": "Please also confirm that you are aware of the client referral and online platform technology consultancy contract relationship between the aforementioned parties.",
    "Start": "Start",
    "Click here for more information.": "Click here for more information."
  },
  zh: {
    "Click here for more information.": "点击了解更多信息。",
    "Start": "点击跳转下一页",
    "Please also confirm that you are aware of the client referral and online platform technology consultancy contract relationship between the aforementioned parties.": "同时，请确认您已知悉上述两方之间存在用户推荐及在线平台技术咨询的合约关系。",
    "Please confirm that you voluntarily and irrevocably authorize DL Digital Family Office (HK) Limited, acting on behalf of DL Securities (HK) Limited, to collect and transfer your personal data and document proofs for the purpose of opening your personal securities account with DL Securities (HK) Limited.": "请确认您自愿且不可撤销地授权德林数字家族办公室（香港）有限公司代表德林证券（香港）有限公司收集并转移您的个人数据和证明文件，用于在德林证券（香港）有限公司开设您的个人证券账户。",
    "The ownership of this website/software belongs to DL Digital Family Office (HK) Limited.": "此网站/软件所有权归属于德林数字家族办公室（香港）有限公司。",
    "DLiFO is an associated company of DL Securities. According to the agreements signed between the parties, DLiFO provides client referral services and website technical support services to DL Securities, and receives compensation and related fees for these services.": "根据双方签订之相关协议，德林数字家办为德林证券提供客户转介服务及网站技术支持服务，并收取业务报酬及相关费用。",
    "DL Securities (HK) Limited is a licensed corporation regulated by the Securities and Futures Commission of Hong Kong, holding a Type 1 regulated activity license, and is qualified to engage as a licensed introducing agent, dealer, or securities non-clearing broker.": "德林证券(香港)有限公司为香港证监会持牌机构，持有第一类受规管活动牌照及合资格从事核准介绍代理人、买卖商或证券非结算交易商。德林数字家办为德林证券相关联之公司。",
    "By proceeding with the account opening steps, you authorize DLiFO, in accordance with the Personal Data (Privacy) Ordinance, to transfer the account opening information you subsequently fill in and provide to DL Securities. You acknowledge that the information and data provided during the securities account opening on this website will be used for the purpose of opening a personal account with DL Securities.": "阁下继续完成开户步骤，即为阁下授权德林数字家办，按照《个人资料（隐私）条例》，将阁下后续填写并提供之开户资料转移至德林证券。阁下知悉在本网站证券开户填写之资料及提供信息，将被用作德林证券个人账户开户使用。",
    "DL Digital Family Office (HK) Limited (hereinafter referred to as 'DLiFO') does not have any legal relationship with you based on rights and obligations arising from securities transactions.": "德林数字家族办公室（香港）有限公司(后称“德林数字家办”)与阁下不存在基于证券交易产生的权利义务法律关系。",
    "By continuing with the account opening process, you voluntarily and irrevocably agree to open a securities account with DL Securities (HK) Limited (hereinafter referred to as 'DL Securities').": "阁下继续完成开户步骤，即为阁下自愿并同意在德林证券(香港)有限公司(后称“德林证券”)开通证券账户。",
    "Dear Client,": "告知客户：",
    "Past 1 year returns": "过去一年回报",
    "Top 10 Holdings": "十大持股",
    "Risk Level": "风险等级",
    "Payment Info": "付款信息",
    "Basic Information": "基本信息",
    "Manage your financial future: Access account information and explore your investment portfolio, all conveniently located in one place.": "管理您的财务未来：访问帐户信息并探索您的投资组合，所有这些都方便地位于一处。",
    "Account": "我的账号",
    "USER PROFILE": "用户个人资料",
    "click the button in the top-right corner to speak with our experts": "单击右上角的按钮与我们的专家交谈",
    "For tailored advice,": "针对量身定制的建议",
    "Try DL Assistant": "尝试 DL 助手",
    "Use 'DL Assistant' for instant help.": "使用 'DL 助手' 获得即时帮助。",    
    "Quick Answers?": "寻找快速答案?",
    "Explore our comprehensive suite of products and expert advisory services tailored for Hong Kong's Capital Investment Entrant Scheme (CIES) clients.": "了解我们为香港资本投资者入境计划 (CIES) 客户量身定制的全面产品和专家咨询服务。",
    "highlight": "精华",
    welcome: "欢迎来到您的",
    "AI Family Office!": "人工智能家族办公室!",
    welcome_subtext: "添加资金，开始构建您的投资组合",
    get_started: "请输入您的电邮地址",
    get_started_subtext: "我们将使用该电邮地址登录您的账户​",
    already_a_member: "已注册用户？请登录",
    not_a_user: "还不是用户？请注册",
    next: "下一步​",
    six_digit_code: "请输入六位数字代码​",
    six_digit_code_sub_text: "我们已向您的电邮地址发送了一个6位数的验证码​",
    request_new_code: "请求一个新代码",
    sec: "秒",
    personal_information: "个人资料​",
    use_a_legal_name: "根据您的政府授权文件，请使用您的法定姓名​",
    first_name: "名字​",
    middle_name: "辅助名​",
    last_name: "姓氏​",
    what_is_investor_status: "请确认您的投资者身份?",
    what_is_investor_status_subtext:
      "这将确定您是否有资格进行私人投资和获得扩展的定价选项",
    select_all_apply: "请选择适用项​",
    investor_status1: "个人净资产超过100万美元",
    investor_status2: "预计将进行超过500万美元的投资",
    investor_status3: "专业人士/持有许可证的个人投资者",
    investor_status4: "以上都不适用​",
    legal_agreements_text: "法律协议​",
    legal_agreements1: "专业投资者免责声明",
    legal_agreements2: "被视为专业投资者的后果",
    continue: "继续​",
    "Waiting on approval": "等待批准",
    home: "主页",
    investments: "家办投资",
    services: "家办服务",
    cies: "投资移民",
    fo_strategy: "家办策略",
    fo_services: "家办服务",
    alternatives: "另类投资",
    dlgpt: "DLGPT",
    account: "我的账号",
    portfolio: "我的组合",
    complete_account_setup: "完成账户注册",
    download_security_form: "下载安全表格",
    not_sure_where_to_start: "不确定从哪里开始",
    not_sure1: "我不知道从哪里开始",
    not_sure2: "什么是DLiFO上最受欢迎的产品",
    not_sure3: "我想要分散风险，但是不知道该怎么做",
    not_sure4: "定存利率是x%。怎么才能收益打败它?",
    not_sure5: "我想要保险",
    "Products": "产品",
    "Fix deposits is at x%. How can I beat that?": "定存利率是x%。怎么才能收益打败它?",
    "That's OK. Lets start with a few questions.":
      "没关系，让我们从几个问题开始。",
    "Which of the following do you think best fits your needs:":
      "您认为以下哪些选项最符合您的需求：",
    "Help me invest": "帮我投资",
    "I need help with identity planning": "我需要身份规划服务",
    "I need estate planning": "我需要遗产规划服务",
    "I'm interested in trust services": "我对信托服务感兴趣",
    "I need insurance": "我需要保险",
    "Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?":
      "太好了，您正在考虑进行投资！为了更好地理解我们如何能够协助您，请您告诉我以下哪个最符合您的投资目标？ ",
    flagship1: "德林旗舰策略 I",
    ": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment":
      ": 我们的旗舰产品。由DLAM13年的投资经验指导，在增长和稳定之间寻求平衡。",
    flagship2: "德林旗舰策略 II",
    "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.":
      "Flagship 旗舰策略 2：利用全球多元化和ETF的流动性，进行有效风险管理。",
    "Flagship 3: Optimizing higher growth through strategic investments in public and private securities":
      "Flagship 旗舰策略 3：精选公共市场和私募市场证券的战略性投资，追求更高的增长潜力。 ",
    "US Treasuries: Protecting your capital through diversified US Treasury":
      "美国国债：多元化的美债组合，为您的资金安全保驾护航",
    "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%":
      "旗舰策略是由 DLAM 利用 DL 集团13年的投资策略和经验推出和管理的标准化投资策略。该策略旨在通过资产地理多元化来保全财富，这是一般的大众富裕投资者无法做到的。旗舰 1 号的投资策略既安全稳定，又能实现收益和效率的最大化。在过去 7 年中，该策略实现了年均约 6% 的复合增长率，累计绝对回报率超过 50%。",
    "Explore Flagship 1": "继续探索 Flagship 1",
    "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.":
      "旗舰 2 号策略是为那些希望充分利用全球分散投资和高流动性ETF所带来的优势的投资者量身定制的。该策略由我们资历深厚的团队管理，他们凭借对全球市场的深入了解构建了一个多样化的投资组合。投资者可以借此策略享受到专业管理的多元ETF组合的益处，这些ETF覆盖了不同的资产类别，而投资者本人无需进行深入的市场分析或具备专业的投资知识。",
    "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.":
      "旗舰 3 号基金是追求精心管理和投资多样化的投资者的完美选择。我们致力于的专业团队利用他们对公开市场和私募市场的深入认识，精选组建了这一支基金。这样，投资者就能够轻松享受到由专业团队打造的多样化投资组合所带来的潜在收益，而无需自己进行深入的市场研究或具备专业的投资知识。 ",
    "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.":
      "在投资策略中，资本保值至关重要。我们的美国国债策略依托于美国政府的信誉和信用，是最安全的投资方式之一。此策略涵盖了短期、中期和长期的国债，根据市场条件提供了风险与收益的均衡配置。 ",
    "What is your prefered destination?": "好的，您的目标地点是哪里？",
    "Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance.":
      "保险在长期财富管理和保护中起着关键作用。以下是我们推崇保险的四大理由： ",
    "Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth.":
      "风险管理与保护：保险能够在您遭受如死亡或疾病等风险事件时，防止经济损失，保护您的财富不受侵蚀。 ",
    "Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries.":
      "遗产规划：人寿保险可以帮助顺畅地将财富传给后代，用以支付遗产税和其他相关费用，避免资产的急速出售，为受益人提供更稳固的经济保障。 ",
    "Investment:Some insurance policies have a savings component that grows over time, adding to your wealth.":
      "投资价值：部分保险产品含有储蓄成分，这部分随着时间的推移可以增值，为您的财富积累增添助力。 ",
    "Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation.":
      "税务筹划：保险还能提供税务上的好处，例如免税的身故赔付和免税的资金增值，帮助您在增长和保全财富方面获得优势。 ",
    "Book a consultation today": "立即预约咨询 ",
    "What is the most popular product on DL DFO?":
      "什么是DLiFO上最受欢迎的产品 ",
    ": This is our flagship strategy, trusted and utilized by traditional family offices. It offers a balanced approach to investing with a focus on stable returns and capital safety.":
      "：这是我们的旗舰策略，深受传统家族办公室的青睐。它秉承一种均衡的投资理念，专注于实现稳定的收益和资本保护。",
    "DL US Treasuries: With current U.S. interest rates at cycle highs and an uncertain macroeconomic climate, U.S. Treasury bonds have become increasingly popular. This strategy focuses on these bonds, providing a steady and dependable income while also ensuring capital preservation.":
      "德林美国国债策略：考虑到目前美国利率正处于周期性高点，加之宏观经济状况的不确定性，美国国债吸引了更多投资者的目光。本策略专注于投资于美国国债，旨在为投资者提供稳固可靠的收入流，并确保资本得到保全。",
    "Explore DL US Treasuries": "继续探索DL美国国债 ",
    "Diversification can indeed be tricky, but our Flagship Strategy 1 can help you jump right in.":
      "投资中分散风险可能会有些复杂，但我们的旗舰策略 1 可以帮助您轻松入门。",
    "Flagship Strategy 1 spreads your money across different asset classes and global regions. This can lower your risk and let you benefit from growth opportunities anywhere in the world.":
      "旗舰策略 1 将您的资金分散投资于不同的资产类别和全球不同地区。这样可以降低您的风险，并让您有机会从世界各地的增长中受益。",
    "With Flagship Strategy 1, you don't have to worry about the details. Our experts handle everything, so you can enjoy the benefits of diversification":
      "选择旗舰策略 1，您无需担心投资的细节。我们的专家会处理一切，让您享受多元化投资带来的好处。 ",
    "FO strategy": "FO策略",
    "That determine your eligibility for private investments and expanded pricing options":
      "享受符合FO质量标准的标准化产品，确保您的投资体验既专业又高效。",
    "TALK TO AN EXPERT": "咨询专家",
    "FOR A PERSONALISED APPROACH": "量身定制的投资指导",
    "Flagship 1": "德林旗舰策略 I",
    "Focus on achieving balanced and stable returns": "专注于均衡稳定的回报",
    "Flagship 2": "德林旗舰策略 II",
    "Explore a diversified investment portfolio with global ETFs":
      "探索全球ETF的多元化投资组合 ",
    "Flagship 3": "德林旗舰策略 III",
    "Well-crafted portfolios in both public and private market investments":
      "精心策划的公开市场和私募市场投资组合",
    "DL US Treasuries": "德林美债策略",
    "Safeguarding your funds": "为您的资金保驾护航 ",
    "PE Funds": "私募基金",
    "100+ funds, details to follow": "100+ 支基金，敬请期待",
    "COMING SOON": "敬请期待 ",
    Invest: "投资",
    "Review your account": "查看您的账户",
    "Product Overview": "产品概述",
    "The goal of this strategy is to pursue high returns while emphasizing asset stability. It focuses on stable sources of income to ensure the safety of funds. By flexibly allocating products with higher absolute returns, it can maximize asset appreciation. At the same time, by establishing a diversified investment portfolio and selecting assets with low correlation to the broader market, it can reduce the systematic risk of the overall investment portfolio. This strategy aims to balance risk and return, provide stable returns, and protect assets from market fluctuations. By considering various investment opportunities and asset classes, this strategy can better adapt to different market environments and provide long-term financial security for investors.":
      "该策略的目标是追求高回报，同时强调资产的稳定性。它注重稳定的收入来源，以确保资金安全。通过灵活配置绝对回报较高的产品，最大限度地实现资产增值。同时，通过建立多元化的投资组合，选择与大盘相关性较低的资产，降低整体投资组合的系统性风险。这一策略旨在平衡风险与收益，提供稳定的回报，并保护资产免受市场波动的影响。通过考虑不同的投资机会和资产类别，该策略可以更好地适应不同的市场环境，为投资者提供长期的财务保障。 ",
    Allocation: "配置 ",
    "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform.": "通过我们平台上的 DL 家族办公室策略、另类投资和独家基金产品，最大化您的投资组合。",
    "Asset Class Allocation": "资产类别分配 ",
    "60% fixed-income assets": "60% 固定收益资产",
    "40% equity assets": "40% 股票资产",
    "Geographical allocation": "地理分配 ",
    "Geographical distribution (%)": "地理占比 (%)",
    Performance: "年度迄今表现 ",
    "North America": "北美 ",
    "Asia Pacific (excluding Japan)": "亚太地区（除日本外） ",
    World: "全球 ",
    Europe: "欧洲 ",
    Japan: "日本 ",
    Total: "总计",
    "Top holdings": "主要持仓",
    "Fees and expected return": "费用和预期回报",
    "Expected return": "预期回报 ",
    "Subscription Fee": "认购费",
    "Management Fee": "管理费",
    "Performance Fee": "表现费",
    Comparison: "对比 ",
    Return: "回报 ",
    "Sharp Ratio": "夏普比率 ",
    "Data room": "资料室 ",
    "This strategy consists of globally highly liquid exchange-traded funds (ETFs). Its uniqueness lies in its high liquidity, transparency, and global asset allocation strategy, aiming to effectively counter market risks. First, the high liquidity of this product enables investors to quickly buy and sell shares, facilitating convenient fund flow. Investors can adjust their investment portfolios quickly to adapt to market changes, whether in normal market conditions or high volatility. Second, high transparency is another notable feature of this product. Investors can access real-time information on the fund's holdings, asset allocation, daily net asset value, and market prices. This transparency provides investors with better decision-making tools, enabling them to assess risks and returns more accurately. Most importantly, this product adopts a global asset allocation strategy. It covers a wide range of targets across different regions, industries, and asset classes to achieve portfolio diversification. By diversifying investments globally, this product can reduce the risks of specific markets or industries and provide more stable investment returns.":
      "该策略由全球高流动性指数基金（ETF）组成。其独特之处在于高流动性、高透明度和全球资产配置策略，旨在有效抵御市场风险。 首先，该产品的高流动性使投资者能够快速买卖基金份额，方便资金流动。无论是在正常市场条件下，还是在剧烈波动时，投资者都可以迅速调整投资组合，以适应市场变化。 其次，高透明度是该产品的另一个显著特点。投资者可以实时了解基金的持股情况、资产配置、每日资产净值和市场价格。这种透明度为投资者提供了更好的决策工具，使他们能够更准确地评估风险和收益。 最重要的是，该产品采用全球资产配置策略。它涵盖了不同地区、行业和资产类别的更广泛目标，以实现投资组合的多元化。通过在全球范围内分散投资，该产品可以降低特定市场或行业的风险，提供更稳定的投资回报。 ",
    "This strategy focuses on helping clients invest in high-quality funds in the market. It aims to provide clients with the most reasonable rates to ensure they can enjoy premium fund management services. Additionally, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. Firstly, by carefully screening, evaluating, and selecting high-quality funds in the market, it provides clients with a wide range of investment choices. These funds have good reputations and records in terms of management teams, performance, and risk control, ensuring investment quality and returns for clients. Secondly, this strategy emphasizes the reasonableness of rates. It strives for the optimal rate conditions for clients through fine rate design and negotiation. This allows clients to enjoy high-quality fund management services at lower costs, thereby increasing investment returns. Meanwhile, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. The DL Family Office has rich investment experience and expertise, capable of conducting indepth analysis of macroeconomic trends and adjusting investment portfolios based on market changes. This combination enables clients to flexibly respond to market fluctuations and achieve stable asset growth.":
      "本策略专注于说明客户投资于市场上的优质基金。它旨在为客户提供最合理的费率，确保客户能够享受到优质的基金管理服务。此外，本产品还与德林家族办公室的宏观策略相结合，实现客户资产的稳健增长。 首先，通过对市场上优质基金的精心筛选、评估和甄选，为客户提供了广泛的投资选择。这些基金在管理团队、业绩表现、风险控制等方面都有良好的口碑和记录，确保了客户的投资质量和回报。其次，这一策略强调利率的合理性。通过精细化设计和谈判，为客户争取最佳费率条件。让客户以较低的成本享受到优质的基金管理服务，从而提高投资收益。同时，该产品与 DL Family Office 的宏观策略相结合，实现了客户资产的稳健增长。DL 家族办公室拥有丰富的投资经验和专业知识，能够对宏观经济走势进行深入分析，并根据市场变化调整投资组合。这种组合使客户能够灵活应对市场波动，实现资产的稳定增长。 ",
    "The investment portfolio of this strategy mainly consists of U.S. government bonds with different maturities. U.S. government bonds with different maturities have distinct risk and return characteristics. By investing in U.S. government bond ETF products with various maturities, this product can achieve balanced returns in different market environments. When the market experiences significant volatility, short-term U.S. government bonds can provide relatively stable income and capital protection. In more optimistic market conditions, long-term U.S. government bonds may offer higher returns. This strategy aims to provide investors with a conservative investment option for reliable returns. By investing in U.S. government bond ETF products, investors can gain income from U.S. government bonds and to some extent reduce the risk of the investment portfolio. This strategy is suitable for investors seeking stable income and capital preservation.":
      "本策略的投资组合主要包括不同期限的美国政府债券。不同期限的美国政府债券具有不同的风险和回报特征。通过投资不同期限的美国政府债券ETF产品，本产品可在不同的市场环境下获得均衡的回报 当市场大幅波动时，短期美国政府债券可提供相对稳定的收益和资本保障。在较为乐观的市场环境下，长期美国政府债券可提供较高的回报。 该策略旨在为投资者提供一个回报可靠的保守型投资选择。通过投资美国政府债券 ETF 产品，投资者可从美国政府债券中获取收益，并在一定程度上降低投资组合的风险。、该策略适合追求稳定收益和资本保值的投资者。 ",
    "FO services": "FO服务",
    "Your online family office": "您的线上家族办公室",
    "Residency Planning": "身份规划 ",
    "Trust Planning": "信托规划 ",
    "Taxation Planning": "税务规划 ",
    "Income Planning": "收入规划 ",
    "Customized your identity layout": "定制您的身份布局",
    "Safeguarding your wealth inheritance": "守护您的财富传承",
    "Professional tax strategies.": "专业税务策略",
    "Thoroughly assess your income situation": "详尽评估您的收入状况",
    "Hong Kong": "香港",
    "Attracting top": "面向全球",
    "talent globally": "吸引顶尖人才",
    Singapore: "新加坡",
    "Attracting high-net-worth family offices": "吸引高净值家族办公室",
    "Attracting high-net": "吸引高净值",
    "-worth family offices": "家族办公室",
    Thailand: "泰国",
    "Introducing long-term": "推出长期",
    "residency visas": "居留签证",
    Malta: "马耳他",
    "Low-threshold access": "低门槛获取",
    "to EU citizenship": "欧盟护照",
    Australia: "澳大利亚",
    "Offering a diverse range of entrepreneurship and investment immigration options":
      "提供多元化创业及投资移民选择",
    "Offering a diverse": "提供多元",
    "range of entrepreneur-": "化创业及",
    "-ship and investment": "投资移",
    "immigration options": "民选择",
    "New Zealand": "新西兰",
    "Attracting high-value": "吸引高价值",
    "investments to promote": "投资促进",
    "economic development": "经济发展",
    Canada: "加拿大",
    "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy":
      "鼓励创新创业人才移民贡献经济",
    "Encouraging innovative": "鼓励创新",
    "and entrepreneurial": "创业人才",
    "talents to immigrate and": "移民贡",
    "contribute to the economy": "献经济",
    Portugal: "葡萄牙",
    "Attain EU residency rights through investment": "通过投资获取欧盟居留权",
    "Attain EU residency": "通过投资获取",
    "rights through investment": "欧盟居留权",
    Greece: "希腊",
    "Attain permanent residency through real estate investment":
      "通过房产投资获得永久居留",
    "Attain permanent": "通过房产",
    "residency through real": "投资获得",
    "estate investment": "永久居留",
    "St Lucia": "圣卢西亚",
    "Attain citizenship by making economic contributions to the country":
      "对国家做出经济贡献获得公民身份",
    "Attain citizenship by": "对国家",
    "making economic": "做出经济",
    "contributions to the": "贡献获得",
    "country": "公民身份",
    Introduction: "项目简介",
    "Application Requirements": "申请条件",
    "Investment Requirements": "投资要求",
    "Investment Duration": "投资时长",
    "Processing Time": "办理周期",
    "Indentity Category": "身份类别",
    "Processing time and identity category": "处理时间和身份类别",
    "Net Asset Value ($)": "资产净值（$)",
    "Portfolio Allocation (as of today)": "投资组合配置（截至今日）",
    "Private Equity": "私募股权",
    "Account settings": "账户设置",
    "Account Verification Status": "账户验证状态 ",
    "General Account": "普通账户 ",
    "Securities Account": "证券账户 ",
    Verified: "已认证 ",
    "Get verified": "账户认证 ",
    "Download as PDF": "下载为PDF",
    Others: "其他",
    "Investment Objective(s)": "投资目标",
    "Sign Out": "退出登录 ",
    "Private Credit": "私募信贷",
    "Real Estate": "地产 ",
    FOF: "FOF",
    "Private Deals": "独家投资机会 ",
    "Hedge Funds": "对冲基金 ",
    Other: "其他 ",
    "Investment Strategy": "投资策略 ",
    "Core Advantages": "核心优势 ",
    "Geographic Focus": "地理焦点 ",
    Investment: "投资",
    "Service Provider": "服务提供者 ",
    "Key Term and Structure": "关键条款和结构 ",
    "Key Term": "关键条款 ",
    "US Treasuries": "美国国债",
    "Available Trust Types": "可用信托类型 ",
    "Legal System": "法律体系 ",
    "Proper Laws Jurisdictions": "适用法律管辖区 ",
    "Powers held by the Settlor/person designated by the Settlor/Trustee":
      "由受托人/受托人指定的人/设立人持有的权力 ",
    "Assets Injected": "注入资产 ",
    "Discretionary Trust": "自由裁量信托 ",
    "Based on English Common Law": "基于英格兰普通法 ",
    "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan":
      "香港、英属维尔京群岛、新加坡、开曼群岛、纳闽",
    "Powers to manage the trust assets and to make distributions to beneficiaries are all held by the trustee to excercise at its discretion":
      "受托人拥有管理信托资产和向受益人分配的全部权力，可以自行行使这些权力 ",
    "Diversified investment portfolios & low risk investment products":
      "多元化投资组合 & 低风险投资产品",
    "Reserved Powers Trust": "保留权力信托 ",
    "Low to high risk investment products, concentrated holding investments & operating companies":
      "低至高风险投资产品、集中持股投资 & 运营公司 ",
    "BVI VISTA Trust Cayman STAR Trust Labuan Special Trust":
      "BVI VISTA信托、开曼STAR信托、纳闽特殊信托 ",
    "The company directors (who can be the settlor of the trust or appointed by the settlor) remain entirely responsible for managing the BVI Holdco and all the trust assets held beneath it":
      "公司董事（可以是信托的设立人或由设立人指定）完全负责管理BVI控股公司及其下所有信托资产 ",
    "Private Trust Company (PTC)": "私人信托公司（PTC） ",
    "British Virgin Islands, Cayman Islands": "英属维尔京群岛、开曼群岛 ",
    "Stay involved with management of family trust assets as a director of the PTC":
      "作为PTC的董事参与管理家族信托资产 ",
    "Similar to a normal discretionary trust, but a Special Trust Advisor can be appointed to guide the trustee on investment decisions over the trust assets":
      "类似于普通的自由裁量信托，但可以指定一名特别信托顾问来指导受托人关于信托资产的投资决策 ",
    "Limited Partnership Trust": "有限合伙信托",
    Labuan: "纳闽 ",
    "Stay involved with management of family trust assets as a director of the General Partner of the Limited Partnership":
      "作为有限合伙的普通合伙人的董事参与管理家族信托资产 ",
    "Settlor reserves and retains control over certain powers, and most commonly seen is the power to make investment decisions over the trust assets, He or she can also appoint an investment manager":
      "设立人保留并保持对某些权力的控制，最常见的是对信托资产的投资决策权，他或她还可以指定一名投资经理 ",
    "Special Provisions Trust": "特殊条款信托 ",
    "Discretionary Trust with Special Trust Advisor":
      "带有特别信托顾问的自由裁量信托 ",
    "Allocation on Private Credit Opportunities on High Defensive Business":
      "在高防御性业务上分配私人信贷机会 ",
    "Emphasis on Downside Protection with Potential Equity Upside":
      "强调防守保护并具有潜在的股权上升空间 ",
    "Proprietary Sourcing by Leveraging Long Standing Relationships":
      "利用长期关系进行专有采购 ",
    "Active Portfolio Management & Risk Controls":
      "积极的投资组合管理与风险控制",
    "Long Only Credit Strategy with Target IRR of Low to Mid-Teens on an unlevered basis":
      "长期唯一信贷策略，目标内部收益率在未杠杆化基础上为低到中十几岁 ",
    "Low correlation with overall market volatility":
      "与整体市场波动的低相关性 ",
    "The source of rare and quality investment opportunities, ability to reach out to the best investment opportunities benefited from DL’s long-term partnership with corporates, leading credit managers and industry advisors":
      "稀缺且优质的投资机会来源，能够抓住最好的投资机会，受益于DL与企业、领先的信用管理人和行业顾问的长期合作 ",
    "Solid and quality collaterals": "优质的抵押品 ",
    "A rigorous due diligence and screening process with relevant resources and experience to evaluate investment opportunities":
      "严格的尽职调查和筛选过程，具有相关资源和经验来评估投资机会 ",
    "Core focus on developed economics, including Hong Kong, Singapore and U.S.":
      "核心关注发达经济体，包括香港、新加坡和美国 ",
    "Opportunistic Exposure to Emerging Asia": "对新兴亚洲的战术性接触 ",
    "Fund Administrator:": "基金管理员：",
    "Cayman Islands Legal Counsel:": "开曼群岛法律顾问：",
    "Auditor:": "审计师：",
    "Fund Name": "基金名称 ",
    "Fund Manager": "基金经理 ",
    "DL Special Opportunities SP": "DL 特别机会 SP",
    "Disruptive Technologies Inc": "颠覆性技术公司",
    Domicile: "注册地",
    "Cayman Islands": "开曼群岛 ",
    "Base Currency": "基础货币 ",
    "Lock up period": "锁定期",
    "12 months": "12 个月 ",
    "Subscription Frequency": "认购频率 ",
    Monthly: "月度",
    "Minimum initial subscription": "最低初始认购 ",
    "Minimum subsequent Subscription": "最低后续认购 ",
    "Redemption Frequency": "赎回频率 ",
    "Notice period": "通知期 ",
    "180 calendar days": "180 天 ",
    quarterly: "季度 ",
    "Seeks to invest primarily in fixed-income securities and credit opportunities,":
      "旨在主要投资于固定收益证券和信贷机会， ",
    "including but not limited to listed or unlisted loans and debt instruments, secured or unsecured loans and debt instruments":
      "包括但不限于上市或非上市贷款和债务工具，有抵押或无抵押贷款和债务工具。 ",
    "Ability to reach out to the best fund managers due to DL's long-term relationship with global funds":
      "由于DL与全球基金的长期关系，能够联系到最佳基金经理",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process":
      "通过严格的尽职调查和筛选过程，以及独特的投资过滤流程，构建成功的投资组合 ",
    "A real estate project located in California, USA":
      "位于美国加利福尼亚州的房地产项目",
    "891 acre residential development site with 73 estate lots":
      "891英亩的住宅开发地，共有73个地产用地 ",
    "Average lot size of 4.38 acres with and equestrian center located in the heart of Carmel Valley":
      "平均每个地块大小为4.38英亩，并拥有位于卡梅尔谷中心的马术中心 ",
    "FA & Custodian : ": "FA & 托管人 : ",
    "Fund Legal Counsel: ": "基金法律顾问: ",
    "Auditor: ": "审计师: ",
    "Maintain a low correlation with the market and reduce the overall systemic risk of the portfolio while pursuing high absolute returns.":
      "保持与市场的低相关性，并在追求高绝对回报的同时降低投资组合的整体系统性风险。 ",
    "Invest in multiple funds to avoid the high volatility of investing in a single fund.":
      "投资于多个基金，以避免投资单一基金的高波动性。 ",
    "Focus on funds that use non-traditional investment methods (i.e. fund performance has low correlation with overall market volatility and are more resistant to volatility).":
      "专注于那些使用非传统投资方法的基金（即基金业绩与整体市场波动性低相关，并且对波动性有更强的抵抗力）。 ",
    "Ability to reach out to the best fund managers due to DL’s long-term relationship with global funds.":
      "由于DL与全球基金的长期关系，能够联系到最佳基金经理。 ",
    "Utilize a strong network of professional parties such as top tier investment banks in sourcing up-and-coming and established funds.":
      "利用顶尖投资银行等专业机构的强大网络来寻找新兴和已建立的基金。",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process.":
      "通过严格的尽职调查和筛选过程，以及独特的投资过滤流程，构建成功的投资组合。 ",
    "Voyage Fund invests in a basket of cross-regional diversified hedge funds.":
      "Voyage Fund投资于一篮子跨区域多元化对冲基金。 ",
    "Effectively reduce portfolio volatility by diversifying into different hedge funds.":
      "通过多元化投资于不同的对冲基金有效降低投资组合波动性。 ",
    "From Jan. 2023 to the present, the annual yield is 2.19%. Compared to MSCI China, Hang Seng, CSI 500, the fund's monthly average return has remained stable.":
      "从2023年1月至今，年收益率为2.19%。与MSCI中国、恒生指数、中证500相比，该基金的月平均回报保持稳定。 ",
    "Comparison of trust Jurisdictions": "信托司法管辖区比较",
    "Quality Migrant Admission Scheme": "优秀人才入境计划",
    "The Hong Kong government launched the Quality Migrant Admission Scheme in 2006. The scheme seeks to attract highly skilled or talented persons to settle in Hong Kong in order to enhance Hong Kong's economic competitiveness.":
      "香港政府自2006年推出的面向全球的优秀人才吸纳 计划，旨在吸引具有良好教育背景的高技术人才或优秀人才来港定居，藉以提升香港在全球市场的竞争力。",
    "Over 18 years of age": "18周岁以上",
    "No criminal record": "无犯罪记录",
    "Filters": "过滤器",
    "Advanced": "进阶",
    "Categories": "类别",
    "Minimum Initial": "投资门槛",
    "AUM": "资产管理规模",
    "Search Products": "搜索产品",
    "Enter keywords here...": "在此输入关键字...",
    "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test":
      "计分制要求：满足综合计分制或成就计分制要求",
    "None needed": "无需投资",
    "6+": "6+个月",
    "Resident Status": "居民身份",
    "Admission Scheme for Mainland Talents and Professionals":
      "输入内地人人才计划",
    "Chinese residents of the Mainland of China (the Mainland) who possess special skills, knowledge or experience of value to and not readily available in the HKSAR may apply to come to work under the ASMTP. The ASMTP is quota-free and non-sector specific.":
      "具备香港特区所需而又缺乏的特别技能、知识或经验的内地中国居民，可根据输入内地人才计划（专才）申请来港工作。输入内地人才计划并无配额限制，亦不限行业。",
    "Three application categories": "三类可申请类别：",
    "Persons with annual income reaching HK$2.5 million or above, or its equivalent in foreign currency, in the year immediately preceding the date of application":
      "过去一年年薪达250万港元或以上的人士；或",
    "Degree graduates of the eligible universities with at least three years of work experience over the past five years immediately preceding the date of application":
      "毕业于全球百强大学并在过去5年内累积3年或以上工作经验的人士；或",
    "Degree graduates of the eligible universities in the past five years immediately preceding the date of application with less than three years of work experience, subject to an annual quota which is to be allotted on a first-come, first-served basis":
      "为符合工作经验要求但最近5年毕业的全球百强大学毕业生",
    "4-6": "4-6个月",
    "Top Talent Pass Scheme": "高端人才通行证计划",
    "Eligible talents include individuals who had an annual salary of HKD 2.5 million or more in the past year, and individuals who graduated from the top 100 universities globally and have accumulated 3 years or more of work experience in the past 5 years. These two types of people can be issued a pass to develop in Hong Kong for a period of 2 years, with no quota restrictions. Graduates from the top 100 universities globally who do not meet the work experience requirements but have graduated in the past 5 years can also be issued passes, with an annual limit of 10,000 people. This scheme lasts for 2 years and will be reviewed one year after its launch.":
      "合资格人才包括过去一年年薪达250万港元或以上的人士，以及毕业于全球百强大学并在过去5年内累积3年或以上工作经验的人士。此两类人士可获发为期2年的通行证来港发展，不设人数限额。未符合工作经验要求但最近5年内毕业的全球百强大学毕业生亦可获发通行证，每年上限1万人。此计划为期2年，计划将于推出一年后检讨",
    "1-2": "1-2个月",
    "Thailand Elite-Visa": "泰国精英签证",
    "The Thailand Elite Visa, initiated by the Royal Thai Government in November 2003, is the world's first visa program that allows foreigners to reside in Thailand for up to 20 years. It is issued by the Thailand Privilege Card Company Limited (TPC), a wholly-owned subsidiary of the Tourism Authority of Thailand, under the Ministry of Tourism and Sports. Holders of the Thailand Elite Visa can enjoy VIP services and other privileges for free.":
      "泰国精英签证是泰国皇家政府于2003年11月发起的全球第一个允许外国人在泰国居住的长达20年的签证计划。是由泰国特权卡有限公司(TPC)发签证，泰国特权卡有限公司（TPC）是泰国旅游局下属全资子公司，隶属旅游部与体育部。持有泰国精英签证可免费享受VIP服务和其他特权。",
    "Over 20 years of age": "20周岁以上",
    "Donation of 600,000 to 2,000,000 Thai Baht": "支付60万-200万泰铢捐款",
    "By making a donation of 600,000 to 2,000,000 Thai Baht, one can obtain long-term residency status for 5 years, 10 years, or 20 years.":
      "支付60万-200万泰铢捐款，可以获得5年/10年/20年长期居留身份",
    "One time donation": "一次性捐赠",
    "2-3": "2-3个月",
    "Permanent Residency Program": "永居计划",
    "On January 12, 2021, the Identity Malta Agency formally announced a new policy for its permanent residency program, named the Malta Permanent Residence Program (MPRP). The cost of implementing the new policy is lower than the previous MRVP project, making the Malta program one of the most cost-effective investment projects on the market.":
      "2021年1月12日，马耳他身份局正式对外发布永居项目新政，名称是马耳他永居项目（Malta Permanent Residence Program），马耳他新政的办理成本低于之前的MRVP项目，也使得马耳他项目成为市场上最具性价比的投资项目之一",
    "Proof of assets worth 50,000 Euros (including proof of liquid assets worth 15,000 Euros)":
      "50万欧资产证明（其中含15万欧流动资产证明）",
    "Meet the government's investment requirements": "满足政府要求投资 ",
    "Administrative fee: 40,000 Euros": "行政管理费：4万欧",
    "Non-profit donation: 2,000 Euros": "非盈利捐款：2000欧",
    "Government donation: For property purchase method, the donation amount is 28,000 Euros; For the rental method, the donation amount is 58,000 Euros":
      "政府捐款：购房方式：捐款金额2.8万欧",
    "Property investment: Purchase a property worth over 300,000 Euros for a total of 5 years or lease a property worth 10,000 Euros per year for a total of 5 years.":
      "投资房产：购买价值超过30万欧元房产，共5年或租赁每年价值在1万欧元的房产，共5年",
    "At least 5 years": "至少5年",
    "8-10": "8-10个月",
    "Permanent Residency": "永居身份",
    "Citizenship by Investment Program": "投资入籍计划",
    "The Malta passport policy was launched in 2014. In July 2020, the Malta passport project reached its upper limit and was closed. At the end of November 2020, the new Malta passport policy, the Granting of Citizenship for Exceptional Services Regulations, 2020, was launched. This policy stipulates that the quota for new citizenship is 1500 families, with a maximum of 400 families per year.":
      "2014年马耳他护照政策推出，2020年7月，马耳他护照项目入籍名额达到其上限关停。2020年11月底，马耳他护照新政Granting of Citizenship for Exceptional Services Regulations,2020 推出，该项政策规定，新政入籍名额为1500组家庭，每年最高400组家庭。",
    "Hold Malta residence for 3 years or 12 months":
      "持有马耳他居留满3年或12个月",
    "Make a donation of 600,000 EUR or 750,000 EUR to the country of Malta.":
      "向马耳他国家捐款60万欧元或75万欧元；",
    "Purchase residential property in Malta valued at least 700,000 EUR and hold for at least 5 years; or lease a residential property with a rent of at least 16,000 EUR 3. per year for a minimum lease period of 5 years. Donate 10,000 EUR to local Maltese organizations involved in charity, culture, sports, science, art, or non-governmental organizations.":
      "在马耳他购买价值至少70万欧元的居住型房产需持有至少5年；或租赁一套租金至少1.6万欧元/年的居住型房产，租赁期至少5年。捐款1万欧元到马耳他当地的慈善、文化、体育、科学、艺术或非政府组织等机构",
    "15-18": "15-18个月",
    Citizenship: "公民身份",
    "188A": "188A",
    "The 188A visa, introduced by the Australian government in 1981 as an entrepreneurial immigration program, was previously known as the 163A visa. To date, it has a history of 40 years and is one of the oldest and most consistently stable immigration programs in the Commonwealth countries.":
      "澳洲188A签证，是澳洲政府于1981年推出的创业移民项目，前身是163A，迄今为止已有40年历史，是英联邦国家中历史久远，政策持续性更好的移民项目之一",
    "The main applicant is under 55 years old.": "主申请人年龄55周岁以下",
    "The individual and family net assets under the name of the couple are not less than 1.25 million AUD.":
      "夫妻名下个人及家庭净资产不低于125 万澳币； ",
    "In the past four fiscal years, in two of those fiscal years, the company's annual turnover is not less than 750,000 AUD.":
      "过去4个财年中其中2个财年, 公司年营业额不低于75万澳币；",
    "In the past four fiscal years, in two of those fiscal years, the shareholding ratio under the couple's name is not less than 30%; if it is a listed company, it is not less than 10%.":
      "过去4个财年中其中2个财年，夫妻名下持股比例不低于30%；如是上市公司则不低于10%；",
    "The score in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system is not less than 65 points.":
      "商业甄选系统（EOI）打分不低于65 分；",
    "As long as either spouse meets the entrepreneurial (residential) requirements, they can become the main applicant for the 888 Permanent Residence Visa.":
      "只要夫妻双方任意一人满足创业（居住）要求，即可成为888永居签证的主申请人 ",
    "During the 5-year residency visa period, meet the entrepreneurial requirements for 2 years.":
      "持5年居留签证期间，满足2年的创业要求； ",
    "During the 2 years of entrepreneurship, reside in the sponsoring state for at least 1 year.":
      ".创业2年期间，在担保州居住至少满1年  ",
    "Meet at least 2 of the following 3 conditions: 1) The family net assets under the couple's name reach 600,000 AUD; 2) The net assets of the company held reach 200,000 AUD; 3) Employ 2 local full-time employees.":
      "以下3个条件，满足至少2个条件：1）夫妻名下家庭净资产达到60万澳币；2）持有公司净资产达到20万澳币；3）雇佣2名当地全职员工",
    "At least 2 years": "至少2年",
    "21-27": "21-27个月",
    "188B": "188B",
    "The 188B visa, introduced by the Australian government in 1981 as an investment immigration program, was previously known as the 165 visa. To date, it has a history of 40 years and is one of the most consistently stable immigration programs in the Commonwealth countries.":
      "洲188B签证，是澳洲政府于1981年推出的投资移民项目，前身是165，迄今为止已有40年历史，是英联邦国家中，澳洲188B是政策持续性稳定的移民项目之一",
    "The main applicant must be under 55 years old.":
      "主申请人年龄55周岁以下；  ",
    "The family's net assets must not be less than 2.75 million AUD over the past two fiscal years.":
      "过去2个财年，家庭净资产不低于275万澳币；   ",
    "Have at least 3 years of successful investment experience, and in one of the past five years, have over 10% shares in a business or own an investment market value of 2.5 million AUD.":
      "3年以上的成功投资经验，并且过去五年有一年在生意里有10%以上的股份或拥有250万澳币的投资市值；",
    "Through the above business or investment, profits exceed 1 million AUD.":
      "通过以上生意或投资盈利超过100万澳币；",
    "Score no less than 65 points in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system.":
      "商业甄选系统（EOI）不低于65分； ",
    "Invest 2.5 million AUD in compliant investments.":
      "投资250万澳币到合规投资中",
    "Hold compliant investments of 2.5 million AUD, with the required proportions as follows:":
      "持有250万澳币的合规投资，比例要求如下：",
    "AUD 500,000 VC and growth private equity funds":
      "AUD 500,000 VC and growth private equity funds",
    "AUD 750,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 750,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 1,250,000 balancing investment": "AUD 1,250,000 balancing investment",
    "At least 3 years": "至少3年",
    "21-25": "21-25个月",
    "188C": "188C",
    "The Australian 188C Investment Immigration is an immigration program aimed at high-net-worth individuals. Applicants are required to declare assets of 5 million AUD and provide a reasonable explanation of the source of funds. Later, the assets of 5 million AUD will be liquidated and invested through a fund company.":
      "澳洲188C投资移民，是面向大资产人士的移民项目，申请人需要申报500万澳元的资产，合理的解释资金来源，后期将500万澳元的资产变现后通过基金公司投资",
    "No age restriction": "无年龄限制要求",
    "No points required, no English requirement": "无需打分，无英语要求",
    "Relaxed residence requirements for later conversion to permanent residence":
      "后期转永居对居住要求宽松",
    "Family assets above 5 million AUD, with a reasonable explanation of the source":
      "家庭资产500万澳币以上，合理解释来源",
    "Invest 5 million AUD in compliant investments":
      "投资500万澳币到合规投资中",
    "Hold compliant investments of 5 million AUD, with the required proportions as follows:":
      "合规投资至少500万澳币，比例要求如下：",
    "AUD 1,000,000 VC and growth private equity funds":
      "AUD 1,000,000 VC and growth private equity funds",
    "AUD 1,500,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 1,500,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 2,500,000 balancing investment": "AUD 2,500,000 balancing investment",
    "25-28": "25-28个月",
    "New Zealand Active Investor Plus": "新西兰主动投资者签证",
    "The Active Investor Plus Visa, established to attract high-value investors, came into effect on 19th September 2022. This new visa replaces the previous investor visa category.":
      "为吸引高价值投资者而设立的Active Investor Plus签证于2022年9月19日生效，新签证取代了之前的投资签证类别。",
    "Language: IELTS score of 5 or above.": "语言：雅思CLB 5以上；",
    "Residence: The main applicant must reside in New Zealand for at least 117 days within the 4-year investment period.":
      "居住：主申请人在投资4年中在新西兰至少居住117天；",
    "Stable Returns": "稳定回报",
    "Global ETFs": "全球ETFs",
    "High-quality Returns": "高质量回报",
    "Safeguarding": "保障性",
    "Log Only": "仅记录",
    "Global Sectors": "全球性",
    "Expected Return": "预期回报",
    "Coming Soon": "即将推出",
    "The fund aims to achieve positive performance": "该基金旨在取得积极的业绩",
    "Investment: Invest between 5 million and 15 million NZD in New Zealand over 4 years.":
      "投资：投资500万到1500万纽币在新西兰4年；",
    "Dependents: Children under the age of 24 who are economically dependent on the main applicant can be included in the application.":
      "24岁及以下经济依附于主申请人的子女可以包含在申请中",
    "Invest at least 5 million NZD directly into enterprises approved by the New Zealand Trade and Enterprise (NZTE), or;":
      "至少500万纽币直接投资到新西兰贸易企业局(NZTE)认可的企业，或；",
    "Invest at least 7.5 million NZD in equity funds or venture capital funds, or;":
      "至少750万纽币投资于股权基金或风投基金，或；",
    "Invest at least 15 million NZD in publicly listed company shares and charitable causes (50% each).":
      "至少1500万纽币投资于上市公司股票和慈善事业(各50%)。",
    "At least 4 years": "至少4年",
    "12-24": "12-24个月",
    "Canada Start-up Visa Program (SUV)": "加拿大创新移民计划（SUV）",
    "The Canadian Startup Visa (SUV) Program was launched by Immigration, Refugees and Citizenship Canada (IRCC) on April 1, 2013, initially as a 5-year pilot project. In 2018, IRCC announced that the program would become a permanent one. The main goal of the Startup Visa Program is to attract innovative foreign entrepreneurs who can contribute to Canada's economic and industrial development and create new job opportunities.":
      "加拿大创新移民计划（SUV）是加拿大联邦移民局在2013年4月1日发布的试点项目为期5年。2018年加拿大移民局宣布该项目成为永久项目。该项目主要的目的是为了吸引具有创新精神的外国企业家为加拿大的经济和行业发展做出新的贡献，创造就业机会",
    "Age: The main applicant must be over 18 years old.":
      "年龄：主申请人18周岁以上；",
    "Education: The main applicant must have a full-time college degree or higher.":
      "学历：主申请人全日制大专以上学历；",
    "Industry Background: Over 3 years of related background in the technology industry (such as IT, communications, biomedical technology, new energy, environmental protection, artificial intelligence, and other tech-related industries).":
      ".行业背景：3年以上科技行业的相关背景（IT、通讯，生物医疗科技、新能源，环保，人工智能等科技相关行业等)",
    "To establish an innovative business in Canada, there is no explicit investment amount requirement, but the amount is usually more than 100,000 Canadian dollars":
      "去加拿大成立一间创新类别公司，无明确的投资金额要求，通常金额要高于10万加币",
    "30-38": "30-38个月",
    "Portugal Golden Visa": "葡萄牙黄金居留计划",
    "On October 8, 2012, the Portuguese Ministry of Internal Affairs issued the Portuguese Golden Residence Permit Program. The law stipulates that citizens from non-EU countries can obtain the Portuguese Golden Residence Permit by making an investment in Portugal.":
      "2012年10月8日，葡萄牙内政部颁布葡萄牙黄金居留许可计划，法案规定：非欧盟国家的公民，可以通过在葡萄牙投资来获得葡萄牙黄金居留许可",
    "500,000 Euros in a fund approved by the Portuguese government":
      "基金: 50万欧元于葡萄牙政府批准的基金",
    "12+": "12+个月",
    "Greece Golden Visa": "希腊投资移民计划",
    "In order to increase domestic consumption and promote the development of the domestic real estate industry, the Greek government announced a new immigration policy for home buyers in July 2015. Non-EU citizens who invest in local properties worth more than 250,000 Euros in Greece can obtain permanent residency in Greece. In March 2016, the Greek government further relaxed the application conditions by removing the requirement for no criminal record. Starting from August 2023, the Greek property immigration policy will be adjusted, with the property purchase requirement in some areas rising from the original 250,000 Euros to 500,000 Euros.":
      "为了增加国内消费及推动国内房地产业的发展，希腊政府于2015年7月公布了新的买房移民政策，凡非欧盟国公民在希腊当地投资购买超过25万欧元的房产，即可获得希腊的永久居留许可，2016年3月，希腊政府又取消了对于无犯罪记录的要求使之申请条件更为宽松。2023年8月开始希腊购房移民将调整政策，部分区域购房要求从原来到25万欧元上涨到50万欧元",
    "Non-EU/European Economic Area/Swiss nationals":
      "非欧盟/欧洲经济区/瑞士国民",
    "At least 14 years old": "年满14岁或以上",
    "Property worth more than 250,000 Euros (some regions require more than 500,000 Euros)":
      "25万欧元以上的房产（部分地区要求50万欧元以上）",
    "Permanent possession": "永久持有",
    "6-8": "6-8个月",
    "St Lucia Citizenship by Investment": "圣卢西亚公民计划",
    "The Saint Lucia Citizenship by Investment Program requires applicants to make a significant economic contribution to the country. As an exchange, and after a strict application process and due diligence, the applicant and their family will be granted full citizenship. The Saint Lucia Citizenship by Investment Program is regulated by the Citizenship by Investment Act No. 14 of 2015.":
      "圣卢西亚投资入籍计划要求申请人为国家做出重大经济贡献。 作为交换，并经过严格的申请程序和尽职调查，申请人及其家人将获得完整的公民身份。 圣卢西亚投资入籍计划受2015年第14号投资入籍法监管。",
    "Donations:": "捐赠：",
    "$100,000 (single applicant); or": "10万美元（单人）；或",
    "$140,000 for a couple; or": "夫妻14万美元；或",
    "$150,000 for a family of 3-4, with each additional person beyond 5 requiring an additional investment of $15,000.":
      "3-4人申请15万美金，5人及以上申请每增加一位增加1.5万美金投资",
    Months: "月份",
    Category: "类别",
    "At least 18 years old": "年满18岁或以上；",
    "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth.":
      "2019年2月的新加坡财政预算案中，财政部部长宣布，针对家族办公室将给予更优惠的免税方案：扩大免税的涵盖范围，并将现行的免税政策13O和13U规定的优惠计划，继续延长至2024年12月31日。新加坡家族办公室的架构主要是由主申请人在新加坡设立单一家族办公室由自己或家庭成员一起成立的两家公司， 一个是基金公司， 一个是基金管理公司（家族办公室)。家族办公室的控股人可以是个人或者控股公司形式。控股公司可以由信托公司持有，以确保财富的隔离。",
    "AUM SGD 20 million": "管理资产：2000万新币",
    "At least SGD 200,000 in total business spending":
      "每年在新加坡的运营开支至少20万新币",
    "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member.":
      "聘请2名专业投资人士，其中一人非家族成员",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market.":
      "至少10％或1000万元（以较低者为准）资金投资在新加坡本地市场",
    "Maintain until application for permanent residency.": "维持到申请永居",
    "AUM SGD 50 million": "管理资产：5000万新币",
    "At least SGD 500,000 in total business spending":
      "每年在新加坡的运营开支至少50万新币",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies, and private equity investments in local unlisted companies. Newly added are climate-related investments and blended finance in which Singapore financial institutions have broad participation.":
      "至少10％或1000万元（以较低者为准）资金投资在本地市场。包括新加坡交易所挂牌的股票、合格债券、本地基金公司发行的基金和本地非上市公司的私募投资。新增与气候相关投资及新加坡金融机构广泛参与的混合融资",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies and private equity investments in local non-listed companies. New additions include climate-related investments and blended finance widely participated by Singapore financial institutions.":
      "至少10％或1000万元（以较低者为准）资金投资在本地市场。包括新加坡交易所挂牌的股票、合格债券、本地基金公司发行的基金和本地非上市公司的私募投资。新增与气候相关的投资及新加坡金融机构广泛参与的混合融资",
    "63% fixed-income assets": "63%固定收益资产",
    "34% equity assets": "34% 股票资产",
    "3% derivatives": "3% 衍生品",
    "37% equity assets": "37% equity assets",
    "100% fixed-income assets": "100% 固定收益资产",
    "20% fixed-income assets": "20%固定收益资产",
    "80% equity assets": "80% 股票资产 ",
    "Are you New User or an Existing DL Holding Client":
      "您是新用户还是DL Holdings的现有客户？",
    "I’m a New User": "我是新用户",
    "I’m an Existing Client": "我是现有客户",
    "Bank Account Number": "收款银行户口",
    "Account Name": "户口名称",
    "Bank Name": " 银行名称",
    "Bank Code": "银行代码",
    "Remittance Code": "汇款代码",
    "Please check your spam or junk mail folder to make sure you didn’t miss the OTP email. If issues persist, please reach out to ":
      "请检查您的垃圾邮件文件夹，确保您没有错过OTP电子邮件。如果问题仍然存在，请联系",
    "Bank of Communications": "交通銀行",
    "Payment Proof": "付款证明",
    "View Payment Proofs": "查看付款证明",
    "view": "查看",
    "Upload Proof": "上传付款证明",
    "PI Account": "PI账户",
    "PRODUCTS": "产品",
    "Investments": "投资",
    "Please complete the security account opening flow": "请完成证券账户开户流程",
    "Go to Investments": "前往投资",
    "LOGIN": "登录",
    "Login": "登录",
    "Login ID": "登录 ID",
    "Enter Login ID": "输入登录 ID",
    "Password": "密码",
    "Enter password": "输入密码",
    "Show": "显示",
    "Hide": "隐藏",
    "Forgot Password?": "忘记密码？",
    "Strategy Information": "策略信息",
    "Performance Comparison": "性能比较",
    "Name": "产品名称",
    "Investment Structure": "产品结构",
    "Discretionary Account": "全权委托账户",
    "Minimum Investment Amount": "投资门槛",
    "No less than $200,000 (With $1,000,000 asset proof)": "不少于$200,000（需有$1,000,000资产证明）",
    "Open Frequency": "锁定期",
    "Fully redemption after 6-month lock-up period": "6个月锁定期后全额赎回",
    "Quarterly redemption with no redemption fee (1-month notice period )": "季度赎回，无赎回费用（1个月通知期）",
    "Top 5 Holdings": "前 5 名持股",
    "BACK": "返回",
    "READ MORE": " 阅读更多 >",
    "Fixed Income": "固定收益",
    "Equity": "股权",
    "Cash": "现金",
    "as of today": "截至今日",
    "Stock Holdings": "持股",
    "Cash Holdings": "现金",
    "Portfolio Summary": "投资组合摘要",
    "Product Type": "产品类型",
    "Product Name": "产品名称",
    "Currency": "货币",
    "Closing Price At": "收盘价在",
    "Market Value": "市场价值",
    "Profit/(Loss)": "利润/(损失)",
    "Phone": "电话号码",
    "Select Product": "选择产品",
    "Flagship 1 Teaser": "旗舰策略 1 簡介",
    "Flagship 2 Teaser": "旗舰策略 2 簡介",
    "Flagship 3 Teaser": "旗舰策略 3 簡介",
    "US Treasury Teaser": "DL 美国国债策略 簡介",
    "SHOW LESS" : "显示较少",
    "DL Flagship Strategy": "德林旗舰策略 I",
    "DL Flagship II Strategy": "德林旗舰策略 II",
    "DL Flagship III Strategy": "德林旗舰策略 III",
  },
  "zh-hk": {
    "Payment Info": "付款資訊",
    "Basic Information": "基本訊息",
    "Manage your financial future: Access account information and explore your investment portfolio, all conveniently located in one place.": "管理您的財務未來：存取帳戶資訊並探索您的投資組合，所有這些都方便地位於一處。",
    "Account": "我的帳號",
    "USER PROFILE": "使用者個人資料",
    "click the button in the top-right corner to speak with our experts": "點擊右上角的按鈕與我們的專家交談",
    "For tailored advice,": "針對量身定制的建議",
    "Try DL Assistant": "嘗試 DL 助手",
    "Use 'DL Assistant' for instant help.": "使用 'DL 助手' 獲得即時幫助。",    
    "Quick Answers?": "尋找快速答案?",
    "Explore our comprehensive suite of products and expert advisory services tailored for Hong Kong's Capital Investment Entrant Scheme (CIES) clients.": "了解我們為香港資本投資者入境計劃 (CIES) 客戶量身定制的全面產品和專家諮詢服務。",
    "highlight": "精華",
    welcome: "歡迎來到你的",
    "AI Family Office!": "人工智能家族辦公室!",
    welcome_subtext: "添加資金，開始構建你的投資組合 ",
    get_started: "請輸入您的電郵地址​",
    get_started_subtext: "我們將使用該電郵地址登錄您的帳戶",
    already_a_member: "以註冊用戶？請登錄",
    not_a_user: "還不是用戶？請註冊",
    next: "下一步​",
    six_digit_code: "請輸入六位數字代碼",
    six_digit_code_sub_text: "我們已向您的電郵地址發送了一個6位數的驗證碼​",
    request_new_code: "請求一個代碼在",
    sec: "秒",
    personal_information: "個人資料",
    use_a_legal_name: "根據您的政府授權文件，請使用您的法定姓名​",
    first_name: "名字​",
    middle_name: "輔助名",
    last_name: "姓氏",
    what_is_investor_status: "請確認您的投資者身分​",
    what_is_investor_status_subtext:
      "這將確認您是否有資格進行私人投資和獲擴展的定價選項",
    select_all_apply: "請選擇適用項",
    investor_status1: "個人淨資產超過100萬美金​",
    investor_status2: "预預計將進行超過500美金的投資​",
    investor_status3: "專業人士/持有許可證的個人投資者",
    investor_status4: "以上都不適用​",
    legal_agreements_text: "法律協議​",
    legal_agreements1: "專業投資者免責聲明",
    legal_agreements2: "被視為專業投資者的後果",
    continue: "繼續​",
    "Waiting on approval": "等待批准",
    "Products": "產品",
    home: "主頁 ",
    investments: "家辦投資",
    services: "家辦服務",
    cies: "投資移民",
    fo_strategy: "家辦策略",
    fo_services: "家辦服務",
    alternatives: "另類投資",
    dlgpt: "DLGPT",
    account: "我的帳號",
    portfolio: "我的組合",
    complete_account_setup: "完成帳戶註冊",
    download_security_form: "Download Security Form",
    not_sure_where_to_start: "我不知道從哪裡開始 ",
    not_sure1: "我不知道從哪裡開始 ",
    not_sure2: "什麼是DLiFO上最受歡迎的產品 ?",
    not_sure3: "我想要分散風險，但是不知道該怎麼做",
    not_sure4: "定存利率是x%。怎麼才能收益打敗它？",
    not_sure5: "我想要保險 ",
    "Fix deposits is at x%. How can I beat that?": "定存利率是x%。怎麼才能收益打敗它？",
    "That's OK. Lets start with a few questions.":
      "沒關係，讓我們從幾個問題開始。 ",
    "Which of the following do you think best fits your needs:":
      "您認為以下哪項最符合您的需求：",
    "Help me invest": "幫我投資 ",
    "I need help with identity planning": "我要移民 ",
    "I need estate planning": "我需要遺產規劃 ",
    "I'm interested in trust services": "我對信託服務感興趣 ",
    "I need insurance": "我需要保險 ",
    "Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?":
      "太好了，您正在考慮進行投資！為了更好地理解我們如何能夠協助您，請你告訴我以下哪個最符合您的投資目標？ ",
    flagship1: "Flagship 旗艦策略 1",
    ": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment":
      ": 我們的旗艦產品。由DLAM13年的投資經驗指導，在增長和穩定之間尋求平衡。 ",
    flagship2: "Flagship 旗艦策略 2",
    "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.":
      "Flagship 旗艦策略 2：利用全球多元化和ETF的流動性，進行有效風險管理。 ",
    "Flagship 3: Optimizing higher growth through strategic investments in public and private securities":
      "Flagship 旗艦策略 3：精選公共市場和私募市場證券的戰略性投資，追求更高的增長潛力。 ",
    "US Treasuries: Protecting your capital through diversified US Treasury":
      "美國國債：多元化的美債組合，為您的資金安全保駕護航 ",
    "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%":
      "旗艦策略是由 DLAM 利用 DL 集團13年的投資策略和經驗推出和管理的標準化投資策略。該策略旨在通過資產地理多元化來保全財富，這是一般的大眾富裕投資者無法做到的。旗艦 1 號的投資策略既安全穩定，又能實現收益和效率的最大化。在過去 7 年中，該策略實現了年均約 6% 的複合增長率，累計絕對回報率超過 50%。 ",
    "Explore Flagship 1": "繼續探索 Flagship 1 ",
    "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform.": "透過我們平台上的DL 家族辦公室策略、另類投資和獨家基金產品，最大化您的投資組合。",
    "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.":
      "旗艦 2 號策略是為那些希望充分利用全球分散投資和高流動性ETF所帶來的優勢的投資者量身定制的。該策略由我們資歷深厚的團隊管理，他們憑藉對全球市場的深入瞭解構建了一個多樣化的投資組合。投資者可以借此策略享受到專業管理的多元ETF組合的益處，這些ETF覆蓋了不同的資產類別，而投資者本人無需進行深入的市場分析或具備專業的投資知識。 ",
    "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.":
      "旗艦 3 號基金是追求精心管理和投資多樣化的投資者的完美選擇。我們致力於的專業團隊利用他們對公開市場和私募市場的深入認識，精選組建了這一支基金。這樣，投資者就能夠輕鬆享受到由專業團隊打造的多樣化投資組合所帶來的潛在收益，而無需自己進行深入的市場研究或具備專業的投資知識。 ",
    "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.":
      "在投資策略中，資本保值至關重要。我們的美國國債策略依託於美國政府的信譽和信用，是最安全的投資方式之一。此策略涵蓋了短期、中期和長期的國債，根據市場條件提供了風險與收益的均衡配置。 ",
    "What is your prefered destination?": "好的，您的目標地點是哪裡？ ",
    "Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance.":
      "保險在長期財富管理和保護中起著關鍵作用。以下是我們推崇保險的四大理由： ",
    "Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth.":
      "風險管理與保護：保險能夠在你遭受如死亡或疾病等風險事件時，防止經濟損失，保護你的財富不受侵蝕。 ",
    "Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries.":
      "遺產規劃：人壽保險可以幫助順暢地將財富傳給後代，用以支付遺產稅和其他相關費用，避免資產的急速出售，為受益人提供更穩固的經濟保障。 ",
    "Investment:Some insurance policies have a savings component that grows over time, adding to your wealth.":
      "投資價值：部分保險產品含有儲蓄成分，這部分隨著時間的推移可以增值，為你的財富積累增添助力。 ",
    "Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation.":
      "稅務籌畫：保險還能提供稅務上的好處，例如免稅的身故賠付和免稅的資金增值，幫助你在增長和保全財富方面獲得優勢。 ",
    "Book a consultation today": "立即預約諮詢 ",
    "What is the most popular product on DL DFO?":
      "什麼是DLiFO上最受歡迎的產品 ",
    ": This is our flagship strategy, trusted and utilized by traditional family offices. It offers a balanced approach to investing with a focus on stable returns and capital safety.":
      ": 這是我們的旗艦策略，深受傳統家族辦公室的青睞。它秉承一種均衡的投資理念，專注於實現穩定的收益和資本保護。",
    "DL US Treasuries: With current U.S. interest rates at cycle highs and an uncertain macroeconomic climate, U.S. Treasury bonds have become increasingly popular. This strategy focuses on these bonds, providing a steady and dependable income while also ensuring capital preservation.":
      "DL 美國國債：考慮到目前美國利率正處於週期性高點，加之宏觀經濟狀況的不確定性，美國國債吸引了更多投資者的目光。本策略專注於投資於美國國債，旨在為投資者提供穩固可靠的收入流，並確保資本得到保全。 ",
    "Explore DL US Treasuries": "探索DL美国国债 ",
    "Diversification can indeed be tricky, but our Flagship Strategy 1 can help you jump right in.":
      "確實，在投資中分散風險可能會有些複雜，但我們的旗艦策略 1 可以幫助您輕鬆入門。 ",
    "Flagship Strategy 1 spreads your money across different asset classes and global regions. This can lower your risk and let you benefit from growth opportunities anywhere in the world.":
      "旗艦策略 1 將您的資金分散投資於不同的資產類別和全球不同地區。這樣可以降低您的風險，並讓您有機會從世界各地的增長中受益。 ",
    "With Flagship Strategy 1, you don't have to worry about the details. Our experts handle everything, so you can enjoy the benefits of diversification":
      "選擇旗艦策略 1，您無需擔心投資的細節。我們的專家會處理一切，讓您享受多元化投資帶來的好處。 ",
    "FO strategy": "FO策略 ",
    "That determine your eligibility for private investments and expanded pricing options":
      "享受符合FO品質標準的標準化產品，確保您的投資體驗既專業又高效。 ",
    "TALK TO AN EXPERT": "諮詢專家  ",
    "FOR A PERSONALISED APPROACH": "量身定制的投資指導",
    "Flagship 1": "旗舰策略 1",
    "Focus on achieving balanced and stable returns": "專注於均衡穩定的回報 ",
    "Flagship 2": "旗舰策略 2",
    "Explore a diversified investment portfolio with global ETFs":
      "探索全球ETF的多元化投資組合 ",
    "Flagship 3": "旗舰策略 3",
    "Well-crafted portfolios in both public and private market investments":
      "精心策劃的公開市場和私募市場投資組合 ",
    "DL US Treasuries": "DL 美國國債 ",
    "Safeguarding your funds": "為您的資金保駕護航 ",
    "PE Funds": "私募基金 ",
    "100+ funds, details to follow": "100+ 支基金，敬請期待 ",
    "COMING SOON": "敬請期待 ",
    Invest: "投資 ",
    "Review your account": "查看您的帳戶 ",
    "Product Overview": "產品概述 ",
    "The goal of this strategy is to pursue high returns while emphasizing asset stability. It focuses on stable sources of income to ensure the safety of funds. By flexibly allocating products with higher absolute returns, it can maximize asset appreciation. At the same time, by establishing a diversified investment portfolio and selecting assets with low correlation to the broader market, it can reduce the systematic risk of the overall investment portfolio. This strategy aims to balance risk and return, provide stable returns, and protect assets from market fluctuations. By considering various investment opportunities and asset classes, this strategy can better adapt to different market environments and provide long-term financial security for investors.":
      "該策略的目標是追求高回報，同時強調資產的穩定性。它注重穩定的收入來源，以確保資金安全。通過靈活配置絕對回報較高的產品，最大限度地實現資產增值。同時，通過建立多元化的投資組合，選擇與大盤相關性較低的資產，降低整體投資組合的系統性風險。這一策略旨在平衡風險與收益，提供穩定的回報，並保護資產免受市場波動的影響。通過考慮不同的投資機會和資產類別，該策略可以更好地適應不同的市場環境，為投資者提供長期的財務保障。 ",
    Allocation: "配置 ",
    "Asset Class Allocation": "資產類別分配 ",
    "60% fixed-income assets": "60% 固定收益資產 ",
    "40% equity assets": "40% 股票資產 ",
    "Geographical allocation": "地理分配 ",
    "Geographical distribution (%)": "地理占比 (%)  ",
    Performance: "年度迄今表現 ",
    "North America": "北美 ",
    "Asia Pacific (excluding Japan)": "亞太地區（除日本外） ",
    World: "全球",
    Europe: "歐洲",
    Japan: "日本",
    Total: "總計",
    "Top holdings": "主要持倉 ",
    "Fees and expected return": "費用和預期回報 ",
    "Expected return": "預期回報 ",
    "Subscription Fee": "認購費 ",
    "Management Fee": "管理費 ",
    "Performance Fee": "表現費 ",
    Comparison: "對比 ",
    Return: "回報 ",
    "Sharp Ratio": "夏普比率 ",
    "Data room": "資料室 ",
    "This strategy consists of globally highly liquid exchange-traded funds (ETFs). Its uniqueness lies in its high liquidity, transparency, and global asset allocation strategy, aiming to effectively counter market risks. First, the high liquidity of this product enables investors to quickly buy and sell shares, facilitating convenient fund flow. Investors can adjust their investment portfolios quickly to adapt to market changes, whether in normal market conditions or high volatility. Second, high transparency is another notable feature of this product. Investors can access real-time information on the fund's holdings, asset allocation, daily net asset value, and market prices. This transparency provides investors with better decision-making tools, enabling them to assess risks and returns more accurately. Most importantly, this product adopts a global asset allocation strategy. It covers a wide range of targets across different regions, industries, and asset classes to achieve portfolio diversification. By diversifying investments globally, this product can reduce the risks of specific markets or industries and provide more stable investment returns.":
      "該策略由全球高流動性指數基金（ETF）組成。其獨特之處在於高流動性、高透明度和全球資產配置策略，旨在有效抵禦市場風險。 首先，該產品的高流動性使投資者能夠快速買賣基金份額，方便資金流動。無論是在正常市場條件下，還是在劇烈波動時，投資者都可以迅速調整投資組合，以適應市場變化。 其次，高透明度是該產品的另一個顯著特點。投資者可以即時瞭解基金的持股情況、資產配置、每日資產淨值和市場價格。這種透明度為投資者提供了更好的決策工具，使他們能夠更準確地評估風險和收益。 最重要的是，該產品採用全球資產配置策略。它涵蓋了不同地區、行業和資產類別的更廣泛目標，以實現投資組合的多元化。通過在全球範圍內分散投資，該產品可以降低特定市場或行業的風險，提供更穩定的投資回報。 ",
    "This strategy focuses on helping clients invest in high-quality funds in the market. It aims to provide clients with the most reasonable rates to ensure they can enjoy premium fund management services. Additionally, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. Firstly, by carefully screening, evaluating, and selecting high-quality funds in the market, it provides clients with a wide range of investment choices. These funds have good reputations and records in terms of management teams, performance, and risk control, ensuring investment quality and returns for clients. Secondly, this strategy emphasizes the reasonableness of rates. It strives for the optimal rate conditions for clients through fine rate design and negotiation. This allows clients to enjoy high-quality fund management services at lower costs, thereby increasing investment returns. Meanwhile, this product combines with the macro strategies of the DL Family Office to achieve steady growth of client assets. The DL Family Office has rich investment experience and expertise, capable of conducting indepth analysis of macroeconomic trends and adjusting investment portfolios based on market changes. This combination enables clients to flexibly respond to market fluctuations and achieve stable asset growth.":
      "本策略專注於說明客戶投資於市場上的優質基金。它旨在為客戶提供最合理的費率，確保客戶能夠享受到優質的基金管理服務。此外，本產品還與德林家族辦公室的宏觀策略相結合，實現客戶資產的穩健增長。 首先，通過對市場上優質基金的精心篩選、評估和甄選，為客戶提供了廣泛的投資選擇。這些基金在管理團隊、業績表現、風險控制等方面都有良好的口碑和記錄，確保了客戶的投資品質和回報。其次，這一策略強調利率的合理性。通過精細化設計和談判，為客戶爭取最佳費率條件。讓客戶以較低的成本享受到優質的基金管理服務，從而提高投資收益。同時，該產品與 DL Family Office 的宏觀策略相結合，實現了客戶資產的穩健增長。DL 家族辦公室擁有豐富的投資經驗和專業知識，能夠對宏觀經濟走勢進行深入分析，並根據市場變化調整投資組合。這種組合使客戶能夠靈活應對市場波動，實現資產的穩定增長。 ",
    "The investment portfolio of this strategy mainly consists of U.S. government bonds with different maturities. U.S. government bonds with different maturities have distinct risk and return characteristics. By investing in U.S. government bond ETF products with various maturities, this product can achieve balanced returns in different market environments. When the market experiences significant volatility, short-term U.S. government bonds can provide relatively stable income and capital protection. In more optimistic market conditions, long-term U.S. government bonds may offer higher returns. This strategy aims to provide investors with a conservative investment option for reliable returns. By investing in U.S. government bond ETF products, investors can gain income from U.S. government bonds and to some extent reduce the risk of the investment portfolio. This strategy is suitable for investors seeking stable income and capital preservation.":
      "本策略的投資組合主要包括不同期限的美國政府債券。不同期限的美國政府債券具有不同的風險和回報特徵。通過投資不同期限的美國政府債券ETF產品，本產品可在不同的市場環境下獲得均衡的回報 當市場大幅波動時，短期美國政府債券可提供相對穩定的收益和資本保障。在較為樂觀的市場環境下，長期美國政府債券可提供較高的回報。 該策略旨在為投資者提供一個回報可靠的保守型投資選擇。通過投資美國政府債券 ETF 產品，投資者可從美國政府債券中獲取收益，並在一定程度上降低投資組合的風險。、 該策略適合追求穩定收益和資本保值的投資者。 ",
    "FO services": "FO服務 ",
    "Your online family office": "您的線上家族辦公室 ",
    "Residency Planning": "身份規劃",
    "Trust Planning": "信託規劃 ",
    "Taxation Planning": "稅務規劃 ",
    "Income Planning": "收入規劃",
    "Customized your identity layout": "定制您的身份佈局 ",
    "Safeguarding your wealth inheritance": "守護您的財富傳承",
    "Professional tax strategies.": "專業稅務策略 ",
    "Thoroughly assess your income situation": "詳盡評估您的收入狀況 ",
    "Hong Kong": "香港",
    "Attracting top": "面向全球",
    "talent globally": "吸引頂尖人才 ",
    Singapore: "新加坡",
    "Attracting high-net-worth family offices": "吸引高淨值家族辦公室",
    "Attracting high-net": "吸引高淨值",
    "-worth family offices": "家族辦公室",
    Thailand: "泰國",
    "Introducing long-term": "推出長期",
    "residency visas": "居留簽證",
    Malta: "馬爾他",
    "Low-threshold access": "低門檻獲",
    "to EU citizenship": "取歐盟護照 ",
    Australia: "澳大利亞",
    "Offering a diverse range of entrepreneurship and investment immigration options":
      "提供多元化創業及投資移民選擇",
    "Offering a diverse": "提供多",
    "range of entrepreneur-": "元化創業",
    "-ship and investment": "及投資",
    "immigration options": "移民選擇",
    "New Zealand": "紐西蘭",
    "Attracting high-value investments to promote economic development":
      "吸引高價值投資促進經濟發展",
    Canada: "加拿大",
    "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy":
      "鼓勵創新創業人才移民貢獻經濟",
    "Encouraging innovative": "鼓勵創新",
    "and entrepreneurial": "創業人才",
    "talents to immigrate and": "移民貢",
    "contribute to the economy": "獻經濟",
    Portugal: "葡萄牙",
    "Attain EU residency rights through investment": "通過投資獲取歐盟居留權",
    "Attain EU residency": "通過投資獲取",
    "rights through investment": "歐盟居留權",
    Greece: "希臘",
    "Attain permanent residency through real estate investment":
      "通過房產投資獲得永久居留",
    "Attain permanent": "通過房產",
    "residency through real": "投資獲得",
    "estate investment": "永久居留",
    "St Lucia": "聖露西亞",
    "Attain citizenship by making economic contributions to the country":
      "對國家做出經濟貢獻獲得公民身份",
    "Attain citizenship by": "對國家",
    "making economic": "做出經濟",
    "contributions to the": "貢獻獲得",
    "country": "公民身份",
    Introduction: "專案簡介",
    "Application Requirements": "申請條件",
    "Investment Requirements": "投資要求",
    "Investment Duration": "投資時長",
    "Processing Time": "辦理週期",
    "Indentity Category": "身分類別",
    "Processing time and identity category": "處理時間和身份類別",
    "Net Asset Value ($)": "淨資產價值（美元)",
    "Portfolio Allocation (as of today)": "投資組合分配（截至今日)",
    "Private Equity": "私募股權",
    "Account settings": "帳戶設置",
    "Account Verification Status": "帳戶驗證狀態 ",
    "General Account": "普通帳戶 ",
    "Securities Account": "證券帳戶 ",
    Verified: "已認證 ",
    "Get verified": "進行驗證",
    "Download as PDF": "下載為PDF",
    Others: "其他",
    "Investment Objective(s)": "投資目的",
    "Sign Out": "退出登錄 ",
    "Private Credit": "私募信貸",
    "Real Estate": "地產",
    FOF: "FOF",
    "Private Deals": "私募股權",
    "Hedge Funds": "對沖基金 ",
    Other: "其他 ",
    "Investment Strategy": "投資策略 ",
    "Core Advantages": "核心優勢 ",
    "Geographic Focus": "地理焦點 ",
    Investment: "投資",
    "Service Provider": "服務提供者 ",
    "Key Term and Structure": "關鍵條款和結構 ",
    "Key Term": "關鍵條款 ",
    "US Treasuries": "美國國債",
    "Available Trust Types ": "可用信託類型 ",
    "Legal System": "法律體系 ",
    "Proper Laws Jurisdictions": "適用法律管轄區 ",
    "Powers held by the Settlor/person designated by the Settlor/Trustee":
      "由受託人/受託人指定的人/設立人持有的權力",
    "Assets Injected": "注入資產 ",
    "Discretionary Trust": "自由裁量信託",
    "Based on English Common Law": "基於英格蘭普通法 ",
    "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan":
      "香港、英屬維京群島、新加坡、開曼群島、納閩 ",
    "Powers to manage the trust assets and to make distributions to beneficiaries are all held by the trustee to excercise at its discretion":
      "受託人擁有管理信託資產和向受益人分配的全部權力，可以自行行使這些權力 ",
    "Diversified investment portfolios & low risk investment products":
      "多元化投資組合 & 低風險投資產品 ",
    "Reserved Powers Trust": "保留權力信託 ",
    "Settlor reserves and retains control over certain powers, and most commonly seen is the power to make investment decisions over the trust assets, He or she can also appoint an investment manager":
      "設立人保留並保持對某些權力的控制，最常見的是對信託資產的投資決策權，他或她還可以指定一名投資經理 ",
    "Low to high risk investment products, concentrated holding investments & operating companies":
      "低至高風險投資產品、集中持股投資 & 運營公司 ",
    "BVI VISTA Trust Cayman STAR Trust Labuan Special Trust":
      "BVI VISTA信託、開曼STAR信託、納閩特殊信託 ",
    "The company directors (who can be the settlor of the trust or appointed by the settlor) remain entirely responsible for managing the BVI Holdco and all the trust assets held beneath it":
      "公司董事（可以是信託的設立人或由設立人指定）完全負責管理BVI控股公司及其下所有信託資產 ",
    "Private Trust Company (PTC)": "私人信託公司（PTC） ",
    "British Virgin Islands, Cayman Islands": "英屬維京群島、開曼群島 ",
    "Stay involved with management of family trust assets as a director of the PTC":
      "作為PTC的董事參與管理家族信託資產 ",
    "Similar to a normal discretionary trust, but a Special Trust Advisor can be appointed to guide the trustee on investment decisions over the trust assets":
      "類似于普通的自由裁量信託，但可以指定一名特別信託顧問來指導受託人關於信託資產的投資決策 ",
    "Limited Partnership Trust": "有限合夥信託 ",
    Labuan: "納閩 ",
    "Stay involved with management of family trust assets as a director of the General Partner of the Limited Partnership":
      "作為有限合夥的普通合夥人的董事參與管理家族信託資產 ",
    "Special Provisions Trust": "特殊條款信託 ",
    "Discretionary Trust with Special Trust Advisor":
      "帶有特別信託顧問的自由裁量信託 ",
    "Allocation on Private Credit Opportunities on High Defensive Business":
      "在高防禦性業務上分配私人信貸機會 ",
    "Emphasis on Downside Protection with Potential Equity Upside":
      "強調防守保護並具有潛在的股權上升空間 ",
    "Proprietary Sourcing by Leveraging Long Standing Relationships":
      "利用長期關係進行專有採購 ",
    "Active Portfolio Management & Risk Controls":
      "積極的投資組合管理與風險控制 ",
    "Long Only Credit Strategy with Target IRR of Low to Mid-Teens on an unlevered basis":
      "長期唯一信貸策略，目標內部收益率在未杠杆化基礎上為低到中十幾歲 ",
    "Low correlation with overall market volatility":
      "與整體市場波動的低相關性 ",
    "The source of rare and quality investment opportunities, ability to reach out to the best investment opportunities benefited from DL’s long-term partnership with corporates, leading credit managers and industry advisors":
      "稀缺且優質的投資機會來源，能夠抓住最好的投資機會，受益於DL與企業、領先的信用管理人和行業顧問的長期合作 ",
    "Solid and quality collaterals": "優質的抵押品 ",
    "A rigorous due diligence and screening process with relevant resources and experience to evaluate investment opportunities":
      "嚴格的盡職調查和篩選過程，具有相關資源和經驗來評估投資機會 ",
    "Core focus on developed economics, including Hong Kong, Singapore and U.S.":
      "核心關注發達經濟體，包括香港、新加坡和美國 ",
    "Opportunistic Exposure to Emerging Asia": "對新興亞洲的戰術性接觸 ",
    "Fund Administrator:": "基金管理員：",
    "Cayman Islands Legal Counsel:": "開曼群島法律顧問：",
    "Auditor:": "審計師：",
    "Fund Name": "基金名稱 ",
    "Fund Manager": "基金經理 ",
    Domicile: "註冊地 ",
    "Cayman Islands": "開曼群島 ",
    "Base Currency": "基礎貨幣 ",
    "Lock up period": "鎖定期 ",
    "12 months": "12 個月 ",
    "Subscription Frequency": "認購頻率 ",
    Monthly: "月度 ",
    "Minimum initial subscription": "最低初始認購 ",
    "Minimum subsequent Subscription": "最低後續認購 ",
    "Redemption Frequency": "贖回頻率 ",
    "Notice period": "通知期 ",
    "180 calendar days": "180 天 ",
    quarterly: "季度 ",
    "Filters": "過濾器",
    "Advanced": "進階",
    "Categories": "類別",
    "Minimum Initial": "最小初始值",
    "AUM": "資產管理規模",
    "Search Products": "搜尋產品",
    "Enter keywords here...": "在此輸入關鍵字...",
    "Seeks to invest primarily in fixed-income securities and credit opportunities,":
      "旨在主要投資於固定收益證券和信貸機會， ",
    "including but not limited to listed or unlisted loans and debt instruments, secured or unsecured loans and debt instruments":
      "包括但不限於上市或非上市貸款和債務工具，有抵押或無抵押貸款和債務工具。 ",
    "Ability to reach out to the best fund managers due to DL's long-term relationship with global funds":
      "由於DL與全球基金的長期關係，能夠聯繫到最佳基金經理 ",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process":
      "通過嚴格的盡職調查和篩選過程，以及獨特的投資過濾流程，構建成功的投資組合 ",
    "A real estate project located in California, USA":
      "位於美國加利福尼亞州的房地產項目 ",
    "891 acre residential development site with 73 estate lots":
      "891英畝的住宅開發地，共有73個地產用地 ",
    "Average lot size of 4.38 acres with and equestrian center located in the heart of Carmel Valley":
      "平均每個地塊大小為4.38英畝，並擁有位於卡梅爾穀中心的馬術中心 ",
    "FA & Custodian : ": "FA & 託管人 : ",
    "Fund Legal Counsel: ": "基金法律顧問: ",
    "Auditor: ": "審計師: ",
    "DL Special Opportunities SP": "DL 特別機會 SP",
    "Disruptive Technologies Inc": "顛覆性技術公司",
    "Maintain a low correlation with the market and reduce the overall systemic risk of the portfolio while pursuing high absolute returns.":
      "保持與市場的低相關性，並在追求高絕對回報的同時降低投資組合的整體系統性風險。 ",
    "Invest in multiple funds to avoid the high volatility of investing in a single fund.":
      "投資於多個基金，以避免投資單一基金的高波動性。 ",
    "Focus on funds that use non-traditional investment methods (i.e. fund performance has low correlation with overall market volatility and are more resistant to volatility)":
      "專注於那些使用非傳統投資方法的基金（即基金業績與整體市場波動性低相關，並且對波動性有更強的抵抗力）。 ",
    "Ability to reach out to the best fund managers due to DL’s long-term relationship with global funds.":
      "由於DL與全球基金的長期關係，能夠聯繫到最佳基金經理。 ",
    "Utilize a strong network of professional parties such as top tier investment banks in sourcing up-and-coming and established funds.":
      "利用頂尖投資銀行等專業機構的強大網路來尋找新興和已建立的基金。 ",
    "Build a successful investment portfolio via rigorous due diligence and screening process, and unique investment filtering process.":
      "通過嚴格的盡職調查和篩選過程，以及獨特的投資過濾流程，構建成功的投資組合。 ",
    "Voyage Fund invests in a basket of cross-regional diversified hedge funds.":
      "Voyage Fund投資於一籃子跨區域多元化對沖基金。 ",
    "Effectively reduce portfolio volatility by diversifying into different hedge funds.":
      "通過多元化投資於不同的對沖基金有效降低投資組合波動性。 ",
    "From Jan. 2023 to the present, the annual yield is 2.19%. Compared to MSCI China, Hang Seng, CSI 500, the fund's monthly average return has remained stable.":
      "從2023年1月至今，年收益率為2.19%。與MSCI中國、恒生指數、中證500相比，該基金的月平均回報保持穩定。 ",
    "Comparison of trust Jurisdictions": "信託司法管轄區比較",
    "Quality Migrant Admission Scheme": "優秀人才入境計劃",
    "The Hong Kong government launched the Quality Migrant Admission Scheme in 2006. The scheme seeks to attract highly skilled or talented persons to settle in Hong Kong in order to enhance Hong Kong's economic competitiveness.":
      "香港政府自2006年推出的面向全球的優秀人才吸收計劃，旨在吸引具有良好教育背景的高技術人才或優秀人才來港定居，藉以提升香港在全球市場的競爭力。",
    "Over 18 years of age": "18歲以",
    "No criminal record": "無犯罪紀錄",
    "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test":
      "計分制要求：滿足綜合計分製或成就計分制要求",
    "None needed": "無需投資",
    "6+": "6+個月",
    "Resident Status": "居民身分",
    "Admission Scheme for Mainland Talents and Professionals":
      "輸入內地人人才計劃",
    "Chinese residents of the Mainland of China (the Mainland) who possess special skills, knowledge or experience of value to and not readily available in the HKSAR may apply to come to work under the ASMTP. The ASMTP is quota-free and non-sector specific.":
      "具備香港特區所需而又缺乏的特別技能、知識或經驗的內地中國居民，可根據輸入內地人才計劃（專才）申請來港工作。輸入內地人才計畫並無配額限制，亦不限業。",
    "Three application categories": "三類可申請類別：",
    "Persons with annual income reaching HK$2.5 million or above, or its equivalent in foreign currency, in the year immediately preceding the date of application":
      "過去一年年薪達250萬港元或以上的人士；",
    "Degree graduates of the eligible universities with at least three years of work experience over the past five years immediately preceding the date of application":
      "畢業於全球百強大學並在過去5年累積3年或以上工作經驗的人士；",
    "Degree graduates of the eligible universities in the past five years immediately preceding the date of application with less than three years of work experience, subject to an annual quota which is to be allotted on a first-come, first-served basis":
      "為符合工作經驗要求但最近5年畢業的全球百強大學畢業生",
    "4-6": "4-6個月",
    "Top Talent Pass Scheme": "高端人才通行證計劃",
    "Eligible talents include individuals who had an annual salary of HKD 2.5 million or more in the past year, and individuals who graduated from the top 100 universities globally and have accumulated 3 years or more of work experience in the past 5 years. These two types of people can be issued a pass to develop in Hong Kong for a period of 2 years, with no quota restrictions. Graduates from the top 100 universities globally who do not meet the work experience requirements but have graduated in the past 5 years can also be issued passes, with an annual limit of 10,000 people. This scheme lasts for 2 years and will be reviewed one year after its launch.":
      "合資格人才包括過去一年年薪達250萬港元或以上的人士，以及畢業於全球百強大學並在過去5年內累積3年或以上工作經驗的人士。此兩類人士可獲發為期2年的通行證來港發展，不設人數限額。未符合工作經驗要求但最近5年內畢業的全球百大畢業生亦可獲發通行證，每年上限1萬人。此計畫為期2年，計畫將於推出一年後檢討",
    "1-2": "1-2個月",
    "Thailand Elite-Visa": "泰國菁英簽證",
    "The Thailand Elite Visa, initiated by the Royal Thai Government in November 2003, is the world's first visa program that allows foreigners to reside in Thailand for up to 20 years. It is issued by the Thailand Privilege Card Company Limited (TPC), a wholly-owned subsidiary of the Tourism Authority of Thailand, under the Ministry of Tourism and Sports. Holders of the Thailand Elite Visa can enjoy VIP services and other privileges for free.":
      "泰國菁英簽證是泰國皇家政府於2003年11月發起的全球第一個允許外國人在泰國居住的長達20年的簽證計畫。是泰國特權卡有限公司(TPC)簽發簽證，泰國特權卡有限公司（TPC）是泰國旅遊局下屬全資子公司，隸屬於旅遊部與體育部。持有泰國精英簽證可免費享有VIP服務和其他特權。",
    "Over 20 years of age": "20歲以上",
    "Donation of 600,000 to 2,000,000 Thai Baht": "支付60萬-200萬泰銖捐款",
    "By making a donation of 600,000 to 2,000,000 Thai Baht, one can obtain long-term residency status for 5 years, 10 years, or 20 years.":
      "支付60萬-200萬泰銖捐款，可以獲得5年/10年/20年長期居留身份",
    "One time donation": "一次性捐贈",
    "2-3": "2-3個月",
    "Permanent Residency Program": "永居計劃",
    "On January 12, 2021, the Identity Malta Agency formally announced a new policy for its permanent residency program, named the Malta Permanent Residence Program (MPRP). The cost of implementing the new policy is lower than the previous MRVP project, making the Malta program one of the most cost-effective investment projects on the market.":
      "2021年1月12日，馬耳他身份局正式對外發布永居項目新政，名稱是馬耳他永居項目（Malta Permanent Residence Program），馬耳他新政的辦理成本低於之前的MRVP項目，也使得馬耳他項目成為市場上最具性價比的投資項目之一",
    "Proof of assets worth 50,000 Euros (including proof of liquid assets worth 15,000 Euros)":
      "50萬歐資產證明（其中含15萬歐流動資產證明）",
    "Meet the government's investment requirements": "滿足政府要求投資",
    "Administrative fee: 40,000 Euros": "行政管理費：4萬歐；",
    "Non-profit donation: 2,000 Euros": "非營利捐款：2000歐",
    "Government donation: For property purchase method, the donation amount is 28,000 Euros; For the rental method, the donation amount is 58,000 Euros":
      "政府捐款：購屋方式：捐款金額2.8萬歐；租屋方式：捐款金額5.8萬歐",
    "Property investment: Purchase a property worth over 300,000 Euros for a total of 5 years or lease a property worth 10,000 Euros per year for a total of 5 years.":
      "投資房產：購買價值超過30萬歐元房產，共5年或租賃每年價值在1萬歐元的房產，共5年",
    "At least 5 years": "至少5年",
    "8-10": "8-10個月",
    "Permanent Residency": "永居身份",
    "Citizenship by Investment Program": "投資入籍計劃",
    "The Malta passport policy was launched in 2014. In July 2020, the Malta passport project reached its upper limit and was closed. At the end of November 2020, the new Malta passport policy, the Granting of Citizenship for Exceptional Services Regulations, 2020, was launched. This policy stipulates that the quota for new citizenship is 1500 families, with a maximum of 400 families per year.":
      "2014年馬耳他護照政策推出，2020年7月，馬耳他護照計畫入籍名額達其上限關停。2020年11月底，馬耳他護照新政Granting of Citizenship for Exceptional Services Regulations,2020 推出，該政策規定，新政入籍名額為1500組家庭，每年最高400組家庭。",
    "Hold Malta residence for 3 years or 12 months":
      "持有馬耳他居住滿3年或12個月",
    "Make a donation of 600,000 EUR or 750,000 EUR to the country of Malta.":
      "向馬耳他國家捐款60萬歐元或75萬歐元；",
    "Purchase residential property in Malta valued at least 700,000 EUR and hold for at least 5 years; or lease a residential property with a rent of at least 16,000 EUR 3. per year for a minimum lease period of 5 years. Donate 10,000 EUR to local Maltese organizations involved in charity, culture, sports, science, art, or non-governmental organizations.":
      "在馬耳他購買價值至少70萬歐元的居住型房產需持有至少5年；或租賃一套租金至少1.6萬歐元/年的居住型房產，租賃期至少5年。3.捐款1萬歐元到馬耳他當地的慈善、文化、體育、科學、藝術或非政府組織等機構",
    "15-18": "15-18個月",
    Citizenship: "公民身份",
    "188A": "188A",
    "The 188A visa, introduced by the Australian government in 1981 as an entrepreneurial immigration program, was previously known as the 163A visa. To date, it has a history of 40 years and is one of the oldest and most consistently stable immigration programs in the Commonwealth countries.":
      "澳洲188A簽證，是澳洲政府於1981年推出的創業移民項目，前身為163A，迄今已有40年歷史，是英聯邦國家中歷史久遠，政策持續性較好的移民項目之一",
    "The main applicant is under 55 years old.": "主申請人年齡55歲以下",
    "The individual and family net assets under the name of the couple are not less than 1.25 million AUD.":
      "夫妻名下個人及家庭淨資產不低於125 萬澳幣；",
    "In the past four fiscal years, in two of those fiscal years, the company's annual turnover is not less than 750,000 AUD.":
      "過去4個財年中其中2個財年, 公司年營業額不低於75萬澳幣；",
    "In the past four fiscal years, in two of those fiscal years, the shareholding ratio under the couple's name is not less than 30%; if it is a listed company, it is not less than 10%.":
      "過去4個財年中其中2個財年，夫妻名下持股比例不低於30%；如是上市公司則不低於10%；",
    "The score in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system is not less than 65 points.":
      "商業甄選系統（EOI）評分不低於65分；",
    "As long as either spouse meets the entrepreneurial (residential) requirements, they can become the main applicant for the 888 Permanent Residence Visa.":
      "只要夫妻任意一人符合創業（居住）要求，即可成為888永居簽證的主申請人",
    "During the 5-year residency visa period, meet the entrepreneurial requirements for 2 years.":
      "持5年居留簽證期間，滿足2年的創業要求； ",
    "During the 2 years of entrepreneurship, reside in the sponsoring state for at least 1 year.":
      "創業2年期間，在擔保州居住至少滿1年",
    "Meet at least 2 of the following 3 conditions: 1) The family net assets under the couple's name reach 600,000 AUD; 2) The net assets of the company held reach 200,000 AUD; 3) Employ 2 local full-time employees.":
      "以下3個條件，滿足至少2個條件：1）夫妻名下家庭淨資產達60萬澳幣；2）持有公司淨資產達20萬澳幣；3）僱用2名當地全職員工",
    "At least 2 years": "至少2年",
    "21-27": "21-27個月",
    "188B": "188B",
    "The 188B visa, introduced by the Australian government in 1981 as an investment immigration program, was previously known as the 165 visa. To date, it has a history of 40 years and is one of the most consistently stable immigration programs in the Commonwealth countries.":
      "洲188B簽證，是澳洲政府於1981年推出的投資移民項目，前身是165，迄今已有40年歷史，是英聯邦國家中，澳洲188B是政策持續性穩定的移民項目之一",
    "The main applicant must be under 55 years old.": "主申請人年齡55歲以下；",
    "The family's net assets must not be less than 2.75 million AUD over the past two fiscal years.":
      "過去2個財年，家庭淨資產不低於275萬澳幣；",
    "Have at least 3 years of successful investment experience, and in one of the past five years, have over 10% shares in a business or own an investment market value of 2.5 million AUD.":
      "3年以上的成功投資經驗，並且過去五年有一年在生意裡有10%以上的股份或擁有250萬澳幣的投資市值；",
    "Through the above business or investment, profits exceed 1 million AUD.":
      "透過以上生意或投資獲利超過100萬澳幣；",
    "Score no less than 65 points in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system.":
      " 商業甄選系統（EOI）不低於65分；6.投資250萬澳幣到合規投資中",
    "Invest 2.5 million AUD in compliant investments.":
      "投資250萬澳幣到合規投資中",
    "Hold compliant investments of 2.5 million AUD, with the required proportions as follows:":
      "持有250萬澳元的合規投資，比例要求如下：",
    "AUD 500,000 VC and growth private equity funds":
      "AUD 500,000 VC and growth private equity funds",
    "AUD 750,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 750,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 1,250,000 balancing investment": "AUD 1,250,000 balancing investment",
    "At least 3 years": "至少3年",
    "21-25": "21-25個月",
    "188C": "188C",
    "The Australian 188C Investment Immigration is an immigration program aimed at high-net-worth individuals. Applicants are required to declare assets of 5 million AUD and provide a reasonable explanation of the source of funds. Later, the assets of 5 million AUD will be liquidated and invested through a fund company.":
      "澳洲188C投資移民，是針對大資產人士的移民項目，申請人需要申報500萬澳元的資產，合理的解釋資金來源，後期將500萬澳元的資產變現後透過基金公司投資",
    "No age restriction": "No age restriction",
    "No points required, no English requirement":
      "No points required, no English requirement",
    "Relaxed residence requirements for later conversion to permanent residence":
      "Relaxed residence requirements for later conversion to permanent residence",
    "Family assets above 5 million AUD, with a reasonable explanation of the source":
      "Family assets above 5 million AUD, with a reasonable explanation of the source",
    "Invest 5 million AUD in compliant investments":
      "Invest 5 million AUD in compliant investments",
    "Hold compliant investments of 5 million AUD, with the required proportions as follows:":
      "合規投資至少500萬澳元，比例要求如下:",
    "AUD 1,000,000 VC and growth private equity funds":
      "AUD 1,000,000 VC and growth private equity funds",
    "AUD 1,500,000 funds invest in emerging copanies listed on Australian Stock Exchange":
      "AUD 1,500,000 funds invest in emerging companies listed on Australian Stock Exchange",
    "AUD 2,500,000 balancing investment": "AUD 2,500,000 balancing investment",
    "25-28": "25-28個月",
    "New Zealand Active Investor Plus": "紐西蘭主動投資者簽證",
    "The Active Investor Plus Visa, established to attract high-value investors, came into effect on 19th September 2022. This new visa replaces the previous investor visa category.":
      "為吸引高價值投資者而設立的Active Investor Plus簽證於2022年9月19日生效，新簽證取代了先前的投資簽證類別。",
    "Language: IELTS score of 5 or above.": "語言：雅思CLB 5以上；",
    "Residence: The main applicant must reside in New Zealand for at least 117 days within the 4-year investment period.":
      "學歷：主申請人全日制大專以上學歷；",
    "Investment: Invest between 5 million and 15 million NZD in New Zealand over 4 years.":
      "語言：雅思CLB 5以上；",
    "Dependents: Children under the age of 24 who are economically dependent on the main applicant can be included in the application.":
      "24歲及以下經濟依附於主申請人的子女可以包含在申請中",
    "Invest at least 5 million NZD directly into enterprises approved by the New Zealand Trade and Enterprise (NZTE), or;":
      "至少500萬紐幣直接投資到新西蘭貿易企業局(NZTE)認可的企業，或； ",
    "Invest at least 7.5 million NZD in equity funds or venture capital funds, or;":
      "至少750萬紐幣投資於股權基金或風投基金，或；",
    "Invest at least 15 million NZD in publicly listed company shares and charitable causes (50% each).":
      "至少1500萬紐幣投資於上市公司股票和慈善事業(各50%)。",
    "At least 4 years": "至少4年",
    "12-24": "12-24個月",
    "Canada Start-up Visa Program (SUV)": "加拿大創新移民計劃（SUV）",
    "The Canadian Startup Visa (SUV) Program was launched by Immigration, Refugees and Citizenship Canada (IRCC) on April 1, 2013, initially as a 5-year pilot project. In 2018, IRCC announced that the program would become a permanent one. The main goal of the Startup Visa Program is to attract innovative foreign entrepreneurs who can contribute to Canada's economic and industrial development and create new job opportunities.":
      "加拿大創新移民計畫（SUV）是加拿大聯邦移民局在2013年4月1日發布的試點計畫為期5年。2018年加拿大移民局宣布該計畫成為永久計畫。該計畫主要的目的是為了吸引具有創新精神的外國企業家為加拿大的經濟和產業發展做出新的貢獻，創造就業機會",
    "Age: The main applicant must be over 18 years old.":
      "年齡：主申請人18歲以上；",
    "Education: The main applicant must have a full-time college degree or higher.":
      "學歷：主申請人全日制大專以上學歷； ",
    "Industry Background: Over 3 years of related background in the technology industry (such as IT, communications, biomedical technology, new energy, environmental protection, artificial intelligence, and other tech-related industries).":
      "行業背景：3年以上科技行業的相關背景（IT、通訊，生物醫療科技、新能源，環保，人工智慧等科技相關產業等)",
    "To establish an innovative business in Canada, there is no explicit investment amount requirement, but the amount is usually more than 100,000 Canadian dollars":
      "去加拿大成立創新類別公司，無明確的投資金額要求，通常金額高於10萬加幣",
    "30-38": "30-38個月",
    "Portugal Golden Visa": "葡萄牙黃金居留計劃",
    "On October 8, 2012, the Portuguese Ministry of Internal Affairs issued the Portuguese Golden Residence Permit Program. The law stipulates that citizens from non-EU countries can obtain the Portuguese Golden Residence Permit by making an investment in Portugal.":
      "2012年10月8日，葡萄牙內政部頒布葡萄牙黃金居留許可計劃，法案規定：非歐盟國家的公民，可以透​​過在葡萄牙投資來獲得葡萄牙黃金居留許可",
    "500,000 Euros in a fund approved by the Portuguese government":
      "基金: 50萬歐元葡萄牙政府批准的基金",
    "12+": "12+個月",
    "Greece Golden Visa": "希臘投資移民計劃",
    "In order to increase domestic consumption and promote the development of the domestic real estate industry, the Greek government announced a new immigration policy for home buyers in July 2015. Non-EU citizens who invest in local properties worth more than 250,000 Euros in Greece can obtain permanent residency in Greece. In March 2016, the Greek government further relaxed the application conditions by removing the requirement for no criminal record. Starting from August 2023, the Greek property immigration policy will be adjusted, with the property purchase requirement in some areas rising from the original 250,000 Euros to 500,000 Euros.":
      "為了增加國內消費及推動國內房地產業的發展，希臘政府於2015年7月公佈了新的買房移民政策，凡非歐盟國公民在希臘當地投資購買超過25萬歐元的房產，即可獲得希臘的永久居留許可，2016年3月，希臘政府又取消了對於無犯罪記錄的要求使之申請條件更為寬鬆。2023年8月開始希臘購屋移民將調整政策，部分區域購屋要求從原來來到25萬歐元漲到50萬歐元",
    "Non-EU/European Economic Area/Swiss nationals":
      "非歐盟/歐洲經濟區/瑞士國民",
    "At least 14 years old": "年滿14歲或以上",
    "Property worth more than 250,000 Euros (some regions require more than 500,000 Euros)":
      "25萬歐元以上的房產（部分地區要求50萬歐元以上）",
    "Stable Returns": "穩定報酬",
    "Global ETFs": "全球ETFs",
    "High-quality Returns": "高品質回報",
    "Safeguarding": "保障性",
    "Log Only": "僅記錄",
    "Global Sectors": "全球性",
    "Expected Return": "预期回报",
    "Coming Soon": "即将推出",
    "The fund aims to achieve positive performance": "該基金旨在取得正面的業績",
    "Permanent possession": "永居身份",
    "6-8": "6-8個月",
    "St Lucia Citizenship by Investment": "聖露西亞公民計劃",
    "The Saint Lucia Citizenship by Investment Program requires applicants to make a significant economic contribution to the country. As an exchange, and after a strict application process and due diligence, the applicant and their family will be granted full citizenship. The Saint Lucia Citizenship by Investment Program is regulated by the Citizenship by Investment Act No. 14 of 2015.":
      "聖露西亞投資入籍計畫要求申請人為國家做出重大經濟貢獻。作為交換，並經過嚴格的申請程序和盡職調查，申請人及其家人將獲得完整的公民身份。聖露西亞投資入籍計畫受2015年第14號投資入籍法監管。",
    "Donations:": "捐贈：",
    "$100,000 (single applicant); or": "10萬美元（單人）；",
    "$140,000 for a couple; or": "夫妻14萬美元；",
    "$150,000 for a family of 3-4, with each additional person beyond 5 requiring an additional investment of $15,000.":
      "3-4人申請15萬美金，5人以上申請每增加一位增加1.5萬美金投資",
    Months: "月份",
    Category: "類別",
    "At least 18 years old": "年滿18歲以上",
    "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth.":
      "2019年2月的新加坡財政預算案中，財政部部長宣布，針對家族辦公室將給予更優惠的免稅方案：擴大免稅的涵蓋範圍，並將現行的免稅政策13O和13U規定的優惠計劃，繼續延長至2024年12月31日。新加坡家族辦公室的架構主要是由主申請人在新加坡設立單一家族辦公室由自己或家庭成員一起成立的兩家公司， 一個是基金公司， 一個是基金管理公司（家族辦公室)。家族辦公室的控股人可以是個人或控股公司形式。控股公司可以由信託公司持有，以確保財富的隔離。",
    "AUM SGD 20 million": "管理資產：2000萬新幣",
    "At least SGD 200,000 in total business spending":
      "每年在新加坡的營運開支至少20萬新幣",
    "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member.":
      "聘請2名專業投資人士，其中一人非家族成員",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market.":
      "至少10％或1000萬元（以較低者為準）資金投資在新加坡本地市場",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies, and private equity investments in local unlisted companies. Newly added are climate-related investments and blended finance in which Singapore financial institutions have broad participation.":
      "至少10％或1000萬元（以較低者為準）資金投資在本地市場。包括新加坡交易所掛牌的股票、合格債券、本地基金公司發行的基金及本地非上市公司的私募投資。新增與氣候相關投資及新加坡金融機構廣泛參與的混合融資",
    "Maintain until application for permanent residency.": "維持到申請永居",
    "AUM SGD 50 million": "管理資產：5000萬新幣",
    "At least SGD 500,000 in total business spending":
      "每年在新加坡的營運開支至少50萬新幣",
    "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies and private equity investments in local non-listed companies. New additions include climate-related investments and blended finance widely participated by Singapore financial institutions.":
      "至少10％或1000萬元（以較低者為準）資金投資在本地市場。包括新加坡交易所掛牌的股票、合格債券、本地基金公司發行的基金及本地非上市公司的私募投資。新增與氣候相關的投資及新加坡金融機構廣泛參與的混合融資",
    "63% fixed-income assets": "63%固定收益資產",
    "34% equity assets": "34%股票資產",
    "3% derivatives": "3%衍生品",
    "37% equity assets": "37% equity assets",
    "100% fixed-income assets": "100%固定收益資產",
    "20% fixed-income assets": "20%固定收益資產",
    "80% equity assets": "80%股票資產",
    "Are you New User or an Existing DL Holding Client":
      "你是新用戶還是DL Holdings的現有客戶？",
    "I’m a New User": "我是新用户",
    "I’m an Existing Client": "我是現有客戶",
    "Bank Account Number": "收款銀行戶口",
    "Account Name": "戶口名稱",
    "Bank Name": "銀行名稱",
    "Bank Code": "銀行代碼",
    "Remittance Code": "匯款代碼",
    "Please check your spam or junk mail folder to make sure you didn’t miss the OTP email. If issues persist, please reach out to ":
      "請檢查您的垃圾郵件文件夾，確保您沒有錯過OTP電子郵件。如果問題仍然存在，請聯繫",
    "Bank of Communications": "交通銀行",
    "Payment Proof": "付款證明",
    "View Payment Proofs": "查看付款證明",
    "view": "檢視",
    "Upload Proof": "上傳付款證明",
    "PI Account": "PI帳號",
    "PRODUCTS": "產品",
    "Investments": "投資",
    "Please complete the security account opening flow": "請完成安全帳戶開戶流程",
    "Go to Investments": "前往投資",
    "Flagship 1 Teaser": "旗舰策略 1 簡介",
    "Flagship 2 Teaser": "旗舰策略 2 簡介",
    "Flagship 3 Teaser": "旗舰策略 3 簡介",
    "US Treasury Teaser": "DL 美国国债策略 簡介",
  },
};

const cache = createIntlCache();

let int = createIntl(
  {
    locale: localStorage.getItem("user-lang") || "en",
    messages: messages[localStorage.getItem("user-lang") || "en"],
  },
  cache
);

export function changeLanguage(lang: string) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache
  );
  int = newIntl;
  localStorage.setItem("user-lang", lang);
}

const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values);
};

export default translate;
