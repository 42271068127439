import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  ChakraProvider,
} from "@chakra-ui/react";

import bellIcon from "../../../assets/bell.svg";
import emptyNotification from '../../../assets/empty-notification.svg'
import whiteBellIcon from "../../../assets/white-bell.svg";
import { CloseIcon } from "@chakra-ui/icons";
const NotificationBox = ({
  setIsNotificationOpened,
  bellClassName,
  isNotificationOpened,
}: any) => {
  return (
    <ChakraProvider>
  <Popover placement="bottom-start" isOpen={isNotificationOpened} onClose={() => {
    setIsNotificationOpened(false)
  }}>
      <PopoverTrigger>
        <div
          className={bellClassName()}
          onClick={() => {
            setIsNotificationOpened(!isNotificationOpened);
          }}
        >
          {isNotificationOpened && <img src={whiteBellIcon} alt="whitebell" />}
          {!isNotificationOpened && <img src={bellIcon} alt="bell" />}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        {/* <PopoverHeader fontWeight='semibold'>Customization</PopoverHeader>
      <PopoverCloseButton bg='purple.500' /> */}
        <PopoverBody>
          <div
            style={{
 
              height: "500px",
        
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "40px",
                padding: "0px 15px",
              }}
            >
              <div style={{ flex: "1 1 auto" }}>Notifications</div>
              <div
                className="close-icon-vector"
                style={{cursor:"pointer"}}
                onClick={() => {
                  setIsNotificationOpened(false);
                }}
              >
                <CloseIcon style={{height:"12px", width:"12px"}}/>
              </div>

            </div>
            <div style={{marginTop:"40px"}}>
              <img src={emptyNotification} />
              <div style={{textAlign:"center"}}>No notification found</div>
            </div>
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
    </ChakraProvider>
  
  );
};

export default NotificationBox;
