import { ArrowForwardIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import "./ComponentSideModal.scss";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import { useNavigate } from "react-router-dom";
import translate from "../../../translate";

const ComponentSideModal = (props: any) => {
  const { onClose, selectedPromptId, setSelectredPromptId } = props;
  const navigate = useNavigate();

  const promptDataObj: any = {
    start: {
      id: "start",
      name: translate("not_sure1"),
      data: {
        response: [
          { name: translate("That's OK. Lets start with a few questions.") },
          { name: translate("Which of the following do you think best fits your needs:") },
        ],
        questions: [
          {
            id: "Help me invest",

            name: translate("Help me invest"),
            data: {
              response: [
                {
                  name: translate("Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?"),
                },
              ],
              questions: [
                {
                  id: "Flagship 1: Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment",
                  name: (
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/fo-strategy/product?id=flagship1");
                        }}
                      >
                        Flagship 1
                      </span>
                      : Our Flagship offering. Striking a balance between growth
                      and stability, guided by DLAM's 13-year investment
                    </div>
                  ),
                  data: {
                    response: [
                      {
                        name: "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%",
                      },
                    ],
                    questions: [
                      {
                        id: "Explore Flagship 1",
                        name: "Explore Flagship 1",
                        link: "/fo-strategy/product?id=flagship1",
                      },
                    ],
                  },
                },
                {
                  id: "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.",
                  name: "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.",
                  data: {
                    response: [
                      {
                        name: "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.",
                      },
                    ],
                  },
                },
                {
                  id: "Flagship 3: Optimizing higher growth through strategic investments in public and private securities",
                  name: "Flagship 3: Optimizing higher growth through strategic investments in public and private securities",
                  data: {
                    response: [
                      {
                        name: "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.",
                      },
                    ],
                  },
                },
                {
                  id: "US Treasuries: Protecting your capital through diversified US Treasury",
                  name: "US Treasuries: Protecting your capital through diversified US Treasury",
                  data: {
                    response: [
                      {
                        name: "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.",
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            id: "I need help with identity planning",
            name: translate("I need help with identity planning"),
            data: {
              response: [
                {
                  name: translate("What is your prefered destination?"),
                },
              ],
            },
          },
          {
            id: "I need estate planning",
            name: translate("I need estate planning"),
            comingSoon: true,
          },
          {
            id: "I'm interested in trust services",
            name: translate("I'm interested in trust services"),
            comingSoon: true,
          },
          {
            id: "I need insurance",
            name: translate("I need insurance"),
            data: {
              response: [
                {
                  name: "Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance.",
                },
                {
                  name: "Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth.",
                },
                {
                  name: "Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries.",
                },
                {
                  name: "Investment:Some insurance policies have a savings component that grows over time, adding to your wealth.",
                },
                {
                  name: "Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation.",
                },
              ],
              questions: [
                {
                  id: "Book a consultation today",
                  name: "Book a consultation today",
                },
              ],
            },
          },
        ],
      },
    },
    "Help me invest": {
      id: "Help me invest",

      name:  translate("Help me invest"),
      data: {
        response: [
          {
            name: translate("Awesome, you're looking to invest! To better understand how we can assist you, could you tell us a little more about your investment goals?"),
          },
        ],
        questions: [
          {
            id: "Flagship 1: Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment",
            name: (
              <div>
                {" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/fo-strategy/product?id=flagship1");
                  }}
                >
                  {translate("flagship1")}
                </span>
                {translate(": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment")}
              </div>
            ),
            data: {
              response: [
                {
                  name: "Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%",
                },
              ],
            },
          },
          {
            id: "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.",
            name: translate("Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management."),
            data: {
              response: [
                {
                  name: "Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise.",
                },
              ],
            },
          },
          {
            id: "Flagship 3: Optimizing higher growth through strategic investments in public and private securities",
            name: translate("Flagship 3: Optimizing higher growth through strategic investments in public and private securities"),
            data: {
              response: [
                {
                  name: "Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience.",
                },
              ],
            },
          },
          {
            id: "US Treasuries: Protecting your capital through diversified US Treasury",
            name: translate("US Treasuries: Protecting your capital through diversified US Treasury"),
            data: {
              response: [
                {
                  name: translate("Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions."),
                },
              ],
            },
          },
        ],
      },
    },
    "Flagship 1: Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment":
      {
        id: "Flagship 1: Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment",
        name: (
          <div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/fo-strategy/product?id=flagship1");
              }}
            >
              {translate("flagship1")}
            </span>
            {translate(": Our Flagship offering. Striking a balance between growth and stability, guided by DLAM's 13-year investment")}
          </div>
        ),
        data: {
          response: [
            {
              name: translate("Flagship strategy is a standardized strategy that is launched and managed by DLAM. DLAM leverages 13-year investment strategies and experience of DL Group. The strategy aims to preserve wealth through asset geographical diversification whereas typical mass affluent investors would not be able to do, The investment strategy provides safety and stability, while maximizing returns and efficiency. Over the past 7 years, the strategy has achieved an average annual compound growth rate of approximately 6% and a cumulative absolute return exceeding 50%"),
            },
          ],
          questions: [
            {
              id: "Explore Flagship 1",
              name: translate("Explore Flagship 1"),
              link: "/fo-strategy/product?id=flagship1",
            },
          ],
        },
      },
    "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.":
      {
        id: "Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management.",
        name: translate("Flagship 2: Leveraging global diversification and liquidity of ETFs for effective risk management."),
        data: {
          response: [
            {
              name: translate("Flagship 2 is a perfect choice for investors seeking to harness the advantages of globally diversified, highly liquid ETFs. Managed by our team of seasoned professionals, this strategy uses their profound understanding of global markets to construct a diverse portfolio. It allows investors to exploit the benefits of a professionally managed, diversified set of ETFs, covering various asset classes, without the necessity for exhaustive personal market analysis or investment expertise."),
            },
          ],
        },
      },
    "Flagship 3: Optimizing higher growth through strategic investments in public and private securities":
      {
        id: "Flagship 3: Optimizing higher growth through strategic investments in public and private securities",
        name: translate("Flagship 3: Optimizing higher growth through strategic investments in public and private securities"),
        data: {
          response: [
            {
              name: translate("Our Flagship 3 is the ideal solution for investors seeking expertly managed, diversified investments. This fund is carefully curated by our team of dedicated professionals who leverage their deep knowledge and understanding of both public and private markets. This allows you to tap into the potential rewards of a professionally managed, diverse portfolio without the need for extensive personal market research or investment experience."),
            },
          ],
        },
      },
    "US Treasuries: Protecting your capital through diversified US Treasury":
      {
        id: "US Treasuries: Protecting your capital through diversified US Treasury",
        name: translate("US Treasuries: Protecting your capital through diversified US Treasury"),
        data: {
          response: [
            {
              name: translate("Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions."),
            },
          ],
        },
      },
    "I need help with identity planning": {
      id: "I need help with identity planning",
      name: translate("I need help with identity planning"),
      data: {
        response: [
          {
            name: translate("What is your prefered destination?"),
          },
        ],
      },
    },
    "I need estate planning": {
      id: "I need estate planning",
      name: "I need estate planning",
      comingSoon: true,
    },
    "I'm interested in trust services": {
      id: "I'm interested in trust services",
      name:translate("I'm interested in trust services"),
      comingSoon: true,
    },
    "I need insurance": {
      id: "I need insurance",
      name: translate("I need insurance"),
      data: {
        response: [
          {
            name: translate("Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance."),
          },
          {
            name: translate("Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth."),
          },
          {
            name: translate("Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries."),
          },
          {
            name: translate("Investment:Some insurance policies have a savings component that grows over time, adding to your wealth."),
          },
          {
            name: translate("Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation."),
          },
        ],
        questions: [
          {
            id: "Book a consultation today",
            name: translate("Book a consultation today"),
          },
        ],
      },
    },
    popular: {
      id: "popular",
      name: translate("What is the most popular product on DL DFO?"),
      data: {
        response: [
          {
            name: (
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/fo-strategy/product?id=flagship1");
                  }}
                >
                  {translate("flagship1")}
                </span>
                {translate(": This is our flagship strategy, trusted and utilized by traditional family offices. It offers a balanced approach to investing with a focus on stable returns and capital safety.")}
              </div>
            ),
          },
          {
            name: translate("DL US Treasuries: With current U.S. interest rates at cycle highs and an uncertain macroeconomic climate, U.S. Treasury bonds have become increasingly popular. This strategy focuses on these bonds, providing a steady and dependable income while also ensuring capital preservation."),
          },
        ],
        questions: [
          {
            id: "Explore Flagship 1",
            name: translate("Explore Flagship 1"),
            link: "/fo-strategy/product?id=flagship1",
          },
          {
            id: "Explore DL US Treasuries",
            name: translate("Explore DL US Treasuries"),
            data: {
              response: [
                {
                  name: "Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions.",
                },
              ],
            },
          },
        ],
      },
    },
    "Explore DL US Treasuries": {
      id: "Explore DL US Treasuries",
      name: translate("Explore DL US Treasuries"),
      data: {
        response: [
          {
            name: translate("Preserving capital is crucial in any investment strategy. Our U.S. Treasury strategy, backed by the full faith and credit of the U.S. government, provides one of the safest investment avenues. The strategy incorporates a mix of short-term, intermediate-term, and long-term Treasury bonds, offering a balance of risk and return tailored to market conditions."),
          },
        ],
      },
    },
    diversification: {
      id: "diversification",
      name: translate("not_sure3"),
      data: {
        response: [
          {
            name: translate("Diversification can indeed be tricky, but our Flagship Strategy 1 can help you jump right in."),
          },
          {
            name: translate("Flagship Strategy 1 spreads your money across different asset classes and global regions. This can lower your risk and let you benefit from growth opportunities anywhere in the world."),
          },
          {
            name: translate("With Flagship Strategy 1, you don't have to worry about the details. Our experts handle everything, so you can enjoy the benefits of diversification"),
          },
        ],
        questions: [
          {
            id: "Explore Flagship 1",
            name: translate("Explore Flagship 1"),
            link: "/fo-strategy/product?id=flagship1",
          },
        ],
      },
    },
    fixdeposit: {
      id: "fixdeposit",
      name: translate("Fix deposits is at x%. How can I beat that?"),
      data: {
        response: [{ name: "List Item" }],
        questions: [{ name: "[placeholder]", id: "[placeholder]" }],
      },
    },
    insurance: {
      id: "insurance",
      name: translate("I need insurance"),
      data: {
        response: [
          {
            name: translate("Great! Insurance can play an essential role in long-term wealth management and protection. Here are four reasons why we love insurance."),
          },
          {
            name: translate("Risk management and protection:Insurance protects against financial loss from events like death or illness, preserving your wealth."),
          },
          {
            name: translate("Estate Planning:Life insurance aids in smoothly transferring wealth to heirs by covering estate taxes and expenses after death. This prevents the need for hasty asset sales, ensuring a more secure financial future for the beneficiaries."),
          },
          {
            name: translate("Investment:Some insurance policies have a savings component that grows over time, adding to your wealth."),
          },
          {
            name: translate("Tax planning: Insurance can provide tax benefits, like tax-free death benefits and tax-deferred growth, aiding in wealth growth and preservation."),
          },
        ],
        questions: [
          {
            name: translate("Book a consultation today"),
            id: "Book a consultation today",
          },
        ],
      },
    },
  };

  const item: any =
    promptDataObj[selectedPromptId[selectedPromptId?.length - 1]];
  return (
    <div className="side-modal">
      {/* {promptData.map((item) => {
        return (
          <> */}
      {/* {item.id === selectedPromptId && ( */}
      <div className="inner-height-modal">
        <div className="side-modal-header">
          <h5>{item?.name}</h5>
          <div className="close-icon-vector" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="side-modal-body">
          {item?.data?.response?.map((dataItem: any) => {
            return <p>{dataItem.name}</p>;
          })}
        </div>
        <div>
          {item?.data?.questions?.map((qItems: any) => {
            return (
              <>
                <div
                  className="side-card-design-black"
                  onClick={() => {
                    if (qItems?.link) {
                      navigate(qItems?.link);
                    } else if (qItems?.data) {
                      selectedPromptId.push(qItems?.id);
                      setSelectredPromptId([...selectedPromptId]);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "19px",
                    }}
                  >
                    <InfoOutlineIcon
                      // color={"#000"}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginTop: "3px",
                      }}
                    />
                    <p>{qItems?.name}</p>
                  </div>
                  {!item.comingSoon && (
                    <div className="arrow-right">
                      <ArrowForwardIcon w={24} h={24} />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/* // )} */}
      {/* </>
        );
      })} */}
    </div>
  );
};

export default ComponentSideModal;
