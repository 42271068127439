
import "../SecurityAccount/SecurityAccount.scss";

// import JotformEmbed from 'react-jotform-embed';
const VideoFormTest = () => {


const formUrl = "https://alt.jotfor.ms/241282922209050"
  // {baseurl}/security-account/241281272627455?formUrl=https://alt.jotfor.ms/241281272627455?uniqueId={submissionId}&customerid={customerid}&environment={environment}&baseurl={baseurl}&1name={name86}&address={typeA211}&3Permanent43={residentialAddress227}&4Mailing={correspondenceAddress229}&8Date={dateof}&6aForeign={typeA121}&Email={email}
  return (

      <div style={{ width: "100%", height: "100vh" }}>

          <>
            <iframe
              id={`JotFormIFrame-241282922209050`}
              title="v.2 W-8BEN"
              // onLoad="window.parent.scrollTo(0,0)"
              allowTransparency={true}
              allow="geolocation; microphone; camera; fullscreen"
              src={formUrl}
              frameBorder={0}
              style={{minWidth:"100%", maxWidth:"100%", border:"none"}}
              className="security-form-container"
              // sandbox="allow-popups"
            >
              {" "}
            </iframe>{" "}
            <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>{" "}
            <script>
              window.jotformEmbedHandler("iframe[id='JotFormIFrame-241282922209050']",
              "https://form.jotform.com/")
            </script>
          </>
       
      </div>

  );
};

export default VideoFormTest;
