import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GET_USER_QUERY {
    user {
      email
      customerId
      firstName
      middleName
      lastName
      qualifiedPurchaser
      qualifiedClient
      accreditedInvestor
      phone
      profilePic
      onboardStep
      currentFormUrl
      existingUser
      clientId
      approvals {
        bcanForm {
          reason
          status
        }
        documentUploadForm {
          reason
          status
        }
        investorAssessmentForm {
          reason
          status
        }
        securityForm {
          reason
          status
        }
        w8BenForm {
          reason
          status
        }
        existingUserForm {
          reason
          status
        }
        paymentProofForm {
          reason
          status
        }
        piForm {
          status
          reason
        }
        videoForm {
          status
          reason
        }
      }
      loginOtp {
        otp
        expiresIn
      }
      account {
        verification {
          verified
        }
        verificationCode
      }
      documentUploadForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      securityForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      bcanForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      w8BenForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      investorAssessmentForm{
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      existingUserForm {
        _id
        customerId
        downloadPdf
        formID
        formTitle
        ip
        pretty
        rawRequest
        submissionID
        username
      }
      paymentProofForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      piForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      videoForm {
        formID
        username
        ip
        formTitle
        customerId
        rawRequest
        submissionID
        pretty
        _id
        downloadPdf
      }
      currentFormUrl
      _id
      createdAt
      updatedAt
      eid
    }
  }
`;


export const GET_ESELF_SIGNATURE = gql`
  query GET_ESELF_SIGNATURE_QUERY {
    eSelfSignature
  }
`;

export const GET_STATEMENTS = gql`
query StatementPagination($sort: SortFindManystatementInput, $filter: FilterFindManystatementInput) {
  statementPagination(sort: $sort, filter: $filter) {
    items {
      customerId
      recordDate
      netAssetValue
      portfolio
      summary
      filePath
      _id
      createdAt
      updatedAt
    }
  }
}
`