import { Grid } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./FoServicesProduct.scss";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import WorldMap, { CountryContext } from "react-svg-worldmap";
import {
  FO_SERVICES_PRODUCT_COUNTRY_URL,
  FO_SERVICES_URL,
  useRouter,
} from "../../routes";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import translate from "../../../translate";
import ProductItem from "../../sub-components/ProductItem";
import CountryItem from "../../sub-components/CountryItem";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import hongKongFlag from "../../../assets/Flag_of_Hong_Kong.png";
import singaporeFlag from "../../../assets/Flag_of_Singapore.png";
import maltaFlag from "../../../assets/Flag_of_Malta.png";
import thailandFlag from "../../../assets/Flag_of_Thailand.png";
import australiaFlag from "../../../assets/Flag_of_Australia.png";
import newzealandFlag from "../../../assets/Flag_of_New_Zealand.png";
import canadaFlag from "../../../assets/Flag_of_Canada.png";
import portugalFlag from "../../../assets/Flag_of_Portugal.png";
import greeceFlag from "../../../assets/Flag_of_Greece.png";
import lucisFlag from "../../../assets/Flag_of_Saint_Lucia.png";

import features from "./features.json";


const countryColors = {
  HKG: "#DE2910CC", // 80% opacity
  SGP: "#EE2536CC", // 80% opacity
  MLT: "#CF142BCC", // 80% opacity
  THA: "#00247DCC", // 80% opacity
  AUS: "#E1BB16CC", // 80% opacity
  NZL: "#012169CC", // 80% opacity
  CAN: "#D80621CC", // 80% opacity
  PRT: "#046A38CC", // 80% opacity
  GRC: "#001489CC", // 80% opacity
  LCA: "#0077C8CC"  // 80% opacity
}


const baseColor = "rgb(140, 140, 140)"; // Light gray base color for other countries

// const markers = [
//   { name: "Hong Kong", coordinates: [114.1694, 22.3193],  content: translate("Attracting top talent globally") },
//   { name: "Singapore", coordinates: [103.8198, 1.3521], content: translate("Attracting high-net-worth family offices")},
//   { name: "Thailand", coordinates: [100.9925, 15.8700], content: translate("Introducing long-term residency visas") },
//   { name: "Malta", coordinates: [14.3754, 35.9375], content: translate("Low-threshold access to EU citizenship")},
//   { name: "Australia", coordinates: [133.7751, -25.2744],  content: translate(
//     "Offering a diverse range of entrepreneurship and investment immigration options"
//   ) },
//   { name: "New Zealand", coordinates: [174.8860, -40.9006], content: translate(
//     "Attracting high-value investments to promote economic development"
//   ) },
//   { name: "Canada", coordinates: [-106.3468, 56.1304],  content: translate(
//     "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy"
//   ) },
//   { name: "Portugal", coordinates: [-8.2245, 39.3999], content: translate("Attain EU residency rights through investment") },
//   { name: "Greece", coordinates: [21.8243, 39.0742], content: translate(
//     "Attain permanent residency through real estate investment"
//   ) },
//   { name: "Saint Lucia", coordinates: [-60.9789, 13.9094],  content: translate(
//     "Attain citizenship by making economic contributions to the country"
//   ) },
// ];

const height = "64"
const xDistance = "17"

const markers = [
  {
    id: "hongkong",
    name: "Hong Kong",
    coordinates: [125.5, 22.6],
    content: (<text x={xDistance} y={70} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Attracting top')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('talent globally')}</tspan>
    </text>),
    flagImage: hongKongFlag,
  },

  {
    id: "thailand",
    name: "Thailand",
    coordinates: [101.5, 35.2],
    content: (<text x={xDistance} y={70} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Introducing long-term')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('residency visas')}</tspan>
    </text>),
    flagImage: thailandFlag,
  },
  {
    id: "malta",
    name: "Malta",
    coordinates: [14.6, 33.2],
    content: (<text x={xDistance} y={70} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Low-threshold access')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('to EU citizenship')}</tspan>
    </text>),
    flagImage: maltaFlag,
  },
  {
    id: "newzeland",
    name: "New Zealand",
    coordinates: [137.5, -30.5],
    content: (<text x={xDistance} y={height} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Attracting high-value')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('investments to promote')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('economic development')}</tspan>
    </text>),
    flagImage: newzealandFlag,
  },
  {
    id: "australia",
    name: "Australia",
    coordinates: [104.5, -24.5],
    content: (<text x={xDistance} y={height} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Offering a diverse')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('range of entrepreneur-')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('-ship and investment')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('immigration options')}</tspan>
    </text>),
    flagImage: australiaFlag,
  },

  {
    id: "singapore",
    name: "Singapore",
    coordinates: [104.2, -5.1],
    content: (<text x={xDistance} y={height} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Attracting high-net')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('-worth family offices')}</tspan>
    </text>),
    flagImage: singaporeFlag,
  },
  {
    id: "canada",
    name: "Canada",
    coordinates: [-110.5, 80.7],
    content: (<text x={xDistance} y={height} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Encouraging innovative')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('and entrepreneurial')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('talents to immigrate and')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('contribute to the economy')}</tspan>
    </text>),
    flagImage: canadaFlag,
  },
  {
    id: "portugal",
    name: "Portugal",
    coordinates: [-20.7, 39.8],
    content: (<text x={xDistance} y={70} font-size="8">
      <tspan x={xDistance} dy="1em">{translate('Attain EU residency')}</tspan>
      <tspan x={xDistance} dy="1em">{translate('rights through investment')}</tspan>
    </text>),
    flagImage: portugalFlag,
  },
  {
    id: "greece",
    name: "Greece",
    coordinates: [22.3, 55.5],
    content:
      (<text x={xDistance} y={68} font-size="8">
        <tspan x={xDistance} dy="1em">{translate('Attain permanent')}</tspan>
        <tspan x={xDistance} dy="1em">{translate('residency through real')}</tspan>
        <tspan x={xDistance} dy="1em">{translate('estate investment')}</tspan>
      </text>),
    flagImage: greeceFlag,
  },
  {
    id: "stlucia",
    name: "Saint Lucia",
    coordinates: [-60.5, 14.2],
    content:
      (<text x={xDistance} y={62} font-size="8">
        <tspan x={xDistance} dy="1em">{translate('Attain citizenship by')}</tspan>
        <tspan x={xDistance} dy="1em">{translate('making economic')}</tspan>
        <tspan x={xDistance} dy="1em">{translate('contributions to the')}</tspan>
        <tspan x={xDistance} dy="1em">{translate('country')}</tspan>
      </text>),
    flagImage: lucisFlag,
  },
];

const dummyData = [
  {
    title: translate("Hong Kong"),
    id: "hongkong",
    content: translate("Attracting top talent globally"),
    backgroundGradient:
      "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
  },
  {
    title: translate("Singapore"),
    id: "singapore",
    content: translate("Attracting high-net-worth family offices"),
    backgroundGradient:
      "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
  },
  {
    title: translate("Thailand"),
    id: "thailand",
    content: translate("Introducing long-term residency visas"),
    backgroundGradient:
      "linear-gradient(135deg, #60482B 19.05%, #FF930F 84.88%)",
  },
  {
    title: translate("Malta"),
    id: "malta",
    content: translate("Low-threshold access to EU citizenship"),
    backgroundGradient:
      "linear-gradient(315deg, #F44369 16.23%, #392E27 80.35%)",
  },
  {
    title: translate("Australia"),
    id: "australia",
    content: translate(
      "Offering a diverse range of entrepreneurship and investment immigration options"
    ),
    backgroundGradient:
      "linear-gradient(315deg, #F44369 16.23%, #392E27 80.35%)",
  },
  {
    title: translate("New Zealand"),
    id: "newzeland",
    content: translate(
      "Attracting high-value investments to promote economic development"
    ),
    backgroundGradient:
      "linear-gradient(315deg, #5CB270 16.23%, #295634 80.35%)",
  },
  {
    title: translate("Canada"),
    id: "canada",
    content: translate(
      "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy"
    ),
    backgroundGradient:
      "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
  },
  {
    title: translate("Portugal"),
    id: "portugal",
    content: translate("Attain EU residency rights through investment"),
    backgroundGradient:
      "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
  },
  {
    title: translate("Greece"),
    id: "greece",
    content: translate(
      "Attain permanent residency through real estate investment"
    ),
    backgroundGradient:
      "linear-gradient(135deg, #60482B 19.05%, #FF930F 84.88%)",
  },
  {
    title: translate("St Lucia"),
    id: "stlucia",
    content: translate(
      "Attain citizenship by making economic contributions to the country"
    ),
    backgroundGradient:
      "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
  },
];

const data = [
  { country: "hk", value: 1389618778 }, // china
  { country: "sg", value: 1311559204 }, // india
  { country: "th", value: 331883986 }, // united states
  { country: "mt", value: 264935824 }, // indonesia
  { country: "au", value: 210797836 }, // pakistan
  { country: "nz", value: 210301591 }, // brazil
  { country: "ca", value: 208679114 }, // nigeria
  { country: "pt", value: 161062905 }, // bangladesh
  { country: "gr", value: 141944641 }, // russia
  { country: "lc", value: 127318112 }, // mexico
];

const getStyle = ({
  countryValue,
  countryCode,
  minValue,
  maxValue,
  color,
}) => ({
  fill: countryCode === "US" ? "blue" : color,
  fillOpacity: countryValue
    ? 0.1 + (1.5 * (countryValue - minValue)) / (maxValue - minValue)
    : 0,
  stroke: "green",
  strokeWidth: 1,
  strokeOpacity: 0.2,
  cursor: "pointer",
});

const FoServicesProduct = () => {
  const navigate = useNavigate();
  const router = useRouter();
  const { pathname } = router;
  const setCountryUrl = (itemId) => {

    navigate(`/fo-services/planning/country?id=${itemId}`);
    // window.location.href = `/fo-services/planning/country?id=${itemId}`;
  };


  return (
    <DashboardContainer>
      <div className="account-page" style={{backgroundImage:"unset"}}>
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "28px",
                  }}
                >
                  <ArrowBackIcon
                    w={28}
                    h={28}
                    style={{ cursor: "pointer", color: "#8c8c8c" }}
                    onClick={() => {
                      navigate(FO_SERVICES_URL);
                    }}
                  />
                  <h3
                    className="gradient-heading"
                    style={{ marginBottom: "0px" }}
                  >
                    {translate("Residency Planning")}
                  </h3>
                  {/* <div style={{ color: "#37454b", fontSize: "14px" }}>
                    {translate("Customize your identity layout")}
                  </div> */}
                </div>
              </div>

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            {/* <div
              style={{
                marginTop: "50px",
                display: "flex",
                // justifyContent: "space-between",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              {dummyData?.map((item) => {
                return (
                  <CountryItem
                    item={item}
                    onClick={() => {
                      setCountryUrl(item?.id);
                    }}
                  />
                );
              })}
            </div> */}
            <div style={{ marginLeft: "-160px" }}>
              <ComposableMap width={900}>
                <Geographies geography={features}>
                  {({ geographies }) =>
                    geographies.map((geo) => {

                      const isHighlighted = countryColors[geo.id];
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          style={{
                            default: {
                              fill: isHighlighted ? isHighlighted : baseColor,
                              outline: "none",
                            },
                            hover: {
                              fill: isHighlighted ? isHighlighted : "#f4f4f4",
                              outline: "none",
                            },
                            pressed: {
                              fill: isHighlighted ? isHighlighted : "#f4f4f4",
                              outline: "none",
                            },
                          }}
                        />
                      );
                    })
                  }
                </Geographies>
                {markers.map(({ name, coordinates, flagImage, content, id }) => (
                  <Marker
                    key={name}
                    coordinates={coordinates}
                    className="marker-container"

                  >

                    <svg
                      width="110px"
                      height="100px"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setCountryUrl(id);
                      }}
                    >

                      <defs>
                        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                          <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur" />
                          <feOffset in="blur" dx="5" dy="5" result="offsetBlur" />
                          <feFlood flood-color="black" flood-opacity="0.5" result="offsetColor" />
                          <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
                          <feMerge>
                            <feMergeNode in="offsetBlur" />
                            <feMergeNode in="SourceGraphic" />
                          </feMerge>
                        </filter>

                        <filter id="shadow1" x="-20%" y="-20%" width="140%" height="140%">
                          <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" /> {/* Blur the graphic */}
                          <feOffset in="blur" dx="5" dy="5" result="offsetBlur" /> {/* Offset the blurred graphic */}
                          <feFlood flood-color="black" flood-opacity="0.5" result="offsetColor" /> {/* Color & opacity for the shadow */}
                          <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
                          <feMerge>
                            <feMergeNode in="offsetBlur" />
                            <feMergeNode in="SourceGraphic" /> {/* The original graphic to be above the shadow */}
                          </feMerge>
                        </filter>
                      </defs>
                      <g class="flag-container">
                        <rect x={0} y={0} width={140} height={120} fill="#fff" filter="url(#shadow1)" stroke="#f4f4f4" strokeWidth={5} />
                        <image filter="url(#shadow)" href={flagImage} height="50px" width="50px" x={12} />
                        {content}

                        {/* <text x="0" y="60" font-size="8">
                          {content}
                        </text> */}
                      </g>
                    </svg>




                  </Marker>
                ))}
              </ComposableMap>
            </div>


            {/* <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WorldMap
                color="red"
                title=""
                value-suffix="people"
                data={data}
                size={600}
                richInteraction
                // frame
              />
            </div> */}
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default FoServicesProduct;
