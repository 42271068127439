import { Button } from "@chakra-ui/react";
import "./ComponentButton.scss";
const ComponentButton = (props: any) => {
  const { title, onClick, type, whiteBgBtn, blackBgBtn, iconRight, style, isLoading } = props;
  return (
    <Button
      type={type}
      className={
        blackBgBtn
          ? "component-button-black"
          : whiteBgBtn
          ? "component-button-white"
          : "component-button"
      }
      onClick={onClick}
      rightIcon={iconRight}
      style={style}
      isLoading={isLoading}

      // {...props}
    >
      {title}
    </Button>
  );
};

export default ComponentButton;
