import { useNavigate } from "react-router-dom";
import MainContainer from "../../sub-components/MainContainer"
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import { LOGIN_URL, SIGN_UP_LEGAL_AGGREMENT__URL } from "../../routes";
import { useEffect } from "react";
import { UPDATE_USER_MUTATION } from "../../graphql/user.mutation";
import { toast } from "react-toastify";

const GoToLegal = () => {
    const navigate = useNavigate();

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            console.log("I am getting called!", data)
            UpdateUser({
                variables: {
                  input: {
                    onboardStep: 4,
                  },
                },
              });
        },
        onError: (err) => {
          console.log("Error in", err.graphQLErrors[0]?.message);
          localStorage.clear();
          window.location.href = LOGIN_URL;
        },
    
        fetchPolicy: "no-cache",
      });

      const [UpdateUser, { data, loading, error }] = useMutation(
        UPDATE_USER_MUTATION,
        {
          onCompleted: (data) => {

            navigate(SIGN_UP_LEGAL_AGGREMENT__URL);
          },
          onError: (err) => {
            console.log("Error in", err.graphQLErrors[0]?.message);
    
            toast.error(err.graphQLErrors[0]?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 1,
              theme: "dark",
            });
            // toast({
            //   title: err.graphQLErrors[0]?.message,
            //   status: "error",
            //   isClosable: true,
            // });
          },
        }
      );
    
    
      useEffect(() => {
        getUser({ variables: {} });
      }, []);
    return <MainContainer>

    </MainContainer>
}

export default GoToLegal