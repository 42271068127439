import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    makeVar,
    split,
    from,
  } from "@apollo/client";
  import { getMainDefinition } from "@apollo/client/utilities";
  import { setContext } from "@apollo/client/link/context";
  import { WebSocketLink } from "@apollo/client/link/ws";
  import { TokenRefreshLink } from "apollo-link-token-refresh";
  import jwt_decode, { JwtPayload, jwtDecode } from "jwt-decode";
  
  export const isJwtValid = (token: string) => {
    if (token) {
      const decodedToken: any = jwtDecode(token)
      if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
        return false;
      }
      return true;
    }
    return false;
  };
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("authToken");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  const token: any = localStorage.getItem("authToken");
//   const refreshToken: any = localStorage.getItem("refreshAuthToken");
  let wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: token ? `Bearer ${token}` : "",
      },
      lazy: true,
    },
  });
  
  export const userObj = makeVar({});
  export const authTokenVar = makeVar(localStorage.getItem("authToken"));
//   export const refreshTokenVar = makeVar(localStorage.getItem("authToken"));
  
  const isTokenExpired = (token: string): Boolean => {
    try {
      if (token) {
        var decodedToken: any = jwtDecode(token)
        var dateNow = new Date();
        if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
          // console.log(
          //   "Inside token expired",
          //   decodedToken?.exp,
          //   (new Date().getTime() + 1) / 1000
          // );
          return true;
        }
      }
    } catch (err) {
      return true;
    }
    // console.log("Token not expired");
    return false;
  };
  
//   const refreshLink = new TokenRefreshLink({
//     isTokenValidOrUndefined: () =>
//       !isTokenExpired(token) || typeof token !== "string",
//     fetchAccessToken: async () => {
//       const refreshTokenQuery = `
//                   query GetToken($refreshToken: String!) {
//                         getToken(refreshToken: $refreshToken) {
//                         accessToken
//                     }
//                   }`;
  
//       const graphqlUrl: any = process.env.REACT_APP_API_ENDPOINT;
//       const response = await fetch(graphqlUrl, {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           query: refreshTokenQuery,
//           variables: {
//             refreshToken: refreshToken,
//           },
//         }),
//       });
//       return response.json();
//     },
//     handleFetch: (accessToken) => {
//       // console.log("accestokenaferfetch", accessToken)
//       // const accessTokenDecrypted = jwt_decode(accessToken);
//       // console.log("Hanlde Fetch", accessToken);
//       authTokenVar(accessToken);
//       localStorage.setItem("authToken", accessToken);
//     },
//     handleResponse: (operation, accessTokenField) => (response: any) => {
//       // here you can parse response, handle errors, prepare returned token to
//       // further operations
  
//       return { access_token: response?.data?.getToken?.accessToken };
//     },
//     handleError: (err) => {
//       userObj({});
//       localStorage.removeItem("authToken");
//       localStorage.removeItem("refreshAuthToken");
//       // showErrorMessage("Session expired. Please login again.");
//     },
//   });
  
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
  
  export const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: from([ authLink, splitLink]),
    cache: new InMemoryCache(),
  });
  