import SumsubWebSdk from "@sumsub/websdk-react";

const VideoVerification = () => {
  const token: any = localStorage.getItem("authToken");
  return (
    <SumsubWebSdk
      testEnv={true}
      accessToken={token}
      expirationHandler={() => token}
      config={
        {
          lang: "en"
          // email: "test@gmail.com",
          // phone: "0912234456"
        }
      }
      // options={{ addViewportTag: false, adaptIframeHeight: true }}
      onMessage={(data: any, payload: any) => console.log("onMessage", data, payload)}
      onError={(data: any) => console.log("onError", data)}
    />
  );
};

export default VideoVerification;
