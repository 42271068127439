import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "./PaymentProofsModal.scss";
import { STATUS, STATUS_TEXT } from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL } from "../../routes";

const PaymentProofsModal = ({
  open,
  onClose,
  filledFormList,
  user
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "security-account-modal",
      }}
    >
      {/* <h2>Status</h2> */}
      <h5 className="black-card-head">Status</h5>
      <ChakraProvider>
        <TableContainer>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th className="table-title"></Th>
                <Th className="table-header-text">Status</Th>
                <Th className="table-header-text">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
                {user?.paymentProofForm &&   <Tr>
                          <Td className="table-title">Cash Deposit</Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color: STATUS_TEXT?.[user?.approvals?.paymentProofForm?.status]?.color,
                            }}
                          >
                            {STATUS_TEXT?.[user?.approvals?.paymentProofForm?.status]?.text}
                          </Td>
                          {user?.approvals?.paymentProofForm?.status !== STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${user?.paymentProofForm?.formID}&formUrl=https://www.jotform.com/edit/${user?.paymentProofForm?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>}
                {filledFormList?.length !== 0 && filledFormList?.map((form:any) => {
                    return (
                        <Tr>
                          <Td className="table-title">{form?.name}</Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color: STATUS_TEXT?.[form?.approval?.status]?.color,
                            }}
                          >
                            {STATUS_TEXT?.[form?.approval?.status]?.text}
                          </Td>
                          {form?.approval?.status !== STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${form?.formID}&formUrl=https://www.jotform.com/edit/${form?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>
                      );
                })}
             

            
            </Tbody>
          </Table>
        </TableContainer>
      </ChakraProvider>


    </Modal>
  );
};

export default PaymentProofsModal;
