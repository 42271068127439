import { useLazyQuery } from "@apollo/client";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./InvestmentForm.scss";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import { useRouter } from "../../routes";
import MainContainer from "../../sub-components/MainContainer";
// import JotformEmbed from 'react-jotform-embed';
const InvestmentForm = () => {
  const router = useRouter();
  const [user, setUser] = useState<any>();
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const formUrl = router?.location?.search?.slice(9);

  console.log("CustomnerId", user?.customerId);
  // https://alt.jotfor.ms/240242429302041&customerid=undefined&environment=staging&baseurl=http://localhost:3001&email=nikitamittal2423@gmail.com&date=26-01-2024&fullName=Nik%20Mittal
  return (
    <DashboardContainer>
      <div style={{ width: "100%", height: "100vh" }}>
        {user?.customerId && (
          <iframe
            id="JotFormIFrame-201877561050049"
            title="Widex Moment Application"
            allowTransparency={true}
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            // src={`https://form.jotform.com/240242572144044?&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}`}
            src={formUrl}
            style={{ border: "none" }}
            className="security-form-container"
            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-popups"
          >
            {" "}
          </iframe>
        )}
      </div>
    </DashboardContainer>
  );
};

export default InvestmentForm;
