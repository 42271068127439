import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { IntlProvider } from "react-intl";

import reportWebVitals from "./reportWebVitals";
import { App } from "./app/index";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { client } from "./app/graphql/client";
import { ChakraBaseProvider, ChakraProvider } from "@chakra-ui/react";

import translate, { changeLanguage, messages } from "./translate";

const lang = localStorage.getItem("user-lang") || "en"

// x
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>
// );
const rootElement = document.getElementById("root");
ReactDOM.render(
  <ApolloProvider client={client} key={lang}>
    <IntlProvider locale={lang} messages={messages[lang]} key={lang}>
    <App key={lang}/>
    </IntlProvider>
   
  </ApolloProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
