import { useEffect, useState } from "react";
import backgroundOverlay from "../../../assets/dfo-background.svg";
import ComponentSidebar from "../../atoms/ComponentSidebar";
import "./DashbordContainer.scss";
import ChatIcon from "../../../assets/chat.svg";
import ComponentSideModal from "../../atoms/ComponentSideModal";
import Launcher from "../../../features/LauncherPrompt";

import { ToastContainer, toast } from "react-toastify";
const DashboardContainer = ({ children, hideSidebar }: any) => {
  useEffect(() => {
    if (document.getElementById("eself-open-button")) {
      const element: any = document.getElementById("eself-open-button");
      element.style.display = "flex";
    }
    if (
      !document.getElementsByClassName("gptbots-chat") ||
      !document.getElementsByClassName("gptbots-chat").length
    ) {
      const script = document.createElement("script");
      script.src =
        "https://www.gptbots.ai/widget/erxmc2sltzblc0bdcvnbrko/chat.js";
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div
      className="dashboard-container"
      // style={{
      //   backgroundImage: `url(${backgroundOverlay})`,
      //   backgroundSize: "cover",
      // }}
      style={{ backgroundColor: "#000" }}
    >
      <div className="main-dashboard-container">
        {!hideSidebar && <ComponentSidebar />}

        {children}
        {/* <div className="chat-icon">
                    <img src={ChatIcon} alt="chat-icon"/>
               </div> */}
        {/* <Launcher
          messageList={[]}
          onFilesSelected={() => {}}
          onMessageWasSent={() => {}}
          agentProfile={{}}
        /> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardContainer;
