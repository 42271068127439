
const ChatAnimation = () => {
  return (
    <svg height="10" width="40" className="loader">
      <circle className="dot" cx="10" cy="3" r="3" style={{ fill: 'grey' }} />
      <circle className="dot" cx="20" cy="3" r="3" style={{ fill: 'grey' }} />
      <circle className="dot" cx="30" cy="3" r="3" style={{ fill: 'grey' }} />
    </svg>
  );
};

export default ChatAnimation;
