import { useLazyQuery } from "@apollo/client";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./SecurityAccount.scss";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import { useRouter } from "../../routes";
// import JotformEmbed from 'react-jotform-embed';
const SecurityAccount = () => {
  const [user, setUser] = useState<any>();
  const router = useRouter();
  const { query } = router;
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const formUrl = router?.location?.search?.slice(9);

  console.log("CustomnerId", user?.customerId, formUrl, router);
  // {baseurl}/security-account/241281272627455?formUrl=https://alt.jotfor.ms/241281272627455?uniqueId={submissionId}&customerid={customerid}&environment={environment}&baseurl={baseurl}&1name={name86}&address={typeA211}&3Permanent43={residentialAddress227}&4Mailing={correspondenceAddress229}&8Date={dateof}&6aForeign={typeA121}&Email={email}
  return (
    <DashboardContainer>
      <div style={{ width: "100%", height: "100vh" }}>
        {user?.customerId && (
          <>
            <iframe
              id={`JotFormIFrame-${query?.formId}`}
              title="v.2 W-8BEN"
              // onLoad="window.parent.scrollTo(0,0)"
              allowTransparency={true}
              allow="geolocation; microphone; camera; fullscreen"
              src={formUrl}
              frameBorder={0}
              style={{minWidth:"100%", maxWidth:"100%", border:"none"}}
              className="security-form-container"
              // sandbox="allow-popups"
            >
              {" "}
            </iframe>{" "}
            <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>{" "}
            <script>
              window.jotformEmbedHandler("iframe[id='JotFormIFrame-{query?.formId}']",
              "https://form.jotform.com/")
            </script>
          </>
          // <iframe
          //   id="JotFormIFrame-201877561050049"
          //   title="Widex Moment Application"
          //   allowTransparency={true}
          //   allowFullScreen={true}
          //   allow="geolocation; microphone; camera"
          //   src={formUrl}
          //   style={{ border: "none" }}
          //   className="security-form-container"
          //   sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-popups"
          // >
          //   {" "}
          // </iframe>
        )}
      </div>
    </DashboardContainer>
  );
};

export default SecurityAccount;
