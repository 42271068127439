import { ArrowForwardIcon } from "@chakra-ui/icons";
import "./ComponentFoCard.scss";
import translate from "../../../translate";
const ComponentFoCard = (props: any) => {
  const { title, content, backgroundGradient, onClick, commingSoon } = props;

  console.log("commingSoon", commingSoon);
  return (
    <div className="card-fo" style={{ background: backgroundGradient }}>
      <div>
        <h6 style={{ minHeight: "42px" }}>{title}</h6>
        <p>{content}</p>
        {/* {commingSoon && (
          <div className="coming-soon">
            <p>COMING SOON</p>
          </div>
        )} */}
      </div>
      {commingSoon && (
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div className="coming-soon">
            <p>{translate("COMING SOON")}</p>
          </div>
        </div>
      )}
      {!commingSoon && (
        <div className="arrow-fwd" onClick={onClick}>
          <ArrowForwardIcon w={24} h={24} />
        </div>
      )}
    </div>
  );
};
export default ComponentFoCard;
