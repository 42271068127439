import { useNavigate } from "react-router-dom";
import ComponentButton from "../../atoms/ComponentButton";
import ComponenetInput from "../../atoms/ComponentInput";
import MainContainer from "../../sub-components/MainContainer";
import "../SignUp/signup.scss";
import {
  LOGIN_URL,
  SIGN_UP_PHONE_URL,
  SIGN_UP_PHONE_VERIFICATION_URL,
} from "../../routes";
import { useMutation, useQuery } from "@apollo/client";
import { FORGOT_PASSWORD_MUTATION } from "../../graphql/user.mutation";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { toast } from "react-toastify";

interface SignUpProps {
  type?: string;
}

const ForgotPassword = ({ type }: SignUpProps) => {
  const navigate = useNavigate();
  //   const toast = useToast();
  const [email, setEmail] = useState("");
  const [ResetPassword, { data, loading, error }] = useMutation(
    FORGOT_PASSWORD_MUTATION,
    {
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);
        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
        // toast({
        //   title: err.graphQLErrors[0]?.message,
        //   status: "error",
        //   isClosable: true,
        // });
      },
    }
  );

  useEffect(() => {
    if (data?.SignUp?.succeed && !loading) {
      navigate(`/signup/verification/${email}`);
    }
  }, [data]);

  const handleChange = (event: any) => {
    setEmail(event?.target?.value);
  };
  const onClickEvent = (event: any) => {
    ResetPassword({ variables: { email: email } });
  };

  return (
    <MainContainer>
      <div className="sign-up">
        <h2>Email</h2>
        <p>We will use it to reset your password</p>
        <div className="sign-up-component">
          <ComponenetInput
            handleChange={handleChange}
            placeholder="name@example.com"
            type={"email"}
            value={email}
            // value={""}
          />
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="reset-pass"
              onClick={() => {
                navigate(LOGIN_URL);
              }}
            >
              Back to login
            </div>
            <ComponentButton title="Send Reset Link" onClick={onClickEvent} />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ForgotPassword;
