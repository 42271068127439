import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import translate from "../../../translate";

const EditUserModal = ({
  isOpen,
  onClose,

  onUpdateUser,
  userData,
}: any) => {
  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [middleName, setMiddleName] = useState(userData?.middleName);
  const [phone, setPhone] = useState(userData?.phone || "");

  const onUpdate = () => {
    onUpdateUser({
      firstName,
      lastName,
      middleName,
      phone,
    });
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "customModal",
      }}
    >
      <div style={{padding:"20px 40px"}}>
      <h2 style={{color:"#fff", fontSize:"20px", fontWeight:"bold"}}>Update User</h2>
      <FormControl style={{ width: "100%", marginTop:"30px" }}>
        <ComponenetInput
          placeholder={translate("first_name")}
          value={firstName}
          handleChange={(e: any) => {
            setFirstName(e.target.value);
          }}
        />
        <div style={{ display: "flex", gap: "40px", marginTop: "40px" }}>
          <ComponenetInput
            placeholder={translate("middle_name")}
            value={middleName}
            handleChange={(e: any) => {
              setMiddleName(e.target.value);
            }}
          />
          <ComponenetInput
            placeholder={translate("last_name")}
            value={lastName}
            handleChange={(e: any) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "40px", marginTop: "40px" }}>
          <ComponenetInput
            placeholder={translate("Phone")}
            value={phone}
            handleChange={(e: any) => {
              setPhone(e.target.value);
            }}
          />
        </div>
      </FormControl>
      <div
        style={{
          display: "flex",
          marginTop: "15px",
          flexDirection: "row-reverse",
        }}
      >
        <ComponentButton title="Update" onClick={onUpdate} />
      </div>
      </div>
     
    </Modal>
  );
};

export default EditUserModal;
