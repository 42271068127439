import { Link, useNavigate } from "react-router-dom";
import DashboardContainer from "../../sub-components/DashbordContainer";
import ComponentButton from "../../atoms/ComponentButton";
import { Grid } from "@chakra-ui/react";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { ALTERNATIVES_PRODUCT_URL } from "../../routes";
import translate from "../../../translate";

import "../FoStrategy/FoStrategy.scss";
import "./Cies.scss";
import "../Accounts/Account.scss";
import ProductItem from "../../sub-components/ProductItem";
import ComponentGradientButton from "../../atoms/ComponentGradientButton";
import ComponentGradientWhiteButton from "../../atoms/ComponentGradientWhiteButton";

const ciesData = [
  {
    name: "Product 1",
    tags: ["MA", "PI"],
    tagLabel: "Stable Returns",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "8-12%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#00173f",
  },
  {
    name: "Product 2",
    tags: ["MA", "PI"],
    tagLabel: "Global ETFs",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "8%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#0076e3",
  },
  {
    name: "Product 3",
    tags: ["MA", "PI"],
    tagLabel: "High-quality Returns",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "15%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#8b428c",
  },
];

const Cies = () => {
  const navigate = useNavigate();

  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div className="title-small-heading">{translate('highlight')}</div>
                <h3 className="gradient-heading">{translate('cies')}</h3>
                <div style={{ color: "#37454b", fontSize: "14px" }}>
                  {translate("Explore our comprehensive suite of products and expert advisory services tailored for Hong Kong's Capital Investment Entrant Scheme (CIES) clients.")}
                </div>
              </div>

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            <div
              style={{
                marginTop: "50px",
                display: "flex",
                // justifyContent: "space-between",
                gap: "20px",
                flexWrap:"wrap"
              }}
            >
              {ciesData?.map((item) => {
                return <ProductItem item={item} />;
              })}
            </div>
          </div>

          <div style={{ color: "#111", marginBottom:"100px" }}>
            <h2 style={{color:"#6A7287", fontSize:"40px", fontWeight:"600", marginBottom:"0px"}}>{translate('Quick Answers?')}</h2>
            <p style={{fontSize:"25px", color:"#6A7287", marginTop:"0px"}}>{translate("Use 'DL Assistant' for instant help.")}</p>
            <a href="https://gptbots.ai/s/conV9mrJ" target="__blank">
            <ComponentGradientWhiteButton title={translate("Try DL Assistant")}></ComponentGradientWhiteButton>
            </a>
          

            <div style={{ color: "#aaa", marginTop: "30px", fontSize: "11px" }}>
              <p style={{ margin: "0px" }}>{translate('For tailored advice,')}</p>
              <p style={{ margin: "0px" }}>
                {translate('click the button in the top-right corner to speak with our experts')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Cies;
