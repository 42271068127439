import {
  Grid,
  Progress,
  ChakraProvider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./FoStrategyProduct.scss";
import {
  ArrowBackIcon,
  ArrowUpIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import ReadMoreReact from "read-more-react";

import { ACCOUNT_URL, EDIT_FORM_URL, SECURITY_ACCOUNT_URL, useRouter } from "../../routes";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import {
  PRODUCT_NAME,
  SECURITY_ACCOUNT_OPENING_ID,
  STATUS,
  STATUS_TEXT,
  VIDEO_VERIFICATION_FORM_ID,
  W8BEN_FROM_ID,
  comparisonData,
  foStrategyObj,
} from "../../dataHelper";
import translate from "../../../translate";
import SecurityFormStatusModal from "../../sub-components/SecurityFormStatusModal";
import SecurityAccountInfoModal from "../../sub-components/SecurityAccountInfoModal";
import {
  GET_ALL_FUNDS_DATA,
  GET_FUNDS_INFO,
  GET_INVESTMENT_FORM,
  GET_STRATEGY_PRODUCT,
  GET_USER_DL_INEVSTMENTS,
  GET_USER_INVESTMENT,
} from "../../graphql/investment.query";
import { isMobile, isDesktop, isTablet, isIPad13 } from "react-device-detect";
import SelectUserModal from "../../sub-components/SelectUserModal";
import AFELogin from "../../sub-components/AFELogin";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InvestmentForm from "../../sub-components/InvestmentForm";
import axios from "axios";
import moment from "moment";
import InvestmentFormStatusModal from "../../sub-components/InvestmentFormStatusModal";
import { orderBy, sortBy } from "lodash";
import CurrencyExchange from "../../sub-components/CurrencyExchange";

// import pdfFile from '../../../documents/flagship1.pdf'

const dummyData = [
  {
    name: "Maple Street",
    progress: "10%",
  },
  {
    name: "El Paso",
    progress: "20%",
  },
  {
    name: "Waco",
    progress: "70%",
  },
  {
    name: "East Silas",
    progress: "90%",
  },
];

export const options = [
  { value: "Fund B", label: "Fund B" },
  { value: "Fund C", label: "Fund C" },
  { value: "Fund D", label: "Fund D" },
  { value: "Fund E", label: "Fund E" },
];

const assetClassObj = {
  abs: "ABS: Asset - Backed Security",
  cash: "Cash",
  convertible_bond: "Convertible Bond",
  deposit: "Deposit",
  equity: "Equity",
  fixed_income: "Fixed Income",
  fund: "Fund",
  government_bond: "Government Bond",
  private_equity: "Private Equity",
};

const FoStrategyProduct = ({
  afeLoginData,
  setAfeLoginData,
  socket,
  setSocket,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);
  const [investmentFormList, setInvestmentFormList] = useState([]);
  const [filledFormList, setFilledList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedFund, setSelectedFund] = useState();
  const [isSelectUserOpened, setIsSelectUserOpened] = useState(false);
  const [isReadMoreExpanded, setIsReadMoreExpanded] = useState(false);
  const [isInvestmentFormOpened, setIsInvestmentFormOpened] = useState(false);
  const [isExistingInvestmentFormOpened, setIsExistingInvestmentFormOpened] =
    useState(false);
  const [fundOptions, setFundOptions] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date("2024-01-01"),
    new Date("2024-07-11"),
  ]);
  const [startDate, endDate] = dateRange;

  console.log("selectedFund", selectedFund);
  const formatData = (data) => {
    // const data = comparisonData[selectedFund];
    const firstSeries = [];
    const secondSeries = [];
    data?.forEach((item) => {
      firstSeries.push([item?.Month * 1000, item?.first]);
      secondSeries.push([item?.Month * 1000, item?.second]);
    });

    return {
      firstSeries,
      secondSeries,
    };
  };

  const [lineChartData, setLineChartData] = useState({
    series: [
      {
        name: "DL Portfolio",
        data: formatData()?.firstSeries,
        color: "#0076e3",
      },
      {
        name: selectedFund,
        data: formatData()?.secondSeries,
        color: "#F8BC9B",
      },
    ],
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 450,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
      },
      title: {
        text: "",
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return `${(val * 100).toFixed(0)}%`;
          },
        },
        title: {
          text: "Price",
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return `${(val * 100).toFixed(0)}%`;
          },
        },
      },
    },
  });

  const router = useRouter();
  const { query } = router;

  const productId = query?.id;
  const [formStatus, setFormStatus] = useState("");
  const [investmentStatus, setInvestmentStatus] = useState("pending");
  const [investmentInfo, setInvestmentInfo] = useState();
  const [isInvestmentStatusModalOpened, setIsInvestmentStatusModalOpened] =
    useState(false);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
        let user = data?.user;
        if (user?.existingUser) {
          if (user?.existingUserForm) {
            if (user?.approvals?.existingUserForm?.status === STATUS.APPROVED) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            setFormStatus("complete");
          }
        } else if (user?.securityForm && user?.w8BenForm && user?.videoForm) {
          if (
            user?.approvals?.securityForm?.status === STATUS.APPROVED &&
            user?.approvals?.w8BenForm?.status === STATUS.APPROVED &&
            user?.approvals?.videoForm?.status === STATUS.APPROVED
          ) {
            setFormStatus("download");
          } else {
            setFormStatus("waiting");
          }
        } else {
          if (user?.securityForm && !user?.w8BenForm) {
            setFormStatus("w8BenFormComplete");
          } else if (
            user?.securityForm &&
            user?.w8BenForm &&
            !user?.videoForm
          ) {
            setFormStatus("videoFormComplete");
          } else {
            setFormStatus("complete");
          }
        }
      }
    },
    fetchPolicy: "no-cache",
  });

  const [getUserInvestments] = useLazyQuery(GET_USER_INVESTMENT, {
    onCompleted: (data) => {
      console.log("getUserInvestments", data);
      if (data?.userInvestments?.items?.[0]) {
        setInvestmentInfo(data?.userInvestments?.items?.[0]);
        setInvestmentStatus(
          data?.userInvestments?.items?.[0]?.approval?.status
        );
      }
    },
    fetchPolicy: "no-cache",
  });

  const [getUserDLInvestments] = useLazyQuery(GET_USER_DL_INEVSTMENTS, {
    onCompleted: (data) => {
      console.log("getUserInvestments", data);
      if (data?.dlUserInvestments?.items?.[0]) {
        setInvestmentInfo(data?.dlUserInvestments?.items?.[0]);
        setInvestmentStatus(
          data?.dlUserInvestments?.items?.[0]?.approval?.status
        );
      }
    },
    fetchPolicy: "no-cache",
  });

  const [getAllFundsData] = useLazyQuery(GET_ALL_FUNDS_DATA, {
    onCompleted: (data) => {
      console.log("getUserInvestments", data);
    },
    fetchPolicy: "no-cache",
  });

  const [getFundInfo] = useLazyQuery(GET_FUNDS_INFO, {
    onCompleted: (data) => {
      console.log("get Funds Info", data);
      if (data?.FundsPagination?.items?.length) {
        const fundList = data?.FundsPagination?.items;
        let funds = [];
        fundList?.forEach((fund) => {
          if (fund?.isin !== productId && fund?.isin !== "LU1916293290") {
            funds.push({
              value: fund?.isin,
              label: fund?.name,
            });
          }
        });
        setSelectedFund(funds[0]);
        const sortedList = sortBy(funds, ["label"]);
        setFundOptions([...sortedList]);
        getFundDetail(funds[0]);
      }
    },
    onError: (err) => {},
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
    // getUserInvestments({
    //   variables: {
    //     filter: {
    //       fundId: productId,
    //     },
    //   },
    // });

    getUserDLInvestments({
      variables: {
        filter: {
          fundId: productId,
        },
      },
    });
    getFundInfo({
      variables: {
        perPage: 30,
      },
    });

    // https://api.allfunds.dev/#overview
    // https://api.allfunds.dev/#operational
    // https://api.allfunds.dev/#yearly-returns
    // https://api.allfunds.dev/#legal-documents
    // https://api.allfunds.dev/#rolling-performance
    // https://api.allfunds.dev/#breakdowns-limited
  }, []);

  // {
  //   name: translate("Flagship 1"),
  //   detailName: translate("DL Flagship Strategy"),
  //   overview: translate(
  //     "The goal of this strategy is to pursue high returns while emphasizing asset stability. It focuses on stable sources of income to ensure the safety of funds. By flexibly allocating products with higher absolute returns, it can maximize asset appreciation. At the same time, by establishing a diversified investment portfolio and selecting assets with low correlation to the broader market, it can reduce the systematic risk of the overall investment portfolio. This strategy aims to balance risk and return, provide stable returns, and protect assets from market fluctuations. By considering various investment opportunities and asset classes, this strategy can better adapt to different market environments and provide long-term financial security for investors."
  //   ),
  //   allocation: {
  //     asset: [
  //       {
  //         label: translate("60% fixed-income assets"),
  //         value: 60,
  //          text: translate("Fixed Income")
  //       },
  //       {
  //         label: translate("35% equity assets"),
  //         value: 35,
  //          text: translate("Equity")
  //       },
  //       {
  //         label: translate("5% cash"),
  //         value: 5,
  //          text: translate("Cash")
  //       }
  //     ],
  //     geography: [
  //       {
  //         name: translate("North America"),
  //         actualCost: "49.30%",
  //         performance: "0.01%",
  //         actualCostNumber: 49.3,
  //       },
  //       {
  //         name: translate("Asia Pacific (excluding Japan)"),
  //         actualCost: "33.30%",
  //         performance: "3.16%",
  //         actualCostNumber: 33.3,
  //       },
  //       {
  //         name: translate("World"),
  //         actualCost: "7.00%",
  //         performance: "0.33%",
  //         actualCostNumber: 7,
  //       },
  //       {
  //         name: translate("Europe"),
  //         actualCost: "5.20%",
  //         performance: "-0.11%",
  //         actualCostNumber: 5,
  //       },
  //       {
  //         name: translate("Japan"),
  //         actualCost: "5.20%",
  //         performance: "0.13%",
  //         actualCostNumber: 5,
  //       },
  //     ],
  //   },
  //   topHoldings: [
  //     {
  //       name: "Vanguard Ultra Short Bond ETF",
  //       percentageText: "17.8%",
  //       percentage: 17.8,
  //     },
  //     {
  //       name: "SPDR Bloomberg 1-3 Month T-Bill ETF",
  //       percentageText: "16.0%",
  //       percentage: 16.0,
  //     },
  //     {
  //       name: "ISHRS Trust ISHRS 1-5 Year Invest Grade Corp Bond ETF",
  //       percentageText: "5.9%",
  //       percentage: 5.9,
  //     },
  //     {
  //       name: "ISHRS Barclays Short Treasury",
  //       percentageText: "5.9%",
  //       percentage: 5.9,
  //     },
  //     {
  //       name: "ISHRS MSCI All Country Asia Ex Japan",
  //       percentageText: "5.8%",
  //       percentage: 5.8,
  //     },
  //   ],
  //   feesAndExpectedReturn: {
  //     expectedReturn: "8-10%",
  //     fees: {
  //       subscriptionFee: "1%",
  //       managementFee: "0.5%",
  //       performanceFee: "10%",
  //     },
  //   },
  //   comparison: {},
  //   details: [
  //     {
  //       name: translate("Flagship 1 Teaser"),
  //       link: "https://dlifo-client-files.s3.eu-west-1.amazonaws.com/docs/Flagship+Teaser+v18ENG+1-2023-12-14T17-22-03.810Z.pdf",
  //     },
  //   ],
  // }

  const [fundData, setFundData] = useState({});

  const getFundDetail = (selectedFund) => {
    let fundDetailData = {};
    if (foStrategyObj[productId]) {
      setFundData({ ...foStrategyObj[productId] });
    } else {
      getAllFundsData({
        variables: {
          targetUrl: `/v1/funds/${productId}/overview`,
        },
      }).then((response) => {
        console.log("Overview Data", response);
        if (response?.data?.allFunds) {
          const overviewData = response?.data?.allFunds;
          fundDetailData.name = overviewData?.name;
          fundDetailData.detailName = overviewData?.name;
          fundDetailData.overview = overviewData?.investment_objective?.en;
          fundDetailData.investmentStructure = overviewData?.legal_structure;

          getAllFundsData({
            variables: {
              targetUrl: `/v1/funds/${productId}/operational`,
            },
          }).then((operationalResponse) => {
            if (operationalResponse?.data?.allFunds) {
              const operationData = operationalResponse?.data?.allFunds;
              fundDetailData.minimumAmount =
                operationData?.minimum_initial_subscription_amount;
              fundDetailData.currency = operationData?.minimums_currency;
              fundDetailData.redemptionFrequency =
                operationData?.redemption_dealing_frequency;
              fundDetailData.subscriptionFee = `${Math.round(
                operationData?.subscription_fee
              )}%`;
              fundDetailData.managementFee = `${Math.round(
                operationData?.management_fee
              )}%`;
              fundDetailData.performanceFee = `${Math.round(
                operationData?.performance_fee
              )}%`;
              getAllFundsData({
                variables: {
                  targetUrl: `/v1/funds/${productId}/performance`,
                },
              }).then((performanceResponse) => {
                if (performanceResponse?.data?.allFunds) {
                  const performanceData = performanceResponse?.data?.allFunds;
                  fundDetailData.expectedReturn = `${Math.round(
                    performanceData?.performance?.one_year
                  )}%`;
                  getAllFundsData({
                    variables: {
                      targetUrl: `/v1/funds/${productId}/documents?kinds=KID&languages=en`,
                    },
                  }).then((legalResponse) => {
                    console.log("Legal response", legalResponse);
                    if (legalResponse?.data?.allFunds?.documents?.length) {
                      const details = [
                        {
                          name: `${overviewData?.name} Teaser`,
                          link: legalResponse?.data?.allFunds?.documents?.[0]
                            ?.url,
                        },
                      ];
                      fundDetailData.details = details;
                    }
                    getAllFundsData({
                      variables: {
                        targetUrl: `/v1/funds/${productId}/breakdowns_limited`,
                      },
                    }).then((breakdownResponse) => {
                      console.log(
                        "Breakdown limited response",
                        breakdownResponse
                      );
                      if (breakdownResponse?.data?.allFunds) {
                        const breakdownData = breakdownResponse?.data?.allFunds;
                        if (breakdownData?.net_asset_allocation) {
                          const allocation = {
                            asset: [],
                          };
                          Object.entries(
                            breakdownData?.net_asset_allocation
                          ).forEach(([key, value]) => {
                            console.log(`Key: ${key}, Value: ${value}`);
                            allocation.asset.push({
                              label: key,
                              value: value,
                              text: assetClassObj[key],
                              valueText: `${Math.round(value)}%`,
                            });
                          });
                          fundDetailData.allocation = allocation;
                        }
                        if (breakdownData?.top_10_underlying_assets) {
                          const topHoldings = [];
                          breakdownData?.top_10_underlying_assets?.forEach(
                            (item) => {
                              topHoldings.push({
                                name: item?.name,
                                percentageText: `${item?.weight?.toFixed(2)}%`,
                                percentage: item?.weight?.toFixed(2),
                              });
                            }
                          );
                          fundDetailData.topHoldings = [...topHoldings];
                        }
                        if (breakdownData?.net_country_exposure) {
                          const geography = [];
                          Object.entries(
                            breakdownData?.net_country_exposure
                          )?.forEach(([key, value]) => {
                            geography.push({
                              name: key,
                              actualCost: `${Math.round(value)}%`,
                              performance: "N/A",
                              actualCostNumber: parseFloat(value?.toFixed(2)),
                            });
                          });
                          console.log("Geography Data", geography);
                          const sortedGeographicValues = orderBy(
                            geography,
                            ["actualCostNumber"],
                            ["desc"]
                          );
                          fundDetailData.geography = [
                            ...sortedGeographicValues,
                          ];
                        }
                      }
                      getPerformanceData(
                        selectedFund,
                        overviewData?.name,
                        startDate,
                        endDate
                      );
                      setFundData({ ...fundDetailData });
                    });
                  });
                }
              });
            }
          });
        }
      });
    }
  };

  const getPerformanceData = (
    selectedFund,
    currentFundName,
    startDate,
    endDate
  ) => {
    getAllFundsData({
      variables: {
        targetUrl: `/v1/funds/${productId}/rolling_performance?start_date=${startDate}&end_date=${endDate}`,
      },
    }).then((performanceResponse) => {
      console.log("Performance response", performanceResponse, selectedFund);
      getAllFundsData({
        variables: {
          targetUrl: `/v1/funds/${selectedFund?.value}/rolling_performance?start_date=${startDate}&end_date=${endDate}`,
        },
      }).then((comparePerformanceresponse) => {
        console.log(
          "Performance response",
          performanceResponse,
          comparePerformanceresponse
        );
        const currentFund =
          performanceResponse?.data?.allFunds?.rolling_performance?.[productId];
        const compareFund =
          comparePerformanceresponse?.data?.allFunds?.rolling_performance?.[
            selectedFund?.value
          ];
        let chartData = [];
        Object.entries(currentFund)?.forEach(([key, value]) => {
          chartData.push({
            Month: moment(key, "YYYY-MM-DD").unix(),
            first: value,
            second: compareFund[key],
          });
        });

        console.log("Chart Data", chartData, currentFund, compareFund);
        const lineChartData = {
          series: [
            {
              name: currentFundName,
              data: formatData(chartData)?.firstSeries,
              color: "#0076e3",
            },
            {
              name: selectedFund?.label,
              data: formatData(chartData)?.secondSeries,
              color: "#F8BC9B",
            },
          ],
          options: {
            chart: {
              type: "area",
              stacked: false,
              height: 450,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            stroke: {
              width: 2,
            },
            title: {
              text: "",
              align: "left",
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100],
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return `${val.toFixed(0)}%`;
                },
              },
              title: {
                text: "Price",
              },
            },
            xaxis: {
              type: "datetime",
            },
            tooltip: {
              shared: false,
              y: {
                formatter: function (val) {
                  return `${val.toFixed(0)}%`;
                },
              },
            },
          },
        };

        console.log("Line Chart Data", lineChartData);
        setLineChartData({ ...lineChartData });
      });
    });
  };

  const getHoldingColor = (percent) => {
    try {
      const _percent = parseFloat(percent);
      if (_percent > 50.0) return "#004E9F";
      else if (_percent > 10.0) return "#035FC0";
      return "#0075EB";
    } catch (err) {
      return "#0076E3";
    }
  };

  const getPerformaceColor = (index) => {
    switch (index % 4) {
      case 0:
        return "#166BC2";
      case 1:
        return "#187FE5";
      case 2:
        return "#7D4A9A";
      case 3:
        return "#9F2F69";
      case 4:
        return "#CC2E5B";
      default:
        return "#0000";
    }
  };

  const getAssetColor = (index) => {
    switch (index) {
      case 0:
        return "#187FE5";

      case 1:
        return "#7D4A9A";

      case 2:
        return "#CC2E5B";

      default:
        return "#0000";
    }
  };

  const getAllocationColor = (index) => {
    switch (index % 4) {
      case 0:
        return "#4291E4";
      case 1:
        return "#7FB5EC";
      case 2:
        return "#AD89C4";
      case 3:
        return "#D57DAA";
      case 4:
        return "#EF93AE";
      default:
        return "#0000";
    }
  };

  console.log("form status", formStatus);

  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          {formStatus === "download" && (
            <>
              {/* {afeLoginData?.loginId && ( */}
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "70%" }}>
                    <div>
                      <ChevronLeftIcon
                        w={22}
                        h={22}
                        style={{ cursor: "pointer", color: "#3185E7" }}
                        onClick={() => {
                          navigate("/fo-strategy");
                        }}
                      />
                      &nbsp;
                      <span className="mx-2 title-small-heading">
                        {translate("BACK")}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "28px",
                      }}
                    >
                      <div>
                        <h3
                          className=""
                          style={{ marginBottom: "0px", fontSize: "30px" }}
                        >
                          {fundData?.name}
                        </h3>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#37454b",
                        fontSize: "14px",
                        marginTop: "20px",
                        position: "relative",
                      }}
                      className="fo-strategy-product-description"
                    >
                      {}
                      <span
                        style={{
                          "-webkit-line-clamp": isReadMoreExpanded
                            ? "unset"
                            : "2",
                        }}
                      >
                        {fundData?.overview}
                      </span>
                      <span
                        className="read-more-container"
                        onClick={() => {
                          setIsReadMoreExpanded(!isReadMoreExpanded);
                        }}
                      >
                        {!isReadMoreExpanded && translate("READ MORE")}
                      </span>
                    </div>
                    <span>Show More...</span>
                  </div>

                  <div
                    style={{ flex: "50%", textAlign: "end" }}
                    className="invest-review-buttons-container"
                  >
                    {user?.customerId && (
                      <>
                        {fundData?.type !== "STATIC" && (
                          <ComponentButton
                            title={
                              <div>
                                {translate("Invest")}
                                <div style={{ fontSize: "10px" }}>
                                  ({translate("Coming Soon")})
                                </div>
                              </div>
                            }
                            disabled={!user?.customerId && !fundData}
                            onClick={() => {
                              // if(investmentStatus === STATUS.PENDING){
                              //   setIsInvestmentFormOpened(true);
                              // } else if(investmentStatus ===  STATUS.APPROVED){
                              //   setIsExistingInvestmentFormOpened(true)
                              // } else {
                              //   setIsInvestmentStatusModalOpened(true)
                              // }
                            }}
                            style={{
                              marginRight: "10px",
                              filter: "grayscale(100%)",
                              height: "55px",
                              cursor: "not-allowed",
                            }}
                          />
                        )}
                        {fundData?.type === "STATIC" && (
                          <ComponentButton
                            title={translate("Invest")}
                            disabled={!user?.customerId && !fundData}
                            onClick={() => {
                              if (investmentStatus === STATUS.PENDING) {
                                setIsInvestmentFormOpened(true);
                              } else if (investmentStatus === STATUS.APPROVED) {
                                setIsExistingInvestmentFormOpened(true);
                              } else {
                                setIsInvestmentStatusModalOpened(true);
                              }
                            }}
                            style={{ marginRight: "10px", height: "55px" }}
                          />
                        )}
                      </>
                    )}

                    <ComponentButton
                      title={translate("Review your account")}
                      whiteBgBtn={true}
                      onClick={() => {
                        navigate(ACCOUNT_URL);
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: fundData?.type === "STATIC" ? "block" : "flex",
                  }}
                >
                  <div
                    style={{
                      width: fundData?.type === "STATIC" ? "100%" : "32%",
                    }}
                  >
                    <div className="title-heading">
                      {translate("Strategy Information")}
                    </div>
                    <div
                      className="security"
                      style={{
                        // backgroundColor: "#f0f3fa",
                        padding: "20px 30px",
                        // display: "flex",
                        flex: "1 1 auto",
                        backgroundImage:
                          "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                      }}
                    >
                      <div
                        style={{
                          // display: "flex",
                          justifyContent: "space-between",
                          alignItems: "unset",
                        }}
                      >
                        <div
                          className="security-vector"
                          style={{
                            height: "unset",
                            padding: "0px",
                            alignItems: "unset",
                            display: "unset",
                          }}
                        >
                          <div className="security-title">
                            <span
                              style={{ fontSize: "12px", color: "#8c8c8c" }}
                            >
                              {translate("Name")}
                            </span>
                            <p style={{ marginBottom: "10px" }}>
                              {fundData?.detailName}
                            </p>

                            <span
                              style={{ fontSize: "12px", color: "#8c8c8c" }}
                            >
                              {translate("Investment Structure")}
                            </span>
                            <p
                              style={{
                                marginBottom: "10px",
                                textTransform: "uppercase",
                              }}
                            >
                              {fundData?.investmentStructure}
                            </p>

                            <div
                              className="divider-line"
                              style={{
                                borderColor: "#8c8c8c",
                                width: "unset",
                                marginBottom: "10px",
                              }}
                            ></div>

                            <span
                              style={{ fontSize: "12px", color: "#8c8c8c" }}
                            >
                              {translate("Minimum Investment Amount")}
                            </span>
                            <p style={{ marginBottom: "10px" }}>
                              HK$
                              {fundData.currency && (
                                <CurrencyExchange
                                  amount={fundData?.minimumAmount}
                                  currency={fundData?.currency}
                                />
                              )}
                            </p>

                            <span
                              style={{ fontSize: "12px", color: "#8c8c8c" }}
                            >
                              {translate("Open Frequency")}
                            </span>
                            <p style={{ marginBottom: "10px" }}>
                              {fundData?.openFrequency || "No lock-up period"}
                            </p>

                            <span
                              style={{ fontSize: "12px", color: "#8c8c8c" }}
                            >
                              {translate("Redemption Frequency")}
                            </span>
                            <p
                              style={{
                                marginBottom: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {fundData?.redemptionFrequency}
                            </p>

                            <div
                              className="divider-line"
                              style={{
                                borderColor: "#8c8c8c",
                                width: "unset",
                                marginBottom: "10px",
                              }}
                            ></div>

                            {fundData?.type === "STATIC" && (
                              <div
                                className="card-data"
                                style={{
                                  gap: "unset",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h4
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      color: "#E92562",
                                    }}
                                  >
                                    {fundData?.interestRate}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#8c8c8c",
                                    }}
                                  >
                                    Interest Rate
                                  </p>
                                </div>
                              </div>
                            )}

                            {fundData?.type !== "STATIC" && (
                              <div
                                className="card-data"
                                style={{
                                  gap: "unset",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h4
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      color: "#E92562",
                                    }}
                                  >
                                    {fundData?.expectedReturn}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#8c8c8c",
                                    }}
                                  >
                                    {translate("Past 1 year returns")}
                                  </p>
                                </div>
                                <div>
                                  <h4
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      color: "#7E4A9B",
                                    }}
                                  >
                                    {fundData?.subscriptionFee}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#8c8c8c",
                                    }}
                                  >
                                    {translate("Subscription Fee")}
                                  </p>
                                </div>
                              </div>
                            )}

                            {fundData?.type !== "STATIC" && (
                              <div
                                className="card-data"
                                style={{
                                  gap: "unset",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h4
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      color: "#BA357B",
                                    }}
                                  >
                                    {fundData?.managementFee}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#8c8c8c",
                                    }}
                                  >
                                    {translate("Management Fee")}
                                  </p>
                                </div>
                                <div>
                                  <h4
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      color: "#5E54AD",
                                    }}
                                  >
                                    {fundData?.performanceFee}
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#8c8c8c",
                                    }}
                                  >
                                    {translate("Performance Fee")}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {fundData?.type !== "STATIC" && (
                    <div
                      style={{
                        marginLeft: "30px",
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 auto",
                      }}
                    >
                      <div className="title-heading">
                        {translate("Performance Comparison")}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          dateFormat="yyyy-MM-dd"
                          endDate={endDate}
                          onChange={(update) => {
                            console.log("Date Range Update", update);
                            setDateRange(update);
                            if (
                              update?.length === 2 &&
                              update[0] &&
                              update[1]
                            ) {
                              getPerformanceData(
                                selectedFund,
                                fundData?.name,
                                update[0],
                                update[1]
                              );
                            }
                          }}
                          isClearable={true}
                        />
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              color: "#000",
                            }),
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "rgb(0, 127, 255)"
                                : "rgb(37, 37, 37)",
                              color: "#fff",
                            }),
                          }}
                          className="fund-input-dropdown"
                          value={selectedFund}
                          options={fundOptions}
                          onChange={(value) => {
                            setSelectedFund(value);
                            getPerformanceData(
                              value,
                              fundData?.name,
                              startDate,
                              endDate
                            );

                            // const chartData = {
                            //   series: [
                            //     {
                            //       name: fundData?.name,
                            //       data: formatData()?.firstSeries,
                            //       color: "#0076e3",
                            //     },
                            //     {
                            //       name: value?.value,
                            //       data: formatData()?.secondSeries,
                            //       color: "#F8BC9B",
                            //     },
                            //   ],
                            //   options: {
                            //     chart: {
                            //       type: "area",
                            //       stacked: false,
                            //       height: 450,
                            //       zoom: {
                            //         type: "x",
                            //         enabled: true,
                            //         autoScaleYaxis: true,
                            //       },
                            //       toolbar: {
                            //         autoSelected: "zoom",
                            //       },
                            //     },
                            //     dataLabels: {
                            //       enabled: false,
                            //     },
                            //     markers: {
                            //       size: 0,
                            //     },
                            //     stroke: {
                            //       width: 2,
                            //     },
                            //     title: {
                            //       text: "",
                            //       align: "left",
                            //     },
                            //     fill: {
                            //       type: "gradient",
                            //       gradient: {
                            //         shadeIntensity: 1,
                            //         inverseColors: false,
                            //         opacityFrom: 0.5,
                            //         opacityTo: 0,
                            //         stops: [0, 90, 100],
                            //       },
                            //     },
                            //     yaxis: {
                            //       labels: {
                            //         formatter: function (val) {
                            //           return `${(val * 100).toFixed(0)}%`;
                            //         },
                            //       },
                            //       title: {
                            //         text: "Price",
                            //       },
                            //     },
                            //     xaxis: {
                            //       type: "datetime",
                            //     },
                            //     tooltip: {
                            //       shared: false,
                            //       y: {
                            //         formatter: function (val) {
                            //           return `${(val * 100).toFixed(0)}%`;
                            //         },
                            //       },
                            //     },
                            //   },
                            // };

                            // console.log("Chart Data", chartData);

                            // setLineChartData(chartData);
                          }}
                        />
                      </div>

                      {lineChartData && (
                        <ReactApexChart
                          options={lineChartData.options}
                          series={lineChartData.series}
                          type="area"
                          height={450}
                        />
                      )}
                    </div>
                  )}
                  {fundData?.type === "STATIC" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 auto",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        className="title-heading"
                        style={{ marginBottom: "0px" }}
                      >
                        {translate("Asset Class Allocation")}
                      </div>
                      <div
                        className="security"
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                          padding: "20px 30px",
                        }}
                      >
                        <div
                          className="card-data-holding"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {fundData?.allocation?.asset?.map((item, index) => {
                            return (
                              <div
                                style={{
                                  width: `${item?.value}%`,
                                  justifyContent: "unset",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "500",
                                    color: getAssetColor(index),
                                  }}
                                >
                                  {item?.valueText}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#000",
                                    fontSize: "10px",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.text}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div className="card-data-holding">
                          <div
                            style={{
                              padding: "0px",
                              backgroundColor: "rgba(140, 140, 140, 0.5)",
                              borderRadius: "5px",
                              marginBottom: "15px",
                              height: "10px",
                              minHeight: "10px",
                              justifyContent: "unset",
                            }}
                          >
                            <div
                              style={{
                                width: `${fundData?.allocation?.asset[0]?.value}%`,
                                backgroundColor: "#004E9F",
                                borderRadius: "5px",
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                                height: "inherit",
                                padding: "0px",
                              }}
                            ></div>
                            {fundData?.allocation?.asset[1] && (
                              <div
                                style={{
                                  width: `${fundData?.allocation?.asset[1]?.value}%`,
                                  backgroundColor: "#8852AB",
                                  borderRadius: "5px",
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                  borderTopRightRadius: "0px",
                                  borderBottomRightRadius: "0px",
                                  height: "inherit",
                                  padding: "0px",
                                }}
                              ></div>
                            )}

                            {fundData?.allocation?.asset?.[2] && (
                              <div
                                style={{
                                  width: `${Math.round(
                                    fundData?.allocation?.asset[2]?.value?.toFixed(
                                      2
                                    )
                                  )}%`,
                                  backgroundColor: "#D14177",
                                  borderRadius: "5px",

                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                  height: "inherit",
                                  padding: "0px",
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {fundData?.type !== "STATIC" && (
                  <>
                    <div style={{ marginTop: "15px", display: "flex" }}>
                      <div style={{ width: "32%", display: "flex" }}>
                        <div
                          className="security"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                            padding: "20px 30px",
                            width: "calc(100% - 60px)",
                          }}
                        >
                          <div className="title-heading">
                            {translate("Top 10 Holdings")}
                          </div>
                          <div className="card-data-holding">
                            {fundData?.topHoldings?.map((item) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "12px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#000",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginRight: "20px",
                                      }}
                                    >
                                      {item?.name}
                                    </div>
                                    <div style={{ color: "#8c8c8c" }}>
                                      {item?.percentageText}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: "0px",
                                      backgroundColor:
                                        "rgba(140, 140, 140, 0.5)",
                                      borderRadius: "5px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: `${item?.percentage}%`,
                                        backgroundColor: `${getHoldingColor(
                                          item?.percentageText
                                        )}`,
                                        borderRadius: "5px",
                                      }}
                                    ></div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          marginLeft: "30px",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                        }}
                      >
                        <div
                          className="security"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                            padding: "20px 30px",
                          }}
                        >
                          <div className="title-heading">
                            {translate("Asset Class Allocation")}
                          </div>
                          <div
                            className="card-data-holding"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {fundData?.allocation?.asset?.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    width: `${item?.value}%`,
                                    justifyContent: "unset",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      color: getAssetColor(index),
                                    }}
                                  >
                                    {item?.valueText}
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#000",
                                      fontSize: "10px",
                                      marginLeft: "5px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item?.text}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                          <div className="card-data-holding">
                            <div
                              style={{
                                padding: "0px",
                                backgroundColor: "rgba(140, 140, 140, 0.5)",
                                borderRadius: "5px",
                                marginBottom: "15px",
                                height: "10px",
                                minHeight: "10px",
                                justifyContent: "unset",
                              }}
                            >
                              <div
                                style={{
                                  width: `${fundData?.allocation?.asset[0]?.value}%`,
                                  backgroundColor: "#004E9F",
                                  borderRadius: "5px",
                                  borderTopRightRadius: "0px",
                                  borderBottomRightRadius: "0px",
                                  height: "inherit",
                                  padding: "0px",
                                }}
                              ></div>
                              {fundData?.allocation?.asset[1] && (
                                <div
                                  style={{
                                    width: `${fundData?.allocation?.asset[1]?.value}%`,
                                    backgroundColor: "#8852AB",
                                    borderRadius: "5px",
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    borderTopRightRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                    height: "inherit",
                                    padding: "0px",
                                  }}
                                ></div>
                              )}

                              {fundData?.allocation?.asset?.[2] && (
                                <div
                                  style={{
                                    width: `${Math.round(
                                      fundData?.allocation?.asset[2]?.value?.toFixed(
                                        2
                                      )
                                    )}%`,
                                    backgroundColor: "#D14177",
                                    borderRadius: "5px",

                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    height: "inherit",
                                    padding: "0px",
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          className="security"
                          style={{
                            padding: "20px 30px",
                            marginTop: "30px",
                            backgroundImage:
                              "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                          }}
                        >
                          <div className="title-heading">
                            {translate("Geographical allocation")}
                          </div>
                          {/* <div
                        style={{
                          fontSize: "12px",
                          color: "#8c8c8c",
                        }}
                      >
                        <span>{translate("Performance")} (YTD)</span>
                        <span
                          style={{
                            color: "#0452A0",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            fontSize: "14px",
                          }}
                        >
                          3.52%
                        </span>
                      </div> */}
                          <div
                            className="card-data-holding"
                            style={{ marginTop: "10px" }}
                          >
                            {fundData?.geography?.length !== 0 && (
                              <div>
                                <p style={{ visibility: "hidden" }}>
                                  {" "}
                                  Asia Pac ex Japan
                                </p>
                                <div
                                  style={{
                                    padding: "0px",
                                    borderTopRightRadius: "20px",
                                    borderRadius: "20px",
                                    width: "200px",
                                    height: "20px",
                                    minWidth: "200px",
                                  }}
                                ></div>
                                {/* <span
                              style={{
                                fontSize: "12px",
                                color: "#8c8c8c",
                                textAlign: "center",
                                marginLeft: "210px",
                                width: "80px",
                                minWidth: "80px",
                              }}
                            >
                              {translate("Geographical distribution (%)")}
                            </span> */}
                                {/* <span
                              style={{
                                fontSize: "12px",
                                color: "#8c8c8c",
                                textAlign: "right",
                              }}
                            >
                              {translate("Performance")} <br /> (YTD)
                            </span> */}
                              </div>
                            )}

                            {fundData?.geography?.map((item, index) => {
                              return (
                                <div>
                                  <p
                                    style={{
                                      maxWidth: "120px",
                                      width: "120px",
                                      minWidth: "120px",
                                      color: "#000",
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                  <div
                                    style={{
                                      padding: "0px",
                                      backgroundColor: "#E0E0E0",
                                      borderTopRightRadius: "20px",
                                      borderRadius: "5px",
                                      width: "100%",
                                      height: "20px",
                                      position: "relative",
                                      flex: "1 1 auto",
                                      marginLeft: "30px",
                                    }}
                                  >
                                    {/* <div
                                    style={{
                                      width: `${item?.actualCostNumber + 10}%`,
                                      backgroundColor: `#BCBCBC`,
                                      borderRadius: "20px",
                                      height: "9px",
                                      position: "absolute",
                                    }}
                                  ></div> */}
                                    <div
                                      style={{
                                        width: `${item?.actualCostNumber}%`,
                                        backgroundColor: `${getAllocationColor(
                                          index
                                        )}`,
                                        borderRadius: "5px",
                                        height: "15px",
                                        position: "absolute",
                                      }}
                                    ></div>
                                  </div>
                                  <span
                                    style={{
                                      color: "#8C8C8C",
                                      fontSize: "14px",
                                      marginLeft: "-66px",
                                      zIndex: "100",
                                      // marginRight: "80px",
                                      width: "50px",
                                      minWidth: "50px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item?.actualCost}
                                  </span>
                                  {/* <span
                                  style={{
                                    fontSize: "14px",
                                    color: getPerformaceColor(index),
                                    width: "41px",
                                    minWidth: "41px",
                                  }}
                                >
                                  {item?.performance}
                                </span> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: "15px", display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                        }}
                      >
                        <div
                          className="security"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, rgb(249, 249, 249), rgb(250, 251, 253))",
                            padding: "20px 30px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="title-heading"
                            style={{ marginBottom: "0px" }}
                          >
                            {translate("Data room")}
                          </div>
                          <div
                            className="card-data-holding"
                            style={{ marginBottom: "6px", marginLeft: "25px" }}
                          >
                            {fundData?.details?.map((item) => {
                              return (
                                <a
                                  href={item?.link}
                                  target="_blank"
                                  style={{ textDecoration: "unset" }}
                                >
                                  <div
                                    className="data-room"
                                    style={{ color: "#8c8c8c" }}
                                  >
                                    <p
                                      style={{
                                        minWidth: "unset",
                                        marginRight: "2px",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    {">"}
                                  </div>
                                </a>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* // )} */}

              {/* {!afeLoginData && (
                <AFELogin
                  afeLoginData={afeLoginData}
                  setAfeLoginData={setAfeLoginData}
                  socket={socket}
                  setSocket={setSocket}
                />
              )} */}
            </>
          )}

          {formStatus === "waiting" && (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className="title-small-heading">PRODUCTS</div>
                  <h3 className="gradient-heading">Investments</h3>
                  <div style={{ color: "#37454b", fontSize: "14px" }}>
                    Maximize your portfolio with DL family office strategies,
                    alternative investments, and exclusive fund offerings on our
                    platform.
                  </div>
                </div>

                <a
                  href="mailto:assetmanagement@dl-holdings.com"
                  target="__blank"
                >
                  <ComponentButton
                    title={translate("TALK TO AN EXPERT")}
                    whiteBgBtn={true}
                  />
                </a>
              </div>
              <div style={{ marginTop: "50px" }}>
                <ChakraProvider>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th className="table-header-text table-header-white-text">
                            Form
                          </Th>
                          <Th className="table-header-text table-header-white-text">
                            Status
                          </Th>
                          <Th className="table-header-text table-header-white-text">
                            Action
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td className="table-title table-white-title">
                            Securities Account Opening Form
                          </Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color:
                                STATUS_TEXT?.[
                                  user?.approvals?.securityForm?.status
                                ]?.color,
                            }}
                          >
                            {
                              STATUS_TEXT?.[
                                user?.approvals?.securityForm?.status
                              ]?.text
                            }
                          </Td>
                          {user?.approvals?.securityForm?.status !==
                            STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${SECURITY_ACCOUNT_OPENING_ID}&formUrl=https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>

                        <Tr>
                          <Td className="table-title table-white-title">
                            W-8BEN
                          </Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color:
                                STATUS_TEXT?.[
                                  user?.approvals?.w8BenForm?.status
                                ]?.color,
                            }}
                          >
                            {
                              STATUS_TEXT?.[user?.approvals?.w8BenForm?.status]
                                ?.text
                            }
                          </Td>
                          {user?.approvals?.w8BenForm?.status !==
                            STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${W8BEN_FROM_ID}&formUrl=https://www.jotform.com/edit/${user?.w8BenForm?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>

                        <Tr>
                          <Td className="table-title table-white-title">
                            Video Verification
                          </Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color:
                                STATUS_TEXT?.[
                                  user?.approvals?.videoForm?.status
                                ]?.color,
                            }}
                          >
                            {
                              STATUS_TEXT?.[user?.approvals?.videoForm?.status]
                                ?.text
                            }
                          </Td>
                          {user?.approvals?.videoForm?.status !==
                            STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${VIDEO_VERIFICATION_FORM_ID}&formUrl=https://www.jotform.com/edit/${user?.videoForm?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>
                        {/* {product?.productPaymentProofForm?.approval?.status === STATUS.SUBMITTED && <Tr>
                          <Td className="table-title">{product?.productPaymentProofForm?.name}</Td>
                          <Td
                            className="table-status-title"
                            style={{
                              color: STATUS_TEXT?.[product?.productPaymentProofForm?.approval?.status]?.color,
                            }}
                          >
                            {STATUS_TEXT?.[product?.productPaymentProofForm?.approval?.status]?.text}
                          </Td>
                          {product?.productPaymentProofForm?.approval?.status !== STATUS.APPROVED && (
                            <Td
                              className="table-action-title"
                              onClick={() => {
                                // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                navigate(
                                  `${EDIT_FORM_URL}?formId=${product?.productPaymentProofForm?.formId}&formUrl=https://www.jotform.com/edit/${product?.productPaymentProofForm?.submissionID}`
                                );
                              }}
                            >
                              Re-submit
                            </Td>
                          )}
                        </Tr>} */}
                        {user?.paymentProofForm && (
                          <Tr>
                            <Td className="table-title">Cash Deposit</Td>
                            <Td
                              className="table-status-title"
                              style={{
                                color:
                                  STATUS_TEXT?.[
                                    user?.approvals?.paymentProofForm?.status
                                  ]?.color,
                              }}
                            >
                              {
                                STATUS_TEXT?.[
                                  user?.approvals?.paymentProofForm?.status
                                ]?.text
                              }
                            </Td>
                            {user?.approvals?.paymentProofForm?.status !==
                              STATUS.APPROVED && (
                              <Td
                                className="table-action-title"
                                onClick={() => {
                                  // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                  navigate(
                                    `${EDIT_FORM_URL}?formId=${user?.paymentProofForm?.formID}&formUrl=https://www.jotform.com/edit/${user?.paymentProofForm?.submissionID}`
                                  );
                                }}
                              >
                                Re-submit
                              </Td>
                            )}
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </ChakraProvider>
              </div>
            </div>
          )}
          {formStatus === "complete" && (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className="title-small-heading">
                    {translate("PRODUCTS")}
                  </div>
                  <h3 className="gradient-heading">
                    {translate("Investments")}
                  </h3>
                  <div style={{ color: "#37454b", fontSize: "14px" }}>
                    {translate(
                      "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform."
                    )}
                  </div>
                </div>

                <a
                  href="mailto:assetmanagement@dl-holdings.com"
                  target="__blank"
                >
                  <ComponentButton
                    title={translate("TALK TO AN EXPERT")}
                    whiteBgBtn={true}
                  />
                </a>
              </div>
              <div
                className="empty-product-container"
                style={{ marginTop: "50px" }}
              >
                <div style={{ marginBottom: "100px" }}>
                  <div
                    style={{
                      marginBottom: "30px",
                      width: "400px",
                      color: "#000",
                    }}
                  >
                    {translate(
                      "Please complete the security account opening flow"
                    )}
                  </div>
                  <ComponentButton
                    // whiteBgBtn={true}
                    title={translate("complete_account_setup")}
                    iconRight={<ChevronRightIcon w={24} h={24} />}
                    onClick={() => {
                      setIsSelectUserOpened(true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {formStatus === "w8BenFormComplete" && (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className="title-small-heading">
                    {translate("PRODUCTS")}
                  </div>
                  <h3 className="gradient-heading">
                    {translate("Investments")}
                  </h3>
                  <div style={{ color: "#37454b", fontSize: "14px" }}>
                    {translate(
                      "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform."
                    )}
                  </div>
                </div>

                <a
                  href="mailto:assetmanagement@dl-holdings.com"
                  target="__blank"
                >
                  <ComponentButton
                    title={translate("TALK TO AN EXPERT")}
                    whiteBgBtn={true}
                  />
                </a>
              </div>
              <div
                className="empty-product-container"
                style={{ marginTop: "50px" }}
              >
                <div style={{ marginBottom: "100px" }}>
                  <div
                    style={{
                      marginBottom: "30px",
                      width: "400px",
                      color: "#000",
                    }}
                  >
                    {translate(
                      "Please complete the security account opening flow"
                    )}
                  </div>
                  <ComponentButton
                    // whiteBgBtn={true}
                    title={"Resume Account Setup"}
                    iconRight={<ChevronRightIcon w={24} h={24} />}
                    onClick={() => {
                      navigate(
                        `${SECURITY_ACCOUNT_URL}/${W8BEN_FROM_ID}?formUrl=https://alt.jotfor.ms/${W8BEN_FROM_ID}?uniqueId=${user?.securityForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&1name=${user?.securityForm?.rawRequest?.q88_name86}&address=${user?.securityForm?.rawRequest?.q211_typeA211}&3Permanent43=${user?.securityForm?.rawRequest?.residentialAddress227}&4Mailing=${user?.securityForm?.rawRequest?.correspondenceAddress229}&8Date=${user?.securityForm?.rawRequest?.dateof}&6aForeign=${user?.securityForm?.rawRequest?.typeA121}&Email=${user?.email}`
                      );
                      // setIsSelectUserOpened(true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {formStatus === "videoFormComplete" && (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className="title-small-heading">
                    {translate("PRODUCTS")}
                  </div>
                  <h3 className="gradient-heading">
                    {translate("Investments")}
                  </h3>
                  <div style={{ color: "#37454b", fontSize: "14px" }}>
                    {translate(
                      "Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform."
                    )}
                  </div>
                </div>

                <a
                  href="mailto:assetmanagement@dl-holdings.com"
                  target="__blank"
                >
                  <ComponentButton
                    title={translate("TALK TO AN EXPERT")}
                    whiteBgBtn={true}
                  />
                </a>
              </div>
              <div
                className="empty-product-container"
                style={{ marginTop: "50px" }}
              >
                <div style={{ marginBottom: "100px" }}>
                  <div
                    style={{
                      marginBottom: "30px",
                      width: "400px",
                      color: "#000",
                    }}
                  >
                    {translate(
                      "Please complete the security account opening flow"
                    )}
                  </div>
                  <ComponentButton
                    // whiteBgBtn={true}
                    title={"Resume Account Setup"}
                    iconRight={<ChevronRightIcon w={24} h={24} />}
                    onClick={() => {
                      navigate(
                        `${SECURITY_ACCOUNT_URL}/${VIDEO_VERIFICATION_FORM_ID}?formUrl=https://alt.jotfor.ms/${VIDEO_VERIFICATION_FORM_ID}?uniqueId=${user?.w8BenForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&email=${user?.email}`
                      );
                      // setIsSelectUserOpened(true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {isSelectUserOpened && (
          <SelectUserModal
            open={isSelectUserOpened}
            onClose={() => {
              setIsSelectUserOpened(false);
            }}
            user={user}
          />
        )}
      </div>
      {isStatusModalOpened && (
        <SecurityFormStatusModal
          user={user}
          open={isStatusModalOpened}
          onClose={() => {
            setIsStatusModalOpened(false);
          }}
        />
      )}
      {isInfoModalOpened && (
        <SelectUserModal
          user={user}
          open={isInfoModalOpened}
          onClose={() => {
            setIsInfoModalOpened(false);
          }}
        />
      )}
      {isInvestmentFormOpened && (
        <InvestmentForm
          user={user}
          open={isInvestmentFormOpened}
          onClose={() => {
            setIsInvestmentFormOpened(false);
          }}
          fundId={productId}
          fundName={fundData?.name}
          fundData={fundData}
          formId={242122040709040}
          title="Subscribe & Invest"
        />
      )}
      {isExistingInvestmentFormOpened && (
        <InvestmentForm
          user={user}
          open={isExistingInvestmentFormOpened}
          onClose={() => {
            setIsExistingInvestmentFormOpened(false);
          }}
          fundId={productId}
          fundName={fundData?.name}
          fundData={fundData}
          formId={242122040709040}
          title="Invest"
        />
      )}
      {isInvestmentStatusModalOpened && (
        <InvestmentFormStatusModal
          open={isInvestmentStatusModalOpened}
          fundInfo={investmentInfo}
          fundName={fundData?.name}
          fundData={fundData}
          formId={242122040709040}
          onClose={() => {
            setIsInvestmentStatusModalOpened(false);
          }}
        />
      )}
    </DashboardContainer>
  );
};

export default FoStrategyProduct;
