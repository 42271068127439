import { useNavigate } from "react-router-dom";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentCheckbox from "../../atoms/ComponentCheckbox";
import MainContainer from "../../sub-components/MainContainer";
import "./LegalAggrement.scss";
import { HOME_URL, LOGIN_URL } from "../../routes";
import ComponentProgressBar from "../../atoms/ComponentProgressBar";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GET_USER } from "../../graphql/user.query";
import { UPDATE_USER_MUTATION } from "../../graphql/user.mutation";
import { toast } from "react-toastify";
import translate from "../../../translate";

const dummyData = [
  {
    name: translate("legal_agreements1")
  },
  {
    name: translate("legal_agreements2")
  },
];

const LegalAggrement = () => {
  const navigate = useNavigate();
  // const toast = useToast();

  const [selectedInvestorStatus, setSelectedInvestorStatus] = useState<any>([]);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // toast.error(err.graphQLErrors[0]?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 1,
      //   theme: "dark",
      // });
    },

    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const [UpdateUser, { data, loading, error }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        navigate(HOME_URL);
      },
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);
        // toast({
        //   title: err.graphQLErrors[0]?.message,
        //   status: "error",
        //   isClosable: true,
        // });

        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
      },
    }
  );

  const onClickEvent = () => {
    UpdateUser({
      variables: {
        input: {
          onboardStep: 4,
        },
      },
    });
  };
  return (
    <div className="legal-container">
      <MainContainer>
        <div
          className="signup-legal-container"
        >
          <div className="sign-up-legal">
            <h2>{translate("legal_agreements_text")}</h2>
            {dummyData.map((item) => {
              return (
                <div style={{ paddingBottom: "8px", width:"100%" }}>
                  <ComponentCheckbox
                    title={item.name}
                    isChecked={selectedInvestorStatus.indexOf(item.name) !== -1}
                    onHandleChange={(checked: boolean) => {
                      if (checked) {
                        selectedInvestorStatus.push(item.name);
                      } else {
                        const index = selectedInvestorStatus.indexOf(item.name);
                        selectedInvestorStatus.splice(index, 1);
                      }
                      setSelectedInvestorStatus([...selectedInvestorStatus]);
                    }}
                  />
                </div>
              );
            })}
            <div className="sign-up-component">
              <ComponentButton
                title={translate("continue")}
                whiteBgBtn={true}
                onClick={onClickEvent}
                isDisabled={selectedInvestorStatus?.length !== 2}
                style={{opacity:selectedInvestorStatus?.length !== 2 ? "0.5": "1"}}
              />
            </div>
          </div>
          <div className="legal-aggrement-data">
            <p>
              As an entity licensed by the Hong Kong Securities and Futures
              Commission (the “SFC”), we are required to comply with the
              provisions of the Securities and Futures Ordinance (Cap.571 of the
              Laws of Hong Kong) (the “SFO”) and the Code of Conduct for Persons
              Licensed by or Registered with the Securities and Futures
              Commission (the “Code”).
            </p>
            <p>
              Accordingly, for the purposes of Sections 174, 175 and Part IV of
              the SFO and Schedule 17 of the Companies (Winding Up and
              Miscellaneous Provisions) Ordinance (Cap.32 of the Laws of Hong
              Kong), we have classified you or your company as a “Professional
              Investor” on the basis that you or your Company fall/ falls into
              one of the following categories in the abovementioned legislation.
            </p>
            <p>
              For the purpose of the Code, you or your Company may be able to be
              treated as a Professional Investor, meaning that you or your
              Company have/ has sufficient investment knowledge and expertise in
              relevant products and markets that we can be exempted from
              providing you or your Company with certain information and
              services that we would ordinarily be required to provide to
              non-professional investors as detailed in Annex 1 – Consequences
              of being treated as Professional Investor as attached to this
              Letter.
            </p>
            <p>
              If you or your Company agree to be treated as a Professional
              Investor, we will be entitled to assume that you or your Company
              have appropriate knowledge, understanding and sufficient expertise
              in relation to the products and markets which we will provide. As
              a result, the information and explanation that will be provided to
              you or your Company as a Professional Investor will be
              considerably less than that which would be provided were you to be
              treated as a non-Professional Investor. Furthermore, as a
              Professional Investor, you or your Company will be deemed to
              understand the particular risks attaching to the investment
              strategies you adopt and the financial instruments and investments
              you or your company makes.
            </p>
            <p>
              Please be reminded that you or your Company have the right to
              withdraw from being treated as a professional investor in respect
              of all products or any part thereof at any time during the course
              of your relationship with us.
            </p>
            <p>
              An annual confirmation exercise is required to ensure that you or
              your Company continue(s) to fulfil the requisite requirements
              under the Code, so please note that we will need to carry out this
              confirmation exercise with you or your company on an annual basis.
            </p>
            <br/>
            <p>
              <b>
                Annex 1 – Consequences of being treated as Professional Investor
              </b>
              </p>
              <p>
              Being categorized as a Professional Investor, pursuant to the Code
              of Conduct for Persons Licensed by or Registered with the SFC (the
              “Code”), the Company will not be required to fulfil certain
              regulatory requirements under the Code.
              </p>
             
              <b>
                <u>
                  Exempt provisions for Corporate Professional Investors &
                  Institutional Professional Investors (Paragraph 15.4 of the
                  Code)
                </u>
              </b>
           
            <ul>
              <li>
                Information about clients
                <ul>
                  <li>
                    the need to establish a client’s financial situation,
                    investment experience and investment objectives (paragraph
                    5.1 and paragraphs 2(d) and 2(e) of Schedule 6 to the Code),
                    except where the Company is providing advice on corporate
                    finance work;
                  </li>
                  <li>
                    the need to ensure the suitability of a recommendation or
                    solicitation (paragraph 5.2 and paragraph 49 of Schedule 6
                    to the Code); and
                  </li>
                  <li>
                    the need to assess the client’s knowledge of derivatives and
                    characterize the client based on his knowledge of
                    derivatives (paragraph 5.1A of the Code);
                  </li>
                </ul>
              </li>
              <li>
                Client agreement
                <ul>
                  <li>
                    the need to enter into a written agreement and the provision
                    of relevant risk disclosure statements (paragraph 6.1,
                    paragraph 2 of Schedule 3, paragraph 2 of Schedule 4 and
                    paragraph 1 of Schedule 6 to the Code);
                  </li>
                </ul>
              </li>
              <li>
                Information for clients
                <ul>
                  <li>
                    the need to disclose transaction related information
                    (paragraph 8.3A of the Code);
                  </li>
                </ul>
              </li>
              <li>
                Discretionary accounts
                <ul>
                  <li>
                    the need for the Company to obtain from the client an
                    authority in a written form prior to effecting transactions
                    for the client without his specific authority (paragraph
                    7.1(a)(ii) of the Code);
                  </li>
                  <li>
                    the need to explain the authority described under paragraph
                    7.1(a)(ii) of the Code and the need to confirm it on an
                    annual basis (paragraph 7.1(b) of the Code); and
                  </li>
                  <li>
                    the need to disclose benefits receivable for effecting
                    transactions for a client under a discretionary account
                    (paragraph 7.2 of the Code).
                  </li>
                </ul>
                (For the avoidance of doubt, the Company should still obtain an
                authorization from a client in order to effect transactions on
                the client’s behalf, however where Professional Investors are
                concerned the procedures for obtaining such authorizations as
                described in (i) and (ii) above are relaxed.)
              </li>

              <li>
                Complex products
                <ul>
                  <li>
                    the need to ensure the suitability of a transaction in a
                    complex product, to provide sufficient information about a
                    complex product and to provide warning statements (paragraph
                    5.5(a) of the Code).
                  </li>
                </ul>
              </li>
            </ul>
            <b>
              <u>
                Exempt provisions for Corporate Professional Investors and
                Individual Professional Investors & Institutional Professional
                Investors (Paragraph 15.5 of the Code)
              </u>
            </b>

            <ul>
              <li>Information for clients</li>
              <ul>
                <li>
                  the need to inform the client about the Company and the
                  identity and status of its employees and others acting on its
                  behalf (paragraph 8.1 of the Code);
                </li>
                <li>
                  the need to confirm promptly with the client the essential
                  features of a transaction after effecting a transaction for a
                  client (paragraph 8.2, paragraph 4 of Schedule 3 and paragraph
                  18 of Schedule 6 to the Code); and
                </li>
                <li>
                  the need to provide the client with documentation on the
                  Nasdaq-Amex Pilot Program (paragraph 1 of Schedule 3 to the
                  Code).
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </MainContainer>
      <ComponentProgressBar defaultValue={3} />
    </div>
  );
};

export default LegalAggrement;
