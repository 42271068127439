import { Grid } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import DashboardContainer from "../../sub-components/DashbordContainer";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import "../FoStrategy/FoStrategy.scss";
import "./FoServices.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  FO_SERVICES_PRODUCT_URL,
  FO_SERVICES_TRUST_PLANNING,
} from "../../routes";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import translate from "../../../translate";

import residencyPlanningImage from "../../../assets/ResidencyPlanning.png";
import trustPlanningImage from "../../../assets/TrustPlanning.png";
import taxationPlanningImage from "../../../assets/TaxationPlanning.png";
import incomePlanningImage from "../../../assets/IncomePlanning.png";
import { useState } from "react";

const dummyData = [
  {
    title: translate("RESIDENCY PLANNING"),
    content: translate("Customize your identity layout"),
    backgroundGradient:
      "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
    commingSoon: false,
    link: FO_SERVICES_PRODUCT_URL,
  },
  {
    title: translate("Trust Planning"),
    content: translate("Safeguarding your wealth inheritance"),
    // backgroundGradient: "#252525",
    backgroundGradient:
      "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
    commingSoon: false,
    link: FO_SERVICES_TRUST_PLANNING,
  },
  {
    title: translate("TAXATION Planning"),
    content: translate("Professional tax strategies"),
    backgroundGradient: "#252525",
    commingSoon: true,
    link: "",
  },
  {
    title: translate("INCOME Planning"),
    content: translate("Thoroughly assess your income situation"),
    backgroundGradient: "#252525",
    commingSoon: true,
    link: "",
  },
  // {
  //   title: "Trust Planning",
  //   content: "That determine your eligibility for private investments.",
  //   backgroundGradient: "#252525",
  //   commingSoon: true,
  // },
  // {
  //   title: "TAXATION Planning",
  //   content: "That determine your eligibility for private investments.",
  //   backgroundGradient: "#252525",
  //   commingSoon: true,
  // },
];
const dummyDataCard = [
  {
    name: "Japan: The Emerging Hotspot for Global Asset Allocation",
    link: "https://www.dlifo.com/blog/japan-the-emerging-hotspot-for-global-asset-allocation",
  },
  {
    name: "What is the risk of the United States defaulting?",
    link: "https://www.dlifo.com/blog/what-is-the-risk-of-the-united-states-defaulting",
  },
  {
    name: "Reconsidering the Perception of Luxury Homes in Hong Kong",
    link: "https://www.dlifo.com/blog/reconsidering-the-perception-of-luxury-homes-in-hong-kong",
  },
  {
    name: "Rare Things are Precious, Just in Time for the Cold Winter",
    link: "https://www.dlifo.com/blog/rare-things-are-precious-just-in-time-for-the-cold-winter",
  },
];

const servicesData = [
  {
    name: "Residency Planning",
    desc: "Customized your identity layout",
    imageUrl: residencyPlanningImage,
    link: FO_SERVICES_PRODUCT_URL,
  },
  {
    name: "Trust Planning",
    desc: "Safeguarding your wealth inheritance",
    imageUrl: trustPlanningImage,
    link: FO_SERVICES_TRUST_PLANNING,
  },
  {
    name: "Taxation Planning",
    desc: "Professional tax strategies.",
    imageUrl: taxationPlanningImage,
    comingSoon: true,
  },
  {
    name: "Income Planning",
    desc: "Thoroughly assess your income situation",
    imageUrl: incomePlanningImage,
    comingSoon: true,
  },
];

const FoServices = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any>(servicesData[0]);

  const getBorderColor = (index: number) => {
    if (index === 0) {
      return "#00173f";
    }
    if (index === 1) {
      return "#0076e3";
    }
    if (index === 2) {
      return "#8b428c";
    }
    if (index === 3) {
      return "#e62762";
    }
  };
  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div className="title-small-heading">{translate('PRODUCTS')}</div>
                <h3 className="gradient-heading">{translate('fo_services')}</h3>
                <div style={{ color: "#37454b", fontSize: "14px" }}>
                  {translate('Your online family office')}
                </div>
              </div>

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <div
                style={{ flex: "1 1", marginRight: "80px", height: "400px" }}
              >
                <div className="image-wrapper">
                  <img
                    src={selectedItem?.imageUrl}
                    style={{ width: "600px", borderRadius: "20px" }}
                  />
                </div>
              </div>
              <section
                style={{ flex: "1 1", color: "#000" }}
                className="accordion accordion--radio"
              >
                {servicesData?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedItem(item);
                      }}
                      className="service-product-container tab"
                      style={{
                        backgroundColor:
                          selectedItem?.name === item?.name
                            ? "#f0f3fa"
                            : "#fff",
                        borderColor: getBorderColor(index),
                      }}
                    >
                      <input
                        type="radio"
                        name="accordion-2"
                        id={item?.name}
                        checked={item?.name === selectedItem?.name}
                      />
                      {/* <div style={{ flex: "1 1 auto" }}> */}
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: "1 1 auto" }}>
                          <label
                            htmlFor={item?.name}
                            className="tab__label"
                            style={{
                              margin: "0px",
                              fontSize:
                                selectedItem?.name === item?.name
                                  ? "24px"
                                  : "20px",
                            }}
                          >
                            {" "}
                            {translate(item?.name)}
                          </label>
                        </div>
                        {!item?.comingSoon && (
                          <div
                            onClick={() => {
                              if (item?.link) {
                                navigate(item?.link);
                              }
                            }}
                          >
                            <ChevronRightIcon
                              style={{
                                color: "#8c8c8c",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div
                        className="tab__content"
                        style={{
                          fontSize: "14px",
                          color: "#777",
                        }}
                      >
                        {translate(item?.desc)}
                      </div>
                    </div>
                  );
                })}

                {/* <section className="accordion accordion--radio">
  <div className="tab">
    <input type="radio" name="accordion-2" id="rd1"/>
    <label htmlFor="rd1" className="tab__label">Radio</label>
    <div className="tab__content">
      <p>If you want to have only one tab open, you can use <code>&lt;input type="checkbox"&gt;</code>.</p>
    </div>
  </div>
  <div className="tab">
    <input type="radio" name="accordion-2" id="rd2"/>
    <label htmlFor="rd2" className="tab__label">Open single</label>
    <div className="tab__content">
      <p>But if you wanna close the opened tab, you must add a "close" button somewhere, like the one below, that is just another styled radio input.</p>
    </div>
  </div>
</section> */}
              </section>
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
    // <DashboardContainer>
    //   <div className="fo-container">
    //     <div className="fo-header">
    //       <div className="fo-heading">
    //         <h2>{translate("FO services")}</h2>
    //         <p>
    //          {translate("Your online family office")}
    //         </p>
    //       </div>
    //       <div className="component-btn">
    //       <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
    //       <ComponentButton title={translate("TALK TO AN EXPERT")} blackBgBtn={true} />
    //       </a>

    //         <p>{translate("FOR A PERSONALISED APPROACH")}</p>
    //       </div>
    //     </div>
    //     <div className="fo-cards">
    //       {/* <Grid templateColumns="repeat(4, 1fr)" gap={15}> */}
    //         {dummyData.map((item) => {
    //           return (
    //             <ComponentFoCard
    //               title={item.title}
    //               content={item.content}
    //               backgroundGradient={item.backgroundGradient}
    //               commingSoon={item.commingSoon}
    //               onClick={() => {
    //                 navigate(item.link);
    //               }}
    //             />
    //           );
    //         })}
    //       {/* </Grid> */}
    //     </div>
    //   </div>
    // </DashboardContainer>
  );
};

export default FoServices;
