import { useEffect, useState } from "react";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./SecurityDisclaimer.scss";
import {
  ChakraProvider,
  Checkbox,
  CheckboxGroup,
  Stack,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL, SECURITY_ACCOUNT_URL } from "../../routes";
import { SECURITY_ACCOUNT_OPENING_ID } from "../../dataHelper";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import translate from "../../../translate";
// https://jotform.com/241281764505456?q121_typeA121[0][0]=Hong Kong&date[month]=08&date[day]=06&date[year]=2024&date190[month]=08&date190[day]=06&date190[year]=2024&date197[month]=08&date197[day]=06&date197[year]=2024&forInvestment=None%20%E6%B2%92%E6%9C%89

const SecurityDisclaimer = () => {
  const navigate = useNavigate();
  const [isFirstCheckboxSelected, setIsFirstCheckboxSelected] = useState(false);
  const [isSecondCheckboxSelected, setIsSecondCheckboxSelected] =
    useState(false);
  const [user, setUser] = useState(null);
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

      const lang = localStorage.getItem("user-lang") || "en"

  return (
    <DashboardContainer>
      <div
        className="account-page security-disclaimer-container"
        style={{ display: "block" }}
      >
        <h3 className="gradient-heading">Disclosure</h3>

        <p>{translate("Dear Client,")}</p>
        <p>
            {translate("By continuing with the account opening process, you voluntarily and irrevocably agree to open a securities account with DL Securities (HK) Limited (hereinafter referred to as 'DL Securities').")}
          
        </p>
        <p>
        {translate("DL Digital Family Office (HK) Limited (hereinafter referred to as 'DLiFO') does not have any legal relationship with you based on rights and obligations arising from securities transactions.")}
        </p>
        <p>
        {translate("By proceeding with the account opening steps, you authorize DLiFO, in accordance with the Personal Data (Privacy) Ordinance, to transfer the account opening information you subsequently fill in and provide to DL Securities. You acknowledge that the information and data provided during the securities account opening on this website will be used for the purpose of opening a personal account with DL Securities.")}
        </p>
        <p>
        {translate("DL Securities (HK) Limited is a licensed corporation regulated by the Securities and Futures Commission of Hong Kong, holding a Type 1 regulated activity license, and is qualified to engage as a licensed introducing agent, dealer, or securities non-clearing broker.")}
        </p>
        <p>
        {translate("DLiFO is an associated company of DL Securities. According to the agreements signed between the parties, DLiFO provides client referral services and website technical support services to DL Securities, and receives compensation and related fees for these services.")}
        </p>

        <p>
        {translate("The ownership of this website/software belongs to DL Digital Family Office (HK) Limited.")}
        </p>
        <ChakraProvider>
          <Stack spacing={5} direction="column">
            <Checkbox
              checked={isFirstCheckboxSelected}
              onChange={(e) => {
                setIsFirstCheckboxSelected(e?.target?.checked);
              }}
            >
                {translate("Please confirm that you voluntarily and irrevocably authorize DL Digital Family Office (HK) Limited, acting on behalf of DL Securities (HK) Limited, to collect and transfer your personal data and document proofs for the purpose of opening your personal securities account with DL Securities (HK) Limited.")}
            </Checkbox>
            <Checkbox
              checked={isSecondCheckboxSelected}
              onChange={(e) => {
                setIsSecondCheckboxSelected(e?.target?.checked);
              }}
            >
              {translate("Please also confirm that you are aware of the client referral and online platform technology consultancy contract relationship between the aforementioned parties.")}
            </Checkbox>
          </Stack>
        </ChakraProvider>

        {isFirstCheckboxSelected && isSecondCheckboxSelected && (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <ComponentButton
              title={translate("Start")}
              onClick={() => {
                navigate(
                  `${SECURITY_ACCOUNT_URL}/${SECURITY_ACCOUNT_OPENING_ID}?formUrl=https://alt.jotfor.ms/${SECURITY_ACCOUNT_OPENING_ID}?Email=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&typeA121[0][0]=Mainland China&clientid=${user?.clientId?.[0]}`
                );
              }}
              style={{
                marginRight: "10px",
                height: "52.5px",
                borderRadius: "5px",
              }}
            />
          </div>
        )}

        <p>
          <a
            href="https://www.dl-holdings.com/en/"
            target="__blank"
            style={{ textDecoration: "underline" }}
          >
            {translate("Click here for more information.")}
          </a>
        </p>
      </div>
    </DashboardContainer>
  );
};

export default SecurityDisclaimer;
