import { useLazyQuery } from "@apollo/client";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./DocumentUpload.scss";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import { useRouter } from "../../routes";
import MainContainer from "../../sub-components/MainContainer";
// import JotformEmbed from 'react-jotform-embed';
const DocumentUpload = () => {
  const [user, setUser] = useState<any>();
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  //   const formUrl = router?.location?.search?.slice(9)

  console.log("CustomnerId", user?.customerId);

  return (
    <MainContainer>
      <div style={{ width: "100%", height: "100vh" }}>
        {user?.customerId && (
          <iframe
            id="JotFormIFrame-201877561050049"
            title="Widex Moment Application"
            allowTransparency={true}
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            src={`https://form.jotform.com/240020965508048?&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}`}
            style={{ border: "none" }}
            className="security-form-container"
            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-popups"
          >
            {" "}
          </iframe>
        )}
      </div>
    </MainContainer>
  );
};

export default DocumentUpload;
