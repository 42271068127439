import { Button } from "@chakra-ui/react";
import "./ComponentGradientButton.scss";
const ComponentGradientButton = (props: any) => {
  const { title, onClick, type, whiteBgBtn, blackBgBtn, iconRight, style } = props;
  return (
    <div className="gradient-button-wrapper"> <a
    type={type}
    className={
      blackBgBtn
        ? "component-gradient-button-black"
        : whiteBgBtn
        ? "component-gradient-button-white"
        : "component-gradient-button"
    }
    onClick={onClick}
    rightIcon={iconRight}
    {...props}
  >
    {title}
  </a></div>
   
  );
};

export default ComponentGradientButton;
