import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button, ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "./CancelApplicationModal.scss";
import {
  SECURITY_ACCOUNT_OPENING_ID,
  STATUS,
  STATUS_TEXT,
  VIDEO_VERIFICATION_FORM_ID,
  W8BEN_FROM_ID,
} from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL } from "../../routes";
import { CANCEL_FORM_MUTATION } from "../../graphql/user.mutation";
import { useMutation } from "@apollo/client";

const CancelApplicationModal = ({
  open,
  onClose,
  user,
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();

  const [CancelForm] = useMutation(CANCEL_FORM_MUTATION, {
    onCompleted: (data) => {
      window.location.reload();

    },
    onError: (err) => {
    
    },
  });

  console.log("user Id", user)
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "cancel-application-account-modal",
      }}
    >

  

<div className="security-account-info-container">
        <div className="security-account-info-text">Are you sure to cancel your application</div>
        <div style={{display:"flex", alignItems:"center"}}>
        {/* <ComponentButton
              title="Back"
              whiteBgBtn
              // iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                onClose()
              }}
            /> */}
             <Button
                    h="1.75rem"
                    size="md"
                    style={{height:"40px", borderRadius:"5px", padding:"10px", color:"#ffff", backgroundColor:"rgb(255, 30, 84)"}}
                    onClick={() => {
                      CancelForm({
                        variables: {
                          userId: user?.customerId,
                        },
                      });
                    }}
                    // onClick={() => {
                    //   setShow(!show);
                    // }}
                  >
                   Cancel Applications
                  </Button>
      </div>
        </div>
    </Modal>
  );
};

export default CancelApplicationModal;
