import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "../SecurityFormStatusModal/SecurityFormStatusModal.scss";
import {
  STATUS,
  STATUS_TEXT,
  VIDEO_VERIFICATION_FORM_ID,
  W8BEN_FROM_ID,
} from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL } from "../../routes";

const InvestmentFormStatusModal = ({
  open,
  onClose,
  fundInfo,
  fundName,
  formId
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "security-account-modal",
      }}
    >
      {/* <h2>Status</h2> */}
      <h5 className="black-card-head" style={{color:"#fff"}}>Status</h5>
      <ChakraProvider>
        <TableContainer>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th className="table-title"></Th>
                <Th className="table-header-text">Status</Th>
                <Th className="table-header-text">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
            
         
                <>
                  <Tr>
                    <Td className="table-title">
                      {fundName}
                    </Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[fundInfo?.approval?.status]
                            ?.color,
                      }}
                    >
                      {
                        STATUS_TEXT?.[fundInfo?.approval?.status]
                          ?.text
                      }
                    </Td>
                    {fundInfo?.approval?.status !==
                      STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                          navigate(
                            `${EDIT_FORM_URL}?formId=${formId}&formUrl=https://www.jotform.com/edit/${fundInfo?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                  {/* <Tr>
                    <Td className="table-title">W-8BEN</Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.w8BenForm?.status]
                            ?.color,
                      }}
                    >
                      {STATUS_TEXT?.[user?.approvals?.w8BenForm?.status]?.text}{" "}
                    </Td>
                    {user?.approvals?.w8BenForm?.status !== STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          navigate(
                            `${EDIT_FORM_URL}?formId=${W8BEN_FROM_ID}&formUrl=https://www.jotform.com/edit/${user?.w8BenForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                  <Tr>
                    <Td className="table-title">Video Verification</Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.videoForm?.status]
                            ?.color,
                      }}
                    >
                      {STATUS_TEXT?.[user?.approvals?.videoForm?.status]?.text}{" "}
                    </Td>
                    {user?.approvals?.videoForm?.status !== STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          navigate(
                            `${EDIT_FORM_URL}?formId=${VIDEO_VERIFICATION_FORM_ID}&formUrl=https://www.jotform.com/edit/${user?.videoForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr> */}
                </>
              
            </Tbody>
          </Table>
        </TableContainer>
      </ChakraProvider>
    </Modal>
  );
};

export default InvestmentFormStatusModal;
