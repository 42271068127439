import { ChakraProvider, Progress } from "@chakra-ui/react";
import "./ComponentProgressBar.scss";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const ComponentProgressBar = (props: any) => {
  const { defaultValue } = props;
  const maxLimit = 3;
  const [showProgress, setShowProgress] = useState(true);
  const showProgressBar = () => {
    if (showProgress) {
      setShowProgress(false);
    } else {
      setShowProgress(true);
    }
  };
  return (
    <div className="progress-bar-component">
      <div
        className="progress-toogle-btn"
        // onClick={() => {
        //   showProgressBar();
        // }}
      >
        {!showProgress && <ChevronUpIcon w={16} h={16} />}
        {showProgress && <ChevronDownIcon w={16} h={16} />}
      </div>
      {showProgress && (
        <div className="progress-bar-data">
          <ChakraProvider>
            <div style={{ display: "flex", gap: "13px", alignItems: "center" ,width:'100%'}}>
              <Progress value={defaultValue} max={maxLimit} />
              <div className="progress-data">
                <p>
                  {defaultValue} of {maxLimit}
                </p>
              </div>
            </div>
          </ChakraProvider>
        </div>
      )}
    </div>
  );
};

export default ComponentProgressBar;
