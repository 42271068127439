import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "./PIFormStatusModal.scss";
import {
  PI_FORM_ID,
  STATUS,
  STATUS_TEXT,
} from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL } from "../../routes";

const PIFormStatusModal = ({
  open,
  onClose,
  user,
  hideDocumentUpload,
  hideSecurityForm,
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "security-account-modal",
      }}
    >
      {/* <h2>Status</h2> */}
      <h5 className="black-card-head">Status</h5>
      <ChakraProvider>
        <TableContainer>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th className="table-title"></Th>
                <Th className="table-header-text">Status</Th>
                <Th className="table-header-text">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* {user?.existingUser && (
                <Tr>
                  <Td className="table-title">
                    Existing Users Account Verification
                  </Td>
                  <Td
                    className="table-status-title"
                    style={{
                      color:
                        STATUS_TEXT?.[user?.approvals?.securityForm?.status]
                          ?.color,
                    }}
                  >
                    {STATUS_TEXT?.[user?.approvals?.securityForm?.status]?.text}
                  </Td>
                  {user?.approvals?.securityForm?.status !==
                    STATUS.APPROVED && (
                    <Td
                      className="table-action-title"
                      onClick={() => {
                        // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                        navigate(
                          `${EDIT_FORM_URL}?formId=240512165451447&formUrl=https://www.jotform.com/edit/${user?.existingUserForm?.submissionID}`
                        );
                      }}
                    >
                      Re-submit
                    </Td>
                  )}
                </Tr>
              )} */}
              {!user?.existingUser && (
                <>
                  <Tr>
                    <Td className="table-title">PI Account Opening Form</Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.piForm?.status]?.color,
                      }}
                    >
                      {STATUS_TEXT?.[user?.approvals?.piForm?.status]?.text}
                    </Td>
                    {user?.approvals?.piForm?.status !== STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                          navigate(
                            `${EDIT_FORM_URL}?formId=${PI_FORM_ID}&formUrl=https://www.jotform.com/edit/${user?.piForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </ChakraProvider>
    </Modal>
  );
};

export default PIFormStatusModal;
