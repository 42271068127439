import { HStack, PinInput, PinInputField } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import MainContainer from "../../sub-components/MainContainer";
import "./SignUpPhoneVerification.scss";
import { useEffect, useState } from "react";
import { HOME_URL, SIGN_UP_PERSONAL_INFO_URL, useRouter } from "../../routes";
import ComponentProgressBar from "../../atoms/ComponentProgressBar";
import { useMutation } from "@apollo/client";
import {
  VERIFY_SIGN_IN_OTP_MUTATION,
  VERIFY_SIGN_UP_OTP_MUTATION,
} from "../../graphql/user.mutation";
import { toast } from "react-toastify";

import translate from "../../../translate";

interface SignUpVerificationProps {
  type?: string;
}

const SignUpPhoneVerification = ({ type }: SignUpVerificationProps) => {
  const [requestCode, setRequestTime] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const router = useRouter();
  const [VerifySignUpOTP, { data, loading, error }] = useMutation(
    VERIFY_SIGN_UP_OTP_MUTATION,
    {
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);
        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
      },
    }
  );

  const [
    VerifySignInOTP,
    { data: loginData, loading: loginLoading, error: loginError },
  ] = useMutation(VERIFY_SIGN_IN_OTP_MUTATION, {
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      toast.error(err.graphQLErrors[0]?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
      });
      // toast({
      //   title: err.graphQLErrors[0]?.message,
      //   status: "error",
      //   isClosable: true,
      // });
    },
  });

  useEffect(() => {
    console.log("data?.VerifySignUpOTP?.succeed", data?.SignUp?.succeed);
    if (data?.VerifySignUpOTP?.accessToken && !loading) {
      localStorage.setItem("authToken", data?.VerifySignUpOTP?.accessToken);
      window.location.href = SIGN_UP_PERSONAL_INFO_URL;
      // navigate(SIGN_UP_PERSONAL_INFO_URL);
    }
  }, [data]);

  useEffect(() => {
    if (loginData?.VerifySignInOTP?.accessToken && !loginLoading) {
      localStorage.setItem(
        "authToken",
        loginData?.VerifySignInOTP?.accessToken
      );
      window.location.href = HOME_URL;
      // navigate(HOME_URL);
    }
  }, [loginData]);

  const onClickEvent = (code: string) => {
    if (type === "login") {
      VerifySignInOTP({
        variables: {
          otp: code || otpCode,
          email: router?.query?.email,
        },
      });
    } else {
      VerifySignUpOTP({
        variables: {
          otp: code || otpCode,
          email: router?.query?.email,
        },
      });
    }
  };
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    if (countdown === 0) {
      clearInterval(interval);
      setRequestTime(false);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <>
      {/* <div className="arrow-back">
        <ArrowBackIcon
          style={{ cursor: "pointer", width: "40px", height: "42px" }}
          onClick={() => {
            navigate(SIGN_UP_PHONE_URL);
          }}
        />
      </div> */}
      <MainContainer>
        <div className="sign-up-phone-verification">
          <h2>{translate("six_digit_code")}</h2>
          <p>{translate("six_digit_code_sub_text")}</p>
          <div className="sign-up-component">
            <div
              style={{ display: "flex", gap: "10px", flexDirection: "column" }}
            >
              <HStack>
                <PinInput
                  placeholder="X"
                  onComplete={(value) => {
                    console.log("On COmplete OTP", value);
                    setOtpCode(value);
                    onClickEvent(value);
                  }}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              {requestCode && (
                <span style={{ fontSize: "18px", fontWeight: "300" }}>
                  {translate("request_new_code")} {countdown} {translate("sec")}
                </span>
              )}

              {!requestCode && (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    cursor: "pointer",
                  }}
                >
                  {translate("request_new_code")}
                </span>
              )}
              <div className="signup-phone-disclaimer-text">
                *
                {translate(
                  "Please check your spam or junk mail folder to make sure you didn’t miss the OTP email. If issues persist, please reach out to "
                )}
                <a
                  style={{ textDecoration: "underline" }}
                  href="mailto:info@chainofdemand.co"
                >
                  info@chainofdemand.co
                </a>
              </div>
            </div>

            <ComponentButton title={translate("next")} onClick={onClickEvent} />
          </div>
        </div>
        {/* <ComponentProgressBar defaultValue={1} /> */}
      </MainContainer>
    </>
  );
};

export default SignUpPhoneVerification;
