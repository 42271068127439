import PropTypes from "prop-types";
import React, { Component, useEffect, useRef, useState } from "react";
import Header from "./Header";
import "./ChatWindow.scss";
import ChatAnimation from "./ChatAnimation";
import { questionObj } from "./helper";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronRightIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import Markdown from "react-markdown";
import { useReactMediaRecorder } from "react-media-recorder";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  Button,
  ChakraProvider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import playIcon from "../../assets/play-fill.svg";
import micIcon from "../../assets/microphone.svg";
import axios from "axios";

// interface ChatWindowProps {
//   isOpen: any;
//   onClose?: any;
//   senderMessage: any;
//   handleMessageValue: any;
//   handleChatList: any;
//   senderChatList: any;
//   setSenderMessage: any;
//   onClickPrompt?: any;
//   promptList?: any;
//   loading?: any;
//   type?: string;
// }
const ChatWindow = (props) => {
  const { senderChatList, onClickPrompt, loading } = props;
  const [activeTab, setActiveTab] = useState("summaries");
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState({});
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        // User granted permission
        // You can now use the stream to record audio
      })
      .catch(function (error) {
        // User denied permission or an error occurred
        console.error(
          "Access to microphone was denied or an error occurred",
          error
        );
      });
  }, []);

  useEffect(() => {
    let intervalId;

    // if () {
    intervalId = setInterval(() => {
      const secondCounter = counter % 60;
      const minuteCounter = Math.floor(counter / 60);

      let computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;
      let computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;

      setSecond(computedSecond);
      setMinute(computedMinute);

      setCounter((counter) => counter + 1);
    }, 1000);
    // }

    return () => clearInterval(intervalId);
  }, [counter]);

  function stopTimer() {
    // setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }
  // const {
  //   status,
  //   startRecording,
  //   stopRecording,
  //   pauseRecording,
  //   clearBlobUrl,
  //   mediaBlobUrl
  // } = useReactMediaRecorder({
  //   video: false,
  //   audio: true,
  //   onStop(blobUrl, blob) {
  //     setAudioUrl(blobUrl)
  //   },
  //   // blobPropertyBag
  // });
  

  useEffect(() => {
    if (audioUrl && isActive) {
      fetch(audioUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = "audio-file.mp3"; // Choose a filename
          const lastModified = new Date(); // Use the current date/time as the last modified date
          console.log("Blob", blob);
          const file = new File([blob], filename, {
            type: blob.type,
            lastModified,
          });
          const formData = new FormData();
          formData.append("file", file);
          // Now you have a File object, which you can use in JavaScript or submit via a form, etc.
          axios
            .post("https://app.dlifo.com/chat-text-stream", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log("Success:", response.data);
              setIsActive(false);
              props.handleMessageValue({
                target: {
                  value: response.data,
                },
              });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => console.error("Error fetching the blob:", error));
    }
  }, [audioUrl, isActive]);

  //   render() {

  let classList = ["sc-chat-window", props.isOpen ? "opened" : "closed"];

  const onPlayAudio = (message) => {
    console.log("Message", message);
    let chatUrl = `https://app.dlifo.com/chat-audio-stream`;

    const data = JSON.stringify({
      text: message,
    });
    setIsAudioLoading(true);

    fetch(chatUrl, {
      method: "post",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: data,
    })
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      // Update image
      .then((url) => {
        console.log("Final Audio Url", url);
        setIsAudioLoading(false);
        setAudioSrc({
          ...audioSrc,
          [message]: url,
        });
      })
      .catch((err) => console.error(err));
  };

  const displayChatData = () => {
    return (
      <div
        className="chatDesign"
        style={{
          height:
            props.type === "page"
              ? "calc(100vh - 209px)"
              : "calc(100vh - 265px)",
        }}
      >
        {props.senderChatList?.length !== 0 && (
          <>
            {props.senderChatList.map((item) => {
              if (item?.type === "sender") {
                return (
                  <div className="chat-item item-right">
                    <div className="right-align-item">{item?.message}</div>
                  </div>
                );
              } else if (item?.type === "receiver") {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "13px",
                    }}
                  >
                    {/* <div className="left-icon-bg"></div> */}
                    <div className="chat-item item-left">
                      {item?.dataType === "text" && (
                        <div className="left-align-item">
                          <Markdown>{item?.message}</Markdown>
                        </div>
                      )}
                      {item?.dataType === "loading" && (
                        <div
                          className="left-align-item"
                          style={{
                            color: "rgba(0, 0, 0, 0.88)",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          <ChatAnimation />
                        </div>
                      )}
                      {item?.dataType !== "text" &&
                        item?.dataType !== "loading" && (
                          <>
                            <div>
                              {item?.message.map((prop) => {
                                return (
                                  <div title={prop.subItem?.question}>
                                    <div
                                      className="left-align-item"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {prop.subItem?.question}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="see-more-btn">
                              <button style={{ color: "#29d1d7" }}>
                                See More
                              </button>
                            </div>
                          </>
                        )}
                    </div>

                    {audioSrc?.[item?.message] && (
                      // <audio
                      //   ref={audioRef}
                      //   controls
                      //   src={audioSrc[item?.message]}
                      //   autoPlay
                      //   style={{ marginBottom: "10px" }}
                      // />

                      <audio
                        id="audio_player"
                        autoPlay
                        style={{ marginBottom: "10px" }}
                        controls
                      >
                        <source
                          id="src_mp3"
                          type="audio/mp3"
                          src={audioSrc[item?.message]}
                        />
                        <source
                          id="src_ogg"
                          type="audio/ogg"
                          src={audioSrc[item?.message]}
                        />
                        Your browser does not support this audio player.
                      </audio>
                    )}
                    {isAudioLoading && !audioSrc?.[item?.message] && (
                      <Button isLoading>Loading Audio</Button>
                    )}
                    {!audioSrc?.[item?.message] &&
                      !isAudioLoading &&
                      item?.completed && (
                        <div
                          className="chat-window-play-button-container"
                          onClick={() => {
                            onPlayAudio(item?.message);
                          }}
                        >
                          <img src={playIcon} alt="home" />
                        </div>
                      )}
                  </div>
                );
              }
            })}
          </>
        )}
        <div ref={messagesEndRef} style={{ height: "10px" }} />
      </div>
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (senderChatList?.length) {
      scrollToBottom();
    }
  }, [JSON.stringify(senderChatList), activeTab]);

  const [recordState, setRecordState] = useState();

  const start = () => {
    // stopTimer()
    setRecordState(RecordState.START);
    // this.setState({
    //   recordState: RecordState.START
    // })
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
    // stopTimer()
    setAudioUrl("");
    // this.setState({
    //   recordState: RecordState.STOP
    // })
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    console.log("audioData", audioData, recordState);
    setAudioUrl(audioData?.url);
    setIsActive(true);
    setRecordState(RecordState.NONE);
    stopTimer();
  };

  return (
    <div className={props.type === "popup" ? classList.join(" ") : ""}>
      {props.type === "popup" && (
        <Header
          // imageUrl={agentProfile.imageUrl}
          onClose={props.onClose}
        />
      )}

      <>
        <div style={{ padding: "20px" }}>{displayChatData()}</div>
        <div id="song"></div>

        <div className="inputSendBtn">
          {/* {audioUrl && <div>
        <audio src={audioUrl} controls />

        </div>} */}
          {!isRecordingStarted && (
            <>
              <input
                style={{ width: "100%" }}
                type="text"
                value={props.senderMessage}
                onChange={props.handleMessageValue}
                placeholder="Type a reply.."
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    props.handleChatList();
                  }
                }}
              />
              <div
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsRecordingStarted(true);
                  stopTimer();
                  start();
                  // clearBlobUrl()
                  //  setIsActive(true);
                  // startRecording();
                }}
              >
                <img src={micIcon} alt="mic" />
              </div>
            </>
          )}
          {isRecordingStarted && (
            <>
              <div
                style={{
                  flex: "1 1 auto",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <div style={{ marginRight: "10px" }}>
                  <span className="minute">{minute}</span>
                  <span>:</span>
                  <span className="second">{second}</span>
                </div>
                Recording...
              </div>
              <div style={{ marginRight: "10px" }}>
                <CheckIcon
                  onClick={() => {
                    stop();
                    // stopTimer()
                    // stopRecording();
                    setIsRecordingStarted(false);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setIsActive(false);
                    stop();
                    // stopRecording()
                    // clearBlobUrl()
                    setAudioUrl("");
                    // stopTimer()

                    setIsRecordingStarted(false);
                  }}
                  style={{ marginLeft: "20px", width: "13px" }}
                />
              </div>
            </>
          )}

          <div>
            <AudioReactRecorder state={recordState} onStop={onStop} />

            {/* <button onClick={start}>Start</button>
        <button onClick={stop}>Stop</button> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default ChatWindow;
