import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button, ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "./SecurityFormStatusModal.scss";
import {
  SECURITY_ACCOUNT_OPENING_ID,
  STATUS,
  STATUS_TEXT,
  VIDEO_VERIFICATION_FORM_ID,
  W8BEN_FROM_ID,
} from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL } from "../../routes";
import { CANCEL_FORM_MUTATION } from "../../graphql/user.mutation";
import { useMutation } from "@apollo/client";

const SecurityFormStatusModal = ({
  open,
  onClose,
  user,
  hideDocumentUpload,
  hideSecurityForm,
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();

  const [CancelForm] = useMutation(CANCEL_FORM_MUTATION, {
    onCompleted: (data) => {
      window.location.reload();

    },
    onError: (err) => {
    
    },
  });

  console.log("user Id", user)
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "security-account-modal",
      }}
    >
   
        <h5 className="black-card-head" style={{color:"#ccc"}}>Status</h5>
      <ChakraProvider>
        <TableContainer>
          <Table variant="simple">

            <Thead>
              <Tr>
                <Th className="table-title"></Th>
                <Th className="table-header-text">Status</Th>
                <Th className="table-header-text">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {user?.existingUser && (
                <Tr>
                  <Td className="table-title">
                    Existing Users Account Verification
                  </Td>
                  <Td
                    className="table-status-title"
                    style={{
                      color:
                        STATUS_TEXT?.[user?.approvals?.securityForm?.status]
                          ?.color,
                    }}
                  >
                    {STATUS_TEXT?.[user?.approvals?.securityForm?.status]?.text}
                  </Td>
                  {user?.approvals?.securityForm?.status !==
                    STATUS.APPROVED && (
                    <Td
                      className="table-action-title"
                      onClick={() => {
                        // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                        navigate(
                          `${EDIT_FORM_URL}?formId=240512165451447&formUrl=https://www.jotform.com/edit/${user?.existingUserForm?.submissionID}`
                        );
                      }}
                    >
                      Re-submit
                    </Td>
                  )}
                </Tr>
              )}
              {!user?.existingUser && (
                <>
                  <Tr>
                    <Td className="table-title">
                      Securities Account Opening Form
                    </Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.securityForm?.status]
                            ?.color,
                      }}
                    >
                      {
                        STATUS_TEXT?.[user?.approvals?.securityForm?.status]
                          ?.text
                      }
                    </Td>
                    {user?.approvals?.securityForm?.status !==
                      STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                          navigate(
                            `${EDIT_FORM_URL}?formId=${SECURITY_ACCOUNT_OPENING_ID}&formUrl=https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                  <Tr>
                    <Td className="table-title">W-8BEN</Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.w8BenForm?.status]
                            ?.color,
                      }}
                    >
                      {STATUS_TEXT?.[user?.approvals?.w8BenForm?.status]?.text}{" "}
                    </Td>
                    {user?.approvals?.w8BenForm?.status !== STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          navigate(
                            `${EDIT_FORM_URL}?formId=${W8BEN_FROM_ID}&formUrl=https://www.jotform.com/edit/${user?.w8BenForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                  <Tr>
                    <Td className="table-title">Video Verification</Td>
                    <Td
                      className="table-status-title"
                      style={{
                        color:
                          STATUS_TEXT?.[user?.approvals?.videoForm?.status]
                            ?.color,
                      }}
                    >
                      {STATUS_TEXT?.[user?.approvals?.videoForm?.status]?.text}{" "}
                    </Td>
                    {user?.approvals?.videoForm?.status !== STATUS.APPROVED && (
                      <Td
                        className="table-action-title"
                        onClick={() => {
                          navigate(
                            `${EDIT_FORM_URL}?formId=${VIDEO_VERIFICATION_FORM_ID}&formUrl=https://www.jotform.com/edit/${user?.videoForm?.submissionID}`
                          );
                        }}
                      >
                        Re-submit
                      </Td>
                    )}
                  </Tr>
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </ChakraProvider>
   

  

      <div style={{marginTop:"30px", marginBottom:"30px", display:"flex", justifyContent:"center"}}>
      <Button
                    h="1.75rem"
                    size="md"
                    style={{height:"40px", borderRadius:"5px", padding:"10px", color:"#ffff", backgroundColor:"rgb(255, 30, 84)"}}
                    onClick={() => {
                      CancelForm({
                        variables: {
                          userId: user?.customerId,
                        },
                      });
                    }}
                    // onClick={() => {
                    //   setShow(!show);
                    // }}
                  >
                   Cancel Applications
                  </Button>
      </div>
    </Modal>
  );
};

export default SecurityFormStatusModal;
