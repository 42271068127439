import { gql } from "@apollo/client";

export const GET_STRATEGY_PRODUCT = gql`
  query GET_STRATEGY_PRODUCT_QUERY($filter: FilterFindManyproductInput) {
    ProductPagination(filter: $filter) {
      items {
        productId
        name
        description
        overview
        holdings
        dataRoom
        allocations
        type
        forms {
          formId
          name
        }
        _id
        createdAt
        updatedAt
      }
    }
  }
`;


export const GET_USER_INVESTMENT = gql`
query UserInvestments($filter: FilterFindManyuserInvestInput) {
  userInvestments(filter: $filter) {
    items {
      ip
      customerId
      fundId
      formID
      submissionID
      rawRequest
      pretty
      approval {
        reason
        status
      }
    }
  }
}
`

export const GET_USER_DL_INEVSTMENTS = gql`
query DlUserInvestments($filter: FilterFindManydlUserInvestInput) {
  dlUserInvestments(filter: $filter) {
    items {
      ip
      customerId
      fundId
      fundName
      formID
      submissionID
      interestRate
      principal
      tenor
      accountNumber
      accountName
      rawRequest
      pretty
      approval {
        status
        reason
      }
      _id
    }
  }
}
`

export const GET_INVESTMENT_FORM = gql`
  query GET_INVESTMENT_FORM_QUERY($filter: FilterFindManyinvestmentFormInput) {
    InvestmentFormPagination(filter: $filter) {
      items {
        username
        ip
        formTitle
        customerId
        rawRequest
        productId
        formID
        submissionID
        pretty
        approval {
          status
          reason
        }
        _id
        downloadPdf
      }
    }
  }
`;


export const GET_FUNDS_INFO = gql`
query FundsPagination($filter: FilterFindManyfundInput, $perPage: Int) {
  FundsPagination(filter: $filter, perPage: $perPage) {
    items {
      id
      isin
      name
      fundId
      asset_class
      investment_objective
      risk_reward_indicator
      one_year
      minimum_initial_subscription_amount
      minimums_currency
      _id
      createdAt
      updatedAt
    }
  }
}
`
export const GET_ALL_FUNDS_DATA = gql`
query AllFunds($targetUrl: String!) {
  allFunds(target_url: $targetUrl)
}
`

export const GET_CURRENCY_EXCHANGE = gql`
query CurrencyExchange($targetUrl: String!) {
  eodHd(target_url: $targetUrl)
}
`