import {
  ChakraProvider,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "./AIBot.scss";
import { useEffect, useState } from "react";
import ChatWindow from "../../../features/LauncherPrompt/ChatWindow";
// #1A202C
const AIBot = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [senderMessage, setSenderMessage] = useState("");
  const [senderChatList, setSenderChatList] = useState([]);
  const [advancedChatList, setAdvancedChatList] = useState([]);
  const [visualChatList, setVisualChatList] = useState([]);

  const [conversationId, setConversationId] = useState("");

  const handleMessageValue = (props: any) => {
    setSenderMessage(props?.target?.value);
  };

  const handleChatList = () => {
    let newMessage: any = [];
    let chatList = [...visualChatList];
    if (tabIndex === 0) {
      chatList = [...senderChatList];
    } else if (tabIndex === 1) {
      chatList = [...advancedChatList];
    } else if (tabIndex === 2) {
      chatList = [...visualChatList];
    }
    newMessage = [
      ...chatList,
      { type: "sender", message: senderMessage, dataType: "text" },
      {
        type: "receiver",
        message: "Loading....",
        dataType: "loading",
      },
    ];
    if (tabIndex === 0) {
      setSenderChatList(newMessage);
    } else if (tabIndex === 1) {
      setAdvancedChatList(newMessage);
    } else if (tabIndex === 2) {
      setVisualChatList(newMessage);
    }

    setSenderMessage("");
    let data = JSON.stringify({
      query: senderMessage,
    });
    const token: any = localStorage.getItem("authToken");

    let chatUrl = "/chat-stream";
    if (tabIndex === 0) {
      chatUrl = "/chat-basic-stream";
    } else if (tabIndex === 1) {
      // chatUrl = "http://ec2-3-254-186-205.eu-west-1.compute.amazonaws.com/chat-test-stream"
      chatUrl = "http://ec2-3-254-186-205.eu-west-1.compute.amazonaws.com/chat-gptbot-stream?type=investment";
      data = JSON.stringify({
        query: senderMessage,
        conversation_id: conversationId,
        type: "investment"
      });
    } else if (tabIndex === 3) {
      chatUrl = "/chat-stream";
    }
    fetch(chatUrl, {
      method: "post",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
      .then(async (response: any) => {
        console.log(JSON.stringify(response.body));
        console.log("I am getting called", response.body);
        if (tabIndex === 0) {
          await handleStream(response.body.getReader(), setSenderChatList);
        } else if (tabIndex === 1) {
          await handleStream(response.body.getReader(), setAdvancedChatList);
        } else if (tabIndex === 2) {
          await handleStream(response.body.getReader(), setVisualChatList);
        }

        // callback?.();
        // callback(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStream = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    setSenderChatList: any
  ): Promise<void> => {
    const decoder = new TextDecoder("utf-8");

    const handleStreamRecursively = async () => {
      const { done, value } = await reader.read();

      if (done) {
        return;
      }

      const dataStrings = decoder
        .decode(value)
        // .trim()
        .split("data: ")
        .filter(Boolean);

      dataStrings.forEach((data) => {
        let parsedData: any;
        try {
          parsedData = data;
        } catch (e) {
          console.log("Exception", e);
        }

        if (parsedData) {
          setSenderChatList((prevHistory: any) => {
            let updatedPreviousHistory = [...prevHistory];
            console.log("Parsed Data", parsedData, updatedPreviousHistory);

            const updatedHistory = [
              ...updatedPreviousHistory,
              // { ...parsedData, message: parsedData },
            ];
            updatedHistory[updatedHistory?.length - 1] = {
              type: "receiver",
              message:
                updatedHistory[updatedHistory?.length - 1]?.dataType !==
                "loading"
                  ? updatedHistory[updatedHistory?.length - 1]?.message +
                    parsedData
                  : parsedData,
              dataType: "text",
            };
            console.log("Updated History", updatedHistory);
            return updatedHistory;
          });
        }
      });

      await handleStreamRecursively();
    };

    await handleStreamRecursively();
  };
  useEffect(() => {
    // if (tabIndex === 2) {
    //   if (!document.getElementById("eself-open-button")) {
    //     const script = document.createElement("script");
    //     script.src = "https://assets.campaignsai.com/dl-holdings/prod/bundle.js";
    //     document.head.appendChild(script);
    //   }
    // } else {
    //   const scriptElem: any = document.getElementById("eself-open-button");
    //   if (scriptElem) {
    //     scriptElem.remove();
    //   }
    // }
  }, [tabIndex]);

  useEffect(() => {
    // if(tabIndex === 1){
    let chatUrl = "/chat-gptbot-stream?type=investment";
    const token: any = localStorage.getItem("authToken");
    fetch(chatUrl, {
      method: "get",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        console.log("gptbot response", response.body);
        const data: any = await response.body?.getReader().read();
        const decoder = new TextDecoder("utf-8");
        const dataStrings = decoder
          .decode(data?.value)
          // .trim()
          .split("data: ")
          .filter(Boolean);
        console.log("Data avlue", dataStrings);
        setConversationId(dataStrings?.[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    // }
    return () => {
      const scriptElem: any = document.getElementById("eself-open-button");
      if (scriptElem) {
        scriptElem.remove();
      }
    };
  }, []);

  console.log("Conversation Id", conversationId);
  return (
    <DashboardContainer>
      <ChakraProvider>
        <div className="ai-section">
          <Tabs
            // position="relative"
            variant="soft-rounded"
            onChange={(index) => setTabIndex(index)}
            style={{ height: "100%" }}
          >
            <TabList
              style={{
                width: "fit-content",
                backgroundColor: "#000",
                padding: "5px",
                borderRadius: "8px",
              }}
            >
              <Tab
                // _selected={{ color: "#fff", bg: "#007FFF" }}
                style={{
                  color: tabIndex === 0 ? "#fff" : "#ccc",
                  width: "130px",
                  borderRadius: "10px",
                  backgroundColor: tabIndex === 0 ? "#007FFF" : "transparent",
                }}
              >
                <div style={{ fontSize: "8px" }}>
                  DLGPT
                  <br />
                  <span style={{ fontSize: "16px" }}>Basic</span>
                </div>
              </Tab>
              <Tab
                style={{
                  color: tabIndex === 1 ? "#fff" : "#ccc",
                  width: "130px",
                  borderRadius: "10px",
                  backgroundColor: tabIndex === 1 ? "#007FFF" : "transparent",
                }}
              >
                <div style={{ fontSize: "8px" }}>
                  DLGPT
                  <br />
                  <span style={{ fontSize: "16px" }}>Advanced</span>
                </div>
              </Tab>
              <Tab
                // _selected={{ color: "#fff", bg: "#007FFF" }}
                style={{
                  color: tabIndex === 2 ? "#fff" : "#ccc",
                  width: "130px",
                  borderRadius: "10px",
                  backgroundColor: tabIndex === 2 ? "#007FFF" : "transparent",
                }}
              >
                <div style={{ fontSize: "8px" }}>
                  DLGPT
                  <br />
                  <span style={{ fontSize: "16px" }}>Visual AI</span>
                </div>
              </Tab>
            </TabList>
            {/* <TabIndicator
              mt="-0.5px"
              height="3px"
              bg="#00FFBF"
              borderRadius="1px"
            /> */}
            <TabPanels style={{ height: "calc(100% - 43px)" }}>
              <TabPanel style={{ height: "100%" }}>
                <ChatWindow
                  isOpen={true}
                  senderMessage={senderMessage}
                  handleMessageValue={handleMessageValue}
                  handleChatList={handleChatList}
                  senderChatList={senderChatList}
                  setSenderMessage={setSenderMessage}
                  type="page"
                  // onClickPrompt={onClickPrompt}
                  // promptList={promptList}
                  // loading={loading}
                />
              </TabPanel>
              <TabPanel style={{ height: "100%" }}>
                <ChatWindow
                  isOpen={true}
                  senderMessage={senderMessage}
                  handleMessageValue={handleMessageValue}
                  handleChatList={handleChatList}
                  senderChatList={advancedChatList}
                  setSenderMessage={setSenderMessage}
                  type="page"
                  // onClickPrompt={onClickPrompt}
                  // promptList={promptList}
                  // loading={loading}
                />
              </TabPanel>
              <TabPanel style={{ height: "100%" }}>
                <ChatWindow
                  isOpen={true}
                  senderMessage={senderMessage}
                  handleMessageValue={handleMessageValue}
                  handleChatList={handleChatList}
                  senderChatList={visualChatList}
                  setSenderMessage={setSenderMessage}
                  type="page"
                  // onClickPrompt={onClickPrompt}
                  // promptList={promptList}
                  // loading={loading}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </ChakraProvider>
    </DashboardContainer>
  );
};

export default AIBot;
