import { gql } from "@apollo/client";

export const GET_HOLDING_NAV_QUERY = gql`
  query GET_HOLDING_NAV_QUERY($filter: FilterFindManyholdingsInput,$perPage: Int) {
    HoldingsPagination(filter: $filter, perPage: $perPage) {
      items {
        date
        cashBalance
        instrumentBalance
        accountId
        nav
        funds {
            marketId
            instrumentId
            ledgerBalance
            closingPrice
            exchangeRate
            currency
            marketValueWQData
        }
        _id
        createdAt
        updatedAt
      }
    }
  }
`;
