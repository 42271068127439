import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import backgroundOverlay2 from "../../../assets/home2.png";
import EuroVector from "../../../assets/euro-vector.svg";
import "./Home.scss";
import { useEffect, useState } from "react";
import ComponentSideModal from "../../atoms/ComponentSideModal";
import { useNavigate } from "react-router-dom";
import { FO_STRATEGY_URL, LOGIN_URL, SECURITY_ACCOUNT_URL, gradientColor } from "../../routes";
import { useLazyQuery } from "@apollo/client";
import { GET_ESELF_SIGNATURE, GET_USER } from "../../graphql/user.query";
import DUIX from "duix-guiji";
// import jwt from 'jsonwebtoken'

import translate, { changeLanguage, messages } from "../../../translate";
import { STATUS, VIDEO_VERIFICATION_FORM_ID, W8BEN_FROM_ID } from "../../dataHelper";
import SecurityFormStatusModal from "../../sub-components/SecurityFormStatusModal";
// import jwt_decode, { JwtPayload, jwtDecode } from "jwt-decode";
// import {} from 'react-jwt'

// import jwt from 'jsonwebtoken';
// import moment from 'moment';
import axios from "axios";
import SelectUserModal from "../../sub-components/SelectUserModal";
import ComponentGradientButton from "../../atoms/ComponentGradientButton";

const dummyData = [
  {
    name: translate("not_sure1"),
    id: "start",
  },
  {
    name: translate("not_sure2"),
    id: "popular",
  },
  {
    name: translate("not_sure3"),
    id: "diversification",
  },
  {
    name: translate("not_sure4"),
    id: "fixdeposit",
  },
  {
    name: translate("not_sure5"),
    id: "insurance",
  },
];
const Home = () => {
  const [sidebarModalOpen, setSidebarModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [selectedPromptId, setSelectredPromptId] = useState([]);

  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);

  const [formStatus, setFormStatus] = useState("waiting");
  const [isSelectUserDialogOpened, setIsSelectUserDialogOpened] =
    useState(false);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
        const user = data?.user;
        if (user?.existingUser) {
          if (user?.existingUserForm) {
            if (user?.approvals?.existingUserForm?.status === STATUS.APPROVED) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            setFormStatus("complete");
          }
        } else {
          if (user?.securityForm && user?.w8BenForm && user?.videoForm) {
            if (
              user?.approvals?.securityForm?.status === STATUS.APPROVED &&
              user?.approvals?.w8BenForm?.status === STATUS.APPROVED &&
              user?.approvals?.videoForm?.status === STATUS.APPROVED
            ) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            if (user?.securityForm && !user?.w8BenForm) {
              setFormStatus("w8BenFormComplete");
            } else if (
              user?.securityForm &&
              user?.w8BenForm &&
              !user?.videoForm
            ) {
              setFormStatus("videoFormComplete");
            } else {
              setFormStatus("complete");
            }
          }
        }
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // toast.error(err.graphQLErrors[0]?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 1,
      //   theme: "dark",
      // });
    },

    fetchPolicy: "no-cache",
  });

  const [getEselfSignature] = useLazyQuery(GET_ESELF_SIGNATURE);

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const startSession = (socket) => {
    // Define the start session message according to your server's API.
    const startSessionMessage = {
      action: "startSession",
      // ... other parameters as required by your server
    };

    // Send the start session message to the server
    socket.send(JSON.stringify(startSessionMessage));
  };

  const promptSelect = (selectedId) => {
    setSelectredPromptId([selectedId]);
    setSidebarModalOpen(true);
  };

  return (
    <DashboardContainer>
      <div
        className="home-section"
        id="home-section"
        style={{
          backgroundImage: `url(${backgroundOverlay2})`,
          backgroundSize: "cover",
        }}
      >
        {/* <div className="remote-container" style={{width:"100px", height:"100px"}}></div> */}
        <div className="left-part">
          <h3 style={{ fontSize: "35px" }}>
            {translate("welcome")} <br />{" "}
            <span
              style={{
                fontSize: "55px",
                lineHeight: "50px",
                marginTop: "10px",
              }}
              className="gradient-heading"
            >
              {translate("AI Family Office!")}
            </span>
          </h3>
          <div
            style={{ borderBottom: "1px solid #aaa" }}
            className="border-container"
          ></div>
          <p> {translate("welcome_subtext")}</p>
          {formStatus === "complete" && (
            <ComponentGradientButton
              title={translate("complete_account_setup")}
              iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                setIsSelectUserDialogOpened(true);
              }}
            />
          )}
          {formStatus === "w8BenFormComplete" && (
            <ComponentGradientButton
              title={"Resume Account Setup"}
              iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                navigate(
                  `${SECURITY_ACCOUNT_URL}/${W8BEN_FROM_ID}?formUrl=https://alt.jotfor.ms/${W8BEN_FROM_ID}?uniqueId=${user?.securityForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&1name=${user?.securityForm?.rawRequest?.q88_name86}&address=${user?.securityForm?.rawRequest?.q211_typeA211}&3Permanent43=${user?.securityForm?.rawRequest?.residentialAddress227}&4Mailing=${user?.securityForm?.rawRequest?.correspondenceAddress229}&8Date=${user?.securityForm?.rawRequest?.dateof}&6aForeign=${user?.securityForm?.rawRequest?.typeA121}&Email=${user?.email}`
                );
              }}
            />
          )}
          {/* {baseurl}/security-account/241282922209050?formUrl=https://alt.jotfor.ms/241282922209050&uniqueId={submissionId}&customerid={customerid}&environment={environment}&baseurl={baseurl}&email={email} */}
             {formStatus === "videoFormComplete" && (
            <ComponentGradientButton
              title={"Resume Account Setup"}
              iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                navigate(
                  `${SECURITY_ACCOUNT_URL}/${VIDEO_VERIFICATION_FORM_ID}?formUrl=https://alt.jotfor.ms/${VIDEO_VERIFICATION_FORM_ID}?uniqueId=${user?.w8BenForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&email=${user?.email}`
                );
              }}
            />
          )}
          {formStatus === "download" && (
            // <a
            //   target="_self"
            //   href={user?.securityForm?.downloadPdf}
            //   download="Invoice.pdf"
            // >
            <ComponentGradientButton
              title={translate("Go to Investments")}
              iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                navigate(FO_STRATEGY_URL);
              }}
            />
            // </a>
          )}
          {formStatus === "waiting" && (
            <ComponentGradientButton
              title={translate("Waiting on approval")}
              iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                setIsStatusModalOpened(true);
              }}
            />
          )}
        </div>
        <div className="right-part">
          <div className="right-inner-height">
            <h5>{translate("not_sure_where_to_start")}</h5>
            {dummyData.map((item) => {
              return (
                <div
                  className="card-design"
                  style={{ cursor: "pointer" }}
                  onClick={() => promptSelect(item.id)}
                >
                  {/* <InfoOutlineIcon
                    color={"#000"}
                    style={{ width: "24px", height: "24px" }}
                  /> */}
                  {/* <div style={{ width: "24px", height: "24px" }}></div> */}
                  {/* <img src={EuroVector} /> */}
                  <p>{item.name}</p>
                </div>
              );
            })}
            {/* <p>SEE OTHER IDEAS</p> */}
          </div>
        </div>
      </div>
      {sidebarModalOpen && (
        <div>
          <ComponentSideModal
            selectedPromptId={selectedPromptId}
            onClose={() => {
              if (selectedPromptId?.length > 1) {
                selectedPromptId.pop();
                setSelectredPromptId([...selectedPromptId]);
              } else {
                setSelectredPromptId([]);
                setSidebarModalOpen(false);
              }
            }}
            setSelectredPromptId={setSelectredPromptId}
          />
        </div>
      )}
      {isStatusModalOpened && (
        <SecurityFormStatusModal
          user={user}
          open={isStatusModalOpened}
          onClose={() => {
            setIsStatusModalOpened(false);
          }}
        />
      )}
      {isSelectUserDialogOpened && (
        <SelectUserModal
          open={isSelectUserDialogOpened}
          onClose={() => {
            setIsSelectUserDialogOpened(false);
          }}
          user={user}
        />
      )}
    </DashboardContainer>
  );
};

export default Home;
