import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import eselfImage from "../../../assets/eself-image.png";
import { ChevronDownIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

// export const options: any = [
//     { value: "en", label: "English" },
//     { value: "zh", label: "简体中文" },
//     { value: "zh-hk", label: "繁體中文" }
//   ];

const PreVirtualAssistantModal = ({
  open,
  onClose,
  user,
  productObj,
}: //   onUpdateUser,
//   userData,
any) => {
  const [isStarted, setIsStarted] = useState(false);
  useEffect(() => {
 
    // if (!document.getElementById("eself-open-button")) {
    //   const script = document.createElement("script");
    //   script.src = "https://assets.campaignsai.com/dl-holdings/prod/bundle.js";
    //   document.head.appendChild(script);
    // } 
   
    // return () => {
    //   const scriptElem: any = document.getElementById("eself-open-button");
    //   if (scriptElem) {
    //     scriptElem.remove();
    //   }
    // };
  
}, [])
  return (
    <Modal
      open={open}
      onClose={() => {
        setIsStarted(false)
        if(document?.getElementById("eself-open-button")){
        
          const eselfContainer = document.getElementById("eself-open-button") as HTMLInputElement
          eselfContainer.style.zIndex = "9999"
         }
        onClose()
      }}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "pre-virtual-assistant-modal",
      }}
    >
      <div className="pre-virtual-left-section">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginRight: "20px",
            minWidth:"440px",
            width:"440px"
          }}
        >
          <div>
            <div className="pre-virtual-hi-text">Hi, I'm Danielle</div>
            <div className="pre-virtual-your-personal">Your Personal AI RM</div>

            <div style={{ color: "#aaa", marginTop: "10px", fontSize:"13px", width:"400px" }}>
            If you'd like to speak with me, simply select the widget and start the conversation!
              {/* <span style={{ color: "#ff1e54", textDecoration: "underline" }}>
                Yes
              </span>
              /
              <span style={{ color: "#ff1e54", textDecoration: "underline" }}>
                No
              </span> */}
            </div>
            <div style={{ color: "#aaa", fontSize:"13px", marginTop:"5px", width:"400px" }}>
            Prefer to chat? Feel free to use DL GPT to build your ideal portfolio
            </div>

            <div style={{ marginTop: "20px" }}>
              <img src={eselfImage} style={{ height: "300px", visibility:"hidden" }} />
            </div>
          </div>
        </div>

        <div
          style={{
            flex: "1 1 ",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <div style={{ height: "400px", width: "400px" }}>
            <iframe
              width="400px"
              height="500px"
              allow="microphone *"
              src="https://www.gptbots.ai/widget/erxmc2sltzblc0bdcvnbrko/chat.html"
            ></iframe>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // marginTop: "50px",
        }}
      >
        {!isStarted &&   
      
         <div  style={{display:"flex", width:"100%"}}>
         <div style={{display:"flex", flex:"1 1 auto", justifyContent:"center", marginLeft:"50px"}}>
         <div className='pre-landing-page-down-container' style={{}} onClick={() => {
           setIsStarted(true);
           if(document?.getElementById("eself-open-button")){
            const eselfContainer = document.getElementById("eself-open-button") as HTMLInputElement
            eselfContainer.style.zIndex = "100"
           }
           
         }}>
 
             <ChevronDownIcon />
 
         </div>
       
         </div>
         <div style={{    marginRight: "18px", color:"rgba(131, 131, 131, 1)", fontWeight:
     "bold", fontSize:"14px", marginTop:"5px", cursor:"pointer", textAlign:"center"}}  onClick={() => {
      window.location.href = 'https://dlifo-v2.webflow.io/';
    }}><ArrowForwardIcon/> <br/>Skip</div>
         </div>
        }
      
      </div>
      {isStarted && (
        <div style={{position:"absolute", top:"0px", height:"100%", width:"100%",  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(131, 131, 131, 1) 100%)", paddingBottom:"100px"}}>
          <div className="are-you-sure-container">
           
        
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "25px",
                marginBottom:"50px"
              }}
            >
              <div
                className="pre-landing-page-down-container"
                onClick={() => {
                  window.location.href = 'https://dlifo-v2.webflow.io/';
                }}
              >
                <ChevronDownIcon />
              </div>
            </div>
            <p style={{margin:"0px", fontSize:"20px"}}>to start your investment journey ?</p>
            <h1 style={{margin:"0px", marginBottom:"5px"}}>Are you ready</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PreVirtualAssistantModal;
