import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import './InvestmentFormModal.scss'

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};


const InvestmentForm = ({
  open,
  onClose,
  user,
  fundId,
  formId,
  fundName,
  type,
  title,
  fundData
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        modal: "investment-form-modal",
      }}
    >
      {title && <div className="investment-form-title">{title}</div>}
    
      <div style={{ width: "100%", height: !type ? "500px" : "620px", overflow:"hidden" }}>
        {user?.customerId && (
          <iframe
            id="JotFormIFrame-201877561050049"
            title="Widex Moment Application"
            allowTransparency={true}
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            // src={`https://form.jotform.com/240242572144044?&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}`}
            src={`https://form.jotform.com/${formId}?&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&nameOf=${fundName}&orderid=ORDER-${uuidv4()}&fundid=${fundId}&tenor=${fundData?.tenor}&interestRate=${fundData?.interestRate}&accountName=DL SECURITIES (HK) LTD – CLIENT’S A/C&accountNumber=${"849-1-010057-01"}`}
            style={{ border: "none" }}
            className="security-form-container"
            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-popups"
          >
            {" "}
          </iframe>
        )}
      </div>
     
       
 


    </Modal>
  );
};

export default InvestmentForm;
