import {
  Navigate,
  Route,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useQuery, useReactiveVar, gql } from "@apollo/client";

import { authTokenVar, isJwtValid, userObj } from "../graphql/client";
import { GET_USER } from "../graphql/user.query";
import {
  DOCUMENT_UPLOAD_URL,
  GO_TO_LEGAL_URL,
  HOME_URL,
  LOGIN_URL,
  SIGN_UP_INVESTOR_STATUS_URL,
  SIGN_UP_LEGAL_AGGREMENT__URL,
  SIGN_UP_PERSONAL_INFO_URL,
  SIGN_UP_URL,
  useRouter,
} from "../routes";
import { useState } from "react";

const GET_TOKEN = gql`
  query getToken($refreshToken: String!) {
    getToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

interface AuthProps {
  children?: any;
  rest?: any;
  allow: string;
  redirectTo: string;
}
const Auth = ({ children, allow, redirectTo }: AuthProps) => {
  const router = useRouter();
  const navigate = useNavigate();
  const authToken = useReactiveVar<any>(authTokenVar);
  const userObject = useReactiveVar<any>(userObj);
  // const { data: getTokenObj } = useQuery(GET_TOKEN, {
  //   variables: {
  //     refreshToken: localStorage?.getItem?.('refreshAuthToken'),
  //   },
  //   skip: !localStorage?.getItem?.('refreshAuthToken'),
  // });

  // if (getTokenObj?.getToken?.accessToken) {
  //   localStorage?.setItem('authToken', getTokenObj?.getToken?.accessToken);
  // }
  const { data: { user: updatedUserObj } = {}, loading } = useQuery(GET_USER, {
    skip: !authToken && Object.keys(userObject).length > 0,
  });
  const { pathname = "" } = useLocation();

  // console.log('Allow', allow);
  // console.log('Is Token Valid', isJwtValid(authToken));

  console.log("Auth Getting called", pathname);

  if (loading) {
    return <></>;
  } else {
    if (allow === "$unauthenticated") {
      if (!isJwtValid(authToken)) return children;
      else {
        // return <Redirect to={'/'} />;
        console.log("I am gettingh called12");
        return <Navigate replace to={HOME_URL} />;
      }
    } else if (allow === "$authenticated" && isJwtValid(authToken)) {
      if (updatedUserObj?.onboardStep === 1) {
        if (pathname !== SIGN_UP_PERSONAL_INFO_URL) {
          return <Navigate replace to={SIGN_UP_PERSONAL_INFO_URL} />;
        } else {
          return children;
        }
      }
      // if (updatedUserObj?.onboardStep === 2) {
      //   if (pathname !== SIGN_UP_INVESTOR_STATUS_URL) {
      //     return <Navigate replace to={SIGN_UP_INVESTOR_STATUS_URL} />;
      //   } else {
      //     return children;
      //   }
      // }
      // if (updatedUserObj?.onboardStep === 3) {
      //   if (pathname !== DOCUMENT_UPLOAD_URL && pathname !== GO_TO_LEGAL_URL) {
      //     return <Navigate replace to={DOCUMENT_UPLOAD_URL} />;
      //   } else {
      //     return children;
      //   }
      // }
      // if (updatedUserObj?.onboardStep === 2) {
      //   if (pathname !== SIGN_UP_LEGAL_AGGREMENT__URL) {
      //     return <Navigate replace to={SIGN_UP_LEGAL_AGGREMENT__URL} />;
      //   } else {
      //     return children;
      //   }
      // }
      return children;

      // if (
      //   (pathname === "/dashboard" || pathname === "/") &&
      //   !updatedUserObj?.onboarded
      // ) {
      //   if (
      //     updatedUserObj?.name &&
      //     updatedUserObj?.role &&
      //     updatedUserObj?.authRole === ADMIN
      //   ) {
      //     return <Redirect to={"/onboarding/add-sop"} />;
      //   } else {
      //     return <Redirect to={"/onboarding/company-info"} />;
      //   }
      // } else if (
      //   (pathname === "/onboarding/add-sop" ||
      //     pathname === "/onboarding/company-info" ||
      //     pathname === "/onboarding/sop-list") &&
      //   updatedUserObj?.onboarded
      // )
      //   return <Redirect to={"/"} />;
      // return children;
    } else {
      //   return <Redirect to={redirectTo} />;
      console.log("I am gettingh called1234", redirectTo);
      return <Navigate replace to={redirectTo} />;
    }
  }
};

export default Auth;
