import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ChakraProvider, FormControl } from "@chakra-ui/react";
import ComponenetInput from "../../atoms/ComponentInput";
import { useEffect, useState } from "react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import Select from "react-select";

import "./UploadNewPaymentProof.scss";
import { PRODUCT_NAME, STATUS, STATUS_TEXT } from "../../dataHelper";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL, INVESTMENT_URL } from "../../routes";
import translate from "../../../translate";

// export const options: any = [
//     { value: "en", label: "English" },
//     { value: "zh", label: "简体中文" },
//     { value: "zh-hk", label: "繁體中文" }
//   ];

const UploadNewPaymentProof = ({
  open,
  onClose,
  user,
  productObj
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();

  const [options, setOptions] = useState<any>([])
  const [selectedProductObj, setSelectedProductObj] = useState<any>(null)
  useEffect(() => {
    let dropdownList: any = []
    Object.keys(productObj)?.forEach((key) => {
        const name = PRODUCT_NAME[key];
        const product = productObj[key];
        dropdownList.push({
            label:name,
            value:product?.id
        })

    })
    dropdownList.push({
        label:"Cash Deposit",
        value: "cash-deposit"
    })
    setOptions(dropdownList)


  }, [])
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        // overlay: "customOverlay",
        modal: "security-account-modal",
      }}
    >
      {/* <h2>Status</h2> */}
      <h5 className="black-card-head" style={{marginBottom:"30px", fontSize:"16px", fontWeight:"400"}}>Select Product</h5>
     
      <Select
   
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
             backgroundColor:"transparent",
             color:"#fff"
            }),
            option:(baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
              ...baseStyles,
             backgroundColor: isSelected ? "rgb(0, 127, 255)": "rgb(37, 37, 37)",
             color:"#fff"
            }),
          }}
          className="language-input-dropdown"
          value={selectedProductObj}
          options={options}
          onChange={(value: any) => {
            setSelectedProductObj(value)
          }}
        />
        <div style={{marginTop:"40px", display:"flex", alignItems:"center", justifyContent:"center"}}>
          

        <ComponentButton
              title={translate("Upload Proof")}
             
              onClick={() => {
                if(selectedProductObj?.value !== "cash-deposit"){
                    navigate(
                        `${INVESTMENT_URL}?formUrl=https://alt.jotfor.ms/240252269886465?Email=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&productid=${selectedProductObj?.value}&productName=${selectedProductObj?.label}`
                      );
                } else if(selectedProductObj?.value === "cash-deposit"){
                    navigate(
                        `${INVESTMENT_URL}?formUrl=https://alt.jotfor.ms/240252269886465?Email=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&productid=&productName=`
                      );
                }
                    
                
              
                // navigate(SIGN_UP_URL);
              }}
              
            />
        </div>

     
      

    </Modal>
  );
};

export default UploadNewPaymentProof;
