import {
  ChakraProvider,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import DashboardContainer from "../../sub-components/DashbordContainer";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import "./FoStrategy.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  EDIT_FORM_URL,
  FO_STRATEGY_PRODUCT_URL,
  FO_STRATEGY_URL,
  LOGIN_URL,
} from "../../routes";
import {
  ChevronRightIcon,
  InfoOutlineIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import translate from "../../../translate";
import {
  STATUS,
  STATUS_TEXT,
  VIDEO_VERIFICATION_FORM_ID,
  W8BEN_FROM_ID,
  formatNumber,
} from "../../dataHelper";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import SelectUserModal from "../../sub-components/SelectUserModal";
import AFELogin from "../../sub-components/AFELogin";
import ProductItem from "../../sub-components/ProductItem";
import Select from "react-select";
import { GET_FUNDS_INFO } from "../../graphql/investment.query";
import {sortBy} from 'lodash'
import axios from "axios";

const dummyData = [
  {
    id: "flagship1",
    title: translate("Flagship 1"),
    content: translate("Focus on achieving balanced and stable returns"),
    backgroundGradient:
      "linear-gradient(135deg, #392D69 19.05%, #B57BEE 84.88%)",
  },
  {
    id: "flagship2",
    title: translate("Flagship 2"),
    content: translate(
      "Explore a diversified investment portfolio with global ETFs"
    ),
    backgroundGradient:
      "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",

    // commingSoon: true,
  },
  {
    id: "flagship3",
    title: translate("Flagship 3"),
    content: translate(
      "Well-crafted portfolios in both public and private market investments"
    ),
    backgroundGradient:
      "linear-gradient(135deg, #60482B 19.05%, #FF930F 84.88%)",
    // commingSoon: true,
  },
  {
    id: "us-treasury",
    title: translate("DL US Treasuries"),
    content: translate("Safeguarding your funds"),
    backgroundGradient:
      "linear-gradient(315deg, #F44369 16.23%, #392E27 80.35%)",
    // commingSoon: true,
  },
  {
    id: "pe-funds",
    title: translate("PE Funds"),
    content: translate("100+ funds, details to follow"),
    // backgroundGradient:
    // "linear-gradient(135deg, #1F354F 19.05%, #0974F1 84.88%)",
    backgroundGradient: "#252525",
    commingSoon: true,
  },
];
const dummyDataCard = [
  {
    name: "Japan: The Emerging Hotspot for Global Asset Allocation",
    link: "https://www.dlifo.com/blog/japan-the-emerging-hotspot-for-global-asset-allocation",
  },
  {
    name: "What is the risk of the United States defaulting?",
    link: "https://www.dlifo.com/blog/what-is-the-risk-of-the-united-states-defaulting",
  },
  {
    name: "Reconsidering the Perception of Luxury Homes in Hong Kong",
    link: "https://www.dlifo.com/blog/reconsidering-the-perception-of-luxury-homes-in-hong-kong",
  },
  {
    name: "Rare Things are Precious, Just in Time for the Cold Winter",
    link: "https://www.dlifo.com/blog/rare-things-are-precious-just-in-time-for-the-cold-winter",
  },
];

// const investmentData = [
//   {
//     id: "flagship1",
//     name: "Flagship 1",
//     tags: ["MA", "PI"],
//     tagLabel: "Stable Returns",
//     description: "Focus on achieving balanced and stable returns",
//     expectedReturn: "8-12%",
//     minimumInitial: "200,000",
//     minimumInitialAmount: "200000",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//   },
//   {
//     id: "flagship2",
//     name: "Flagship 2",
//     tags: ["MA", "PI"],
//     tagLabel: "Global ETFs",
//     description: "Explore a diversified investment portfolio with global ETFs",
//     expectedReturn: "8%",
//     minimumInitial: "200,000",
//     minimumInitialAmount: "200000",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//   },
//   {
//     id: "flagship3",
//     name: "Flagship 3",
//     tags: ["MA", "PI"],
//     tagLabel: "High-quality Returns",
//     description:
//       "Well-crafted portfolios in both public and private market investments",
//     expectedReturn: "15%",
//     minimumInitial: "200,000",
//     minimumInitialAmount: "200000",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//   },
//   {
//     id: "us-treasury",
//     name: "DL US Treasuries",
//     tags: ["FI", "PI"],
//     tagLabel: "Safeguarding",
//     description: "Safeguarding your funds",
//     expectedReturn: "5%",
//     minimumInitial: "200,000",
//     minimumInitialAmount: "200000",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//   },
//   {
//     id: "dl-special-opportunities",
//     name: "DL Special Opportunities SP",
//     tags: ["PC", "PI"],
//     tagLabel: "Log Only",
//     description:
//       "Allocation on Private Credit Opportunities on High Defensive Business",
//     expectedReturn: "-",
//     minimumInitial: "200,000",
//     minimumInitialAmount: "200000",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//     comingSoon: true,
//     isShow: true,
//   },
//   {
//     id: "disruptive-technologies",
//     name: "Disruptive Technologies Inc",
//     tags: ["EQ", "RI"],
//     tagLabel: "Global Sectors",
//     description: "The fund aims to achieve positive performance",
//     expectedReturn: "3.89%",
//     minimumInitial: "0",
//     minimumInitialAmount: "0",
//     color: "transparent",
//     backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
//     comingSoon: true,
//     isShow: true,
//   },
// ];

const investmentData = [
  {
    id: "dl-fixed-deposit-1",
    name: "DL Fixed Deposit (1-month tenor, 3.94% interest rate)",
    tags: ["FI"],
    // tagLabel: "Stable Returns",
    description: "DL Fixed Deposit with 1-month tenor and interest rate of 3.94%.",
    riskLevel: "",
    minimumInitial: "0",
    minimumInitialAmount: "0",
    color: "transparent",
    backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
  },
  {
    id: "dl-fixed-deposit-2",
    name: "DL Fixed Deposit (3-month tenor, 4.00% interest rate)",
    tags: ["FI"],
    // tagLabel: "Stable Returns",
    description: "DL Fixed Deposit with 3-month tenor and interest rate of 4.00%.",
    riskLevel: "",
    minimumInitial: "0",
    minimumInitialAmount: "0",
    color: "transparent",
    backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
  },
  {
    id: "dl-fixed-deposit-3",
    name: "DL Fixed Deposit (6-month tenor, 3.39% interest rate)",
    tags: ["FI"],
    // tagLabel: "Stable Returns",
    description: "DL Fixed Deposit with 6-month tenor and interest rate of 3.39%.",
    riskLevel: "",
    minimumInitial: "0",
    minimumInitialAmount: "0",
    color: "transparent",
    backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
  }
];

const options = [
  {
    label: "Fixed Income",
    value: "FI",
  },
  {
    label: "Equity",
    value: "EQ",
  },
  {
    label: "Money Markets",
    value: "MM",
  },
  {
    label: "Alternatives",
    value: "AL",
  },
  {
    label: "Multi-Asset",
    value: "MA",
  },
  {
    label: "Convertibles",
    value: "CO"
  }
  // {
  //   label: "RI",
  //   value: "RI",
  // },
];


const optionsObj:any = {
  "Fixed Income": "FI",
  "Equity": "EQ",
  "Money Markets": "MM",
  "Alternatives": "AL",
    "Multi-Asset": "MA",
    "Convertibles": "CO"
}

const minimumInitialOptions = [
  {
    label: "$0",
    value: 0,
  },
  {
    label: "$1000+",
    value: 1000,
  },
  {
    label: "$2000+",
    value: 2000,
  },
  {
    label: "$000+",
    value: 3000,
  },
  {
    label: "$4000+",
    value: 4000,
  },
  {
    label: "$5000+",
    value: 50000,
  },
];

const riskLevelOptions = [
  {
    label: "1",
    value: 1
  },
  {
    label: "2",
    value: 2
  },
  {
    label: "3",
    value: 3
  },
  {
    label: "4",
    value: 4
  },
  {
    label: "5",
    value: 5
  },
  {
    label: "6",
    value: 6
  },
  {
    label: "7",
    value: 7
  }
];

const FoStrategy = ({ afeLoginData, setAfeLoginData }: any) => {
  console.log("afeLoginData", afeLoginData);
  const [searchValue, setSearchValue] = useState("")
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [formStatus, setFormStatus] = useState("");
  const [isSelectUserOpened, setIsSelectUserOpened] = useState(false);
  const [category, setCategory] = useState<any>()
  const [initialOptions, setInitialOptions] = useState<any>()
  const [riskOptions, setRiskOptions] = useState<any>()
  const [fundListData, setFundListData] = useState<any>([])
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
        const user = data?.user;
        if (user?.existingUser) {
          if (user?.existingUserForm) {
            if (user?.approvals?.existingUserForm?.status === STATUS.APPROVED) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            setFormStatus("complete");
          }
        } else if (user?.securityForm && user?.w8BenForm && user?.videoForm) {
          if (
            user?.approvals?.securityForm?.status === STATUS.APPROVED &&
            user?.approvals?.w8BenForm?.status === STATUS.APPROVED &&
            user?.approvals?.videoForm?.status === STATUS.APPROVED
          ) {
            setFormStatus("download");
          } else {
            setFormStatus("waiting");
          }
        } else {
          setFormStatus("complete");
        }
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
    },
    fetchPolicy: "no-cache",
  });

  const [getFundInfo] = useLazyQuery(GET_FUNDS_INFO, {
    onCompleted: (data) => {
  
      console.log("get Funds Info", data)
      if(data?.FundsPagination?.items?.length){
        const fundList = data?.FundsPagination?.items;
        let funds:any = []
        investmentData?.forEach(fixedInvestment => {
          funds.push({
            id: fixedInvestment?.id,
            name: fixedInvestment?.name,
            tags: fixedInvestment?.tags,
            // tagLabel: "Stable Returns",
            description: fixedInvestment?.description,
            // expectedReturn: "8-12%",
            minimumInitial: fixedInvestment?.minimumInitial,
            minimumInitialAmount:  fixedInvestment?.minimumInitialAmount,
            currency: "HKD",
            color: "transparent",
            backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
            riskLevel: fixedInvestment?.riskLevel,
             type: "STATIC"
          })
        })
        fundList?.forEach((fund:any) => {
          if(fund?.isin !== "LU1916293290"){
            const firstLine = fund?.["investment_objective"]?.en.split('.')[0] + '.';
            funds.push(  {
              id: fund?.isin,
              name: fund?.name,
              tags: [optionsObj[fund?.asset_class]],
              // tagLabel: "Stable Returns",
              description: firstLine,
              // expectedReturn: "8-12%",
              minimumInitial: formatNumber(fund?.minimum_initial_subscription_amount),
              minimumInitialAmount: fund?.minimum_initial_subscription_amount,
              currency: fund?.minimums_currency,
              color: "transparent",
              backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
              riskLevel: fund?.risk_reward_indicator,
               type: "ALLFUNDS"
            })
          }
         
        })
        const token: any = localStorage.getItem("authToken");
        // let config = {
        //   method: "get",
        //   maxBodyLength: Infinity,
        //   url: `http://ec2-3-254-186-205.eu-west-1.compute.amazonaws.com/api/afe/fundInfo`,
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authentication: `Bearer ${token}`,
        //   }
        // };
      //   const afeFundList = [
      //     {
      //         "FUND_ID": "1",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "BUSDHKZ",
      //         "FUND_CNAME": "華夏精選美元貨幣基金",
      //         "FUND_ENAME": "ChinaAMC Select USD Money Market Fund",
      //         "CHI_DESC": "短期存款和優質貨幣市場工具",
      //         "ENG_DESC": "Invest in short-term deposits and high quality money market instruments",
      //         "HYPERLINK": "https://www.chinaamc.com.hk/zh-hant/product/chinaamc-select-usd-money-market-fund/",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "B",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.02",
      //         "START_TIME": "2023-12-04 11:00:00",
      //         "END_TIME": "2033-12-04 11:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "",
      //         "LAST_CLOSE_PRICE": "",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "2",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "CHMMFAH",
      //         "FUND_CNAME": "華夏精選貨幣基金",
      //         "FUND_ENAME": "ChinaAMC Select Money Market Fund ",
      //         "CHI_DESC": "華夏精選貨幣基金 - A 類港幣基金單位",
      //         "ENG_DESC": "ChinaAMC Select Money Market Fund - Class A HKD Unit",
      //         "HYPERLINK": "https://www.chinaamc.com.hk/zh-hans/product/chinaamc-select-usd-money-market-fund/https://www.chinaamc.com.hk/zh-hans/product/chinaamc-select-usd-money-market-fund/",
      //         "CURRENCY": "HKD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "10000.0",
      //         "SUB_FEE": "0.02",
      //         "START_TIME": "2023-12-04 11:00:00",
      //         "END_TIME": "2098-12-04 11:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "",
      //         "LAST_CLOSE_PRICE": "",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "8",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "IE00B3ML7W26",
      //         "FUND_CNAME": "FTGF WESTERN ASSET GLOBAL HIGH YIELD \"A\" (AUDHDG) INC",
      //         "FUND_ENAME": "FTGF WESTERN ASSET GLOBAL HIGH YIELD \"A\" (AUDHDG) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "AUD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "3",
      //         "LAST_CLOSE_PRICE": "69.37",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "9",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "IE00BD1DSB51",
      //         "FUND_CNAME": "BAILLIE GIFFORD WORLDWIDE LONG TERM GLOBAL GROWTH  \"A\" (USD) ACC",
      //         "FUND_ENAME": "BAILLIE GIFFORD WORLDWIDE LONG TERM GLOBAL GROWTH  \"A\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "6",
      //         "LAST_CLOSE_PRICE": "19.9082",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "10",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "IE00BZ1LVK33",
      //         "FUND_CNAME": "WELLINGTON GLOBAL CREDIT ESG  \"D\" (USD) ACC",
      //         "FUND_ENAME": "WELLINGTON GLOBAL CREDIT ESG  \"D\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "5000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "3",
      //         "LAST_CLOSE_PRICE": "9.8497",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "3",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0029871042",
      //         "FUND_CNAME": "TEMPLETON GLOBAL BOND \"A\" (USD) INC",
      //         "FUND_ENAME": "TEMPLETON GLOBAL BOND \"A\" (USD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "TEMPLETON GLOBAL BOND \"A\" (USD) INC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "3",
      //         "LAST_CLOSE_PRICE": "10.83",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "11",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0132414144",
      //         "FUND_CNAME": "abrdn SICAV I - SELECT EMERGING MARKETS BOND \"A\" (USD) ACC",
      //         "FUND_ENAME": "abrdn SICAV I - SELECT EMERGING MARKETS BOND \"A\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "3",
      //         "LAST_CLOSE_PRICE": "45.2325",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "12",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0348735423",
      //         "FUND_CNAME": "ALLIANZ HONG KONG EQUITY \"A\" (USD) INC",
      //         "FUND_ENAME": "ALLIANZ HONG KONG EQUITY \"A\" (USD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "ALLIANZ HONG KONG EQUITY \"A\" (USD) INC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "5",
      //         "LAST_CLOSE_PRICE": "193.3489",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "4",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0348832204",
      //         "FUND_CNAME": "ALLIANZ CHINA EQUITY \"P\" (USD) INC",
      //         "FUND_ENAME": "ALLIANZ CHINA EQUITY \"P\" (USD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "ALLIANZ CHINA EQUITY \"P\" (USD) INC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "3000000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "5",
      //         "LAST_CLOSE_PRICE": "1102.58",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "5",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0568621618",
      //         "FUND_CNAME": "AMUNDI FUNDS CASH USD \"A2\" (USD) ACC",
      //         "FUND_ENAME": "AMUNDI FUNDS CASH USD \"A2\" (USD) ACC",
      //         "CHI_DESC": "TEST CN",
      //         "ENG_DESC": "TEST EN",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "2.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "1",
      //         "LAST_CLOSE_PRICE": "120.25",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "13",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0757428866",
      //         "FUND_CNAME": "CT (LUX) I GLOBAL SMALLER COMPANIES \"AE\" (USD) ACC",
      //         "FUND_ENAME": "CT (LUX) I GLOBAL SMALLER COMPANIES \"AE\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "2500.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "6",
      //         "LAST_CLOSE_PRICE": "49.0572",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "14",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0815945547",
      //         "FUND_CNAME": "ALLIANZ HKD INCOME \"AM\" (HKD) INC",
      //         "FUND_ENAME": "ALLIANZ HKD INCOME \"AM\" (HKD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "HKD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "2",
      //         "LAST_CLOSE_PRICE": "9.2521",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "15",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU0820561818",
      //         "FUND_CNAME": "ALLIANZ INCOME AND GROWTH \"AM\" (USD) INC",
      //         "FUND_ENAME": "ALLIANZ INCOME AND GROWTH \"AM\" (USD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "4",
      //         "LAST_CLOSE_PRICE": "8.3974",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "16",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU1400636491",
      //         "FUND_CNAME": "ALLIANZ EUROPE INCOME AND GROWTH \"AMG\" (USDHDG) INC",
      //         "FUND_ENAME": "ALLIANZ EUROPE INCOME AND GROWTH \"AMG\" (USDHDG) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "3",
      //         "LAST_CLOSE_PRICE": "8.919",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "17",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU1548497426",
      //         "FUND_CNAME": "ALLIANZ GLOBAL ARTIFICIAL INTELLIGENCE \"AT\" (USD) ACC",
      //         "FUND_ENAME": "ALLIANZ GLOBAL ARTIFICIAL INTELLIGENCE \"AT\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "ALLIANZ GLOBAL ARTIFICIAL INTELLIGENCE \"AT\" (USD) ACC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "6",
      //         "LAST_CLOSE_PRICE": "28.2883",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "18",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU1916293290",
      //         "FUND_CNAME": "AMF II EURO ALPHA BOND \"C\" (USD) ACC",
      //         "FUND_ENAME": "AMF II EURO ALPHA BOND \"C\" (USD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "4",
      //         "LAST_CLOSE_PRICE": "56.8",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "6",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "LU1985811782",
      //         "FUND_CNAME": "貨幣基金",
      //         "FUND_ENAME": "MFS MERIDIAN FUNDS CONTRARIAN VALUE \"A1\" (USD) ACC",
      //         "CHI_DESC": "貨幣基金",
      //         "ENG_DESC": "FUND",
      //         "HYPERLINK": "",
      //         "CURRENCY": "USD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "6",
      //         "LAST_CLOSE_PRICE": "19.26",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "19",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "SG9999000194",
      //         "FUND_CNAME": "FSSA REGIONAL CHINA \"A\" (SGD) ACC",
      //         "FUND_ENAME": "FSSA REGIONAL CHINA \"A\" (SGD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "FSSA REGIONAL CHINA \"A\" (SGD) ACC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "SGD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "6",
      //         "LAST_CLOSE_PRICE": "4.185",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "20",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "SG9999001382",
      //         "FUND_CNAME": "UNITED SGD \"A\" (SGD) ACC",
      //         "FUND_ENAME": "UNITED SGD \"A\" (SGD) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "UNITED SGD \"A\" (SGD) ACC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "SGD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "2",
      //         "LAST_CLOSE_PRICE": "2.072",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "21",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "SG9999002067",
      //         "FUND_CNAME": "FIRST SENTIER BRIDGE \"A\" (SGD) INC",
      //         "FUND_ENAME": "FIRST SENTIER BRIDGE \"A\" (SGD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "FIRST SENTIER BRIDGE \"A\" (SGD) INC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "SGD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "4",
      //         "LAST_CLOSE_PRICE": "1.5192",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "22",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "SG9999002083",
      //         "FUND_CNAME": "FSSA DIVIDEND ADVANTAGE \"A\" (SGD) INC",
      //         "FUND_ENAME": "FSSA DIVIDEND ADVANTAGE \"A\" (SGD) INC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "FSSA DIVIDEND ADVANTAGE \"A\" (SGD) INC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "SGD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "5",
      //         "LAST_CLOSE_PRICE": "1.7648",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     },
      //     {
      //         "FUND_ID": "7",
      //         "MARKETID": "FUND",
      //         "FUND_CODE": "SG9999017628",
      //         "FUND_CNAME": "UNITED ASIAN HIGH YIELD BOND \"A\" (SGDHDG) ACC",
      //         "FUND_ENAME": "UNITED ASIAN HIGH YIELD BOND \"A\" (SGDHDG) ACC",
      //         "CHI_DESC": "",
      //         "ENG_DESC": "UNITED ASIAN HIGH YIELD BOND \"A\" (SGDHDG) ACC",
      //         "HYPERLINK": "",
      //         "CURRENCY": "SGD",
      //         "FUND_CLASS": "A",
      //         "MIN_AMT": "1000.0",
      //         "SUB_FEE": "0.0",
      //         "START_TIME": "1989-12-01 01:00:00",
      //         "END_TIME": "2098-11-03 07:00:00",
      //         "FUND_STATUS": "Open",
      //         "REINVEST": "F",
      //         "EXCHANGE": "FUND",
      //         "FUND_HOUSE": "ALL_FUND",
      //         "RISK_SCORE": "4",
      //         "LAST_CLOSE_PRICE": ".909",
      //         "STATUS": "OK",
      //         "Msg_ID": "FUND_INFO_RECORD"
      //     }
      // ]

     
      
      //   axios
      //   .request(config)
      //   .then((response) => {

      //     console.log("AFE Response for funds", response?.data?.RESULT?.FUND_INFO)
      //     if(response?.data?.RESULT?.FUND_INFO?.length){
      //       const afeFundList = response?.data?.RESULT?.FUND_INFO
      //       afeFundList?.forEach((fund:any) => {
      //           funds.unshift(  {
      //             id: fund?.["FUND_ID"],
      //             name: fund?.["FUND_ENAME"],
      //             tags: [],
      //             // tagLabel: "Stable Returns",
      //             description: fund?.["ENG_DESC"],
      //             // expectedReturn: "8-12%",
      //             minimumInitial: 0,
      //             minimumInitialAmount: 0,
      //             currency: fund?.["CURRENCY"],
      //             color: "transparent",
      //             backgroundImage: "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
      //             riskLevel: fund?.["RISK_SCORE"],
      //             type: "AFE"
      //           })
              
             
      //       })
      //     }
          
      //   })
        setFundListData([...funds])
       

      }
    },
    onError: (err) => {

    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
    getFundInfo({variables: {
      perPage: 30
    }})
  }, []);


  console.log("Category Select", category)


  const getInvestmentData = () => {
    let list: any = []
    fundListData.forEach((item:any) => {
      if(item?.name?.toLowerCase().includes(searchValue?.toLowerCase()?.trim()) || !searchValue?.trim()){
        let isFound = true
        console.log("category", initialOptions, category, riskOptions)
        if(category?.value && isFound){
          if(item?.tags?.indexOf(category?.value) !== -1){
            isFound = true
          } else {
            isFound = false
          }
          

        }
        if(initialOptions?.value && isFound){
          if(item?.minimumInitialAmount === initialOptions?.value){
            isFound = true
          } else {
            isFound = false
          }
          
        }
        if(riskOptions?.value && isFound){
          if(item?.riskLevel === riskOptions?.value){
            isFound = true
          } else {
            isFound = false
          }
          
        }
        console.log("isFound", isFound, item)
        // if(category?.value && initialOptions){
        //   if(item?.tags?.indexOf(category?.value) !== -1 && item?.minimumInitialAmount === initialOptions?.value){
        //     list.push(item)
        //   }
        // }  else if(category?.value && !initialOptions){
        //   if(item?.tags?.indexOf(category?.value) !== -1){
        //     list.push(item)
        //   }
        // } else if(!category && initialOptions){
        //   if(item?.minimumInitialAmount === initialOptions?.value){
        //     list.push(item)
        //   }
        // }
        if(isFound){
          list.push(item)
        }
        // else {
        //   list.push(item)
        // }
       
      }
    })
    // const sortedList = sortBy(list, ["name"])
    return list
  }

  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          <>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1 1 auto" }}>
                  <div className="title-small-heading">{translate('PRODUCTS')}</div>
                  <h3 className="gradient-heading">{translate('Investments')}</h3>
                  <div style={{ color: "#37454b", fontSize: "14px" }}>
                    {translate("Maximize your portfolio with DL family office strategies, alternative investments, and exclusive fund offerings on our platform.")}
                  </div>
                </div>

                <a
                  href="mailto:assetmanagement@dl-holdings.com"
                  target="__blank"
                >
                  <ComponentButton
                    title={translate("TALK TO AN EXPERT")}
                    whiteBgBtn={true}
                  />
                </a>
              </div>

              <div style={{ marginTop: "30px" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1 1 auto", marginRight: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="title-heading"
                        style={{ marginBottom: "0px" }}
                      >
                        {translate('Filters')}
                      </div>
                      {/* <div
                        className={`cies-tag-2`}
                        style={{ marginLeft: "10px" }}
                      >
                        {translate('Advanced')}
                      </div> */}
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: "1 1 auto" }}>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              color: "#fff",
                              borderColor: "transparent",
                              borderBottom: "1px solid rgb(170, 170, 170)",
                              borderRadius: "0px"
                            }),
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "rgb(0, 127, 255)"
                                : "rgb(37, 37, 37)",
                              color: "#fff",
                            }),
                          }}
                          placeholder={translate("Categories")}
                          className="investment-input-dropdown"
                        value={category}
                        options={options}
                        isClearable={true}
                        onChange={(value: any) => {
                          console.log("New Category", value)
                          setCategory(value);
                        }}
                        />
                      </div>
                      <div style={{ flex: "1 1 auto", marginLeft: "15px" }}>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              color: "#fff",
                              borderColor: "transparent",
                              borderBottom: "1px solid rgb(170, 170, 170)",
                              borderRadius: "0px"
                            }),
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "rgb(0, 127, 255)"
                                : "rgb(37, 37, 37)",
                              color: "#fff",
                            }),
                          }}
                          className="investment-input-dropdown"
                          placeholder={translate("Minimum Initial")}
                        value={initialOptions}
                        options={minimumInitialOptions}
                        isClearable={true}
                        onChange={(value: any) => {
                          setInitialOptions(value);
                        }}
                        />
                      </div>
                      <div style={{ flex: "1 1 auto", marginLeft: "15px" }}>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              color: "#fff",
                              borderColor: "transparent",
                              borderBottom: "1px solid rgb(170, 170, 170)",
                              borderRadius: "0px"
                            }),
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "rgb(0, 127, 255)"
                                : "rgb(37, 37, 37)",
                              color: "#fff",
                            }),
                          }}
                          className="investment-input-dropdown"
                          // value={selectedProductObj}

                          placeholder={translate("Risk Level")}
                          value={riskOptions}
                          options={riskLevelOptions}
                          isClearable={true}
                          onChange={(value: any) => {
                            setRiskOptions(value);
                          }}
                        // onChange={(value: any) => {
                        //   setSelectedProductObj(value);
                        // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "300px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="title-heading"
                        style={{ marginBottom: "0px" }}
                      >
                        {translate('Search Products')}
                      </div>
                    </div>

                    <div style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                      <ChakraProvider>
                        <InputGroup>

                          <Input type="text" placeholder={translate("Enter keywords here...")} style={{ borderRadius: "5px" }} value={searchValue} onChange={(e) => {
                            setSearchValue(e.target.value)

                          }} />
                          <InputRightElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                          </InputRightElement>
                        </InputGroup>
                      </ChakraProvider>

                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  // justifyContent: "space-between",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                {getInvestmentData()?.map((item: any) => {
                
                    return (
                      <ProductItem
                        item={item}
                        onClick={() => {
                          if (!item?.comingSoon) {
                            navigate(`${FO_STRATEGY_PRODUCT_URL}?id=${item?.id}`);
                          }
                        }}
                      />
                    );
                  

                })}
              </div>
            </div>
          </>
        </div>
        {isSelectUserOpened && (
          <SelectUserModal
            open={isSelectUserOpened}
            onClose={() => {
              setIsSelectUserOpened(false);
            }}
            user={user}
          />
        )}
      </div>
    </DashboardContainer>
  );
};

export default FoStrategy;
