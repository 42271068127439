import "./ComponentSidebar.scss";
import logo from "../../../assets/dlifo.png";
import HomeVector from "../../../assets/home-vector.svg";
import FoStrategyVector from "../../../assets/fo-strategy.svg";
import FoServiceVector from "../../../assets/fo-service.svg";
import Alternative from "../../../assets/alternatives.svg";
import Account from "../../../assets/account.svg";
import { useNavigate } from "react-router-dom";

import translate, { changeLanguage } from "../../../translate";
import {
  ACCOUNT_URL,
  ALTERNATIVES_URL,
  FO_SERVICES_URL,
  FO_STRATEGY_URL,
  HOME_URL,
  PORTFOLIO_URL,
  AI_BOT_URL,
  useRouter,
  LOGIN_URL,
  CIES_URL,
  AI_STRATEGIES_URL,
} from "../../routes";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import { STATUS } from "../../dataHelper";
import SecurityFormStatusModal from "../../sub-components/SecurityFormStatusModal";
import SecurityAccountInfoModal from "../../sub-components/SecurityAccountInfoModal";
import { HamburgerIcon } from "@chakra-ui/icons";
import SelectUserModal from "../../sub-components/SelectUserModal";
import ComponentProgressBar from "../ComponentProgressBar";

export const options: any = [
  { value: "en", label: "English" },
  { value: "zh", label: "简体中文" },
  // { value: "zh-hk", label: "繁體中文" },
];

const ComponentSidebar = (props: any) => {
  const { accountModal } = props;
  const navigate = useNavigate();
  const router = useRouter();
  const { pathname } = router;

  const [user, setUser] = useState();
  const [formStatus, setFormStatus] = useState("waiting");
  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = useRef();

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
        const user = data?.user;
        if (user?.existingUser) {
          if (user?.existingUserForm) {
            if (user?.approvals?.existingUserForm?.status === STATUS.APPROVED) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            setFormStatus("complete");
          }
        } else if (user?.paymentProofForm) {
          if (user?.approvals?.paymentProofForm?.status === STATUS.APPROVED) {
            setFormStatus("download");
          } else {
            setFormStatus("waiting");
          }
        } else if (
          user?.securityForm &&
          user?.w8BenForm &&
          user?.bcanForm &&
          user?.investorAssessmentForm &&
          user?.documentUploadForm
        ) {
          if (
            user?.approvals?.securityForm?.status === STATUS.APPROVED &&
            user?.approvals?.w8BenForm?.status === STATUS.APPROVED &&
            user?.approvals?.bcanForm?.status === STATUS.APPROVED &&
            user?.approvals?.investorAssessmentForm?.status ===
              STATUS.APPROVED &&
            user?.approvals?.documentUploadForm?.status === STATUS.APPROVED
          ) {
            setFormStatus("download");
          } else {
            setFormStatus("waiting");
          }
        } else {
          setFormStatus("complete");
        }
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // toast.error(err.graphQLErrors[0]?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 1,
      //   theme: "dark",
      // });
    },

    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const getIsSelectedStyle = useCallback(
    (url: any) => {
      return {
        backgroundColor: pathname === url ? "" : "",
        color: pathname === url ? "#fff" : "",
      };
    },
    [pathname]
  );
  const getIsSelectedClassName = useCallback(
    (url: any) => {
      if (pathname === url) {
        return "sidebar-item active-item";
      } else {
        return "sidebar-item";
      }
    },
    [pathname]
  );

  const getLangValue = () => {
    const lang = localStorage.getItem("user-lang");
    if (lang === "en") {
      return { value: "en", label: "English" };
    } else if (lang === "zh") {
      return { value: "zh", label: "简体中文" };
    } else if (lang === "zh-hk") {
      return { value: "zh-hk", label: "繁體中文" };
    }
  };

  const getLangValueSelect = (type:any) => {
    const lang = localStorage.getItem("user-lang");
    if (lang === type || (!lang && type === "en")) {
      return {
        borderTop:"2px solid #fff",
        borderBottom:"2px solid #fff"
      }
    } 
    // else if (lang === "zh") {
    //   return {
    //     borderTop:"2px solid #fff",
    //     borderBottom:"2px solid #fff"
    //   }
    // } 
    // else if (lang === "zh-hk") {
    //   return {
    //     borderTop:"2px solid #fff",
    //     borderBottom:"2px solid #fff"
    //   }
    // }
    // return {
    //   borderTop:"2px solid #fff",
    //   borderBottom:"2px solid #fff"
    // }
  };
  return (
    <>
      <div
        className="sidebar"
        style={{
          backgroundColor:
            pathname === "/" || pathname === "/home" ? "#000" : "#00173f",
        }}
      >
        <div className="sidebar-container">
          <div className="sidebar-logo">
            <img
              src={logo}
              alt="logo"
              style={{ cursor: "pointer", height: "50px" }}
              onClick={() => {
                navigate(HOME_URL);
              }}
            />
          </div>

          <div
            style={{
              marginTop: "30px",
              marginLeft: "12px",
              borderBottom: "3px solid",
              width: "fit-content",
              paddingBottom: "20px",
              fontSize: "18px",
              marginBottom: "10px",
              color: "#fff",
            }}
          >
            {/* MENU */}
          </div> 
          <div
            className={getIsSelectedClassName(HOME_URL)}
            style={getIsSelectedStyle(HOME_URL)}
            onClick={() => {
              navigate(HOME_URL);
            }}
          >
            {/* <img src={HomeVector} alt="home" /> */}
            <p>{translate("home")}</p>
          </div>
          <div
            className={getIsSelectedClassName(FO_STRATEGY_URL)}
            style={getIsSelectedStyle(FO_STRATEGY_URL)}
            onClick={() => {
              navigate(FO_STRATEGY_URL);
            }}
          >
            {/* <img src={FoStrategyVector} alt="home" /> */}
            {/* <p>{translate("fo_strategy")}</p> */}
            <p>{translate("investments")}</p>
          </div>
          <div
            className={getIsSelectedClassName(FO_SERVICES_URL)}
            style={getIsSelectedStyle(FO_SERVICES_URL)}
            onClick={() => {
              navigate(FO_SERVICES_URL);
            }}
          >
            {/* <img src={FoServiceVector} alt="home" /> */}
            <p>{translate("services")}</p>
          </div>
          {/* <div
          className={getIsSelectedClassName(ALTERNATIVES_URL)}
          style={getIsSelectedStyle(ALTERNATIVES_URL)}
          onClick={() => {
            navigate(ALTERNATIVES_URL);
          }}
        >
          <img src={Alternative} alt="home" />
          <p>{translate("alternatives")}</p>
        </div> */}
          <div
            className={getIsSelectedClassName(CIES_URL)}
            style={getIsSelectedStyle(CIES_URL)}
            onClick={() => {
              navigate(CIES_URL);
            }}
          >
            {/* <img src={Alternative} alt="home" /> */}
            <p>{translate("cies")}</p>
          </div>
          {/* <div
            className={getIsSelectedClassName(AI_STRATEGIES_URL)}
            style={getIsSelectedStyle(AI_STRATEGIES_URL)}
            onClick={() => {
              navigate(AI_STRATEGIES_URL);
            }}
          >

            <p>AI Strategies</p>
          </div> */}
          <div
            className={getIsSelectedClassName(ACCOUNT_URL)}
            style={getIsSelectedStyle(ACCOUNT_URL)}
            onClick={() => {
              navigate(ACCOUNT_URL);
            }}
          >
            {/* <img src={Account} alt="home" /> */}
            <p>{translate("account")}</p>
          </div>
        </div>
        {/* <ChakraProvider> */}
        <div style={{ padding: "44px 40px", display:"flex", alignItems:"center" }}>
        <div style={{...getLangValueSelect("en"), height:"30px", display:"flex", alignItems:"center", color:"#fff", cursor:"pointer"}} onClick={() => {
             localStorage.setItem("user-lang", "en");
             changeLanguage("en");
             window.location.reload();
        }}>EN</div>
          <div style={{...getLangValueSelect("zh"), marginLeft:"15px", height:"30px", display:"flex", alignItems:"center", color:"#fff", cursor:"pointer"}} onClick={() => {
             localStorage.setItem("user-lang", "zh");
             changeLanguage("zh");
             window.location.reload();
        }}>CN</div>
          
        </div>
        {/* <ComponentProgressBar defaultValue={1} /> */}
        {isStatusModalOpened && (
          <SecurityFormStatusModal
            open={isStatusModalOpened}
            onClose={() => {
              setIsStatusModalOpened(false);
            }}
            user={user}
          />
        )}
        {isInfoModalOpened && (
          <SelectUserModal
            open={isInfoModalOpened}
            onClose={() => {
              setIsInfoModalOpened(false);
            }}
            user={user}
          />
        )}

        {/* </ChakraProvider> */}
      </div>
      <div className="mobile-header">
        <div className="mobile-logo-container">
          <img
            src={logo}
            alt="logo"
            style={{ cursor: "pointer", height: "40px" }}
            onClick={() => {
              navigate(HOME_URL);
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>

        
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "transparent",
                color: "#fff",
              }),
              option: (
                baseStyles,
                { data, isDisabled, isFocused, isSelected }
              ) => ({
                ...baseStyles,
                backgroundColor: isSelected
                  ? "rgb(0, 127, 255)"
                  : "rgb(37, 37, 37)",
                color: "#fff",
              }),
            }}
            className="language-input-dropdown"
            value={getLangValue() || { label: "English", value: "en" }}
            options={options}
            onChange={(value: any) => {
              console.log("language Value", value);
              localStorage.setItem("user-lang", value?.value);
              changeLanguage(value?.value);
              window.location.reload();
            }}
          />

          <div className="mobile-menu-container" ref={btnRef} onClick={onOpen}>
            <HamburgerIcon />{" "}
          </div>
        </div>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
          isFullHeight
          colorScheme="black"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                padding: "10px",
                justifyContent: "end",
                paddingRight: "10px",
              }}
            />
            <DrawerBody style={{ height: "100%", backgroundColor: "#252525" }}>
              <div style={{ paddingTop: "30px", paddingLeft:"10px" }}>
                <div
                  className={getIsSelectedClassName(HOME_URL)}
                  style={getIsSelectedStyle(HOME_URL)}
                  onClick={() => {
                    navigate(HOME_URL);
                  }}
                >
                
                  <p>{translate("home")}</p>
                </div>
                <div
                  className={getIsSelectedClassName(FO_STRATEGY_URL)}
                  style={getIsSelectedStyle(FO_STRATEGY_URL)}
                  onClick={() => {
                    navigate(FO_STRATEGY_URL);
                  }}
                >
      
      <p>{translate("investments")}</p>
                </div>
                <div
                  className={getIsSelectedClassName(FO_SERVICES_URL)}
                  style={getIsSelectedStyle(FO_SERVICES_URL)}
                  onClick={() => {
                    navigate(FO_SERVICES_URL);
                  }}
                >
          
                  <p>{translate("services")}</p>
                </div>

                <div
                  className={getIsSelectedClassName(CIES_URL)}
                  style={getIsSelectedStyle(CIES_URL)}
                  onClick={() => {
                    navigate(CIES_URL);
                  }}
                >
          
                  <p>{translate("cies")}</p>
                </div>
                {/* <div
                  className={getIsSelectedClassName(ALTERNATIVES_URL)}
                  style={getIsSelectedStyle(ALTERNATIVES_URL)}
                  onClick={() => {
                    navigate(ALTERNATIVES_URL);
                  }}
                >
                  <img src={Alternative} alt="home" />
                  <p>{translate("alternatives")}</p>
                </div> */}
                <div
                  className={getIsSelectedClassName(ACCOUNT_URL)}
                  style={getIsSelectedStyle(ACCOUNT_URL)}
                  onClick={() => {
                    navigate(ACCOUNT_URL);
                  }}
                >
                  <p>{translate("account")}</p>
                </div>
              </div>
            </DrawerBody>
            {/* <DrawerHeader>Create your account</DrawerHeader>
          <DrawerBody>
            <Input placeholder='Type here...' />
          </DrawerBody> */}
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};

export default ComponentSidebar;
