import queryString from "query-string";
import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const HOME_URL = "/home";
export const ACCOUNT_URL = "/account";
export const LOGIN_URL = "/login";
export const SIGN_UP_URL = "/signup";
export const SECURITY_ACCOUNT_URL = "/security-account";
export const SIGN_UP_PHONE_URL = "/signup/phone";
export const SIGN_UP_PHONE_VERIFICATION_URL = "/signup/verification/:email?";
export const SIGN_IN_VERIFICATION_URL = "/login/verification/:email?";
export const SIGN_UP_PERSONAL_INFO_URL = "/signup/personal-info";
export const SIGN_UP_INVESTOR_STATUS_URL = "/signup/investor-status";
export const SIGN_UP_LEGAL_AGGREMENT__URL = "/signup/legal-aggrement";
export const DOCUMENT_UPLOAD_URL = "/signup/document-upload"
export const GO_TO_LEGAL_URL = "/signup/go-to-legal"
export const FO_STRATEGY_URL = "/fo-strategy";
export const FO_SERVICES_URL = "/fo-services";
export const FO_STRATEGY_PRODUCT_URL = "/fo-strategy/product";
export const FO_SERVICES_PRODUCT_URL = "/fo-services/planning";
export const FO_SERVICES_TRUST_PLANNING = "/fo-services/trust-planning"
export const FO_SERVICES_PRODUCT_COUNTRY_URL = "/fo-services/planning/country";
export const ALTERNATIVES_URL = "/alternatives";
export const ALTERNATIVES_PRODUCT_URL = "/alternatives/product";
export const AI_BOT_URL = "/dlgpt"
export const PORTFOLIO_URL = "/portfolio";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const VIDEO_VERIFICATION_URL = "/video-verification"
export const LEGAL_GPT_URL = "/legal-gpt"
export const INVESTMENT_GPT_URL = "/investment-gpt"
export const CORPORATE_FINANCE_GPT_URL = "/corporate-finance-gpt"
export const INVESTMENT_URL = "/investment"
export const EDIT_FORM_URL = "/edit"
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_FILE_UPLOAD = "/admin/upload"
export const PRE_LANDING_PAGE = "/pre-landing"
export const CIES_URL = "/cies"
export const AI_STRATEGIES_URL = "/ai-strategies"
export const SECURITY_DISCLAIMER_URL = "/security-disclaimer"
export const VIDEO_FORM_TEST = "/video-form-test"



export const RESET_PASSWORD_URL = "/reset-password";

export const gradientColor = "linear-gradient(135deg, #0076e3 0%, #000 56%)"

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history: any = useNavigate();
  //   const match = useMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },

      //   match,
      location,
      history,
    };
  }, [params, location, history]);
};

export function formatCompactNumber(number: any) {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1) + "T";
  }
}

//   export const breadcrumbObj: any {

//   }
