import { gql } from "@apollo/client";

// Define mutation
export const SIGN_UP_MUTATION = gql`
  mutation SignUpMutaton($email: String!) {
    SignUp(email: $email) {
      succeed
    }
  }
`;

export const VERIFY_SIGN_UP_OTP_MUTATION = gql`
  mutation VerifySignUpOtpMutation($otp: String!, $email: String!) {
    VerifySignUpOTP(otp: $otp, email: $email) {
      accessToken
    }
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation SignInWithOTP($email: String!) {
    SignInWithOTP(email: $email) {
      succeed
    }
  }
`;
export const VERIFY_SIGN_IN_OTP_MUTATION = gql`
  mutation VerifySignInOTP($email: String!, $otp: String!) {
    VerifySignInOTP(email: $email, otp: $otp) {
      accessToken
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($input: UserUpdateInput) {
    UpdateUser(input: $input) {
      email
      customerId
      firstName
      middleName
      lastName
      qualifiedPurchaser
      qualifiedClient
      accreditedInvestor
      phone
      onboardStep
      profilePic
      loginOtp {
        otp
        expiresIn
      }
      account {
        verification {
          verified
        }
        verificationCode
      }
      _id
      createdAt
      updatedAt
      eid
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    Logout {
      succeed
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    ResetPassword(email: $email) {
      succeed
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation NewPassword($token: String!, $newPassword: String!) {
    NewPassword(token: $token, newPassword: $newPassword) {
      accessToken
    }
  }
`;

 export const GET_SIGNED_URL = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;

export const ADD_STATEMENT_MUTATION = gql`
mutation AddUserStatement($filepath: String!) {
  AddUserStatement(filepath: $filepath) {
    succeed
  }
}
`

export const CANCEL_FORM_MUTATION = gql`
mutation CancelUserForms($userId: String!) {
  CancelUserForms(userId: $userId) {
    email
    clientId
    customerId
    firstName
    middleName
    lastName
    qualifiedPurchaser
    qualifiedClient
    accreditedInvestor
    phone
    profilePic
    onboardStep
    currentFormUrl
    _id
    createdAt
    updatedAt
    eid
  }
}

`

