import { Grid, Progress, ChakraProvider } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import "../FoStrategyProduct/FoStrategyProduct.scss";
import { ArrowBackIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT_URL,
  FO_STRATEGY_URL,
  SECURITY_ACCOUNT_URL,
  useRouter,
} from "../../routes";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import ArrowUp from "../../../assets/arrow-up-right-square.svg";
import Video from "../../../assets/video-background.svg";
import PlayBtn from "../../../assets/play-btn.svg";
import GraphProgress from "../../../assets/graph-progress.svg";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import { SECURITY_ACCOUNT_OPENING_ID, alternativesObj, foStrategyObj } from "../../dataHelper";
import translate from "../../../translate";



const AlternativesProduct = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);

  const router = useRouter();
  const { query } = router;

  const productId: any = query?.id;

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);
  return (
    <DashboardContainer>
      <div className="fo-container height-full">
        <div className="fo-header-product">
          <div className="fo-heading-product">
            <div style={{ display: "flex", alignItems: "center", gap: "28px" }}>
              <ArrowBackIcon
                w={28}
                h={28}
                style={{ cursor: "pointer", color: "#ffffff" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <h2>{alternativesObj?.[productId]?.name}</h2>
            </div>
            {/* <p>
              That determine your eligibility for private investments and
              expanded pricing options
            </p> */}
          </div>
          <div className="component-btn-row">
            <ComponentButton
              title={translate("Invest")}
              whiteBgBtn={true}
              onClick={() => {
                navigate(
                  `${SECURITY_ACCOUNT_URL}?formUrl=https://alt.jotfor.ms/${SECURITY_ACCOUNT_OPENING_ID}?Email=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}`
                );
              }}
            />
            <ComponentButton
              title={translate("Review your account")}
              blackBgBtn={true}
              onClick={() => {
                navigate(ACCOUNT_URL);
              }}
            />
          </div>
        </div>
        <div className="fo-cards">
          <Grid templateColumns="repeat(2, 1fr)" gap={20}>
            <div>
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Investment Strategy")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.investmentStrategy}
                    </p>
                  </div>
                }
              />
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Core Advantages")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.coreAdvantages}
                    </p>
                  </div>
                }
              />
              {alternativesObj?.[productId]?.performance &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Performance")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.performance}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.geographicFocus &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Geographic Focus")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.geographicFocus}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.investment &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Investment")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.investment}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.serviceProvider &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Service Provider")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.serviceProvider}
                    </p>
                  </div>
                }
              />}
               
               

              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">Allocation</h5>

                    <p className="black-card-sub-head">
                      Asset Class allocation
                    </p>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      {foStrategyObj?.[productId]?.allocation?.asset?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item}</p>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <p
                      className="black-card-sub-head"
                      style={{ marginTop: "40px" }}
                    >
                      Geographical allocation
                    </p>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      <div>
                        <p style={{ visibility: "hidden" }}>
                          {" "}
                          Asia Pac ex Japan
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Acutal cost (%) to the <br /> portfolio
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Performance <br /> (Ytd)
                        </span>
                      </div>
                      {foStrategyObj?.[productId]?.allocation?.geography?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item?.name}</p>
                              <span>{item?.actualCost}</span>
                              <span>{item?.performance}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                   
                  </div>
                }
              /> */}
            
            </div>
            <div style={{width:"100%"}}>
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Key Term and Structure")}</h5>
                    <p className="black-card-sub-head">
                      {translate("Key Term")}
                    </p>
                    <div className="card-data-holding">
                      {alternativesObj?.[productId]?.keyTerm?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item?.key}</p>
                              <p style={{textAlign:"right"}}>{item?.value}</p>
                              {/* <span>9.3%</span> */}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                }
              />
              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">
                      Fees and expected return
                    </h5>
                    
                    <div className="card-data">
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.expectedReturn
                          }
                        </h4>
                        <p>Expected return</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.subscriptionFee
                          }
                        </h4>
                        <p>Subscription Fee</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.managementFee
                          }
                        </h4>
                        <p>Management Fee</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.performanceFee
                          }
                        </h4>
                        <p>Performance Fee</p>
                      </div>
                    </div>
                  </div>
                }
              /> */}

              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">Comparison</h5>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      <div>
                        <p style={{ visibility: "hidden" }}>
                          {" "}
                          Asia Pac ex Japan
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Return
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Sharp Ratio
                        </span>
                      </div>

                      <div>
                        <p>Flagship III</p>
                        
                      </div>
                      <div>
                        <p>BlackRock Global Multi-Asset Income Fund</p>
                      
                      </div>
                      <div>
                        <p>JPMorgan Multi Income </p>
                     
                      </div>
                      <div>
                        <p>Franklin Income Fund </p>
                     
                      </div>
                    </div>
                  </div>
                }
              /> */}

              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Data room")}</h5>
                    <div className="card-data-holding">
                      {alternativesObj?.[productId]?.details?.map((item: any) => {
                        return (
                          <a
                            href={item?.link}
                            target="_blank"
                          >
                            <div className="data-room">
                              <p>{item?.name}</p>
                              <img src={ArrowUp} />
                            </div>
                          </a>
                        );
                      })}

                      {/* <div className="data-room">
                        <p>Prospectus</p>
                        <img src={ArrowUp} />
                      </div>
                      <div className="data-room">
                        <p>iDirect private market fund website</p>
                        <img src={ArrowUp} />
                      </div> */}
                    </div>
                  </div>
                }
              />
            </div>
          </Grid>
        </div>

        <div className="fo-cards-mobile">
       
            <div>
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Investment Strategy")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.investmentStrategy}
                    </p>
                  </div>
                }
              />
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Core Advantages")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.coreAdvantages}
                    </p>
                  </div>
                }
              />
              {alternativesObj?.[productId]?.performance &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Performance")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.performance}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.geographicFocus &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Geographic Focus")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.geographicFocus}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.investment &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Investment")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.investment}
                    </p>
                  </div>
                }
              />}

{alternativesObj?.[productId]?.serviceProvider &&  <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Service Provider")}</h5>
                    <p style={{ marginTop: "10px", color: "#fff" }}>
                      {alternativesObj?.[productId]?.serviceProvider}
                    </p>
                  </div>
                }
              />}
               
               

              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">Allocation</h5>

                    <p className="black-card-sub-head">
                      Asset Class allocation
                    </p>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      {foStrategyObj?.[productId]?.allocation?.asset?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item}</p>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <p
                      className="black-card-sub-head"
                      style={{ marginTop: "40px" }}
                    >
                      Geographical allocation
                    </p>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      <div>
                        <p style={{ visibility: "hidden" }}>
                          {" "}
                          Asia Pac ex Japan
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Acutal cost (%) to the <br /> portfolio
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Performance <br /> (Ytd)
                        </span>
                      </div>
                      {foStrategyObj?.[productId]?.allocation?.geography?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item?.name}</p>
                              <span>{item?.actualCost}</span>
                              <span>{item?.performance}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                   
                  </div>
                }
              /> */}
            
            </div>
            <div style={{width:"100%"}}>
              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Key Term and Structure")}</h5>
                    <p className="black-card-sub-head">
                      {translate("Key Term")}
                    </p>
                    <div className="card-data-holding">
                      {alternativesObj?.[productId]?.keyTerm?.map(
                        (item: any) => {
                          return (
                            <div>
                              <p>{item?.key}</p>
                              <p style={{textAlign:"right"}}>{item?.value}</p>
                              {/* <span>9.3%</span> */}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                }
              />
              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">
                      Fees and expected return
                    </h5>
                    
                    <div className="card-data">
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.expectedReturn
                          }
                        </h4>
                        <p>Expected return</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.subscriptionFee
                          }
                        </h4>
                        <p>Subscription Fee</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.managementFee
                          }
                        </h4>
                        <p>Management Fee</p>
                      </div>
                      <div>
                        <h4>
                          {
                            foStrategyObj?.[productId]?.feesAndExpectedReturn
                              ?.fees?.performanceFee
                          }
                        </h4>
                        <p>Performance Fee</p>
                      </div>
                    </div>
                  </div>
                }
              /> */}

              {/* <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">Comparison</h5>

                    <div
                      className="card-data-holding"
                      style={{ marginTop: "10px" }}
                    >
                      <div>
                        <p style={{ visibility: "hidden" }}>
                          {" "}
                          Asia Pac ex Japan
                        </p>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Return
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#ccc",
                            textAlign: "center",
                          }}
                        >
                          Sharp Ratio
                        </span>
                      </div>

                      <div>
                        <p>Flagship III</p>
                        
                      </div>
                      <div>
                        <p>BlackRock Global Multi-Asset Income Fund</p>
                      
                      </div>
                      <div>
                        <p>JPMorgan Multi Income </p>
                     
                      </div>
                      <div>
                        <p>Franklin Income Fund </p>
                     
                      </div>
                    </div>
                  </div>
                }
              /> */}

              <ComponentBlackCard
                children={
                  <div>
                    <h5 className="black-card-head">{translate("Data room")}</h5>
                    <div className="card-data-holding">
                      {alternativesObj?.[productId]?.details?.map((item: any) => {
                        return (
                          <a
                            href={item?.link}
                            target="_blank"
                          >
                            <div className="data-room">
                              <p>{item?.name}</p>
                              <img src={ArrowUp} />
                            </div>
                          </a>
                        );
                      })}

                      {/* <div className="data-room">
                        <p>Prospectus</p>
                        <img src={ArrowUp} />
                      </div>
                      <div className="data-room">
                        <p>iDirect private market fund website</p>
                        <img src={ArrowUp} />
                      </div> */}
                    </div>
                  </div>
                }
              />
            </div>
       
        </div>
      </div>
    </DashboardContainer>
  );
};

export default AlternativesProduct;
