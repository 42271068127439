import { ReactNode, useEffect, useRef, useState } from 'react'
import { Button, ChakraProvider, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup } from '@chakra-ui/react'
import logo from "../../../assets/logo.svg";
import { useNavigate } from 'react-router-dom';
import { HOME_URL } from '../../routes';
import logoutIcon from '../../../assets/sign-out-fill.svg'

import './AdminUpload.scss'
import { useMutation } from '@apollo/client';
import { ADD_STATEMENT_MUTATION, GET_SIGNED_URL } from '../../graphql/user.mutation';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



type FileUploadProps = {
    register?: any
    accept?: string
    multiple?: boolean
    children?: ReactNode,
    setIsUploading: any,
    isUploading: any
}

const FileUpload = (props: FileUploadProps) => {
    const { register, accept, multiple, children, isUploading, setIsUploading } = props
    let inputRef:any = useRef<HTMLInputElement | null>(null)
    //   const { ref, ...rest } = register as {ref: (instance: HTMLInputElement | null) => void}
    const [getSignedUrl] = useMutation(GET_SIGNED_URL);
    const [addStatement] = useMutation(ADD_STATEMENT_MUTATION)
    const handleClick = () => inputRef.current?.click()

    // const imageCallback = async (files:any) => {
    //     console.log('Enter Image Callback', files);
    //     if (files && files.length) {
    //     //   setIsUploading(true);
    //     //   setIsloading(true);
    //       let file = files[0];
    //       var fr = new FileReader();
    //       fr.onload = function () {
    //         // console.log('Image index', index);
           
    
    //         // cardData.content[0].image = [fr.result];
            
    
    //         // document.getElementById(outImage).src = fr.result;
    //       };
    //       fr.readAsDataURL(files[0]);
    //       const { name: filename, type } = file;
    //       const response = await getSignedUrl({
    //         variables: {
    //           getSignedUrlInput: [{ filename: filename, mimetype: type }],
    //         },
    //       });
    //       if (response) {
    //         if (
    //           response &&
    //           response.data &&
    //           response.data.getSignedUrl &&
    //           response.data.getSignedUrl.length
    //         ) {
    //           const data = response.data.getSignedUrl[0];
    //           const signedRequestUrl = data.signedRequestUrl;
    //           const imageUrl = data.url;
    //           const reader = new FileReader();
    //           reader.readAsDataURL(file);
    //           await (reader.onload = (event: any) => {
    //             let binaryString = event?.target!.result;
    //             let binary = atob((binaryString as string)!.split(',')[1]);
    //             let array = [];
    //             for (var j = 0; j < binary.length; j++) {
    //               array.push(binary.charCodeAt(j));
    //             }
    //             let blobData = new Blob([new Uint8Array(array)], {
    //               type: file?.type,
    //             });
    
    //             axios
    //               .put(signedRequestUrl, blobData)
    //               .then(() => {
                   
    
                  
    //                 // setIsUploading(false);
    //                 // setIsloading(false);
    //                 // noti.success({
    //                 //   message: 'Image Uploaded Successfully',
    //                 // });
    //                 alert("Files Uploaded Successfully!")
    //               })
    //               .catch(function (error: any) {
    //                 console.error(error);
    //               });
    //           });
    //         }
    //       }
    //     }
    //   };


      const uploadFiles = async (files:any) => {
        // setUploading(true);

     setIsUploading(true)
        try {
            let fileNames = []
            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                fileNames.push({
                    filename: file?.name,
                    mimetype: file?.type
                })
            }
        //   const fileNames = files.map((file:any) => {
        //     return {
        //         filename: file?.name,
        //         mimetype: file?.type
        //     }
        //   });

          console.log("File Names", fileNames)
          const { data } = await getSignedUrl({ variables: { getSignedUrlInput: fileNames } });
          const  signedUrls = data.getSignedUrl;
          console.log("Responses", data)
          const uploadPromises = signedUrls.map(async (signedData:any, index:any) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[index]);
             (reader.onload = async (event: any) => {
              let binaryString = event?.target!.result;
              let binary = atob((binaryString as string)!.split(',')[1]);
              let array = [];
              for (var j = 0; j < binary.length; j++) {
                array.push(binary.charCodeAt(j));
              }
              let blobData = new Blob([new Uint8Array(array)], {
                type: files[index]?.type,
              });

               await fetch(signedData?.signedRequestUrl, {
              method: 'PUT',
              body:blobData
            });

            console.log("Signed Data", signedData)
            const {data} = await addStatement({
                variables:{
                    filepath: signedData?.url
                }
            })

            console.log("Add Statement Added Successfully")
            setIsUploading(false)
            toast.success("File Uploaded Successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 1,
                theme: "dark",
              });
  
            //   await axios
            //     .put(signedData?.signedRequestUrl, blobData)
            //     .then(() => {
              
            //     console.log("wefdwef")
                
            //     })
            //     .catch(function (error: any) {
            //       console.error(error);
            //     });
            });
            // await fetch(signedData?.signedRequestUrl, {
            //   method: 'PUT',
            //   body: files[index]
            // });
          });
          await Promise.all(uploadPromises);
        //   alert('Files uploaded successfully!');
        //   setFiles([]);
        } catch (error) {
          console.error('Error uploading files:', error);
          setIsUploading(false)
          toast.error("Error in file upload", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 1,
            theme: "dark",
          });
        } finally {
        //   setUploading(false);
        setIsUploading(false)
        }
      };
    

    // const fileChange = (e: any) => {
    //     console.log('File Change gettin called');
    //     imageCallback(inputRef.files);
    //   };

    return (
        <InputGroup onClick={handleClick} style={{ width: "fit-content" }}>
            <input
                type={'file'}
                multiple={multiple || false}
                hidden
                accept={accept}
                //   {...rest}
                ref={(e) => {
                    // ref(e)
                    inputRef.current = e
                }}
                onChange={(e) => {
                    console.log('I ma getting called');
                    uploadFiles(inputRef?.current?.files);
                  }}
            />
            <>
                {children}
            </>
        </InputGroup>
    )
}

type FormValues = {
    file_: FileList
}

const AdminUpload = () => {
    const ref = useRef()
    const navigate = useNavigate()
   const [isUploading, setIsUploading] = useState(false)

    const validateFiles = (value: FileList) => {
        if (value.length < 1) {
            return 'Files is required'
        }
        for (const file of Array.from(value)) {
            const fsMb = file.size / (1024 * 1024)
            const MAX_FILE_SIZE = 10
            if (fsMb > MAX_FILE_SIZE) {
                return 'Max file size 10mb'
            }
        }
        return true
    }

    useEffect(() => {
        if (document.getElementById("eself-open-button")) {
            const element: any = document.getElementById("eself-open-button")
            element.style.display = "none"
        }

    }, [])

    return (

        <ChakraProvider>
            <div style={{height:"100vh"}}>
                <div className='admin-upload-logo-container'>
                    <div style={{flex:"1 1 auto"}}>
                    <img
                        src={logo}
                        alt="logo"
                        style={{ cursor: "pointer", height: "40px" }}
                        onClick={() => {
                            navigate(HOME_URL);
                        }}
                    />
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{marginRight:"20px", fontWeight:"500"}}>
                        Welcome Admin! 
                        </span>
                        <img src={logoutIcon} alt="logout" style={{width:"20px", cursor:"pointer"}}/>
                      
                    </div>
                  
                </div>
                <div style={{ display: "flex", width: "100%", height: "calc(100% - 80px)", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {/* <Input
                        placeholder="Select Date and Time"
                        size="md"
                        type="datetime-local"
                        style={{ width: "fit-content", marginBottom: "20px" }}
                    /> */}
                    <div style={{marginBottom:"20px", fontSize:"20px"}}>Select File to upload</div>
                    <FileUpload
                       isUploading={isUploading}
                       setIsUploading={setIsUploading}
                        multiple={false}
                    // register={ref}
                    >
                        <Button isLoading={isUploading}>
                            Upload File
                        </Button>
                    </FileUpload>
                </div>
            </div>


<ToastContainer/>
        </ChakraProvider>







    )
}

export default AdminUpload