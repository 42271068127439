import {
  ChakraProvider,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  background,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import ReactApexChart from "react-apexcharts";
import "./Portfolio.scss";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import {
  EDIT_FORM_URL,
  FO_STRATEGY_URL,
  formatCompactNumber,
} from "../../routes";
import { useNavigate } from "react-router-dom";
import translate from "../../../translate";
import { useLazyQuery } from "@apollo/client";
import { GET_STATEMENTS, GET_USER } from "../../graphql/user.query";
import { useEffect, useState } from "react";
import {
  GET_INVESTMENT_FORM,
  GET_STRATEGY_PRODUCT,
} from "../../graphql/investment.query";
import { groupBy } from "lodash";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { STATUS, STATUS_TEXT } from "../../dataHelper";
import moment from "moment";
import { GET_HOLDING_NAV_QUERY } from "../../graphql/profile.query";

const Portfolio = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [investmentFormObj, setInvestmentFormObj] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);

  const [formStatus, setFormStatus] = useState("empty");

  const [currentAssetValue, setCurrentAssetValue] = useState(null)
  const [portfolioSummary, setPortfolioSummary] = useState([]);
  const [lineChartData, setLineChartData] = useState<any>({
    series: [
      {
        name: translate("Net Asset Value ($)"),
        data: [],
      },
    ],
    xaxis: {
      categories: [
      ],
      title: {
        text: translate("Net Asset Value ($)"),
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          return formatCompactNumber(val);
        },
      },
    },

    options: {
      tooltip: {
        fillSeriesColor: false,
        theme: false,
        style: {
          color: "#000",
          background: "#000",
        },
      },
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
          type: "x",
        },
      },
      dataLabels: {
        enabled: true,
      },

      stroke: {
        curve: "straight",
      },
      title: {
        text: "Product Trends by Month",
        align: "center",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
  });
  const [pieChartData, setPieChartData] = useState<any>({
    series: [],
    labels: [
      

    ],

    options: {
      legend: {
        show: true,
        position: "bottom",
        floating: true,
        verticalAlign: "bottom",
        align: "center",
      },
      chart: {
        type: "donut",
        height: 450,
      },

      dataLabels: {
        enabled: true,
      },

     
    },
  })

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
        getStatments({
          variables: {
            filter: {
              customerId: data?.user?.customerId
            },
            sort: "RECORDDATE_ASC"
          }
        })
      }
    },
    fetchPolicy: "no-cache",
  });

 

  const [getStrategyProduct] = useLazyQuery(GET_STRATEGY_PRODUCT, {
    onCompleted: (data) => {
      if (data?.ProductPagination?.items?.length) {
  

        getProductInvestmentForms(data?.ProductPagination?.items);

      }
    },
    fetchPolicy: "no-cache",
  });

  const [getStatments] = useLazyQuery(GET_STATEMENTS, {
    onCompleted: (data) => {
      console.log("get Statements data", data)
      if(data?.statementPagination?.items?.length){
        const statements = data?.statementPagination?.items
        let length = statements?.length
        let netAssetValue = statements?.[length -1]?.netAssetValue;
        netAssetValue = parseFloat(netAssetValue).toFixed(2)
        setCurrentAssetValue(netAssetValue)
        const groupedData = groupBy(statements?.[length -1]?.portfolio, "productCategory")
        let lineChartItems:any = []
        let dates:any = []
        statements?.forEach((statement:any) => {
          const netAssetValue = parseFloat(statement?.netAssetValue).toFixed(2)
          lineChartItems.push(netAssetValue)
          const dateLabel = moment(statement?.recordDate).format("MMM Do YY"); 
          dates.push(dateLabel)
        })

        lineChartData.series[0].data = [...lineChartItems]
        lineChartData.xaxis.categories = [...dates]
        setLineChartData({...lineChartData})
        let labels:any = []
        let series:any = []
        Object.keys(groupedData)?.forEach(key => {
          labels.push(key);
          let total = 0;
          const categoryData = groupedData[key];
          categoryData?.forEach(item => {
            total += Number(item?.actualCostPercent)
          })
          series.push(total*100)
        })
        pieChartData.series = [...series];
        pieChartData.labels = [...labels];
        setPieChartData(pieChartData)

        const lastStatement = statements?.[length -1]
        let records:any = []
        lastStatement?.portfolio?.forEach((portfolioItem:any) => {
          records.push({
            'Product Code': portfolioItem?.productCode,
            'Product Name': portfolioItem?.["Product Name"],
            Currency: portfolioItem?.currency,
            'Closing Price at 18 Jan 24': portfolioItem?.closingPrice,
            'Market Value at 18 Jan 24':portfolioItem?.marketValue,
            'Unrealised Profit/(Loss)': portfolioItem?.profitLoss,
          })

        })
        setPortfolioSummary(records)
      
      }


    },
    fetchPolicy: "no-cache",
  })

  const getProductInvestmentForms = (productList: any) => {
    getInvestmentFormStatus({
      variables: {},
    }).then((response: any) => {
      const data = response?.data;
    
      if (data?.InvestmentFormPagination?.items?.length) {
        let productListWithStatus: any = [];
        const groupedProduct = groupBy(
          data?.InvestmentFormPagination?.items,
          "productId"
        );
        console.log("ProductId", groupedProduct);

        Object.keys(groupedProduct)?.forEach((itemKey: any) => {
          const item = groupedProduct[itemKey];
          const productData = productList?.find(
            (p: any) => p.productId === itemKey
          );

          console.log(
            " Object.keys(groupedProduct)?.length",
            Object.keys(groupedProduct)?.length,
            productData?.forms?.length
          );
          if (item?.length === productData?.forms?.length) {
            const statusFormList: any = [];
            let productPaymentProofForm: any = null
            productData?.forms?.forEach((form: any) => {
              const isFormPresent = data?.InvestmentFormPagination?.items?.find(
                (i: any) => i?.formID === form?.formId
              );
              console.log("isFormPresent", isFormPresent);
              
                if (form?.formId !== "240252269886465") {
                  statusFormList.push({
                    ...form,
                    ...isFormPresent?.approval,
                    ...isFormPresent,
                  });
                } else {
                  productPaymentProofForm = {
                    ...form,
                    ...isFormPresent?.approval,
                    ...isFormPresent,
                  }
                }
  
              
            


            });

            productListWithStatus.push({
              id: itemKey,
              name: productData?.name,
              formList: [...statusFormList],
              productPaymentProofForm: productPaymentProofForm
            });
          }
        });
        setProductList([...productListWithStatus]);
      }
    });
  };

  console.log("productListWithStatus", productList);

  const [getInvestmentFormStatus] = useLazyQuery(GET_INVESTMENT_FORM, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    let isCompleted = false;
    if (productList?.length) {
      
      productList?.forEach((item: any) => {
        const formList = item?.formList;
        let counter = 0;
        formList?.forEach((form: any) => {
          if (form?.approval?.status === STATUS.APPROVED) {
            counter++;
          }
        });
        if (counter === formList?.length && !isCompleted) {
          isCompleted = true;
        }
        if (item?.productPaymentProofForm?.approval?.status === STATUS.APPROVED) {
          isCompleted = true
        }
      });
    
    } 

    if (user?.paymentProofForm && user?.approvals?.paymentProofForm?.status === STATUS.APPROVED) {
      isCompleted = true;
    }
    if (isCompleted) {
      setFormStatus("completed");
    } else {

      setFormStatus("waiting");
    }
  }, [productList?.length, user]);

  useEffect(() => {
    getUser({ variables: {} });
    getStrategyProduct({
      variables: {},
    });

  }, []);


  const portfolioData = {
    headers: [
      'Product Code',
      'Product Name',
      'Currency',
      // 'Quantity',
      // 'Cost Per Unit',
      // 'Transaction Cost Per unit',
      // 'Total Cost',
      // 'Actual Cost (%) to the Portfolio',
      // 'Target Cost (%) to the Portfolio',
      // 'Difference between Actual and Target Costs',
      'Closing Price at 18 Jan 24',
      'Market Value at 18 Jan 24',
      'Unrealised Profit/(Loss)'
    ],
   
    client: { clientId: '10030002', clientName: 'Ma Hing Mo Raymond' },
    positionDate: "2024-01-18T00:00:00.000Z",
    netAsset: '848520.5333792042'
  }
  const state = {
    series: [
      {
        name: translate("Net Asset Value ($)"),
        data: [
          848520.53,
        ],
      },
    ],
    xaxis: {
      categories: [
        "Jan 18",
        
      ],
      title: {
        text: translate("Net Asset Value ($)"),
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          return formatCompactNumber(val);
        },
      },
    },

    options: {
      tooltip: {
        fillSeriesColor: false,
        theme: false,
        style: {
          color: "#000",
          background: "#000",
        },
      },
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
          type: "x",
        },
      },
      dataLabels: {
        enabled: true,
      },

      stroke: {
        curve: "straight",
      },
      title: {
        text: "Product Trends by Month",
        align: "center",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
  };
  const pieState = {
    series: [19.5, 37.9, 1.9, 40.7],
    labels: [
      "Equity",
      "Fixed Income",
      "Others",
      "Alternative",

    ],

    options: {
      legend: {
        show: true,
        position: "bottom",
        floating: true,
        verticalAlign: "bottom",
        align: "center",
      },
      chart: {
        type: "donut",
        height: 450,
      },

      dataLabels: {
        enabled: true,
      },

     
    },
  };
  const barChartUs = {
    series: [
      {
        name: translate("US Treasuries"),
        data: [5, -2, 3, 1, 2, 0.5, 0.1, -1.0, -0.4, 0.3, 3, 2],
      },
    ],

    xaxis: {
      title: {
        text: translate("US Treasuries"),
      },
      categories: [
        "Dec 22",
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
      ],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "left",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (val: any) {
          return `${val}%`;
        },
      },
    },
    options: {
      chart: {
        height: 450,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      // dataLabels: {
      //   formatter: function (val: any) {
      //     return val + "%";
      //   },
      //   enabled: true,
      //   offsetY: -10,
      //   style: {
      //     fontSize: "12px",
      //     colors: ["#304758"],
      //   },
      // },

      dataLabels: {
        enabled: false,
      },
      // dataLabels: {
      //   enabled: true,
      //   offsetY: -20,
      //   style: {
      //     fontSize: "12px",
      //     colors: ["#304758"],
      //   },
      // },

      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };
  const barChartflagship = {
    series: [
      {
        name: translate("Flagship 1"),
        data: [3, 5, -1, -0.1, 2, 4.3, -1, -0.5, 2, 2.1, 3.3, 1.4],
      },
    ],
    xaxis: {
      title: {
        text: translate("Flagship 1"),
      },
      categories: [
        "Dec 22",
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
      ],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "left",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (val: any) {
          return `${val}%`;
        },
      },
    },
    datLabels: {
      enabled: false,
    },
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      // dataLabels: {
      //   enabled: false,
      //   offsetY: -20,
      //   style: {
      //     fontSize: "12px",
      //     colors: ["#304758"],
      //   },

      //   formatter: function (val: any) {
      //     return `${val}%`;
      //   },
      // },

      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  const getNumberValue = (header: string, value: any) => {

    if (header === "Closing Price At 18 Jan 24" || header === "Market Value at 18 Jan 24" || header === "Unrealised Profit/(Loss)") {
      return parseFloat(value).toFixed(2)
    }
    return value
  }

  console.log("Form status", formStatus)

  console.log("Portfolio Data", currentAssetValue, pieChartData, lineChartData, portfolioSummary)
  return (
    <DashboardContainer>
      <div className="fo-container">
        <div className="fo-header">
          <div className="fo-heading">
            <h2>{translate("portfolio")}</h2>
            {/* <p>
              That determine your eligibility for private investments and
              expanded pricing options
            </p> */}
          </div>
          <div className="component-btn">
            <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
              <ComponentButton
                title={translate("TALK TO AN EXPERT")}
                blackBgBtn={true}
              />
            </a>
            <p>{translate("FOR A PERSONALISED APPROACH")}</p>
          </div>
        </div>
        {formStatus === "empty" && (
          <div className="empty-product-container">
            <div style={{ marginBottom: "100px" }}>
              <div style={{ marginBottom: "30px", width: "400px" }}>
                Please complete the investment flow for any of the FO strategy
                product
              </div>
              <ComponentButton
                whiteBgBtn={true}
                title="Go to FO Strategy Page"
                iconRight={<ChevronRightIcon w={24} h={24} />}
                onClick={() => {
                  navigate(FO_STRATEGY_URL);
                }}
              />
            </div>
          </div>
        )}
        {formStatus === "waiting" && (
          <div>
            {productList?.map((product: any) => {
              console.log("product product", product);
              return (
                <div style={{ marginTop: "20px" }}>
                  <h5 className="black-card-head">{product?.name}</h5>
                  <ChakraProvider>
                    <TableContainer>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th className="table-title"></Th>
                            <Th className="table-header-text">Status</Th>
                            <Th className="table-header-text">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {product?.formList?.map((form: any) => {
                            return (
                              <Tr>
                                <Td className="table-title">{form?.name}</Td>
                                <Td
                                  className="table-status-title"
                                  style={{
                                    color: STATUS_TEXT?.[form?.status]?.color,
                                  }}
                                >
                                  {STATUS_TEXT?.[form?.status]?.text}
                                </Td>
                                {form?.status !== STATUS.APPROVED && (
                                  <Td
                                    className="table-action-title"
                                    onClick={() => {
                                      // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                      navigate(
                                        `${EDIT_FORM_URL}?formId=${form?.formId}&formUrl=https://www.jotform.com/edit/${form?.submissionID}`
                                      );
                                    }}
                                  >
                                    Re-submit
                                  </Td>
                                )}
                              </Tr>
                            );
                          })}
                          {product?.productPaymentProofForm?.approval?.status === STATUS.SUBMITTED && <Tr>
                                <Td className="table-title">{product?.productPaymentProofForm?.name}</Td>
                                <Td
                                  className="table-status-title"
                                  style={{
                                    color: STATUS_TEXT?.[product?.productPaymentProofForm?.approval?.status]?.color,
                                  }}
                                >
                                  {STATUS_TEXT?.[product?.productPaymentProofForm?.approval?.status]?.text}
                                </Td>
                                {product?.productPaymentProofForm?.approval?.status !== STATUS.APPROVED && (
                                  <Td
                                    className="table-action-title"
                                    onClick={() => {
                                      // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                      navigate(
                                        `${EDIT_FORM_URL}?formId=${product?.productPaymentProofForm?.formId}&formUrl=https://www.jotform.com/edit/${product?.productPaymentProofForm?.submissionID}`
                                      );
                                    }}
                                  >
                                    Re-submit
                                  </Td>
                                )}
                              </Tr>}
                              {user?.paymentProofForm && <Tr>
                                <Td className="table-title">Cash Deposit</Td>
                                <Td
                                  className="table-status-title"
                                  style={{
                                    color: STATUS_TEXT?.[user?.approvals?.paymentProofForm?.status]?.color,
                                  }}
                                >
                                  {STATUS_TEXT?.[user?.approvals?.paymentProofForm?.status]?.text}
                                </Td>
                                {user?.approvals?.paymentProofForm?.status !== STATUS.APPROVED && (
                                  <Td
                                    className="table-action-title"
                                    onClick={() => {
                                      // window.location.href = `https://www.jotform.com/edit/${user?.securityForm?.submissionID}`
                                      navigate(
                                        `${EDIT_FORM_URL}?formId=${user?.paymentProofForm?.formID}&formUrl=https://www.jotform.com/edit/${user?.paymentProofForm?.submissionID}`
                                      );
                                    }}
                                  >
                                    Re-submit
                                  </Td>
                                )}
                              </Tr>}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </ChakraProvider>
                </div>
              );
            })}
          </div>
        )}
        {formStatus === "completed" && (
          <>
            <div className="chart-data">
              <div className="chart-line">
              <ComponentBlackCard
                  children={
                    <>
                      <h5
                        style={{ marginBottom: "10px" }}
                        className="black-card-head"
                      >
                        {translate("Net Asset Value ($)")} (as of today)
                      </h5>
                      <div>
                        <div
                          style={{
                            marginTop: "10px",
                            fontSize: "26px",
                            color: "rgb(0, 255, 191)",
                            marginBottom: "30px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          HK$ {currentAssetValue}
                        </div>
                        {lineChartData?.xaxis?.categories?.length &&  <ReactApexChart
                          options={lineChartData}
                          series={lineChartData.series}
                          type="line"
                          height={300}
                        />}
                       
                      </div>
                    </>
                  }
                />
              
              </div>
              <div className="chart-pie">
                {/* {pieChartData?.series?.length !== } */}
                <ComponentBlackCard
                  children={
                    <>
                      <h5
                        style={{ marginBottom: "30px" }}
                        className="black-card-head"
                      >
                        {translate("Portfolio Allocation (as of today)")}
                      </h5>
                      {pieChartData?.series?.length &&   <ReactApexChart
                        options={pieChartData}
                        series={pieChartData.series}
                        type="donut"
                        height={450}
                      />}
                    
                    </>
                  }
                />
              </div>
            </div>
            <div className="chart-data" style={{ width: "100%", paddingTop: "30px" }}>
              <ComponentBlackCard
                children={
                  <>
                    <h5
                      style={{ marginBottom: "20px" }}
                      className="black-card-head"
                    >
                      Portfolio Summary
                    </h5>
                    <div>

                      <ChakraProvider>
                        <TableContainer>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                {portfolioData?.headers?.map((data: any, index: number) => {
                                  return <Th className="table-header-text">{data}</Th>
                                })}

                              </Tr>
                            </Thead>
                            <Tbody>
                              {portfolioSummary?.map((data: any, index: number) => {
                                return <Tr>
                                  {portfolioData?.headers?.map((header: string) => {
                                    return <Td className="portfolio-table-title">{getNumberValue(header, data?.[header])}</Td>

                                  })}

                                </Tr>

                              })}
                              {/* <Tr>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title">Cash</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">HKD</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">816.25</Td>
                                <Td className="portfolio-table-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title">Cash</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">USD</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title">1129.01</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>HKD</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>850533.28</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>52936.80</Td>

                              </Tr> */}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </ChakraProvider>
                    </div>
                  </>
                }
                style={{ width: "100%" }}
              />
            </div>
            {/* <Grid templateColumns="repeat(2, 1fr)" gap={20}>
              <div>
                <ComponentBlackCard
                  children={
                    <>
                      <h5
                        style={{ marginBottom: "10px" }}
                        className="black-card-head"
                      >
                        {translate("US Treasuries")}
                      </h5>
                      <ReactApexChart
                        options={barChartUs}
                        series={barChartUs.series}
                        type="bar"
                        height={450}
                      />
                    </>
                  }
                />
              </div>
              <div>
                <ComponentBlackCard
                  children={
                    <>
                      <h5
                        style={{ marginBottom: "10px", cursor: "pointer" }}
                        className="black-card-head"
                        onClick={() => {
                          navigate("/fo-strategy/product");
                        }}
                      >
                        {translate("Flagship 1")}
                      </h5>
                      <ReactApexChart
                        options={barChartflagship}
                        series={barChartflagship.series}
                        type="bar"
                        height={450}
                      />
                    </>
                  }
                />
              </div>
            </Grid> */}
          </>
        )}
      </div>
    </DashboardContainer>
  );
};

export default Portfolio;
