import { useLazyQuery } from "@apollo/client";
import { GET_CURRENCY_EXCHANGE } from "../../graphql/investment.query";
import { useEffect, useState } from "react";
import { formatNumber } from "../../dataHelper";

const CurrencyExchange = ({currency, amount}) => {
    const [minimalAmount, setMinimalAmount] = useState(amount)
    const [getCurrency] = useLazyQuery(GET_CURRENCY_EXCHANGE, {
        onCompleted: (data) => {
      
      if(data?.eodHd?.length){
        const currencyExchange = data?.eodHd?.[data?.eodHd?.length - 1]?.adjusted_close
        if(currency !== "HKD"){
            let amountData = currencyExchange*amount
           
            setMinimalAmount(formatNumber(Math.round(amountData)))
        } else {
            setMinimalAmount(formatNumber(Math.round(amount)))
        }
     

      } else {
        setMinimalAmount(formatNumber(Math.round(amount)))
      }
        },
    
        fetchPolicy: "no-cache",
      });


      useEffect(() => {
        getCurrency({
            variables: {
                targetUrl: `/eod/${currency}HKD.FOREX?fmt=json`
            }
        })

      }, [])

    return <>{minimalAmount}</>

}

export default CurrencyExchange