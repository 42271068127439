import { Grid } from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import DashboardContainer from "../../sub-components/DashbordContainer";
import EuroVectorBlack from "../../../assets/EuroVectorBlack.svg";
import "./AIStrategies.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  FO_SERVICES_PRODUCT_URL,
  FO_SERVICES_TRUST_PLANNING,
} from "../../routes";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import translate from "../../../translate";
import ProductItem from "../../sub-components/ProductItem";

const ciesData = [
  {
    name: "AI Strategy 1",
    tags: ["MA", "PI"],
    tagLabel: "Stable Returns",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "8-12%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#00173f",
  },
  {
    name: "AI Strategy 2",
    tags: ["MA", "PI"],
    tagLabel: "Global ETFs",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "8%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#0076e3",
  },
  {
    name: "AI Strategy 3",
    tags: ["MA", "PI"],
    tagLabel: "High-quality Returns",
    description:
      "Offer a concise overview tailored to the unique aspects of the product",
    expectedReturn: "15%",
    minimumInitial: "200,000",
    comingSoon: true,
    color: "#8b428c",
  },
];

const AIStrategies = () => {
  const navigate = useNavigate();
  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div className="title-small-heading">PRODUCTS</div>
                <h3 className="gradient-heading">
                  AI Strategies (Coming soon)
                </h3>
                <div style={{ color: "#37454b", fontSize: "14px" }}>
                  This tool combines big data analysis of individual
                  transactions, delving Family Office's macro strategies, and
                  client personal tags to provide comprehensive quantitative
                  asset allocation recommendations for PI and retail customers.
                </div>
              </div>

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            <div
              style={{
                marginTop: "50px",
                display: "flex",
                // justifyContent: "space-between",
                gap: "20px",
              }}
            >
              {ciesData?.map((item) => {
                return <ProductItem item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default AIStrategies;
