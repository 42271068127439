import { Input } from "@chakra-ui/react";
import "./ComponentInput.scss";

const ComponenetInput = ({ value, handleChange, placeholder, type }: any) => {
  return (
    <Input
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      type={type}
      className="componentInput"
    />
  );
};

export default ComponenetInput;
