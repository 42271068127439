import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  FO_SERVICES_PRODUCT_COUNTRY_URL,
  FO_SERVICES_PRODUCT_URL,
  FO_SERVICES_URL,
  useRouter,
} from "../../routes";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import "./FoServiceProductCountry.scss";
import ProjectVector from "../../../assets/project-vector.svg";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import translate from "../../../translate";
import { SECURITY_ACCOUNT_OPENING_ID } from "../../dataHelper";

const countryData = [
  {
    id: "hongkong",
    content: {
      name: translate("Hong Kong"),
      description: translate("Attracting top talent globally"),
      data: [
        {
          id: "hongkong1",
          name: translate("Quality Migrant Admission Scheme"),
          introduction: translate(
            "The Hong Kong government launched the Quality Migrant Admission Scheme in 2006. The scheme seeks to attract highly skilled or talented persons to settle in Hong Kong in order to enhance Hong Kong's economic competitiveness."
          ),
          applicationRequirement: [
            { name: translate("Over 18 years of age") },
            { name: translate("No criminal record") },
            {
              name: translate(
                "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test"
              ),
            },
          ],
          investmentRequirements: [{ name: translate("None needed") }],
          investmentDuration: "N/A",
          processingTime: translate("6+"),
          indentityCategory: translate("Resident Status"),
        },
        {
          id: "hongkong2",
          name: translate(
            "Admission Scheme for Mainland Talents and Professionals"
          ),
          introduction: translate(
            "Chinese residents of the Mainland of China (the Mainland) who possess special skills, knowledge or experience of value to and not readily available in the HKSAR may apply to come to work under the ASMTP. The ASMTP is quota-free and non-sector specific."
          ),
          applicationRequirement: [
            { name: translate("Over 18 years of age") },
            { name: translate("No criminal record") },
            { name: translate("Three application categories") },
            {
              name: translate(
                "Persons with annual income reaching HK$2.5 million or above, or its equivalent in foreign currency, in the year immediately preceding the date of application"
              ),
            },
            {
              name: translate(
                "Degree graduates of the eligible universities with at least three years of work experience over the past five years immediately preceding the date of application"
              ),
            },
            {
              name: translate(
                "Degree graduates of the eligible universities in the past five years immediately preceding the date of application with less than three years of work experience, subject to an annual quota which is to be allotted on a first-come, first-served basis"
              ),
            },
          ],
          investmentRequirements: [{ name: translate("None needed") }],
          investmentDuration: "N/A",
          processingTime: translate("4-6"),
          indentityCategory: translate("Resident Status"),
        },
        {
          id: "hongkong3",
          name: translate("Top Talent Pass Scheme"),
          introduction: translate(
            "Eligible talents include individuals who had an annual salary of HKD 2.5 million or more in the past year, and individuals who graduated from the top 100 universities globally and have accumulated 3 years or more of work experience in the past 5 years. These two types of people can be issued a pass to develop in Hong Kong for a period of 2 years, with no quota restrictions. Graduates from the top 100 universities globally who do not meet the work experience requirements but have graduated in the past 5 years can also be issued passes, with an annual limit of 10,000 people. This scheme lasts for 2 years and will be reviewed one year after its launch."
          ),
          applicationRequirement: [
            { name: translate("Over 18 years of age") },
            { name: translate("No criminal record") },
            {
              name: translate(
                "Points-based test: applicants will be assessed under the General Points Test or the Achievement-based Points Test"
              ),
            },
          ],
          investmentRequirements: [{ name: translate("None needed") }],
          investmentDuration: "N/A",
          processingTime: translate("1-2"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "singapore",
    content: {
      name: translate("Singapore"),
      description: translate("Attracting high-net-worth family offices"),
      data: [
        {
          id: "singapore1",
          name: "13O",
          introduction: translate(
            "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth."
          ),
          applicationRequirement: [
            { name: translate("AUM SGD 20 million") },
            {
              name: translate(
                "At least SGD 200,000 in total business spending"
              ),
            },
            {
              name: translate(
                "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member."
              ),
            },
            {
              name: translate(
                "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market."
              ),
            },
          ],
          investmentRequirements: [
            { name: translate("AUM SGD 20 million") },
            {
              name: translate(
                "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies, and private equity investments in local unlisted companies. Newly added are climate-related investments and blended finance in which Singapore financial institutions have broad participation."
              ),
            },
          ],
          investmentDuration: translate(
            "Maintain until application for permanent residency."
          ),
          processingTime: translate("8-10"),
          indentityCategory: translate("Resident Status"),
        },
        {
          id: "singapore2",
          name: "13U",
          introduction: translate(
            "In the Singapore Budget in February 2019, the Minister of Finance announced that more preferential tax exemption schemes will be provided for family offices: expanding the scope of tax exemption, and continuing the preferential plans stipulated by the current tax exemption policies 13O and 13U until December 31, 2024. The structure of the Singapore family office is mainly composed of a single family office established by the main applicant in Singapore and two companies established together with his or her family members, one is a fund company, and the other is a fund management company (family office). The controlling person of the family office can be an individual or in the form of a holding company. The holding company can be held by a trust company to ensure the isolation of wealth."
          ),
          applicationRequirement: [
            { name: translate("AUM SGD 50 million") },
            {
              name: translate(
                "At least SGD 500,000 in total business spending"
              ),
            },
            {
              name: translate(
                "Hire 3 investment professional from Singapore, 2 of which are professional investors, and one of them is not a family member."
              ),
            },
            {
              name: translate(
                "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local Singapore market."
              ),
            },
          ],
          investmentRequirements: [
            { name: translate("AUM SGD 50 million") },
            {
              name: translate(
                "Invest at least 10% or 10 million dollars (whichever is lower) of funds in the local market. This includes stocks listed on the Singapore Exchange, qualifying bonds, funds issued by local fund companies and private equity investments in local non-listed companies. New additions include climate-related investments and blended finance widely participated by Singapore financial institutions."
              ),
            },
          ],
          investmentDuration: translate(
            "Maintain until application for permanent residency."
          ),
          processingTime: translate("8-10"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "thailand",
    content: {
      name: translate("Thailand"),
      description: translate("Introducing long-term residency visas"),
      data: [
        {
          id: "thailand",
          name: translate("Thailand Elite-Visa"),
          introduction: translate(
            "The Thailand Elite Visa, initiated by the Royal Thai Government in November 2003, is the world's first visa program that allows foreigners to reside in Thailand for up to 20 years. It is issued by the Thailand Privilege Card Company Limited (TPC), a wholly-owned subsidiary of the Tourism Authority of Thailand, under the Ministry of Tourism and Sports. Holders of the Thailand Elite Visa can enjoy VIP services and other privileges for free."
          ),
          applicationRequirement: [
            { name: translate("Over 20 years of age") },
            { name: translate("Donation of 600,000 to 2,000,000 Thai Baht") },
          ],
          investmentRequirements: [
            {
              name: translate(
                "By making a donation of 600,000 to 2,000,000 Thai Baht, one can obtain long-term residency status for 5 years, 10 years, or 20 years."
              ),
            },
          ],
          investmentDuration: translate("One time donation"),
          processingTime: translate("2-3"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "malta",
    content: {
      name: translate("Malta"),
      description: translate("Low-threshold access to EU citizenship"),
      data: [
        {
          id: "malta1",
          name: translate("Permanent Residency Program"),
          introduction: translate(
            "On January 12, 2021, the Identity Malta Agency formally announced a new policy for its permanent residency program, named the Malta Permanent Residence Program (MPRP). The cost of implementing the new policy is lower than the previous MRVP project, making the Malta program one of the most cost-effective investment projects on the market."
          ),
          applicationRequirement: [
            { name: translate("Over 18 years of age") },
            { name: translate("No criminal record") },
            {
              name: translate(
                "Proof of assets worth 50,000 Euros (including proof of liquid assets worth 15,000 Euros)"
              ),
            },
            {
              name: translate("Meet the government's investment requirements"),
            },
          ],
          investmentRequirements: [
            { name: translate("Administrative fee: 40,000 Euros") },
            { name: translate("Non-profit donation: 2,000 Euros") },
            {
              name: translate(
                "Government donation: For property purchase method, the donation amount is 28,000 Euros; For the rental method, the donation amount is 58,000 Euros"
              ),
            },
            {
              name: translate(
                "Property investment: Purchase a property worth over 300,000 Euros for a total of 5 years or lease a property worth 10,000 Euros per year for a total of 5 years."
              ),
            },
          ],
          investmentDuration: translate("At least 5 years"),
          processingTime: translate("8-10"),
          indentityCategory: translate("Permanent Residency"),
        },
        {
          id: "malta2",
          name: translate("Citizenship by Investment Program"),
          introduction: translate(
            "The Malta passport policy was launched in 2014. In July 2020, the Malta passport project reached its upper limit and was closed. At the end of November 2020, the new Malta passport policy, the Granting of Citizenship for Exceptional Services Regulations, 2020, was launched. This policy stipulates that the quota for new citizenship is 1500 families, with a maximum of 400 families per year."
          ),
          applicationRequirement: [
            { name: translate("Over 18 years of age") },
            { name: translate("No criminal record") },
            {
              name: translate("Hold Malta residence for 3 years or 12 months"),
            },
            {
              name: translate("Meet the government's investment requirements"),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "Make a donation of 600,000 EUR or 750,000 EUR to the country of Malta."
              ),
            },
            {
              name: translate(
                "Purchase residential property in Malta valued at least 700,000 EUR and hold for at least 5 years; or lease a residential property with a rent of at least 16,000 EUR 3. per year for a minimum lease period of 5 years. Donate 10,000 EUR to local Maltese organizations involved in charity, culture, sports, science, art, or non-governmental organizations."
              ),
            },
          ],
          investmentDuration: translate("At least 5 years"),
          processingTime: translate("15-18"),
          indentityCategory: translate("Citizenship"),
        },
      ],
    },
  },
  {
    id: "australia",
    content: {
      name: translate("Australia"),
      description: translate(
        "Offering a diverse range of entrepreneurship and investment immigration options"
      ),
      data: [
        {
          id: "malta1",
          name: "188A",
          introduction: translate(
            "The 188A visa, introduced by the Australian government in 1981 as an entrepreneurial immigration program, was previously known as the 163A visa. To date, it has a history of 40 years and is one of the oldest and most consistently stable immigration programs in the Commonwealth countries."
          ),
          applicationRequirement: [
            { name: translate("The main applicant is under 55 years old.") },
            {
              name: translate(
                "The individual and family net assets under the name of the couple are not less than 1.25 million AUD."
              ),
            },
            {
              name: translate(
                "In the past four fiscal years, in two of those fiscal years, the company's annual turnover is not less than 750,000 AUD."
              ),
            },
            {
              name: translate(
                "In the past four fiscal years, in two of those fiscal years, the shareholding ratio under the couple's name is not less than 30%; if it is a listed company, it is not less than 10%."
              ),
            },
            {
              name: translate(
                "The score in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system is not less than 65 points."
              ),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "As long as either spouse meets the entrepreneurial (residential) requirements, they can become the main applicant for the 888 Permanent Residence Visa."
              ),
            },
            {
              name: translate(
                "During the 5-year residency visa period, meet the entrepreneurial requirements for 2 years."
              ),
            },
            {
              name: translate(
                "During the 2 years of entrepreneurship, reside in the sponsoring state for at least 1 year."
              ),
            },
            {
              name: translate(
                "Meet at least 2 of the following 3 conditions: 1) The family net assets under the couple's name reach 600,000 AUD; 2) The net assets of the company held reach 200,000 AUD; 3) Employ 2 local full-time employees."
              ),
            },
          ],
          investmentDuration: translate("At least 2 years"),
          processingTime: translate("21-27"),
          indentityCategory: translate("Resident Status"),
        },
        {
          id: "malta2",
          name: "188B",
          introduction: translate(
            "The 188B visa, introduced by the Australian government in 1981 as an investment immigration program, was previously known as the 165 visa. To date, it has a history of 40 years and is one of the most consistently stable immigration programs in the Commonwealth countries."
          ),

          applicationRequirement: [
            {
              name: translate("The main applicant must be under 55 years old."),
            },
            {
              name: translate(
                "The family's net assets must not be less than 2.75 million AUD over the past two fiscal years."
              ),
            },
            {
              name: translate(
                "Have at least 3 years of successful investment experience, and in one of the past five years, have over 10% shares in a business or own an investment market value of 2.5 million AUD."
              ),
            },
            {
              name: translate(
                "Through the above business or investment, profits exceed 1 million AUD."
              ),
            },
            {
              name: translate(
                "Score no less than 65 points in the Business Talent (Subclass 132) Visa Expression of Interest (EOI) system."
              ),
            },
            {
              name: translate(
                "Invest 2.5 million AUD in compliant investments."
              ),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "Hold compliant investments of 2.5 million AUD, with the required proportions as follows:"
              ),
            },
            {
              name: "AUD 500,000 VC and growth private equity funds",
            },
            {
              name: "AUD 750,000 funds invest in emerging copanies listed on Australian Stock Exchange",
            },
            {
              name: "AUD 1,250,000 balancing investment",
            },
          ],
          investmentDuration: translate("At least 3 years"),
          processingTime: translate("21-25"),
          indentityCategory: translate("Resident Status"),
        },
        {
          id: "malta2",
          name: "188C",
          introduction: translate(
            "The Australian 188C Investment Immigration is an immigration program aimed at high-net-worth individuals. Applicants are required to declare assets of 5 million AUD and provide a reasonable explanation of the source of funds. Later, the assets of 5 million AUD will be liquidated and invested through a fund company."
          ),
          applicationRequirement: [
            { name: translate("No age restriction") },
            { name: translate("No points required, no English requirement") },
            {
              name: translate(
                "Relaxed residence requirements for later conversion to permanent residence"
              ),
            },
            {
              name: translate(
                "Family assets above 5 million AUD, with a reasonable explanation of the source"
              ),
            },
            {
              name: translate("Invest 5 million AUD in compliant investments"),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "Hold compliant investments of 5 million AUD, with the required proportions as follows:"
              ),
            },
            {
              name: translate(
                "AUD 1,000,000 VC and growth private equity funds"
              ),
            },
            {
              name: translate(
                "AUD 1,500,000 funds invest in emerging copanies listed on Australian Stock Exchange"
              ),
            },
            {
              name: translate("AUD 2,500,000 balancing investment"),
            },
          ],
          investmentDuration: translate("At least 3 years"),
          processingTime: translate("25-28"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "newzeland",
    content: {
      name: translate("New Zealand"),
      description: translate(
        "Attracting high-value investments to promote economic development"
      ),
      data: [
        {
          id: "new-zeland",
          name: translate("New Zealand Active Investor Plus"),
          introduction: translate(
            "The Active Investor Plus Visa, established to attract high-value investors, came into effect on 19th September 2022. This new visa replaces the previous investor visa category."
          ),
          applicationRequirement: [
            { name: translate("Language: IELTS score of 5 or above.") },
            {
              name: translate(
                "Residence: The main applicant must reside in New Zealand for at least 117 days within the 4-year investment period."
              ),
            },
            {
              name: translate(
                "Investment: Invest between 5 million and 15 million NZD in New Zealand over 4 years."
              ),
            },
            {
              name: translate(
                "Dependents: Children under the age of 24 who are economically dependent on the main applicant can be included in the application."
              ),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "Invest at least 5 million NZD directly into enterprises approved by the New Zealand Trade and Enterprise (NZTE), or;"
              ),
            },
            {
              name: translate(
                "Invest at least 7.5 million NZD in equity funds or venture capital funds, or;"
              ),
            },
            {
              name: translate(
                "Invest at least 15 million NZD in publicly listed company shares and charitable causes (50% each)."
              ),
            },
          ],
          investmentDuration: translate("At least 4 years"),
          processingTime: translate("12-24"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "canada",
    content: {
      name: translate("Canada"),
      description: translate(
        "Encouraging innovative and entrepreneurial talents to immigrate and contribute to the economy"
      ),
      data: [
        {
          id: "canada1",
          name: translate("Canada Start-up Visa Program (SUV)"),
          introduction: translate(
            "The Canadian Startup Visa (SUV) Program was launched by Immigration, Refugees and Citizenship Canada (IRCC) on April 1, 2013, initially as a 5-year pilot project. In 2018, IRCC announced that the program would become a permanent one. The main goal of the Startup Visa Program is to attract innovative foreign entrepreneurs who can contribute to Canada's economic and industrial development and create new job opportunities."
          ),
          applicationRequirement: [
            {
              name: translate(
                "Age: The main applicant must be over 18 years old."
              ),
            },
            {
              name: translate(
                "Education: The main applicant must have a full-time college degree or higher."
              ),
            },
            { name: translate("Language: IELTS score of 5 or above.") },
            {
              name: translate(
                "Industry Background: Over 3 years of related background in the technology industry (such as IT, communications, biomedical technology, new energy, environmental protection, artificial intelligence, and other tech-related industries)."
              ),
            },
          ],
          investmentRequirements: [
            {
              name: translate(
                "To establish an innovative business in Canada, there is no explicit investment amount requirement, but the amount is usually more than 100,000 Canadian dollars"
              ),
            },
          ],
          investmentDuration: translate("At least 2 years"),
          processingTime: translate("30-38"),
          indentityCategory: translate("Permanent Residency"),
        },
      ],
    },
  },
  {
    id: "portugal",
    content: {
      name: translate("Portugal"),
      description: translate("Attain EU residency rights through investment"),
      data: [
        {
          id: "portugal1",
          name: translate("Portugal Golden Visa"),
          introduction: translate(
            "On October 8, 2012, the Portuguese Ministry of Internal Affairs issued the Portuguese Golden Residence Permit Program. The law stipulates that citizens from non-EU countries can obtain the Portuguese Golden Residence Permit by making an investment in Portugal."
          ),
          applicationRequirement: [
            { name: translate("At least 18 years old") },
            { name: translate("No criminal record") },
            { name: translate("Meets the required investment criteria") },
            { name: translate("No criminal record") },
          ],
          investmentRequirements: [
            {
              name: translate(
                "500,000 Euros in a fund approved by the Portuguese government"
              ),
            },
          ],
          investmentDuration: translate("At least 5 years"),
          processingTime: translate("12+"),
          indentityCategory: translate("Resident Status"),
        },
      ],
    },
  },
  {
    id: "greece",
    content: {
      name: translate("Greece"),
      description: translate(
        "Attain permanent residency through real estate investment"
      ),
      data: [
        {
          id: "greece1",
          name: translate("Greece Golden Visa"),
          introduction: translate(
            "In order to increase domestic consumption and promote the development of the domestic real estate industry, the Greek government announced a new immigration policy for home buyers in July 2015. Non-EU citizens who invest in local properties worth more than 250,000 Euros in Greece can obtain permanent residency in Greece. In March 2016, the Greek government further relaxed the application conditions by removing the requirement for no criminal record. Starting from August 2023, the Greek property immigration policy will be adjusted, with the property purchase requirement in some areas rising from the original 250,000 Euros to 500,000 Euros."
          ),
          applicationRequirement: [
            {
              name: translate("Non-EU/European Economic Area/Swiss nationals"),
            },
            { name: translate("At least 14 years old") },
            { name: translate("Meets the required investment criteria") },
            { name: translate("No criminal record") },
          ],
          investmentRequirements: [
            {
              name: translate(
                "Property worth more than 250,000 Euros (some regions require more than 500,000 Euros)"
              ),
            },
          ],
          investmentDuration: translate("Permanent possession"),
          processingTime: translate("6-8"),
          indentityCategory: translate("Permanent Residency"),
        },
      ],
    },
  },
  {
    id: "stlucia",
    content: {
      name: translate("St Lucia"),
      description: translate(
        "Attain citizenship by making economic contributions to the country"
      ),
      data: [
        {
          id: "stLucia",
          name: translate("St Lucia Citizenship by Investment"),
          introduction: translate(
            "The Saint Lucia Citizenship by Investment Program requires applicants to make a significant economic contribution to the country. As an exchange, and after a strict application process and due diligence, the applicant and their family will be granted full citizenship. The Saint Lucia Citizenship by Investment Program is regulated by the Citizenship by Investment Act No. 14 of 2015."
          ),
          applicationRequirement: [
            { name: translate("At least 18 years old") },
            { name: translate("No criminal record") },
            { name: translate("Meets the required investment criteria") },
          ],
          investmentRequirements: [
            {
              name: translate("Donations:"),
            },
            {
              name: translate("$100,000 (single applicant); or"),
            },
            {
              name: translate("$140,000 for a couple; or"),
            },
            {
              name: translate(
                "$150,000 for a family of 3-4, with each additional person beyond 5 requiring an additional investment of $15,000."
              ),
            },
          ],
          investmentDuration: translate("At least 5 years"),
          processingTime: translate("6+"),
          indentityCategory: translate("Citizenship"),
        },
      ],
    },
  },
];

const FoServicesProductCountry = () => {
  const navigate = useNavigate();
  const router = useRouter();
  const { pathname, query } = router;
  const selectedCountry = query?.id?.toString();
  const [user, setUser] = useState<any>(null);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);
  const [selectedItem, setSelectedItem] = useState<any>();
  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryData: any = countryData?.find(
        (country) => country?.id === selectedCountry
      );
      const firstData = selectedCountryData?.content?.data?.[0];
      setSelectedItem(firstData);
    }
  }, [selectedCountry]);

  const getBorderColor = (index: number) => {
    if (index === 0) {
      return "#00173f";
    }
    if (index === 1) {
      return "#0076e3";
    }
    if (index === 2) {
      return "#8b428c";
    }
    if (index === 3) {
      return "#e62762";
    }
  };
  return (
    <DashboardContainer>
      <div className="account-page" style={{backgroundImage:"unset"}}>
        <div className="account-section">
          {countryData?.map((item) => {
            return (
              <>
                {item?.id === selectedCountry && (
                  <>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: "1 1 auto" }}>
                          {/* <div className="title-small-heading">PRODUCTS</div> */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "28px",
                            }}
                          >
                            <ArrowBackIcon
                              w={28}
                              h={28}
                              style={{ cursor: "pointer", color: "#8c8c8c" }}
                              onClick={() => {
                                navigate(FO_SERVICES_PRODUCT_URL);
                              }}
                            />
                            <h3
                              className="gradient-heading"
                              style={{ marginBottom: "0px" }}
                            >
                              {item?.content?.name}
                            </h3>
                          </div>
                        </div>

                        <a
                          href="mailto:assetmanagement@dl-holdings.com"
                          target="__blank"
                        >
                          <ComponentButton
                            title={translate("TALK TO AN EXPERT")}
                            whiteBgBtn={true}
                          />
                        </a>
                      </div>

                      <div style={{ paddingTop: "30px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <section
                            style={{ flex: "1 1", color: "#000" }}
                            className="accordion accordion--radio"
                          >
                            {item?.content?.data?.map((item: any, index) => {
                              return (
                                <div
                                  onClick={() => {
                                    setSelectedItem(item);
                                  }}
                                  className="service-product-container country-product-container tab"
                                  style={{
                                    backgroundColor:
                                      selectedItem?.name === item?.name
                                        ? "#f0f3fa"
                                        : "#fff",
                                    borderColor:
                                      selectedItem?.name === item?.name
                                        ? getBorderColor(index)
                                        : "transparent",
                                    borderBottom:
                                      selectedItem?.name === item?.name
                                        ? "0px"
                                        : "0.5px solid #E6E7E6",
                                    boxShadow:
                                      selectedItem?.name === item?.name
                                        ? "0 5px 10px 0 rgba(4, 10, 41, .2)"
                                        : "none",
                                    marginBottom:
                                      selectedItem?.name === item?.name
                                        ? "15px"
                                        : "0px",
                                    marginRight: "20px",
                                    // display:"flex"
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="accordion-2"
                                    id={item?.name}
                                    checked={item?.name === selectedItem?.name}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div style={{ flex: "1 1 auto" }}>
                                      <label
                                        htmlFor={item?.name}
                                        className="tab__label"
                                        style={{
                                          margin: "0px",
                                          fontSize:
                                            selectedItem?.name === item?.name
                                              ? "24px"
                                              : "20px",
                                        }}
                                      >
                                        {" "}
                                        {item?.name}
                                      </label>
                                    </div>
                                    {!item?.comingSoon && (
                                      <div
                                        onClick={() => {
                                          if (item?.link) {
                                            navigate(item?.link);
                                          }
                                        }}
                                      >
                                        <ChevronRightIcon
                                          style={{
                                            color: "#8c8c8c",
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="tab__content"
                                    style={{
                                      fontSize: "14px",
                                      color: "#777",
                                    }}
                                  ></div>
                                </div>
                              );
                            })}
                          </section>

                          <div
                            style={{
                              flex: "1 1",
                              color: "#000",
                              marginLeft: "100px",
                            }}
                          >
                            <div className="trust-detail-card">
                              <div>
                                <h5 className="black-card-head">
                                  {translate("Introduction")}
                                </h5>
                                <div
                                  className="card-data"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <p>{selectedItem?.introduction}</p>
                                </div>
                              </div>

                              <div style={{ marginTop: "35px" }}>
                                <h5 className="black-card-head">
                                  {translate("Application Requirements")}
                                </h5>
                                <div
                                  className="card-data-holding"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <ul>
                                    {selectedItem?.applicationRequirement.map(
                                      (appItem: any) => {
                                        return (
                                          <div>
                                            <li>
                                              <p>{appItem?.name}</p>
                                            </li>
                                          </div>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>

                              <div style={{ marginTop: "35px" }}>
                                <h5 className="black-card-head">
                                  {translate(
                                    "Processing time and identity category"
                                  )}
                                </h5>
                                <div className="card-data">
                                  <div>
                                    <h4>{selectedItem?.processingTime}</h4>
                                    <p>{translate("Months")}</p>
                                  </div>
                                  <div>
                                    <h4>{selectedItem?.indentityCategory}</h4>
                                    <p>{translate("Category")}</p>
                                  </div>
                                </div>
                              </div>

                              <div style={{ marginTop: "35px" }}>
                                <h5 className="black-card-head">
                                  {translate("Investment Requirements")}
                                </h5>
                                <div
                                  className="card-data-holding"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <ul>
                                    {selectedItem?.investmentRequirements.map(
                                      (investItem: any) => {
                                        return (
                                          <div>
                                            <li>
                                              <p>{investItem.name}</p>
                                            </li>
                                          </div>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>

                              <div style={{ marginTop: "35px" }}>
                                <h5 className="black-card-head">
                                  {translate("Investment Duration")}
                                </h5>
                                <div
                                  className="card-data-holding"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <div>
                                    <p>{selectedItem?.investmentDuration}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
      {/* <>
        {countryData.map((item) => {
          return (
            <>
              {item.id === selectedCountry && (
                <>
                  {
                    <div className="fo-container">
                      <div className="fo-header-product-planning">
                        <div className="fo-heading-product">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "28px",
                            }}
                          >
                            <ArrowBackIcon
                              w={28}
                              h={28}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(FO_SERVICES_PRODUCT_URL);
                              }}
                            />
                            <h2>{item?.content?.name}</h2>
                          </div>
                          <p>{item?.content?.description}</p>
                        </div>
                        <div className="component-btn">
                          <a
                            href="mailto:assetmanagement@dl-holdings.com"
                            target="__blank"
                          >
                            <ComponentButton
                              title={translate("TALK TO AN EXPERT")}
                              blackBgBtn={true}
                            />
                          </a>
                          <p>{translate("FOR A PERSONALISED APPROACH")}</p>
                        </div>
                      </div>
                      <div className="fo-services-country-accordian-container">
                        <Accordion allowMultiple>
                          {item?.content?.data.map((dataItem) => {
                            return (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton className="accordion-title">
                                    <img src={ProjectVector} alt="image" />
                                    <Box as="span" flex="1" textAlign="left">
                                      {dataItem.name}
                                    </Box>
                                    <AccordionIcon w={25} h={25} />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row-reverse",
                                      marginBottom: "20px",
                                      marginRight: "30px",
                                    }}
                                  >
                                    <ComponentButton
                                      title={translate("Invest")}
                                      whiteBgBtn={true}
                                      onClick={() => {
                                      }}
                                    />
                                  </div>
                                  <div className="accordian-data">
                                    <div className="left-side">
                                      <ComponentBlackCard
                                        children={
                                          <div>
                                            <h5 className="black-card-head">
                                              {translate("Introduction")}
                                            </h5>
                                            <div
                                              className="card-data"
                                              style={{ paddingTop: "25px" }}
                                            >
                                              <p>{dataItem.introduction}</p>
                                            </div>
                                          </div>
                                        }
                                      />
                                      <ComponentBlackCard
                                        children={
                                          <div>
                                            <h5 className="black-card-head">
                                              {translate(
                                                "Application Requirements"
                                              )}
                                            </h5>
                                            <div
                                              className="card-data-holding"
                                              style={{ paddingTop: "10px" }}
                                            >
                                              <ul>
                                                {dataItem.applicationRequirement.map(
                                                  (appItem) => {
                                                    return (
                                                      <div>
                                                        <li>
                                                          <p>{appItem?.name}</p>
                                                        </li>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        }
                                      />
                                    </div>
                                    <div className="right-side">
                                      <ComponentBlackCard
                                        children={
                                          <div>
                                            <h5 className="black-card-head">
                                              {translate(
                                                "Processing time and identity category"
                                              )}
                                            </h5>
                                            <div className="card-data">
                                              <div>
                                                <h4>
                                                  {dataItem.processingTime}
                                                </h4>
                                                <p>{translate("Months")}</p>
                                              </div>
                                              <div>
                                                <h4>
                                                  {dataItem.indentityCategory}
                                                </h4>
                                                <p>{translate("Category")}</p>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      />
                                      <ComponentBlackCard
                                        children={
                                          <div>
                                            <h5 className="black-card-head">
                                              {translate(
                                                "Investment Requirements"
                                              )}
                                            </h5>
                                            <div
                                              className="card-data-holding"
                                              style={{ paddingTop: "10px" }}
                                            >
                                              <ul>
                                                {dataItem.investmentRequirements.map(
                                                  (investItem) => {
                                                    return (
                                                      <div>
                                                        <li>
                                                          <p>
                                                            {investItem.name}
                                                          </p>
                                                        </li>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        }
                                      />
                                      <ComponentBlackCard
                                        children={
                                          <div>
                                            <h5 className="black-card-head">
                                              {translate("Investment Duration")}
                                            </h5>
                                            <div
                                              className="card-data-holding"
                                              style={{ paddingTop: "10px" }}
                                            >
                                              <div>
                                                <p>
                                                  {dataItem.investmentDuration}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      />
                                    </div>
                                  </div>
                                </AccordionPanel>
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                      </div>
                    </div>
                  }
                </>
              )}
            </>
          );
        })}
      </> */}
    </DashboardContainer>
  );
};

export default FoServicesProductCountry;
