import { useNavigate } from "react-router-dom";
import ComponentButton from "../../atoms/ComponentButton";
import ComponenetInput from "../../atoms/ComponentInput";
import MainContainer from "../../sub-components/MainContainer";
import translate from "../../../translate";
import DUIX from 'duix-guiji'

import { ToastContainer, toast } from "react-toastify";
import "./signup.scss";
import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  SIGN_UP_PHONE_URL,
  SIGN_UP_PHONE_VERIFICATION_URL,
  SIGN_UP_URL,
} from "../../routes";
import { useMutation, useQuery } from "@apollo/client";
import {
  SIGN_IN_MUTATION,
  SIGN_UP_MUTATION,
} from "../../graphql/user.mutation";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";

// interface SignUpProps {
//   type?: string;
// }

const SignUp = ({ type }) => {
  const navigate = useNavigate();
  // const toast = useToast();
  const [email, setEmail] = useState("");
  const [signUp, { data, loading, error }] = useMutation(SIGN_UP_MUTATION, {
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);

      toast.error(err.graphQLErrors[0]?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
      });
      // toast({
      //   title: err.graphQLErrors[0]?.message,
      //   status: "error",
      //   isClosable: true,
      // });
    },
  });

  const [login, { data: loginData, loading: loginLoading, error: loginError }] =
    useMutation(SIGN_IN_MUTATION, {
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);
        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
      },
    });

  useEffect(() => {
    if (data?.SignUp?.succeed && !loading) {
      navigate(`/signup/verification/${email}`);
    }
  }, [data]);

  useEffect(() => {
    if (loginData?.SignInWithOTP?.succeed && !loginLoading) {
      navigate(`/login/verification/${email}`);
    }
  }, [loginData]);

  const handleChange = (event) => {
    setEmail(event?.target?.value);
  };
  const onClickEvent = (event) => {
    if (type === "login") {
      login({
        variables: {
          email: email,
        },
      });
    } else {
      signUp({ variables: { email: email } });
    }
  };

  useEffect(() => {
    const duix = new DUIX()
    duix.on('error', data => {
      console.error(data)
    })
    duix.on('intialSucccess', () => {
      console.info('intialSucccess')
      // 此时初始化成功，可调用start
      duix.start({ conversationId: '1734858863616589825', openAsr: false }).then(res => {
        console.info('start', res)
      })
    })
    duix.on('bye', (data) => {
      console.info('bye', data)
    })
    duix.on('progress', progress => {
      console.info('progress', progress)
    })
    duix.on('show', () => {
      console.info('show')
      // 此时可确认视频已
      document.querySelector('#modal').style.display = "none"
    })
    duix.on('openAsrSuccess', () => {
      console.info('openAsrSuccess')
    })
    duix.on('asrClose', () => {
      console.info('asrClose')
    })
    duix.on('speakStart', (data) => {
      console.info('speakStart', data)
    })
    duix.on('speakEnd', (data) => {
      console.info('speakEnd', data)
    })
    duix.on('speakSection', (data) => {
      console.info('speakSection', data)
    })
    duix.on('speakError', (data) => {
      console.info('speakError', data)
    })
    duix.on('asrResult', data => {
      console.info('asrResult', data)
    })
    duix.on('report', data => {
      // console.info('report', data)
    })
    duix.init({
      sign: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjExODQ1NjYzNzc2NjM1NjU4MjQiLCJpYXQiOjE3MDczOTAzNjEsImV4cCI6MTcwNzQ3Njc2MX0.7R1c71wWShGO_G1xqHsYXlKP5tRxvQlX7GkSQPSptwY",
      containerLable: '.container-label'
    }).then(data => {
      console.info('init', data)
    })
  }, [])

  return (
    <MainContainer>
      <div className="container-label"></div>
      <div className="sign-up">
        <h2>{translate("get_started")}</h2>
        <p>{translate("get_started_subtext")}</p>
        <div className="sign-up-component">
          <ComponenetInput
            handleChange={handleChange}
            placeholder="name@example.com"
            type={"email"}
            value={email}
            // value={""}
          />
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {type === "signup" && (
              <div
                className="reset-pass"
                onClick={() => {
                  navigate(LOGIN_URL);
                }}
              >
                {translate("already_a_member")}
              </div>
            )}
            {type === "login" && (
              <div
                className="reset-pass"
                onClick={() => {
                  navigate(SIGN_UP_URL);
                }}
              >
                 {translate("not_a_user")}
              </div>
            )}
            <div>
              {/* {type === "login" && (
                <div
                  className="reset-pass"
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    navigate(FORGOT_PASSWORD_URL);
                  }}
                >
                  Forgot Password?
                </div>
              )} */}

              <ComponentButton title={translate("next")} onClick={onClickEvent} />
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default SignUp;
