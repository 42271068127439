import "react-responsive-modal/styles.css";
import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Modal } from "react-responsive-modal";
import ComponentButton from "../../atoms/ComponentButton";
import "./SelectUserModal.scss";
import { useNavigate } from "react-router-dom";
import { EDIT_FORM_URL, SECURITY_ACCOUNT_URL, SECURITY_DISCLAIMER_URL } from "../../routes";
import translate from "../../../translate";
import { SECURITY_ACCOUNT_OPENING_ID } from "../../dataHelper";
import { useState } from "react";

const SelectUserModal = ({
  open,
  onClose,
  user,
  hideDocumentUpload,
  hideSecurityForm,
}: //   onUpdateUser,
//   userData,
any) => {
  const navigate = useNavigate();


  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        modal: "security-account-info-modal",
      }}
    >
      <div className="security-account-info-container">
        <div className="security-account-info-text">{translate("Are you New User or an Existing DL Holding Client")}</div>
        <div style={{display:"flex", alignItems:"center"}}>
        <ComponentButton
              title={translate("I’m a New User")}
              // iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                navigate(`${SECURITY_DISCLAIMER_URL}`)
             
              }}
              style={{marginRight:"20px"}}
            />
             <ComponentButton
              title={translate("I’m an Existing Client")}
              whiteBgBtn
              // iconRight={<ChevronRightIcon w={24} h={24} />}
              onClick={() => {
                navigate(`${SECURITY_ACCOUNT_URL}/240512165451447?formUrl=https://alt.jotfor.ms/240512165451447?email14=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}`);
              }}
            />
      </div>
        </div>

       
 


    </Modal>
  );
};

export default SelectUserModal;
