import { url } from "inspector";
import "./main-container.scss";
import backgroundOverlay from "../../../assets/dfo-background.svg";
import backgroundOverlay2 from '../../../assets/web_app_backgroun.svg'

import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeLanguage } from "../../../translate";
import { options } from "../../atoms/ComponentSidebar";
import { useEffect } from "react";
import { gradientColor } from "../../routes";

const getLangValue = () => {
  const lang = localStorage.getItem("user-lang");
  if (lang === "en") {
    return { value: "en", label: "English" };
  } else if (lang === "zh") {
    return { value: "zh", label: "简体中文" };
  } 
  // else if(lang === "zh-hk"){
  //   return { value: "zh-hk", label: "繁體中文" };
  // }
};

const MainContainer = ({ children }: any) => {

  useEffect(() => {
    if(document.getElementById("eself-open-button")){
      const element: any =  document.getElementById("eself-open-button")
      element.style.display = "none"
    }

  }, [])
  return (
    <div
      className="main-container"
      style={{ backgroundImage: `url(${backgroundOverlay2})` }}
      // style={{background: gradientColor}}
    >
      <div
        style={{
          marginTop: "40px",
          marginRight: "40px",
          display: "flex",
          flexDirection: "row-reverse",
          width:"100%"
        }}
      >
        <div>
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "transparent",
              color: "#fff",
            }),
            option: (
              baseStyles,
              { data, isDisabled, isFocused, isSelected }
            ) => ({
              ...baseStyles,
              backgroundColor: isSelected
                ? "rgb(0, 127, 255)"
                : "rgb(37, 37, 37)",
              color: "#fff",
            }),
          }}
          className="language-input-dropdown"
          value={getLangValue() || { label: "English", value: "en" }}
          options={options}
          onChange={(value: any) => {
            console.log("language Value", value);
            localStorage.setItem("user-lang", value?.value);
            changeLanguage(value?.value);
            window.location.reload();
          }}
        />
        </div>
      
      </div>

      <div className="main-child-container">{children}</div>
      <ToastContainer />
    </div>
  );
};

export default MainContainer;
