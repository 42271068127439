import React, { Component } from 'react';
import closeIcon from './close-icon.png';

const Header = (props:{  onClose:any }) => {
  return (
    <div className="sc-header">
      {/* <img className="sc-header--img" src={imageUrl} alt="" /> */}
      <div className="sc-header--team-name"> DLDFO</div>
      <div className="sc-header--close-button" onClick={props.onClose}>
        <img src={closeIcon} alt="" />
      </div>
    </div>
  );
};

export default Header;
