import DashboardContainer from "../../sub-components/DashbordContainer";
import "./Account.scss";
import "../Portfolio/Portfolio.scss";
import { groupBy } from "lodash";
import ProfileImage from "../../../assets/profile.svg";
import PhoneVector from "../../../assets/phone.svg";
import MailVector from "../../../assets/mail.svg";
import HashKeyVector from "../../../assets/hash-key.svg";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";
import { PieChart } from "react-minimal-pie-chart";
import Select from "react-select";
import {
  ChevronRightIcon,
  CloseIcon,
  EditIcon,
  EmailIcon,
  PhoneIcon,
  CheckCircleIcon,
  InfoIcon,
  QuestionIcon,
} from "@chakra-ui/icons";
import {
  ChakraProvider,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  background,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import DollarBag from "../../../assets/dollar-bag.svg";
import { useNavigate } from "react-router-dom";
import {
  ALTERNATIVES_URL,
  EDIT_FORM_URL,
  INVESTMENT_URL,
  LOGIN_URL,
  SECURITY_ACCOUNT_URL,
  SIGN_UP_URL,
  formatCompactNumber,
} from "../../routes";

// import { Grid, useToast } from "@chakra-ui/react";
import { GET_USER } from "../../graphql/user.query";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  LOGOUT_MUTATION,
  UPDATE_USER_MUTATION,
} from "../../graphql/user.mutation";
import EditUserModal from "../../sub-components/EditUserModal";
import { toast } from "react-toastify";
import translate from "../../../translate";
import {
  PI_FORM_ID,
  PRODUCT_NAME,
  SOCKET_URL,
  STATUS,
  STATUS_TEXT,
  formatNumber,
  parseCommaNumber,
  getMonth,
  foStrategyObj,
  W8BEN_FROM_ID,
  VIDEO_VERIFICATION_FORM_ID,
} from "../../dataHelper";
import SecurityFormStatusModal from "../../sub-components/SecurityFormStatusModal";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isDesktop,
} from "react-device-detect";
import {
  GET_INVESTMENT_FORM,
  GET_STRATEGY_PRODUCT,
  GET_USER_INVESTMENT,
} from "../../graphql/investment.query";
import SelectUserModal from "../../sub-components/SelectUserModal";
import PaymentProofsModal from "../../sub-components/PaymentProofsModal";
import UploadNewPaymentProof from "../../sub-components/UploadNewPaymentProof";
import PIFormStatusModal from "../../sub-components/PIFormStatusModal";
import AFELogin from "../../sub-components/AFELogin";
import { GET_HOLDING_NAV_QUERY } from "../../graphql/profile.query";
import moment from "moment";

import bellIcon from "../../../assets/bell.svg";

import whiteBellIcon from "../../../assets/white-bell.svg";
import NotificationBox from "../../sub-components/NotificationBox";
import axios from "axios";
import InvestmentForm from "../../sub-components/InvestmentForm";
import CancelApplicationModal from "../../sub-components/CancelApplicationModal";

const Account = ({ afeLoginData, setAfeLoginData, socket, setSocket }: any) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [selectedClientId, setSelectedClientId] = useState("")
  const [clientIds, setClientIds] = useState([])
  const pieData = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7], // CSS-style declaration
  ];

  const options = {
    title: "My Daily Activities",
    pieHole: 0.4,
    is3D: false,
  };

  const currentDate = new Date();

  const AtDate = `${currentDate.getDate()} ${getMonth(
    currentDate.getMonth()
  )} ${currentDate.getUTCFullYear()}`;

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);
  const [isSecurityStatusModalOpened, setIsSecurityStatusModalOpened] =
    useState(false);
  const [formStatus, setFormStatus] = useState("waiting");
  const [piFormStatus, setPiFormStatus] = useState("waiting");
  const [isSelectUserOpened, setIsSelectUserOpened] = useState(false);

  const [filledFormList, setFilledFormList] = useState([]);
  const [isPaymentProofDialogOpened, setIsPaymentProofDialogOpened] =
    useState(false);
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);

  const [isPIStatusModalOpened, setPIStatusModalOpened] = useState(false);

  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);

  const [productObj, setProductObj] = useState<any>({
    flagship1: {
      id: "",
      status: "",
      form: null,
    },
    flagship2: {
      id: "",
      status: "",
      form: null,
    },
    flagship3: {
      id: "",
      status: "",
      form: null,
    },
    "us-treasury": {
      id: "",
      status: "",
      form: null,
    },
  });

  const [Logout, { data, loading, error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: (data) => {
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // navigate(LOGIN_URL);
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      toast.error(err.graphQLErrors[0]?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
      });
    },
  });
  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.email) {
        setUser(data?.user);
        if(data?.user?.clientId?.length){
          setSelectedClientId(data?.user?.clientId?.[0])
          let list: any = []
          data?.user?.clientId?.forEach((clientId: any) => {
            list.push({
              label: clientId,
              value: clientId
            })
          })
          setClientIds(list)
        }
        const user = data?.user;
        if (user?.existingUser) {
          if (user?.existingUserForm) {
            if (user?.approvals?.existingUserForm?.status === STATUS.APPROVED) {
              setFormStatus("download");
            } else {
              setFormStatus("waiting");
            }
          } else {
            setFormStatus("complete");
          }
        } else if (user?.securityForm && user?.w8BenForm && user?.videoForm) {
          if (
            user?.approvals?.securityForm?.status === STATUS.APPROVED &&
            user?.approvals?.w8BenForm?.status === STATUS.APPROVED &&
            user?.approvals?.videoForm?.status === STATUS.APPROVED
          ) {
            setFormStatus("download");
          } else {
            setFormStatus("waiting");
          }
        } else {
          if (user?.securityForm && !user?.w8BenForm) {
            setFormStatus("w8BenFormComplete");
          } else if (
            user?.securityForm &&
            user?.w8BenForm &&
            !user?.videoForm
          ) {
            setFormStatus("videoFormComplete");
          } else {
            setFormStatus("complete");
          }
        }
        if (user?.piForm) {
          if (user?.approvals?.piForm?.status === STATUS.APPROVED) {
            setPiFormStatus("download");
          } else {
            setPiFormStatus("waiting");
          }
        } else {
          setPiFormStatus("complete");
        }
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
      // toast.error(err.graphQLErrors[0]?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 1,
      //   theme: "dark",
      // });
    },
    fetchPolicy: "no-cache",
  });

  const [lineChartData, setLineChartData] = useState<any>({
    series: [
      {
        name: translate("Net Asset Value (HK$)"),
        data: [
          // "416751.84", "416751.84", "416752.84", "416752.84", "420752.84"
        ],
        color: "#0076e3",
      },
    ],

    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 200,
        zoom: {
          enabled: true,
          type: "x",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: "MMM 'yy",
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return formatNumber(val);
          },
        },
        title: {
          text: "Price",
        },
      },
      tooltip: {
        x: {
          show: true,
          format: "MMM dd, yyyy",
          formatter: undefined,
        },
      },
    },
  });

  const [getNavQuery] = useLazyQuery(GET_HOLDING_NAV_QUERY, {
    onCompleted: (data) => {
      if (data?.HoldingsPagination?.items?.length) {
        let dateList: any = [];
        let dataPoints: any = [];
        data?.HoldingsPagination?.items?.forEach((item: any) => {
          const momentDate = moment(item?.date).unix();
          // dateList.push(momentDate)
          dataPoints.push([momentDate * 1000, parseInt(item?.nav?.toFixed(0))]);
        });
        lineChartData.series[0].data = [...dataPoints];
        setLineChartData({ ...lineChartData });
      }
    },
    fetchPolicy: "no-cache",
  });

  const [connected, setConnected] = useState(false);

  const [cashHoldingPct, setCashHoldingPct] = useState(0);
  const [stockHoldingPct, setStockHoldingPct] = useState(0);
  const [currentAssetValue, setCurrentAssetValue] = useState(0);
  const [isRedeemEnabled, setIsRedeemEnabled] = useState(false);

  const sendMessage = (message: any) => {
    console.log("Send Message", message, socket);
    socket.send(JSON.stringify(message));
  };

  const [getUserInvestments] = useLazyQuery(GET_USER_INVESTMENT, {
    onCompleted: (data) => {
      console.log("getUserInvestments", data);
      if (data?.userInvestments?.items?.length) {
        setIsRedeemEnabled(true);
      } else {
        setIsRedeemEnabled(false);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
    // getStrategyProduct({ variables: {} });

    getUserInvestments({
      variables: {
        filter: {
          approval: {
            status: "approved",
          },
        },
      },
    });

    // const _socket = new WebSocket(SOCKET_URL);

    // _socket.onopen = (socket) => {
    //   setConnected(true);
    //   setSocket(_socket);
    // };
    // _socket.onerror = (error) => {
    //   console.error("WebSocket error:", error);
    // };

    // _socket.onmessage = (event) => {
    //   // const messagesDiv = document.getElementById("messages");
    //   const receivedData = JSON.parse(event.data);
    //   console.log("receivedData123", receivedData);
    //   if (receivedData !== undefined) {
    //     if (receivedData.Msg_ID === "HOLDING_QUERY_RESPONSE"){
    //       console.log("Holding result", receivedData);
    //       if (receivedData?.RESULT) {
    //         let list: any = [];
    //         let cashHoldingCount = 0;
    //         let stockholdingCount = 0;
    //         if (receivedData?.RESULT?.CASH_HOLDING?.length) {
    //           receivedData?.RESULT?.CASH_HOLDING?.map((data: any) => {
    //             list.push({
    //               "Product Type": "Cash Holding",
    //               "Product Name": "Cash Holding",
    //               Currency: data?.CURRENCY_CODE,
    //               [`Closing Price At ${AtDate}`]: "-",
    //               "Market Value": data?.AVAILABLE_MKT_VAL,
    //               "Profit/(Loss)": data?.TOTAL_PNL,
    //             });
    //             cashHoldingCount += parseFloat(
    //               data?.AVAILABLE_BAL.replace(/,/g, "")
    //             );
    //           });
    //         }
    //         if (receivedData?.RESULT?.STOCK_HOLDING?.length) {
    //           receivedData?.RESULT?.STOCK_HOLDING?.map((data: any) => {
    //             list.push({
    //               "Product Type": "Stock Holding",
    //               "Product Name": data?.ENAME,
    //               Currency: data?.CURRENCY_CODE,
    //               [`Closing Price At ${AtDate}`]: data?.LAST_CLOSE_PRICE,
    //               "Market Value": data?.AVAILABLE_MKT_VAL,
    //               "Profit/(Loss)": ((parseCommaNumber(data?.AVAILABLE_QUANTITY)*parseCommaNumber(data?.AVG_COST)) - (parseCommaNumber(data?.AVAILABLE_QUANTITY)*parseCommaNumber(data?.LAST_CLOSE_PRICE))),
    //             });

    //             console.log("List Data", [...list])
    //             stockholdingCount += (parseFloat(
    //               data?.HOLD_QUANTITY.replace(/,/g, "")) * parseFloat(
    //                 data?.LAST_CLOSE_PRICE.replace(/,/g, "") )
    //             );
    //           });
    //         }
    //         const totalMarketvalue = cashHoldingCount + stockholdingCount;
    //         setCurrentAssetValue(totalMarketvalue)
    //         setCashHoldingPct(cashHoldingCount / totalMarketvalue);
    //         setStockHoldingPct(stockholdingCount / totalMarketvalue);
    //         setPortfolioSummary([...list]);
    //       }
    //     }

    //   }
    // };

    // const dummyData = {
    //   LOGIN_ID: "50103026",
    //   CLIENT_ID: "50103026",
    //   CHANNEL: "WS",
    //   SESSION_KEY: "OTY4NTE5ZTUtZmYxMi00Yzhh",
    //   BUYING_POWER: "18,733,952.04",
    //   CNY_BUYING_POWER: "N/A",
    //   USD_BUYING_POWER: "2,422,299.99",
    //   SGD_BUYING_POWER: "0",
    //   AVAILABLE_MKT_VAL: "6,275,250.00",
    //   STATUS: "OK",
    //   RESULT: {
    //     CASH_HOLDING: [
    //       {
    //         BUYING_POWER: "18,733,952.04",
    //         AVAILABLE_MKT_VAL: "6,275,250.00",
    //         TODAY_INTRADAY_PNL: "0.00",
    //         TOTAL_PNL: "6,275,250.00",
    //         NET_DEPOSIT: "1,000,000.00",
    //         UNDER_DUE_AMT: "0.00",
    //         DUE_AND_OVERDUE_AMT: "0.00",
    //         AVAILABLE_MARGIN_VAL: "5,020,200.00",
    //         EX_RATE: "1.000000",
    //         BUY_SOLD_CONSIDERATION: "0.00",
    //         ACCRUED_INTEREST: "0.00",
    //         SOLD_CONSIDERATION: "0.00",
    //         BUY_CONSIDERATION: "0.00",
    //         UNSETTLED_CASH: "0.00",
    //         CASH_ON_HOLD: "0.00",
    //         CASH_BALANCE: "1,000,000.00",
    //         AVAILABLE_BAL: "1,000,000.00",
    //         CURRENCY_CODE: "HKD",
    //         STATUS: "OK",
    //         Msg_ID: "CASH_HOLDING",
    //       },
    //       {
    //         BUYING_POWER: "2,422,299.99",
    //         AVAILABLE_MKT_VAL: "811,389.82",
    //         TODAY_INTRADAY_PNL: "0.00",
    //         TOTAL_PNL: "811,389.82",
    //         NET_DEPOSIT: "1,000,000.00",
    //         UNDER_DUE_AMT: "0.00",
    //         DUE_AND_OVERDUE_AMT: "0.00",
    //         AVAILABLE_MARGIN_VAL: "",
    //         EX_RATE: "7.730000",
    //         BUY_SOLD_CONSIDERATION: "0.00",
    //         ACCRUED_INTEREST: "0.00",
    //         SOLD_CONSIDERATION: "0.00",
    //         BUY_CONSIDERATION: "0.00",
    //         UNSETTLED_CASH: "0.00",
    //         CASH_ON_HOLD: "0.00",
    //         CASH_BALANCE: "1,000,000.00",
    //         AVAILABLE_BAL: "1,000,000.00",
    //         CURRENCY_CODE: "USD",
    //         STATUS: "OK",
    //         Msg_ID: "CASH_HOLDING",
    //       },
    //     ],
    //     STOCK_HOLDING: [
    //       {
    //         LEDGAR_BAL: "100,000",
    //         EX_RATE: "1.000000",
    //         MARGIN_PCT: "80.00%",
    //         MARGIN_VAL: "1,584,800.00",
    //         AVAILABLE_MKT_VAL: "1,981,000.00",
    //         CURRENT_AVG_COST: "0.000",
    //         AVG_COST: "0.000",
    //         LAST_CLOSE_PRICE: "19.810",
    //         CURRENCY_CODE: "HKD",
    //         EXCHG_CD: "SEHK",
    //         TODAY_BOUGHT_QTY: "0",
    //         TODAY_SOLD_QTY: "0",
    //         TODAY_SELL_QTY: "0",
    //         DUE_AND_OVERDUE_QTY: "0",
    //         UNDER_DUE_REC_QTY: "0",
    //         UNDER_DUE_PAY_QTY: "0",
    //         UNDER_DUE_QTY: "0",
    //         HOLD_QUANTITY: "100,000",
    //         AVAILABLE_QUANTITY: "100,000",
    //         TNAME: "盈富基金",
    //         SNAME: "盈富基金",
    //         ENAME: "TRACKER FUND",
    //         INSTRUMENT_NAME: "TRACKER FUND",
    //         INSTRUMENT_CODE: "2800",
    //         MARKET: "HK",
    //         STATUS: "OK",
    //         Msg_ID: "STOCK_HOLDING",
    //       },
    //       {
    //         LEDGAR_BAL: "5,000",
    //         EX_RATE: "1.000000",
    //         MARGIN_PCT: "80.00%",
    //         MARGIN_VAL: "275,400.00",
    //         AVAILABLE_MKT_VAL: "344,250.00",
    //         CURRENT_AVG_COST: "0.000",
    //         AVG_COST: "0.000",
    //         LAST_CLOSE_PRICE: "68.850",
    //         CURRENCY_CODE: "HKD",
    //         EXCHG_CD: "SEHK",
    //         TODAY_BOUGHT_QTY: "0",
    //         TODAY_SOLD_QTY: "0",
    //         TODAY_SELL_QTY: "0",
    //         DUE_AND_OVERDUE_QTY: "0",
    //         UNDER_DUE_REC_QTY: "0",
    //         UNDER_DUE_PAY_QTY: "0",
    //         UNDER_DUE_QTY: "0",
    //         HOLD_QUANTITY: "5,000",
    //         AVAILABLE_QUANTITY: "5,000",
    //         TNAME: "匯豐控股",
    //         SNAME: "汇丰控股",
    //         ENAME: "HSBC HOLDINGS",
    //         INSTRUMENT_NAME: "HSBC HOLDINGS",
    //         INSTRUMENT_CODE: "5",
    //         MARKET: "HK",
    //         STATUS: "OK",
    //         Msg_ID: "STOCK_HOLDING",
    //       },
    //       {
    //         LEDGAR_BAL: "10,000",
    //         EX_RATE: "1.000000",
    //         MARGIN_PCT: "80.00%",
    //         MARGIN_VAL: "3,160,000.00",
    //         AVAILABLE_MKT_VAL: "3,950,000.00",
    //         CURRENT_AVG_COST: "0.000",
    //         AVG_COST: "0.000",
    //         LAST_CLOSE_PRICE: "395.000",
    //         CURRENCY_CODE: "HKD",
    //         EXCHG_CD: "SEHK",
    //         TODAY_BOUGHT_QTY: "0",
    //         TODAY_SOLD_QTY: "0",
    //         TODAY_SELL_QTY: "0",
    //         DUE_AND_OVERDUE_QTY: "0",
    //         UNDER_DUE_REC_QTY: "0",
    //         UNDER_DUE_PAY_QTY: "0",
    //         UNDER_DUE_QTY: "0",
    //         HOLD_QUANTITY: "10,000",
    //         AVAILABLE_QUANTITY: "10,000",
    //         TNAME: "騰訊控股",
    //         SNAME: "腾讯控股",
    //         ENAME: "TENCENT",
    //         INSTRUMENT_NAME: "TENCENT",
    //         INSTRUMENT_CODE: "700",
    //         MARKET: "HK",
    //         STATUS: "OK",
    //         Msg_ID: "STOCK_HOLDING",
    //       },
    //     ],
    //   },
    //   Msg_ID: "HOLDING_QUERY_RESPONSE",
    // };

    // if (dummyData?.RESULT) {
    //   let list: any = [];
    //   let cashHoldingCount = 0;
    //   let stockholdingCount = 0;
    //   if (dummyData?.RESULT?.CASH_HOLDING?.length) {
    //     dummyData?.RESULT?.CASH_HOLDING?.map((data: any) => {
    //       list.push({
    //         "Product Type": "CASH_HOLDING",
    //         "Product Name": "CASH_HOLDING",
    //         Currency: data?.CURRENCY_CODE,
    //         "Closing Price": "-",
    //         "Market Value": data?.AVAILABLE_MKT_VAL,
    //         "Unrealised Profit/(Loss)": data?.TOTAL_PNL,
    //       });
    //       cashHoldingCount += parseFloat(
    //         data?.AVAILABLE_MKT_VAL.replace(/,/g, "")
    //       );
    //     });
    //   }
    //   if (dummyData?.RESULT?.STOCK_HOLDING?.length) {
    //     dummyData?.RESULT?.STOCK_HOLDING?.map((data: any) => {
    //       list.push({
    //         "Product Type": "STOCK_HOLDING",
    //         "Product Name": data?.ENAME,
    //         Currency: data?.CURRENCY_CODE,
    //         "Closing Price": data?.LAST_CLOSE_PRICE,
    //         "Market Value": data?.AVAILABLE_MKT_VAL,
    //         "Unrealised Profit/(Loss)": "-",
    //       });
    //       stockholdingCount += parseFloat(
    //         data?.AVAILABLE_MKT_VAL.replace(/,/g, "")
    //       );
    //     });
    //   }
    //   const totalMarketvalue = cashHoldingCount + stockholdingCount;
    //   setCashHoldingPct(cashHoldingCount / totalMarketvalue);
    //   setStockHoldingPct(stockholdingCount / totalMarketvalue);
    //   setPortfolioSummary(list);
    // }
  }, []);

  const getProfitLossValue = (data: number) => {
    console.log("getProfitLossValue", data);
    if (data < 0) {
      return `-HK$ ${formatNumber(Math.abs(Math.floor(data)))}`;
    } else {
      return `HK$ ${formatNumber(Math.floor(data))}`;
    }
  };

  useEffect(() => {
    if (selectedClientId) {
      const data = JSON.stringify({
        clientId: selectedClientId,
      });
      const token: any = localStorage.getItem("authToken");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://app.dlifo.com/api/afe/holdings`,
        headers: {
          "Content-Type": "application/json",
          Authentication: `Bearer ${token}`,
        },
        data: data,
      };

      // const receivedData = {
      //   LOGIN_ID: "COD_TESTING",
      //   CLIENT_ID: "10030002",
      //   CHANNEL: "WU",
      //   SESSION_KEY: "YWZlNDhjNmUtZGFmZC00ZGFk",
      //   BUYING_POWER: "28,747.49",
      //   CNY_BUYING_POWER: "N/A",
      //   USD_BUYING_POWER: "3,694.05",
      //   SGD_BUYING_POWER: "0",
      //   AVAILABLE_MKT_VAL: "833,263.24",
      //   STATUS: "OK",
      //   RESULT: {
      //     CASH_HOLDING: [
      //       {
      //         BUYING_POWER: "28,747.49",
      //         AVAILABLE_MKT_VAL: "833,263.24",
      //         TODAY_INTRADAY_PNL: "0.00",
      //         TOTAL_PNL: "79,924.08",
      //         NET_DEPOSIT: "0.00",
      //         UNDER_DUE_AMT: "0.00",
      //         DUE_AND_OVERDUE_AMT: "0.00",
      //         AVAILABLE_MARGIN_VAL: "0.00",
      //         EX_RATE: "1.000000",
      //         BUY_SOLD_CONSIDERATION: "0.00",
      //         ACCRUED_INTEREST: "0.00",
      //         SOLD_CONSIDERATION: "0.00",
      //         BUY_CONSIDERATION: "0.00",
      //         UNSETTLED_CASH: "0.00",
      //         CASH_ON_HOLD: "0.00",
      //         CASH_BALANCE: "8,820.49",
      //         AVAILABLE_BAL: "8,820.49",
      //         CURRENCY_CODE: "HKD",
      //         STATUS: "OK",
      //         Msg_ID: "CASH_HOLDING",
      //       },
      //       {
      //         BUYING_POWER: "3,694.05",
      //         AVAILABLE_MKT_VAL: "107,074.32",
      //         TODAY_INTRADAY_PNL: "0.00",
      //         TOTAL_PNL: "10,270.24",
      //         NET_DEPOSIT: "0.00",
      //         UNDER_DUE_AMT: "0.00",
      //         DUE_AND_OVERDUE_AMT: "0.00",
      //         AVAILABLE_MARGIN_VAL: "",
      //         EX_RATE: "7.780000",
      //         BUY_SOLD_CONSIDERATION: "0.00",
      //         ACCRUED_INTEREST: "0.00",
      //         SOLD_CONSIDERATION: "0.00",
      //         BUY_CONSIDERATION: "0.00",
      //         UNSETTLED_CASH: "0.00",
      //         CASH_ON_HOLD: "0.00",
      //         CASH_BALANCE: "2,560.62",
      //         AVAILABLE_BAL: "2,560.62",
      //         CURRENCY_CODE: "USD",
      //         STATUS: "OK",
      //         Msg_ID: "CASH_HOLDING",
      //       },
      //     ],
      //     STOCK_HOLDING: [
      //       {
      //         LEDGAR_BAL: "22",
      //         EX_RATE: "1.000000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "233,128.61",
      //         CURRENT_AVG_COST: "8,746.599",
      //         AVG_COST: "8,746.599",
      //         LAST_CLOSE_PRICE: "10,596.755",
      //         CURRENCY_CODE: "HKD",
      //         EXCHG_CD: "BDHK",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "22",
      //         AVAILABLE_QUANTITY: "22",
      //         TNAME:
      //           "CMF GLOBAL QUANTITATIVE MULTI-ASSET SEGREGATED PORTFOLIO COMPANY - CMF FS ASIA E",
      //         SNAME:
      //           "CMF GLOBAL QUANTITATIVE MULTI-ASSET SEGREGATED PORTFOLIO COMPANY - CMF FS ASIA E",
      //         ENAME:
      //           "CMF GLOBAL QUANTITATIVE MULTI-ASSET SEGREGATED PORTFOLIO COMPANY - CMF FS ASIA E",
      //         INSTRUMENT_NAME:
      //           "CMF GLOBAL QUANTITATIVE MULTI-ASSET SEGREGATED PORTFOLIO COMPANY - CMF FS ASIA E",
      //         INSTRUMENT_CODE: "CMFG-SP3-C",
      //         MARKET: "OR",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "16",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "17,218.72",
      //         CURRENT_AVG_COST: "979.51",
      //         AVG_COST: "979.51",
      //         LAST_CLOSE_PRICE: "1,076.17",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "FUND",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "16",
      //         AVAILABLE_QUANTITY: "16",
      //         TNAME:
      //           "VSTH GLOBAL INVESTMENT SPC - VSTH DIRECT LENDING SP - CLASS A~SERIES00128JUN28(N",
      //         SNAME:
      //           "VSTH GLOBAL INVESTMENT SPC - VSTH DIRECT LENDING SP - CLASS A~SERIES00128JUN28(N",
      //         ENAME:
      //           "VSTH GLOBAL INVESTMENT SPC - VSTH DIRECT LENDING SP - CLASS A~SERIES00128JUN28(N",
      //         INSTRUMENT_NAME:
      //           "VSTH GLOBAL INVESTMENT SPC - VSTH DIRECT LENDING SP - CLASS A~SERIES00128JUN28(N",
      //         INSTRUMENT_CODE: "VSTHDL-A",
      //         MARKET: "OR",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "28",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "2,072.28",
      //         CURRENT_AVG_COST: "68.0525",
      //         AVG_COST: "68.0525",
      //         LAST_CLOSE_PRICE: "74.0100",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "28",
      //         AVAILABLE_QUANTITY: "28",
      //         TNAME: "iShares MSCI All Country Asia Ex Japan",
      //         SNAME: "iShares MSCI All Country Asia Ex Japan",
      //         ENAME: "iShares MSCI All Country Asia Ex Japan",
      //         INSTRUMENT_NAME: "iShares MSCI All Country Asia Ex Japan",
      //         INSTRUMENT_CODE: "AAXJ",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "127",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "6,189.98",
      //         CURRENT_AVG_COST: "48.9854",
      //         AVG_COST: "48.9854",
      //         LAST_CLOSE_PRICE: "48.7400",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "127",
      //         AVAILABLE_QUANTITY: "127",
      //         TNAME: "Vanguard Total Intl",
      //         SNAME: "Vanguard Total Intl",
      //         ENAME: "Vanguard Total Intl",
      //         INSTRUMENT_NAME: "Vanguard Total Intl",
      //         INSTRUMENT_CODE: "BNDX",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "44",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "682.44",
      //         CURRENT_AVG_COST: "14.8291",
      //         AVG_COST: "14.8291",
      //         LAST_CLOSE_PRICE: "15.5100",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "44",
      //         AVAILABLE_QUANTITY: "44",
      //         TNAME: "GLOBAL X DATA CENTER REITS & DIGI INFRA ETF",
      //         SNAME: "GLOBAL X DATA CENTER REITS & DIGI INFRA ETF",
      //         ENAME: "GLOBAL X DATA CENTER REITS & DIGI INFRA ETF",
      //         INSTRUMENT_NAME: "GLOBAL X DATA CENTER REITS & DIGI INFRA ETF",
      //         INSTRUMENT_CODE: "DTCR",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "52",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "4,144.40",
      //         CURRENT_AVG_COST: "72.8160",
      //         AVG_COST: "72.8160",
      //         LAST_CLOSE_PRICE: "79.7000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "52",
      //         AVAILABLE_QUANTITY: "52",
      //         TNAME: "iShares Trust iShares ESG Aware MSCI EAFE ETF",
      //         SNAME: "iShares Trust iShares ESG Aware MSCI EAFE ETF",
      //         ENAME: "iShares Trust iShares ESG Aware MSCI EAFE ETF",
      //         INSTRUMENT_NAME: "iShares Trust iShares ESG Aware MSCI EAFE ETF",
      //         INSTRUMENT_CODE: "ESGD",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "71",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "7,016.22",
      //         CURRENT_AVG_COST: "86.5446",
      //         AVG_COST: "86.5446",
      //         LAST_CLOSE_PRICE: "98.8200",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "71",
      //         AVAILABLE_QUANTITY: "71",
      //         TNAME: "Vanguard ESG US Stock",
      //         SNAME: "Vanguard ESG US Stock",
      //         ENAME: "Vanguard ESG US Stock",
      //         INSTRUMENT_NAME: "Vanguard ESG US Stock",
      //         INSTRUMENT_CODE: "ESGV",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "160",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "11,404.80",
      //         CURRENT_AVG_COST: "68.8778",
      //         AVG_COST: "68.8778",
      //         LAST_CLOSE_PRICE: "71.2800",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "160",
      //         AVAILABLE_QUANTITY: "160",
      //         TNAME: "ISHRS MSCI Japan",
      //         SNAME: "ISHRS MSCI Japan",
      //         ENAME: "ISHRS MSCI Japan",
      //         INSTRUMENT_NAME: "ISHRS MSCI Japan",
      //         INSTRUMENT_CODE: "EWJ",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "11",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "2,412.96",
      //         CURRENT_AVG_COST: "170.7791",
      //         AVG_COST: "170.7791",
      //         LAST_CLOSE_PRICE: "219.3600",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "11",
      //         AVAILABLE_QUANTITY: "11",
      //         TNAME: "SPDR GLD - Amex",
      //         SNAME: "SPDR GLD - Amex",
      //         ENAME: "SPDR GLD - Amex",
      //         INSTRUMENT_NAME: "SPDR GLD - Amex",
      //         INSTRUMENT_CODE: "GLD",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "74",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "2,979.98",
      //         CURRENT_AVG_COST: "42.2854",
      //         AVG_COST: "42.2854",
      //         LAST_CLOSE_PRICE: "40.2700",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "74",
      //         AVAILABLE_QUANTITY: "74",
      //         TNAME: "Flexshares Global Upstream Natural Res",
      //         SNAME: "Flexshares Global Upstream Natural Res",
      //         ENAME: "Flexshares Global Upstream Natural Res",
      //         INSTRUMENT_NAME: "Flexshares Global Upstream Natural Res",
      //         INSTRUMENT_CODE: "GUNR",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "123",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "4,018.41",
      //         CURRENT_AVG_COST: "32.094553",
      //         AVG_COST: "32.094553",
      //         LAST_CLOSE_PRICE: "32.670000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "123",
      //         AVAILABLE_QUANTITY: "123",
      //         TNAME: "ISHARE BITCOIN TRUST - ETF",
      //         SNAME: "ISHARE BITCOIN TRUST - ETF",
      //         ENAME: "ISHARE BITCOIN TRUST - ETF",
      //         INSTRUMENT_NAME: "ISHARE BITCOIN TRUST - ETF",
      //         INSTRUMENT_CODE: "IBIT",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "35",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "3,242.40",
      //         CURRENT_AVG_COST: "90.080000",
      //         AVG_COST: "90.080000",
      //         LAST_CLOSE_PRICE: "92.640000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "35",
      //         AVAILABLE_QUANTITY: "35",
      //         TNAME: "iShares Global Healthcare",
      //         SNAME: "iShares Global Healthcare",
      //         ENAME: "iShares Global Healthcare",
      //         INSTRUMENT_NAME: "iShares Global Healthcare",
      //         INSTRUMENT_CODE: "IXJ",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "23",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "1,975.70",
      //         CURRENT_AVG_COST: "84.0809",
      //         AVG_COST: "84.0809",
      //         LAST_CLOSE_PRICE: "85.9000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "23",
      //         AVAILABLE_QUANTITY: "23",
      //         TNAME: "VanEck Morningstar Wide Moat ETF",
      //         SNAME: "VanEck Morningstar Wide Moat ETF",
      //         ENAME: "VanEck Morningstar Wide Moat ETF",
      //         INSTRUMENT_NAME: "VanEck Morningstar Wide Moat ETF",
      //         INSTRUMENT_CODE: "MOAT",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "19",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "3,292.51",
      //         CURRENT_AVG_COST: "158.392632",
      //         AVG_COST: "158.392632",
      //         LAST_CLOSE_PRICE: "173.290000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "19",
      //         AVAILABLE_QUANTITY: "19",
      //         TNAME: "iShares MSCI USA Quality Factor ETF",
      //         SNAME: "iShares MSCI USA Quality Factor ETF",
      //         ENAME: "iShares MSCI USA Quality Factor ETF",
      //         INSTRUMENT_NAME: "iShares MSCI USA Quality Factor ETF",
      //         INSTRUMENT_CODE: "QUAL",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "10",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "2,748.10",
      //         CURRENT_AVG_COST: "202.280000",
      //         AVG_COST: "202.280000",
      //         LAST_CLOSE_PRICE: "274.810000",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "10",
      //         AVAILABLE_QUANTITY: "10",
      //         TNAME: "VanEck Semiconductor ETF",
      //         SNAME: "VanEck Semiconductor ETF",
      //         ENAME: "VanEck Semiconductor ETF",
      //         INSTRUMENT_NAME: "VanEck Semiconductor ETF",
      //         INSTRUMENT_CODE: "SMH",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "40",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "3,021.60",
      //         CURRENT_AVG_COST: "73.2688",
      //         AVG_COST: "73.2688",
      //         LAST_CLOSE_PRICE: "75.5400",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "40",
      //         AVAILABLE_QUANTITY: "40",
      //         TNAME: "VG Ftse Pacf",
      //         SNAME: "VG Ftse Pacf",
      //         ENAME: "VG Ftse Pacf",
      //         INSTRUMENT_NAME: "VG Ftse Pacf",
      //         INSTRUMENT_CODE: "VPL",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //       {
      //         LEDGAR_BAL: "95",
      //         EX_RATE: "7.780000",
      //         MARGIN_PCT: "0.00%",
      //         MARGIN_VAL: "0.00",
      //         AVAILABLE_MKT_VAL: "4,696.80",
      //         CURRENT_AVG_COST: "49.1536",
      //         AVG_COST: "49.1536",
      //         LAST_CLOSE_PRICE: "49.4400",
      //         CURRENCY_CODE: "USD",
      //         EXCHG_CD: "SEUS",
      //         TODAY_BOUGHT_QTY: "0",
      //         TODAY_SOLD_QTY: "0",
      //         TODAY_SELL_QTY: "0",
      //         DUE_AND_OVERDUE_QTY: "0",
      //         UNDER_DUE_REC_QTY: "0",
      //         UNDER_DUE_PAY_QTY: "0",
      //         UNDER_DUE_QTY: "0",
      //         HOLD_QUANTITY: "95",
      //         AVAILABLE_QUANTITY: "95",
      //         TNAME: "Vanguard Ultra Short-Term Bond ETF",
      //         SNAME: "Vanguard Ultra Short-Term Bond ETF",
      //         ENAME: "Vanguard Ultra Short-Term Bond ETF",
      //         INSTRUMENT_NAME: "Vanguard Ultra Short-Term Bond ETF",
      //         INSTRUMENT_CODE: "VUSB",
      //         MARKET: "US",
      //         STATUS: "OK",
      //         Msg_ID: "STOCK_HOLDING",
      //       },
      //     ],
      //   },
      //   Msg_ID: "HOLDING_QUERY_RESPONSE",
      // };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          if (response?.data) {
            const receivedData = response?.data;
            if (receivedData !== undefined) {
              if (receivedData.Msg_ID === "HOLDING_QUERY_RESPONSE") {
                console.log("Holding result", receivedData);
                if (receivedData?.RESULT) {
                  let list: any = [];
                  let cashHoldingCount = 0;
                  let stockholdingCount = 0;
                  if (receivedData?.RESULT?.CASH_HOLDING?.length) {
                    receivedData?.RESULT?.CASH_HOLDING?.map((data: any) => {
                      list.push({
                        "Product Type": "Cash Holding",
                        "Product Name": "Cash Holding",
                        Currency: data?.CURRENCY_CODE,
                        [`Closing Price At ${AtDate}`]: "-",
                        "Market Value": `HK$ ${formatNumber(
                          Math.round(parseCommaNumber(data?.AVAILABLE_MKT_VAL))
                        )}`,
                        "Profit/(Loss)": getProfitLossValue(
                          parseCommaNumber(data?.TOTAL_PNL)
                        ),
                      });
                      cashHoldingCount += parseFloat(
                        data?.AVAILABLE_BAL.replace(/,/g, "")
                      );
                    });
                  }
                  if (receivedData?.RESULT?.STOCK_HOLDING?.length) {
                    receivedData?.RESULT?.STOCK_HOLDING?.map((data: any) => {
                      list.push({
                        "Product Type": "Stock Holding",
                        "Product Name": data?.ENAME,
                        Currency: data?.CURRENCY_CODE,
                        [`Closing Price At ${AtDate}`]: `HK$ ${formatNumber(
                          Math.round(parseCommaNumber(data?.LAST_CLOSE_PRICE))
                        )}`,
                        "Market Value": `HK$ ${formatNumber(
                          Math.round(parseCommaNumber(data?.AVAILABLE_MKT_VAL))
                        )}`,
                        "Profit/(Loss)": getProfitLossValue(
                          parseCommaNumber(data?.AVAILABLE_QUANTITY) *
                            parseCommaNumber(data?.AVG_COST) -
                            parseCommaNumber(data?.AVAILABLE_QUANTITY) *
                              parseCommaNumber(data?.LAST_CLOSE_PRICE)
                        ),
                      });

                      console.log("List Data", [...list]);
                      stockholdingCount +=
                        parseFloat(data?.HOLD_QUANTITY.replace(/,/g, "")) *
                        parseFloat(data?.LAST_CLOSE_PRICE.replace(/,/g, ""));
                    });
                  }
                  const totalMarketvalue = cashHoldingCount + stockholdingCount;
                  setCurrentAssetValue(totalMarketvalue);
                  setCashHoldingPct(cashHoldingCount / totalMarketvalue);
                  setStockHoldingPct(stockholdingCount / totalMarketvalue);
                  setPortfolioSummary([...list]);
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      getNavQuery({
        variables: {
          filter: {
            // accountId: "10030002",
            accountId: selectedClientId,
          },
          perPage: 365,
        },
      });
    }
  }, [selectedClientId]);

  const [UpdateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: (data) => {
      console.log("USer Data", data);
      setUser(data?.UpdateUser);
      toast.success("User Updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
      });
      setIsOpenEditModal(false);
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);

      toast.error(err.graphQLErrors[0]?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        theme: "dark",
      });
    },
  });

  const onUpdateUser = (values: any) => {
    console.log("User Values", values);
    UpdateUser({
      variables: {
        input: values,
      },
    });
  };

  const getInitials = () => {
    let initials = "";
    if (user?.firstName) {
      initials = user?.firstName?.[0];
    }
    if (user?.middleName) {
      initials = `${initials}${user?.middleName?.[0]}`;
    }
    if (user?.lastName) {
      initials = `${initials}${user?.lastName?.[0]}`;
    }
    return initials;
  };

  const [getStrategyProduct] = useLazyQuery(GET_STRATEGY_PRODUCT, {
    onCompleted: (data) => {
      if (data?.ProductPagination?.items?.length) {
        productObj["flagship1"].id =
          data?.ProductPagination?.items?.[0]?.productId;
        productObj["flagship2"].id =
          data?.ProductPagination?.items?.[1]?.productId;
        productObj["flagship3"].id =
          data?.ProductPagination?.items?.[2]?.productId;
        productObj["us-treasury"].id =
          data?.ProductPagination?.items?.[3]?.productId;

        setProductObj({ ...productObj });
        getProductInvestmentForms(data?.ProductPagination?.items);
        // setSelectedProductId(data?.ProductPagination?.items?.[0]?.productId)
        // setInvestmentFormList(data?.ProductPagination?.items?.[0]?.forms)
        // getInvestmentFormStatus({
        //   variables: {
        //     filter: {
        //       productId: data?.ProductPagination?.items?.[0]?.productId
        //     }

        //   }
        // })
      }
    },
    fetchPolicy: "no-cache",
  });

  const getProductInvestmentForms = (productList: any) => {
    getInvestmentFormStatus({
      variables: {},
    }).then((response: any) => {
      const data = response?.data;
      if (data?.InvestmentFormPagination?.items?.length) {
        let productListWithStatus: any = [];
        const groupedProduct = groupBy(
          data?.InvestmentFormPagination?.items,
          "productId"
        );
        console.log("ProductId", groupedProduct);
        const statusFormList: any = [];
        Object.keys(groupedProduct)?.forEach((itemKey: any) => {
          const item = groupedProduct[itemKey];
          const productData = productList?.find(
            (p: any) => p.productId === itemKey
          );

          console.log(
            " Object.keys(groupedProduct)?.length",
            Object.keys(groupedProduct)?.length,
            productData?.forms?.length
          );
          // if (item?.length === productData?.forms?.length) {

          productData?.forms?.forEach((form: any) => {
            const isFormPresent = data?.InvestmentFormPagination?.items?.find(
              (i: any) => i?.formID === form?.formId
            );

            if (isFormPresent?.formID === "240252269886465") {
              if (productObj["flagship1"]?.id === itemKey) {
                productObj["flagship1"].status =
                  isFormPresent?.approval?.status;
                productObj["flagship1"].form = isFormPresent;
                statusFormList.push({
                  ...isFormPresent,
                  name: PRODUCT_NAME["flagship1"],
                });
              } else if (productObj["flagship2"]?.id === itemKey) {
                productObj["flagship2"].status =
                  isFormPresent?.approval?.status;
                productObj["flagship2"].form = isFormPresent;
                statusFormList.push({
                  ...isFormPresent,
                  name: PRODUCT_NAME["flagship2"],
                });
              } else if (productObj["flagship3"]?.id === itemKey) {
                productObj["flagship3"].status =
                  isFormPresent?.approval?.status;
                productObj["flagship3"].form = isFormPresent;
                statusFormList.push({
                  ...isFormPresent,
                  name: PRODUCT_NAME["flagship3"],
                });
              } else if (productObj["us-treasury"]?.id === itemKey) {
                productObj["us-treasury"].status =
                  isFormPresent?.approval?.status;
                productObj["us-treasury"].form = isFormPresent;
                statusFormList.push({
                  ...isFormPresent,
                  name: PRODUCT_NAME["us-treasury"],
                });
              }
              // statusFormList.push({
              //   ...form,
              //   ...isFormPresent?.approval,
              //   ...isFormPresent,
              // });
            }
          });

          console.log(" productObj[", productObj["flagship1"]);

          // if(productObj["flagship1"]?.id === itemKey){
          //   productObj["flagship1"].status =
          // }
          // productListWithStatus.push({
          //   id: itemKey,
          //   name: productData?.name,
          //   formList: [...statusFormList],
          // });
          // }
        });

        setProductObj({ ...productObj });
        setFilledFormList(statusFormList);
      }
    });
  };

  const [getInvestmentFormStatus] = useLazyQuery(GET_INVESTMENT_FORM, {
    fetchPolicy: "no-cache",
  });
  const portfolioData = {
    headers: [
      translate("Product Type"),
      translate("Product Name"),
      translate("Currency"),
      // 'Quantity',
      // 'Cost Per Unit',
      // 'Transaction Cost Per unit',
      // 'Total Cost',
      // 'Actual Cost (%) to the Portfolio',
      // 'Target Cost (%) to the Portfolio',
      // 'Difference between Actual and Target Costs',
      translate("Closing Price At") + " " + AtDate,
      translate("Market Value"),
      translate("Profit/(Loss)"),
    ],

    client: { clientId: "10030002", clientName: "Ma Hing Mo Raymond" },
    positionDate: "2024-01-18T00:00:00.000Z",
    netAsset: "848520.5333792042",
  };
  const getNumberValue = (header: string, value: any) => {
    if (
      (header === "Closing Price At 18 Jan 24" ||
        header === "Market Value at 18 Jan 24") &&
      value !== "-"
    ) {
      return parseFloat(value).toFixed(2);
    }
    return value;
  };

  const [portfolioSummary, setPortfolioSummary] = useState<any>([
    // {
    //   "Product Code": "VUSB           ",
    //   "Product Name": "Vanguard Ultra Short-Term Bond ETF",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "49.68",
    //   "Market Value at 18 Jan 24": "6607.44",
    //   "Unrealised Profit/(Loss)": "20.919999999999163",
    // },
    // {
    //   "Product Code": "AAXJ           ",
    //   "Product Name": "iShares MSCI All Country Asia Ex Japan",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "63.05",
    //   "Market Value at 18 Jan 24": "2585.0499999999997",
    //   "Unrealised Profit/(Loss)": "-50.77000000000044",
    // },
    // {
    //   "Product Code": "EWJ            ",
    //   "Product Name": "ISHRS MSCI Japan",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "66.21",
    //   "Market Value at 18 Jan 24": "926.9399999999999",
    //   "Unrealised Profit/(Loss)": "19.379999999999995",
    // },
    // {
    //   "Product Code": "GLD            ",
    //   "Product Name": "SPDR GLD - Amex",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "188.45",
    //   "Market Value at 18 Jan 24": "753.8",
    //   "Unrealised Profit/(Loss)": "-11.920000000000073",
    // },
    // {
    //   "Product Code": "CMFG-SP3-C     ",
    //   "Product Name":
    //     "CMF Global Quantitative Multi-Asset Segregated Portfolio Company - CMF FS Asia Equity Opportunity SP-Class-SP3-C",
    //   Currency: "HKD",
    //   "Closing Price at 18 Jan 24": "11045.535",
    //   "Market Value at 18 Jan 24": "122649.62064",
    //   "Unrealised Profit/(Loss)": "122649.62064",
    // },
    // {
    //   "Product Code": "2800",
    //   "Product Name": "TRACKER FUND",
    //   Currency: "HKD",
    //   "Closing Price at 18 Jan 24": "15.62",
    //   "Market Value at 18 Jan 24": "15620",
    //   "Unrealised Profit/(Loss)": "-884.3899999999994",
    // },
    // {
    //   "Product Code": "ESGD           ",
    //   "Product Name": "Ishares ESG Aware MSCI EAFE ETF",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "75.25",
    //   "Market Value at 18 Jan 24": "1806",
    //   "Unrealised Profit/(Loss)": "7",
    // },
    // {
    //   "Product Code": "ESGV           ",
    //   "Product Name": "Vanguard ESG US Stock ETF",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "86.21",
    //   "Market Value at 18 Jan 24": "862.0999999999999",
    //   "Unrealised Profit/(Loss)": "8.599999999999909",
    // },
    // {
    //   "Product Code": "VPL            ",
    //   "Product Name": "Vanguard FTSE Pacific ETF",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "71.38",
    //   "Market Value at 18 Jan 24": "856.56",
    //   "Unrealised Profit/(Loss)": "-16.959999999999923",
    // },
    // {
    //   "Product Code": "VSTHDL-A       ",
    //   "Product Name":
    //     "VSTH GLOBAL INVESTMENT SPC-VSTH DIRECT LENDING SP-CLASS A",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "1033.72",
    //   "Market Value at 18 Jan 24": "7846.96852",
    //   "Unrealised Profit/(Loss)": "7846.96852",
    // },
    // {
    //   "Product Code": "BNDX           ",
    //   "Product Name": "VANGUARD TOTAL INTL BOND ETF",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "49.08",
    //   "Market Value at 18 Jan 24": "2993.88",
    //   "Unrealised Profit/(Loss)": "-1.2399999999997817",
    // },
    // {
    //   "Product Code": "MOAT           ",
    //   "Product Name": "VANECK MORNINGSTAR WIDE MOAT",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "83.31",
    //   "Market Value at 18 Jan 24": "416.55",
    //   "Unrealised Profit/(Loss)": "-13.149999999999977",
    // },
    // {
    //   "Product Code": "VPN            ",
    //   "Product Name": "GLOBAL X DATA CENTER REITS",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "14.5",
    //   "Market Value at 18 Jan 24": "304.5",
    //   "Unrealised Profit/(Loss)": "-12.660000000000025",
    // },
    // {
    //   "Product Code": "GUNR           ",
    //   "Product Name": "FLEXSHARES GLOBAL UPSTREAM N",
    //   Currency: "USD",
    //   "Closing Price at 18 Jan 24": "38.42",
    //   "Market Value at 18 Jan 24": "422.62",
    //   "Unrealised Profit/(Loss)": "-30.029999999999973",
    // },
  ]);
  const defaultLabelStyle = {
    fontSize: "5px",
    fontFamily: "sans-serif",
    fill: "#fff",
  };

  const [pieChartData, setPieChartData] = useState<any>({
    series: [50, 35, 8, 5],
    labels: ["Fixed Income", "Equity", "Others", "Alternative"],
    options: {
      legend: {
        show: true,
        position: "bottom",
        floating: false,
        verticalAlign: "bottom",
        align: "center",
      },
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: true,
      },
    },
  });

  console.log("currentAssetValue", currentAssetValue);

  const bellClassName = () => {
    if (isNotificationOpened) {
      return "bell-container bell-active";
    }
    return "bell-container";
  };
  const [isInvestmentFormOpened, setIsInvestmentFormOpened] = useState(false);

  return (
    <DashboardContainer>
      <div className="account-page">
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                <div className="title-small-heading">
                  {translate("USER PROFILE")}
                </div>
                <h3 className="gradient-heading">{translate("Account")}</h3>
                <div style={{ color: "#37454b", fontSize: "14px" }}>
                  {translate(
                    "Manage your financial future: Access account information and explore your investment portfolio, all conveniently located in one place."
                  )}
                </div>
              </div>
              <NotificationBox
                setIsNotificationOpened={setIsNotificationOpened}
                isNotificationOpened={isNotificationOpened}
                bellClassName={bellClassName}
              />

              {/* <div className={bellClassName()} onClick={() => {
                setIsNotificationOpened(!isNotificationOpened)
              }}>
                {isNotificationOpened &&   <img src={whiteBellIcon} alt="whitebell"/>}
                {!isNotificationOpened &&   <img src={bellIcon} alt="bell"/>}
              </div> */}
              {isRedeemEnabled && (
                <ComponentButton
                  title={"Redeem"}
                  disabled={!user?.customerId}
                  onClick={() => {
                    setIsInvestmentFormOpened(true);
                  }}
                  style={{
                    marginRight: "10px",
                    height: "52.5px",
                    borderRadius: "5px",
                  }}
                />
              )}

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            <div style={{ marginTop: "30px", display: "flex" }}>
              <div style={{ width: "32%" }}>
                <div className="title-heading">
                  {translate("Basic Information")}
                </div>
                <div className="profile-section">
                  <div className="profile-initial-section">
                    <p>{getInitials()}</p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h6>
                      {user?.firstName}{" "}
                      {user?.middleName && <span>{user?.middleName}</span>}{" "}
                      {user?.lastName && <span>{user?.lastName}</span>}
                    </h6>
                    <EditIcon
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                        color: "#000",
                      }}
                      onClick={() => {
                        setIsOpenEditModal(true);
                      }}
                    />

                    {/* <p style={{ cursor: "pointer" }}>Add profile picture</p> */}
                  </div>
                </div>
                <div className="social-contact">
                  <div className="contact-social">
                    <img src={PhoneVector} />
                    <span>{user?.phone}</span>
                  </div>

                  <div className="contact-social">
                    <img src={MailVector} />
                    <span>{user?.email}</span>
                  </div>
                </div>

                <div className="divider-line"></div>

                <div className="security">
                  <h5>{translate("Account Verification Status")}</h5>
                  <div
                    className="security-vector"
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, rgba(0, 118, 227, 0.4), #fff)",
                      marginTop: "10px",
                    }}
                  >
                    <CheckCircleIcon style={{ color: "#0076e3" }} />
                    {/* <img src={HashKeyVector} alt="hash-key" /> */}
                    <div
                      className="security-title"
                      style={{ flex: "1 1 auto" }}
                    >
                      <p style={{ color: "#0076e3", fontWeight: "700" }}>
                        {translate("General Account")}
                      </p>
                      {/* <span style={{ color: STATUS_TEXT["approved"]?.color }}>
                    Verified
                  </span> */}
                    </div>
                  </div>
                  <div
                    className="security-vector"
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, rgba(255, 30, 84, 0.4), #fff)",
                      marginTop: "10px",
                    }}
                  >
                    <div className="security-title" style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        {formStatus === "download" && (
                          <CheckCircleIcon style={{ color: "#ff1e54" }} />
                        )}
                        {formStatus === "waiting" && (
                          <InfoIcon style={{ color: "#ff1e54" }} />
                        )}
                        {(formStatus === "complete" ||
                          formStatus === "w8BenFormComplete" ||
                          formStatus === "videoFormComplete") && (
                          <QuestionIcon style={{ color: "#ff1e54" }} />
                        )}
                        <p
                          style={{
                            color: "#ff1e54",
                            fontWeight: "700",
                            flex: "1 1 auto",
                          }}
                        >
                          {translate("Securities Account")}
                        </p>
                        {(formStatus === "waiting" ||
                          formStatus === "w8BenFormComplete" ||
                          formStatus === "videoFormComplete") && (
                          <CloseIcon
                            style={{
                              color: "#ff1e54",
                              cursor: "pointer",
                              fontSize: "12px",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              setIsCancelModalOpened(true);
                            }}
                          />
                        )}
                      </div>

                      {/* {formStatus === "download" && (
                   

                    <span
                      style={{
                        cursor: "pointer",
                        color: STATUS_TEXT["approved"]?.color,
                      }}
                    >
                      Verified
                    </span>
                   
                  )} */}
                      {formStatus === "complete" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#ff1e54",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            setIsSelectUserOpened(true);
                          }}
                        >
                          {translate("Get verified")}
                        </span>
                      )}
                      {formStatus === "w8BenFormComplete" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#ff1e54",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            navigate(
                              `${SECURITY_ACCOUNT_URL}/${W8BEN_FROM_ID}?formUrl=https://alt.jotfor.ms/${W8BEN_FROM_ID}?uniqueId=${user?.securityForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&1name=${user?.securityForm?.rawRequest?.q88_name86}&address=${user?.securityForm?.rawRequest?.q211_typeA211}&3Permanent43=${user?.securityForm?.rawRequest?.residentialAddress227}&4Mailing=${user?.securityForm?.rawRequest?.correspondenceAddress229}&8Date=${user?.securityForm?.rawRequest?.dateof}&6aForeign=${user?.securityForm?.rawRequest?.typeA121}&Email=${user?.email}`
                            );
                          }}
                        >
                          Resume Application
                        </span>
                      )}
                      {formStatus === "videoFormComplete" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#ff1e54",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            navigate(
                              `${SECURITY_ACCOUNT_URL}/${VIDEO_VERIFICATION_FORM_ID}?formUrl=https://alt.jotfor.ms/${VIDEO_VERIFICATION_FORM_ID}?uniqueId=${user?.w8BenForm?.submissionID}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&email=${user?.email}`
                            );
                          }}
                        >
                          Resume Application
                        </span>
                      )}
                      {formStatus === "waiting" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#ff1e54",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            setIsSecurityStatusModalOpened(true);
                          }}
                        >
                          {translate("Waiting on approval")}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="security-vector"
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, rgba(139, 66, 140, 0.4), #fff)",
                      marginTop: "10px",
                    }}
                  >
                    <div className="security-title">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        {piFormStatus === "download" && (
                          <CheckCircleIcon style={{ color: "#8b428c" }} />
                        )}
                        {piFormStatus === "waiting" && (
                          <InfoIcon style={{ color: "#8b428c" }} />
                        )}
                        {piFormStatus === "complete" && (
                          <QuestionIcon style={{ color: "#8b428c" }} />
                        )}
                        <p style={{ color: "#8b428c", fontWeight: "700" }}>
                          {translate("PI Account")}
                        </p>
                      </div>

                      {/* {formStatus === "download" && (
                   

                    <span
                      style={{
                        cursor: "pointer",
                        color: STATUS_TEXT["approved"]?.color,
                      }}
                    >
                      Verified
                    </span>
                   
                  )} */}
                      {piFormStatus === "complete" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#8b428c",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            // setIsSelectUserOpened(true);
                            navigate(
                              `${SECURITY_ACCOUNT_URL}/${PI_FORM_ID}?formUrl=https://alt.jotfor.ms/${PI_FORM_ID}?Email=${user?.email}&environment=${process.env.REACT_APP_ENV}&customerid=${user?.customerId}&baseurl=${window.location.origin}&email79=${user?.email}`
                            );
                          }}
                        >
                          {translate("Get verified")}
                        </span>
                      )}
                      {piFormStatus === "waiting" && (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#8b428c",
                            marginLeft: "32px",
                          }}
                          onClick={() => {
                            setPIStatusModalOpened(true);
                          }}
                        >
                          {translate("Waiting on approval")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  width: "68%",
                  flexDirection: "column",
                }}
              >
                <div className="title-heading">{translate("Payment Info")}</div>
                <div
                  className="security"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
                    padding: "20px 30px",
                    display: "flex",
                    flex: "1 1 auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                    }}
                  >
                    <div
                      className="security-vector"
                      style={{
                        height: "unset",
                        padding: "0px",
                        alignItems: "unset",
                      }}
                    >
                      <div className="security-title">
                        <span style={{ color: "#A6A6A6" }}>
                          {translate("Bank Account Number")}
                        </span>
                        <p style={{ marginBottom: "10px" }}>849-1-010057-01</p>

                        <span style={{ color: "#A6A6A6" }}>
                          {translate("Account Name")}
                        </span>
                        <p style={{ marginBottom: "10px" }}>
                          DL SECURITIES (HK) LTD – CLIENT’S A/C
                        </p>

                        <div
                          style={{
                            borderBottom: "1px solid #A6A6A6",
                            marginTop: "15px",
                            marginBottom: "15px",
                            marginRight: "22px",
                          }}
                        ></div>

                        <span style={{ color: "#A6A6A6" }}>
                          {translate("Bank Name")}
                        </span>
                        <p style={{ marginBottom: "10px" }}>
                          {translate("Bank of Communications")}
                        </p>

                        <span style={{ color: "#A6A6A6" }}>
                          {translate("Bank Code")}
                        </span>
                        <p style={{ marginBottom: "10px" }}>382</p>

                        <span style={{ color: "#A6A6A6" }}>
                          {translate("Remittance Code")}
                        </span>
                        <p style={{ marginBottom: "10px" }}>
                          COMMHKHK:DL SECURITIES (HK) LTD – CLIENT’S A/C
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "20%" }}>
                    <h5>{translate("Payment Proof")}</h5>
                    <div
                      className="security-vector"
                      style={{
                        marginTop: "10px",
                        paddingLeft: "0px",
                        height: "unset",
                      }}
                    >
                      <img src={HashKeyVector} alt="hash-key" />
                      <div className="security-title">
                        <p>{translate("View Payment Proofs")}</p>

                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#ff1e54",
                          }}
                          onClick={() => {
                            setIsPaymentProofDialogOpened(true);
                          }}
                        >
                          {translate("view")}
                        </span>
                      </div>
                    </div>

                    <div
                      className="security-vector"
                      style={{ marginTop: "20px" }}
                    >
                      <ComponentButton
                        title={translate("Upload Proof")}
                        iconRight={<ChevronRightIcon w="24" h="24" />}
                        onClick={() => {
                          setIsUploadModalOpened(true);
                          // navigate(SIGN_UP_URL);
                        }}
                        style={{ height: "48px", fontSize: "14px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {formStatus === "download" && (
            <>
              {/* {!afeLoginData && (
                <div style={{ marginTop: "50px" }}>
                  <AFELogin
                    afeLoginData={afeLoginData}
                    setAfeLoginData={setAfeLoginData}
                    socket={socket}
                    setSocket={setSocket}
                    AtDate={AtDate}
                    setCurrentAssetValue={setCurrentAssetValue}
                    setCashHoldingPct={setCashHoldingPct}
                    setStockHoldingPct={setStockHoldingPct}
                    setPortfolioSummary={setPortfolioSummary}
                    type="account"
                  />
                </div>M
              )} */}
              {user?.clientId?.length && (
                <>
                <div style={{marginTop:"30px", display:"flex", alignItems:"center"}}>
                <div className="title-heading" style={{marginBottom:"0px", marginRight:"20px"}}>Client ID</div>
                <div>
                <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              color: "#fff",
                              borderColor: "transparent",
                              borderBottom: "1px solid rgb(170, 170, 170)",
                              borderRadius: "0px"
                            }),
                            option: (
                              baseStyles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "rgb(0, 127, 255)"
                                : "rgb(37, 37, 37)",
                              color: "#fff",
                            }),
                          }}
                          placeholder={translate("Categories")}
                          className="investment-input-dropdown"
                        value={{label: selectedClientId, value: selectedClientId}}
                        options={clientIds}
                        // isClearable={true}
                        onChange={(value: any) => {
                          setSelectedClientId(value?.value);
                        }}
                        />
                </div>
                </div>
                
                  <div className="chart-data">
                    <div className="chart-pie">
                      <ComponentBlackCard
                        children={
                          <>
                            <h5
                              style={{
                                marginBottom: "30px",
                                color: "#0752A0",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              className="black-card-head"
                            >
                              {translate("Portfolio Allocation (as of today)")}
                            </h5>
                            {/* {pieChartData?.series?.length &&   <ReactApexChart
                       options={pieChartData}
                       series={pieChartData.series}
                       type="donut"
                       height={300}
                     />} */}
                            <PieChart
                              data={[
                                {
                                  title: "One",
                                  value: cashHoldingPct,
                                  color: "#9461B3",
                                },
                                {
                                  title: "Two",
                                  value: stockHoldingPct,
                                  color: "#0076e3",
                                },
                                // { title: "Three", value: 8, color: "#8b428c" },
                                // { title: "Three", value: 5, color: "#e62762" },
                              ]}
                              lineWidth={30}
                              style={{ height: "270px" }}
                              label={({ dataEntry }) =>
                                Math.round(dataEntry.percentage) + "%"
                              }
                              labelStyle={defaultLabelStyle}
                              labelPosition={100 - 15}
                            />

                            <div className="pie-chart-comntainer">
                              <div className="pie-chart-legend">
                                <span
                                  style={{ backgroundColor: "#0076e3" }}
                                  className="pie-chart-legend-color"
                                ></span>
                                {translate("Stock Holdings")}
                              </div>
                              <div className="pie-chart-legend">
                                <span
                                  style={{ backgroundColor: "#9461B3" }}
                                  className="pie-chart-legend-color"
                                ></span>
                                {translate("Cash Holdings")}
                              </div>

                              {/* <div className="pie-chart-legend">
                            <span
                              style={{ backgroundColor: "#8b428c" }}
                              className="pie-chart-legend-color"
                            ></span>
                            Alternative
                          </div>
                          <div className="pie-chart-legend">
                            <span
                              style={{ backgroundColor: "#e62762" }}
                              className="pie-chart-legend-color"
                            ></span>
                            Others
                          </div> */}
                            </div>
                          </>
                        }
                        style={{
                          backgroundColor: "rgb(240, 243, 250)",
                          backgroundImage:
                            "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
                          borderRadius: "0px",
                        }}
                      />
                    </div>
                    <div className="chart-line">
                      <ComponentBlackCard
                        children={
                          <>
                            <h5
                              style={{
                                marginBottom: "10px",
                                color: "#0752A0",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              className="black-card-head"
                            >
                              {translate("Net Asset Value ($)")} (
                              {translate("as of today")})
                            </h5>
                            <div>
                              <div
                                style={{
                                  marginTop: "10px",
                                  fontSize: "18px",
                                  color: "#0752A0",
                                  marginBottom: "30px",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                <span style={{ color: "#afafaf" }}>
                                  HK$
                                </span>{" "}
                                {formatNumber(currentAssetValue)}
                              </div>
                              {lineChartData?.series?.[0]?.data?.length && (
                                <ReactApexChart
                                  options={lineChartData.options}
                                  series={lineChartData.series}
                                  type="area"
                                  height={300}
                                />
                              )}
                            </div>
                          </>
                        }
                        style={{ backgroundColor: "#fff", borderRadius: "0px" }}
                      />
                    </div>
                  </div>
                  <div
                    className="chart-data"
                    style={{ width: "100%", paddingTop: "30px" }}
                  >
                    <ComponentBlackCard
                      children={
                        <>
                          <h5
                            style={{
                              marginBottom: "20px",
                              color: "#0752A0",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            className="black-card-head"
                          >
                            {translate("Portfolio Summary")}
                          </h5>
                          <div>
                            <ChakraProvider>
                              <TableContainer>
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      {portfolioData?.headers?.map(
                                        (data: any, index: number) => {
                                          return (
                                            <Th className="table-header-summary-text">
                                              {data}
                                            </Th>
                                          );
                                        }
                                      )}
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {portfolioSummary?.map(
                                      (data: any, index: number) => {
                                        return (
                                          <Tr>
                                            {portfolioData?.headers?.map(
                                              (header: string) => {
                                                return (
                                                  <Td className="portfolio-table-summary-title">
                                                    {getNumberValue(
                                                      header,
                                                      data?.[header]
                                                    )}
                                                  </Td>
                                                );
                                              }
                                            )}
                                          </Tr>
                                        );
                                      }
                                    )}
                                    {/* <Tr>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title">Cash</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">HKD</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">816.25</Td>
                                <Td className="portfolio-table-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title">Cash</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title">USD</Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title">1129.01</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title"></Td>
                              </Tr>
                              <Tr>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title"></Td>
                                <Td className="portfolio-table-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>HKD</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>850533.28</Td>
                                <Td className="portfolio-table-title portfolio-table-bold-title" style={{ fontWeight: "600", borderBottom: "1px solid #fff" }}>52936.80</Td>

                              </Tr> */}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </ChakraProvider>
                          </div>
                        </>
                      }
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(240, 243, 250)",
                        backgroundImage:
                          "linear-gradient(to right, #f9f9f9 , #FAFBFD)",
                        borderRadius: "0px",
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div style={{ marginTop: "30px" }}>
            <ComponentButton
              title={translate("Sign Out")}
              iconRight={<ChevronRightIcon w="24" h="24" />}
              onClick={() => {
                Logout({ variables: {} });
                // navigate(SIGN_UP_URL);
              }}
            />
          </div>
        </div>
        {isOpenEditModal && (
          <EditUserModal
            isOpen={isOpenEditModal}
            onClose={() => {
              setIsOpenEditModal(false);
            }}
            onUpdateUser={onUpdateUser}
            userData={user}
          />
        )}
        {/* {isStatusModalOpened && <SecurityFormStatusModal user={user} open={isStatusModalOpened} onClose={() => {
        setIsStatusModalOpened(false)
      }} hideSecurityForm/>} */}
        {isCancelModalOpened && (
          <CancelApplicationModal
            user={user}
            open={isCancelModalOpened}
            onClose={() => {
              setIsCancelModalOpened(false);
            }}
          />
        )}
        {isSecurityStatusModalOpened && (
          <SecurityFormStatusModal
            user={user}
            open={isSecurityStatusModalOpened}
            onClose={() => {
              setIsSecurityStatusModalOpened(false);
            }}
          />
        )}
        {isPIStatusModalOpened && (
          <PIFormStatusModal
            user={user}
            open={isPIStatusModalOpened}
            onClose={() => {
              setPIStatusModalOpened(false);
            }}
          />
        )}
        {isSelectUserOpened && (
          <SelectUserModal
            open={isSelectUserOpened}
            onClose={() => {
              setIsSelectUserOpened(false);
            }}
            user={user}
          />
        )}
        {isPaymentProofDialogOpened && (
          <PaymentProofsModal
            open={isPaymentProofDialogOpened}
            onClose={() => {
              setIsPaymentProofDialogOpened(false);
            }}
            filledFormList={filledFormList}
            user={user}
          />
        )}
        {isUploadModalOpened && (
          <UploadNewPaymentProof
            open={isUploadModalOpened}
            onClose={() => {
              setIsUploadModalOpened(false);
            }}
            productObj={productObj}
            user={user}
          />
        )}
        {isInvestmentFormOpened && (
          <InvestmentForm
            user={user}
            open={isInvestmentFormOpened}
            onClose={() => {
              setIsInvestmentFormOpened(false);
            }}
            type={"redem"}
            fundId={""}
            formId={241982218754060}
            title="Redeem"
          />
        )}
      </div>
    </DashboardContainer>
  );
};

export default Account;