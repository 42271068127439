import { Button } from '@chakra-ui/react'
import './PreLanding.scss'

import prelandingImage from '../../../assets/pre-landing1.png'
import { ChevronDownIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import PreVirtualAssistantModal from './PreVirtualAssistantModal'

const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${prelandingImage})`,
}


const PreLanding = () => {

    const [isModalOpened, setIsModalOpened] = useState(false)
    return <div style={backgroundStyle} className='pre-landing-main-container'>
        <div className='pre-landing-content-container'>
            {!isModalOpened && <>
                <div className='pre-landing-text-container'>
            <div className='welcome-text'>Welcome to the </div>
            <div className='future-text'>Future</div>
            <div className='wealth-management-text'>of Wealth Management</div>
            <div className='your-own-text'>Your Own AI Family Office Awaits</div>
            <div className='pre-landing-button-container'>
            <Button className='pre-landing-button' onClick={() => {
            setIsModalOpened(true)
        }}>Discover the Future</Button>
            </div>
           
        </div>
        <div  style={{display:"flex", width:"100%"}}>
        <div style={{display:"flex", flex:"1 1 auto", justifyContent:"center", marginLeft:"50px"}}>
        <div className='pre-landing-page-down-container' style={{}} onClick={() => {
            setIsModalOpened(true)
        }}>

            <ChevronDownIcon />

        </div>
      
        </div>
        <div style={{    marginRight: "18px", color:"rgba(131, 131, 131, 1)", fontWeight:
    "bold", fontSize:"14px", marginTop:"5px", cursor:"pointer", textAlign:"center"}}  onClick={() => {
        window.location.href = 'https://dlifo-v2.webflow.io/';
      }}><ArrowForwardIcon/> <br/>Skip</div>
        </div>
      
        
            </>}
         
      
       
        </div>
        {isModalOpened && <PreVirtualAssistantModal open={isModalOpened} onClose={() => {
            setIsModalOpened(false)
        }}/>}
        
    </div>
}


export default PreLanding