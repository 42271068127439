import { useNavigate } from "react-router-dom";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentCheckbox from "../../atoms/ComponentCheckbox";
import MainContainer from "../../sub-components/MainContainer";
import "./InvestorStatus.scss";
import { LOGIN_URL, SIGN_UP_LEGAL_AGGREMENT__URL } from "../../routes";
import ComponentProgressBar from "../../atoms/ComponentProgressBar";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_USER } from "../../graphql/user.query";
import { UPDATE_USER_MUTATION } from "../../graphql/user.mutation";
import { useToast } from "@chakra-ui/react";
import { toast } from "react-toastify";

import translate from "../../../translate";

const dummyData = [
  {
    name: "",
    conditions: [
      {
        termName: translate("investor_status1"),
      },
      {
        termName: translate("investor_status2"),
      },
      {
        termName: translate("investor_status3"),
      },
      {
        termName: translate("investor_status4"),
      },
    ],
  },
];

const InvestorStatus = () => {
  const navigate = useNavigate();

  const [selectedInvestorStatus, setSelectedInvestorStatus] = useState<any>([]);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.accreditedInvestor?.length) {
        setSelectedInvestorStatus(data?.user?.accreditedInvestor);
      }
    },
    onError: (err) => {
      console.log("Error in", err.graphQLErrors[0]?.message);
      localStorage.clear();
      window.location.href = LOGIN_URL;
    },

    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const [UpdateUser, { data, loading, error }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        navigate(SIGN_UP_LEGAL_AGGREMENT__URL);
      },
      onError: (err) => {
        console.log("Error in", err.graphQLErrors[0]?.message);

        toast.error(err.graphQLErrors[0]?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 1,
          theme: "dark",
        });
        // toast({
        //   title: err.graphQLErrors[0]?.message,
        //   status: "error",
        //   isClosable: true,
        // });
      },
    }
  );

  const onClickEvent = () => {
    if(selectedInvestorStatus?.length && selectedInvestorStatus?.indexOf( translate("investor_status4")) === -1){
      UpdateUser({
        variables: {
          input: {
            accreditedInvestor: selectedInvestorStatus,
  
            onboardStep: 3,
          },
        },
      });
    }
  
  };

  return (
    <div className="investor-status">
      <MainContainer>
        <div
        
          className="signup-investor-container"
        >
          <div className="sign-up-investor">
            <h2>{translate("what_is_investor_status")}</h2>
            <p>
            {translate("what_is_investor_status_subtext")}
            </p>
            <div className="investor-type">
              <p style={{ cursor: "pointer", fontWeight: "500" }}>
                {translate("select_all_apply")}
              </p>
            </div>
            <div className="sign-up-component">
              <ComponentButton
                title= {translate("next")}
                whiteBgBtn={true}
                onClick={onClickEvent}
                isDisabled={selectedInvestorStatus?.indexOf( translate("investor_status4")) !== -1 || !selectedInvestorStatus?.length}
                style={{opacity: selectedInvestorStatus?.indexOf( translate("investor_status4")) !== -1 || !selectedInvestorStatus?.length ? "0.5": "1"}}
              />
            </div>
          </div>
          <div className="checkbox-data">
            {dummyData.map((items) => {
              return (
                <div style={{ paddingBottom: "40px" }}>
                  <h6
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      margin: "0",
                      paddingBottom: "12px",
                    }}
                  >
                    {items.name}
                  </h6>
                  {items.conditions.map((subItem) => {
                    return (
                      <div style={{ paddingBottom: "12px" }}>
                        <ComponentCheckbox
                          title={subItem.termName}
                          isChecked={
                            selectedInvestorStatus.indexOf(subItem.termName) !==
                            -1
                          }
                          onHandleChange={(checked: boolean) => {
                            if (checked) {
                              selectedInvestorStatus.push(subItem.termName);
                            } else {
                              const index = selectedInvestorStatus.indexOf(
                                subItem.termName
                              );
                              selectedInvestorStatus.splice(index, 1);
                            }
                            setSelectedInvestorStatus([
                              ...selectedInvestorStatus,
                            ]);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </MainContainer>
      <ComponentProgressBar defaultValue={3} />
    </div>
  );
};

export default InvestorStatus;
