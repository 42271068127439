import React, { useEffect, useState } from "react";
import { useRouter } from "../../routes";
import DashboardContainer from "../../sub-components/DashbordContainer";

const IframeComponent: React.FC = () => {
  const router = useRouter();
  const formId = router?.query?.formId;

  useEffect(() => {
    // setTimeout(() => {
    const loadIframeScript = () => {
      const ifr = document.getElementById(
        `JotFormIFrame-${formId}`
      ) as HTMLIFrameElement;

      if (ifr) {
        let src = ifr.src;
        let iframeParams: string[] = [];
        if (window.location.href && window.location.href.indexOf("?") > -1) {
          iframeParams = iframeParams.concat(
            window.location.href
              .substr(window.location.href.indexOf("?") + 1)
              .split("&")
          );
        }
        if (src && src.indexOf("?") > -1) {
          iframeParams = iframeParams.concat(
            src.substr(src.indexOf("?") + 1).split("&")
          );
          src = src.substr(0, src.indexOf("?"));
        }
        iframeParams.push("isIframeEmbed=1");
        ifr.src = src + "?" + iframeParams.join("&");
      }
    };

    const handleIFrameMessage = async (e: MessageEvent) => {
      if (typeof e.data === "object") {
        return;
      }
      const args = e.data.split(":");
      let iframe: HTMLIFrameElement | any;
      if (args.length > 2) {
        iframe = document.getElementById(
          "JotFormIFrame-" + args[args.length - 1]
        ) as HTMLIFrameElement;
      } else {
        iframe = document.getElementById("JotFormIFrame") as HTMLIFrameElement;
      }
      if (!iframe) {
        return;
      }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          const src = args[1];
          const script = document.createElement("script");
          script.src = src;
          script.type = "text/javascript";
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if (window.document.exitFullscreen) window.document.exitFullscreen();
          else if ((window.document as any).mozCancelFullScreen)
            (window.document as any).mozCancelFullScreen();
          // else if (await window.document.exitFullscreen()) window.document.webkitExitFullscreen();
          else if ((window.document as any).msExitFullscreen)
            (window.document as any).msExitFullscreen();
          break;
        default:
          break;
      }

      const isJotForm = e.origin.includes("jotform");
      if (isJotForm) {
        const iframe = document.getElementById(
          `JotFormIFrame-${formId}`
        ) as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
          const urls = {
            docurl: encodeURIComponent(document.URL),
            referrer: encodeURIComponent(document.referrer),
          };

          iframe.contentWindow.postMessage(
            JSON.stringify({ type: "urls", value: urls }),
            "*"
          );
        }
      }
    };

    loadIframeScript();
    window.addEventListener("message", handleIFrameMessage, false);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleIFrameMessage, false);
    };
    // }, 20000);

    // window.addEventListener("message", handleIFrameMessage, false);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         window.removeEventListener("message", handleIFrameMessage, false);
    //     }
  }, []);

  const formUrl: any = router?.query?.formUrl;
  console.log("document.URL", document.URL);

  return (
    <DashboardContainer>
      <div style={{ width: "100%", height: "100vh" }}>
        <iframe
          id={`JotFormIFrame-${formId}`}
          title="JotFormIFrame"
          allowTransparency={true}
          allowFullScreen={true}
          allow="geolocation; microphone; camera"
          src={formUrl}
          style={{ border: "none" }}
          className="security-form-container"
          sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-popups"
        >
          {" "}
        </iframe>
      </div>
    </DashboardContainer>
  );
};

export default IframeComponent;
