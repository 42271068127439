import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
} from "@chakra-ui/react";
import ComponentButton from "../../atoms/ComponentButton";
import DashboardContainer from "../../sub-components/DashbordContainer";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  FO_SERVICES_PRODUCT_COUNTRY_URL,
  FO_SERVICES_PRODUCT_URL,
  FO_SERVICES_URL,
  useRouter,
} from "../../routes";
import ComponentFoCard from "../../atoms/ComponentFoCards";
import ComponentBlackCard from "../../atoms/ComponentBlackCard";
import "../FoServicesProductCountry/FoServiceProductCountry.scss";
import ProjectVector from "../../../assets/project-vector.svg";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/user.query";
import translate from "../../../translate";

const availableTrustTypes = [
  {
    id: "Discretionary Trust",
    name: translate("Discretionary Trust"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate(
        "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan"
      ),
      powerHeld: translate(
        "Powers to manage the trust assets and to make distributions to beneficiaries are all held by the trustee to excercise at its discretion"
      ),
      assetsInjected: translate(
        "Diversified investment portfolios & low risk investment products"
      ),
    },
  },
  {
    id: "Reserved Powers Trust",
    name: translate("Reserved Powers Trust"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate(
        "Hong Kong,British Virgin Islands, Singapore, Cayman Islands, Labuan"
      ),
      powerHeld: translate(
        "Settlor reserves and retains control over certain powers, and most commonly seen is the power to make investment decisions over the trust assets, He or she can also appoint an investment manager"
      ),
      assetsInjected: translate(
        "Low to high risk investment products, concentrated holding investments & operating companies"
      ),
    },
  },
  {
    id: "Special Provisions Trust",
    name: translate("Special Provisions Trust"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate(
        "BVI VISTA Trust Cayman STAR Trust Labuan Special Trust"
      ),
      powerHeld: translate(
        "The company directors (who can be the settlor of the trust or appointed by the settlor) remain entirely responsible for managing the BVI Holdco and all the trust assets held beneath it"
      ),
      assetsInjected: translate(
        "Low to high risk investment products, concentrated holding investments & operating companies"
      ),
    },
  },
  {
    id: "Private Trust Company (PTC) ",
    name: translate("Private Trust Company (PTC)"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate(
        "British Virgin Islands, Cayman Islands"
      ),
      powerHeld: translate(
        "Stay involved with management of family trust assets as a director of the PTC"
      ),
      assetsInjected: translate(
        "Low to high risk investment products, concentrated holding investments & operating companies"
      ),
    },
  },
  {
    id: "Discretionary Trust with Special Trust Advisor",
    name: translate("Discretionary Trust with Special Trust Advisor"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate("New Zealand"),
      powerHeld: translate(
        "Similar to a normal discretionary trust, but a Special Trust Advisor can be appointed to guide the trustee on investment decisions over the trust assets"
      ),
      assetsInjected: translate(
        "Low to high risk investment products, concentrated holding investments & operating companies"
      ),
    },
  },
  {
    id: "Limited Partnership Trust",
    name: translate("Limited Partnership Trust"),
    content: {
      legalSystem: translate("Based on English Common Law"),
      properLawsJurisdictions: translate("Labuan"),
      powerHeld: translate(
        "Stay involved with management of family trust assets as a director of the General Partner of the Limited Partnership"
      ),
      assetsInjected: translate(
        "Low to high risk investment products, concentrated holding investments & operating companies"
      ),
    },
  },
];

const TrustPlanning = () => {
  const navigate = useNavigate();
  const router = useRouter();
  const { pathname, query } = router;
  const selectedCountry = query?.id?.toString();
  const [user, setUser] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(availableTrustTypes[0]);
  const [secondSelectedItem, setSecondSelectedItem] = useState<any>(
    availableTrustTypes[1]
  );

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      if (data?.user?.firstName) {
        setUser(data?.user);
      }
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser({ variables: {} });
  }, []);

  const getBorderColor = (index: number) => {
    if (index === 0) {
      return "#00173f";
    }
    if (index === 1) {
      return "#0076e3";
    }
    if (index === 2) {
      return "#8b428c";
    }
    if (index === 3) {
      return "#e62762";
    }
  };
  return (
    <DashboardContainer>
      <div className="account-page" style={{backgroundImage:"unset"}}>
        <div className="account-section">
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: "1 1 auto" }}>
                {/* <div className="title-small-heading">PRODUCTS</div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "28px",
                  }}
                >
                  <ArrowBackIcon
                    w={28}
                    h={28}
                    style={{ cursor: "pointer", color: "#8c8c8c" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <h3
                    className="gradient-heading"
                    style={{ marginBottom: "0px" }}
                  >
                    {translate("Trust Planning")}
                  </h3>
                </div>
              </div>

              <a href="mailto:assetmanagement@dl-holdings.com" target="__blank">
                <ComponentButton
                  title={translate("TALK TO AN EXPERT")}
                  whiteBgBtn={true}
                />
              </a>
            </div>

            <div style={{ paddingTop: "30px" }}>
              <h4 style={{ color: "#8c8c8c", fontSize: "20px" }}>
                {translate("Available Trust Types")}{" "}
              </h4>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <section
                  style={{ flex: "1 1", color: "#000" }}
                  className="accordion accordion--radio"
                >
                  {availableTrustTypes?.map((item: any, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedItem(item);
                        }}
                        className="service-product-container tab"
                        style={{
                          backgroundColor:
                            selectedItem?.name === item?.name
                              ? "#f0f3fa"
                              : "#fff",
                          borderColor:
                            selectedItem?.name === item?.name
                              ? getBorderColor(index)
                              : "transparent",
                        }}
                      >
                        <input
                          type="radio"
                          name="accordion-2"
                          id={item?.name}
                          checked={item?.name === selectedItem?.name}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "1 1 auto" }}>
                            <label
                              htmlFor={item?.name}
                              className="tab__label"
                              style={{
                                margin: "0px",
                                fontSize:
                                  selectedItem?.name === item?.name
                                    ? "24px"
                                    : "20px",
                              }}
                            >
                              {item?.name}
                            </label>
                          </div>
                          {!item?.comingSoon && (
                            <div
                              onClick={() => {
                                if (item?.link) {
                                  navigate(item?.link);
                                }
                              }}
                            >
                              <ChevronRightIcon
                                style={{
                                  color: "#8c8c8c",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="tab__content"
                          style={{
                            fontSize: "14px",
                            color: "#777",
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </section>

                <div
                  style={{ flex: "1 1", color: "#000", marginLeft: "100px" }}
                >
                  <div className="trust-detail-card">
                    <div>
                      <h5 className="black-card-head">
                        {translate("Legal System")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{selectedItem?.content?.legalSystem}</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate("Proper Laws Jurisdictions")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{selectedItem?.content?.properLawsJurisdictions}</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate(
                          "Powers held by the Settlor/person designated by the Settlor/Trustee"
                        )}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{selectedItem?.content?.powerHeld}</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate("Assets Injected")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{selectedItem?.content?.assetsInjected}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "30px" }}>
              <h4 style={{ color: "#8c8c8c", fontSize: "20px" }}>
                {translate("Comparison of trust Jurisdictions")}
              </h4>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <section
                  style={{ flex: "1 1", color: "#000" }}
                  className="accordion accordion--radio"
                >
                  {availableTrustTypes?.map((item: any, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSecondSelectedItem(item);
                        }}
                        className="service-product-container tab"
                        style={{
                          backgroundColor:
                            secondSelectedItem?.name === item?.name
                              ? "#f0f3fa"
                              : "#fff",
                          borderColor:
                            secondSelectedItem?.name === item?.name
                              ? getBorderColor(index)
                              : "transparent",
                        }}
                      >
                        <input
                          type="radio"
                          name="accordion-2"
                          id={`${item?.name}2`}
                          checked={item?.name === secondSelectedItem?.name}
                        />

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "1 1 auto" }}>
                            <label
                              htmlFor={`${item?.name}2`}
                              className="tab__label"
                              style={{
                                margin: "0px",
                                fontSize:
                                  secondSelectedItem?.name === item?.name
                                    ? "24px"
                                    : "20px",
                              }}
                            >
                              {" "}
                              {item?.name}
                            </label>
                          </div>
                          {!item?.comingSoon && (
                            <div
                              onClick={() => {
                                if (item?.link) {
                                  navigate(item?.link);
                                }
                              }}
                            >
                              <ChevronRightIcon
                                style={{
                                  color: "#8c8c8c",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="tab__content"
                          style={{
                            fontSize: "14px",
                            color: "#777",
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </section>

                <div
                  style={{ flex: "1 1", color: "#000", marginLeft: "100px" }}
                >
                  <div className="trust-detail-card">
                    <div>
                      <h5 className="black-card-head">
                        {translate("Legal System")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{secondSelectedItem?.content?.legalSystem}</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate("Proper Laws Jurisdictions")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>
                          {secondSelectedItem?.content?.properLawsJurisdictions}
                        </p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate(
                          "Powers held by the Settlor/person designated by the Settlor/Trustee"
                        )}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{secondSelectedItem?.content?.powerHeld}</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "25px" }}>
                      <h5 className="black-card-head">
                        {translate("Assets Injected")}
                      </h5>
                      <div className="card-data" style={{ paddingTop: "10px" }}>
                        <p>{secondSelectedItem?.content?.assetsInjected}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default TrustPlanning;
